

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_CLASSROOM_FILTER_ITEM_CLICK } from "../types";

// Actions




export const handleClassroomItemClick = ({
  classroomItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_CLASSROOM_FILTER_ITEM_CLICK,
    payload: classroomItem
  });
}  




