import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import React, {useEffect} from "react";
import {Container, Grid, TableBody} from "@material-ui/core";
import {CustomTable} from "../../components/table/CustomTable";
import TableRow from "@mui/material/TableRow";
import {StyledTableCell, StyledTableRow} from "../../components/table/StyledTableComponents";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import {convertToMomentDateTime} from "../../helpers/moment/convertToMomentDateTime";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import {CustomPaginationV2} from "../../components/CustomPaginationV2";

export const Tickets = () => {
    const [tickets, setTickets] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);

    const fetchTickets = async (page) => {
        const response = await axios.get(urlConstants.getTickets, {
            params: {
                page
            },
        })
        setTickets(response.data.tickets);
        setCount(response.data.count);
    }

    useEffect(() => {
        fetchTickets(page);
    }, [page])
    
    
    return (
        <Container
            id='speaklabs-subscription-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Tickets</title></Helmet>
            <Grid
                container
                direction='column'
                // justify='space-between'
                style={{
                    // minHeight: clientAppConstants.screenContainerMinHeight
                }}
            >
                <ScreenTitle   titleText='Tickets'    />
                <CustomTable>
                    <TableHead id='manidar-mani-table-head'>
                        <TableRow>
                            <StyledTableCell size= 'small' align='left'>Event Title</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Online-Offline</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>User</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                <Tooltip title='Ticket or Reservation'>
                                    <span>Ticket</span>
                                </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Ticket Purchase Date</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Event Date</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Platform</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Payment</StyledTableCell>
                            <StyledTableCell size= 'small' align='right'>Status</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map(item => (
                            <StyledTableRow key={item._id}>
                                <StyledTableCell
                                    size='small'
                                >
                                    <Button
                                        variant="text"
                                        style={{
                                            textTransform: 'none',
                                            lineHeight: 1.2,
                                            // color:'teal',
                                            color: clientAppConstants.linkButtonTextColor,
                                        }}
                                        component={NavLink}
                                        to={'/event/view/' + item.eventId?._id}
                                    >
                                        {item.eventId?.eventTitle}
                                    </Button>
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                >
                                    {item.eventId?.isEventOnline ? 'Online' : 'Offline'}
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                >
                                    <Button
                                        variant="text"
                                        style={{
                                            textTransform: 'none',
                                            lineHeight: 1.2,
                                            // color:'teal',
                                            color: clientAppConstants.linkButtonTextColor,
                                        }}
                                        component={NavLink}
                                        to={'/user/edit/' + item.userId?._id}
                                    >
                                        {item.userId?.nameSurname ?? item.userId?.email}
                                    </Button>
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                    align='center'
                                >
                                    {item.isReservation && item.isCanceled ? 'Reservation'  : 'Ticket' }
                                    {item.isCanceled && "Canceled"}
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                >
                                    {convertToMomentDateTime(item.ticketPurchaseDate)}
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                >
                                    {convertToMomentDateTime(item.eventId?.eventDate)}
                                </StyledTableCell>

                                <StyledTableCell
                                    align='center'
                                    size='small'
                                >
                                    {
                                        // item.purchasePlatform.map(
                                        //   (platformItem, ind ) => {
                                        //     let _color;
                                        //     switch (platformItem) {
                                        //       case 'paypal':
                                        //         _color = "primary";
                                        //         break;
                                        //       case 'admin-panel':
                                        //         _color = "success";
                                        //         break;
                                        //       case 'subscription':
                                        //         _color = "error";
                                        //         break;
                                        //       default:
                                        //         _color = 'error';

                                        //     }
                                        //     return (
                                        //       <Chip className='speaklabs-activity-platform-chip' label={platformItem} color={_color} />
                                        //     )
                                        //   }
                                        // )

                                        (( ) => {
                                            let purchasePlatform =  item.purchasePlatform;
                                            let _color;
                                            switch (purchasePlatform) {
                                                case 'paypal':
                                                    _color = "primary";
                                                    break;
                                                case 'admin-panel':
                                                    _color = "success";
                                                    break;
                                                case 'subscription':
                                                    _color = "error";
                                                    break;
                                                default:
                                                    _color = 'error';

                                            }
                                            return (
                                                <Chip className='speaklabs-activity-platform-chip' label={purchasePlatform} color={_color} />
                                            )
                                        })()
                                    }
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                    align='right'
                                >
                                    {item.ticketPrice ? ('$' + item.ticketPrice.toFixed(2) ) : '-'}
                                </StyledTableCell>

                                {/* Ticket Count */}
                                <StyledTableCell
                                    size='small'
                                    align='right'
                                >
                                    {item.ticketStatus}
                                </StyledTableCell>

                                <StyledTableCell
                                    size='small'
                                    align='center'
                                >
                                    {/* <EventItemTicketButton

        /> */}
                                    <IconButton component={ NavLink} to={'/ticket/edit/' + item._id}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </CustomTable>
                <CustomPaginationV2
                    currentPaginationIndex={page}
                    rowsPerPage={10}
                    count={Math.ceil(count / 10)}
                    handleOnChange={(event, newPage) => setPage(newPage)}
                />
            </Grid>
        </Container>
    );
}