import React from 'react'
import { useSelector, useDispatch } from "react-redux";




import {
  Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

// Constants
import { clientAppConstants } from '../constants';





export const CustomPagination = ({
  isScreenLoading,
  // itemCount,
  currentPaginationIndex,
  isPaginationLoading,
  handleOnChange,

}) => {
  // Global Variables
  const itemCount = useSelector(state => state.filterReducer.itemCount);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);

  const dispatch = useDispatch();

  // const _pageFilterCount = clientAppConstants.pagePaginationFilterCount;

  const usePaginationStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
      display: 'flex',
      justifyContent: 'center'
    },
  }));
  const paginationClasses = usePaginationStyles();

  return (
    <Grid item className='w-100'>
      {!isScreenLoading && itemCount !== 0 && (
        <div className={paginationClasses.root}>
          <Pagination
            count={
              itemCount % pageFilterCount > 0
                ? ((itemCount - itemCount % pageFilterCount) / pageFilterCount + 1)
                : itemCount / pageFilterCount
            }
            shape="rounded"
            page={currentPaginationIndex}
            onChange={handleOnChange}

          />
          {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
        </div>
      )
      }
    </Grid>
  )
}
