import React from "react";
import { connect } from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';


const AlertComponent = ({
  alerts
}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      // display: 'block',
      // position: 'absolute',
      position: 'fixed',
      zIndex: 50,
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      // marginTop: 64,  //NAVBAR
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {
        alerts.length > 0 
        && alerts.map(alert => (
            <Alert
              key={alert.id}
              variant='filled'
              severity= {
                (
                  alert.type === 'error'
                  || alert.type === 'danger'
                  || alert.type === 'warning'
                  || alert.type === 'info'
                  || alert.type === 'success'
                ) 
                  ? alert.type
                  : 'error'
              }
            >
              {alert.msg}
            </Alert>
          ))
      }
    </div>
  )
}




const mapStateToProps = (state) => ({
  alerts : state.alertReducer
});

export default connect(mapStateToProps )(AlertComponent);




