import {
  LANGUAGES_FETCHED,
  LANGUAGE_ADDED,
  LANGUAGE_ADD_STARTED,
  LANGUAGE_ADD_ERROR,
  LANGUAGE_UPDATED,
  LANGUAGE_DELETED
} from "../actions/types";


const initialState = {
  languagess: [],
  loading: true,
  isLanguagesFetched: false,
  isAddingLanguage: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LANGUAGES_FETCHED:      
      return {
        ...state,
        languages: [...payload],
        loading: false,
        isLanguagesFetched: true,
      };
    case LANGUAGE_ADD_STARTED:
      return {
        ...state,
        isAddingLanguage: true,
      };
    case LANGUAGE_ADDED:
      return {
        ...state,
        languages: [...state.languages, payload],
        loading: false,
        isAddingLanguage: false,
      };
    case LANGUAGE_ADD_ERROR:
      return {
        ...state,
        isAddingLanguage: false,
      };
    case LANGUAGE_UPDATED:
      return {
        ...state,
        languages: state.languages.map( languageItem =>
          languageItem._id === payload._id ? payload : languageItem
        ),
        loading: false,
      }
    case LANGUAGE_DELETED:
      return {
        ...state,
        languages: state.languages.filter( languageItem => languageItem._id !== payload._id   ),
        loading: false,
      }
    default:
      return state;
  }
}