import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOKEN_FAIL,
  APP_INITIALISED,
  ADMIN_LOADED,
  AUTH_ERROR,
  SET_AUTH_LOADING,
} from "../../actions/types";
import { urlConstants } from "../../constants";
import { setAlert } from "../alertActions/setAlert";



export const loadAdmin =  () => async dispatch => {

  // let localStorageAuth = JSON.parse(localStorage.auth);
  // if (
  //   localStorage.auth 
  //   && ( //'admin' || 'editor' || 'writer'
  //     JSON.parse(localStorage.auth).userType === 'admin'  
  //     || JSON.parse(localStorage.auth).userType === 'editor'
  //     || JSON.parse(localStorage.auth).userType === 'writer'
  //   )
  // ) {
  //   // setAuthToken(localStorage.auth);
  // }

  try {
    dispatch({ 
      type: SET_AUTH_LOADING,
      payload: true
    });
    const res = await axios.get(  urlConstants.loadAdminnUrl);
    // console.log(res.data.admin);
    // console.log(res.data.userType);

    console.log(res.body);


    dispatch({
      type: ADMIN_LOADED,
      payload: res.data
    });

  } catch (err) {
    console.log('ADMIN LOAD ERROR');
    dispatch({ type: AUTH_ERROR });
  }
};