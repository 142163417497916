import React from 'react'
import { NavLink } from 'react-router-dom'



// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


// Icons
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';

// Components
import { SendNotificationButton } from '../../components/notification/SendNotificationButton';
import { CopyEventButton } from '../../components/event/copyEvent/CopyEventButton';
import { CancelEventButton } from '../../components/event/cancelEvent/CancelEventButton';

export const EventAddEditScreenBottomButtons = ({
  eventId,
  formData
}) => {  
  return (
   
    <Grid item sm={12} className='mt-2 pb-1 w-100'>
      <Grid container sm={12} className='w-100'>        

        <Grid item className='pr-2'>
          <Button
            variant='contained'
            color='success'
            component={NavLink}
            // to={'/event/tickets/' + 'eventTickets11' }
            to={'/event/tickets/' + eventId }
            endIcon={<ReceiptIcon  />}
          >
            Tickets
          </Button>                        
                       
        </Grid>
        <Grid item className='pr-2'>
         <SendNotificationButton />                     
                       
        </Grid>
        <Grid item className='pr-2'>
         {/* <SendNotificationButton />                      */}
          <CopyEventButton 
            eventId={eventId}
          />
        </Grid>
        <Grid item className='pr-2'>
         {/* <SendNotificationButton />                      */}
          <CancelEventButton
              eventId={eventId}
          />
        </Grid>

      </Grid>
    </Grid>
  )
}
