import React, { useState } from 'react'
import{  NavLink ,withRouter, useHistory} from 'react-router-dom'




// Material Components
// import {
//   TableRow,
//   TableCell,
//   Button,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
// } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

// CSS
import './UserActivityItem.css'

// Constants
import { clientAppConstants } from '../../constants';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';


// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentOnlyTime } from '../../helpers/moment/convertToMomentOnlyTime';



export const UserActivityItem = ({userActivityItem}) => {

  const {
    _id,
    // userId,
    activityType,
    activityCreationDate,
    subscriptionId,
    eventTitle,
    eventId,
    classroomId,
    platform,
    payment,
    balance,
  } = userActivityItem

  return (
    <StyledTableRow>
      
      <StyledTableCell
        align='center'
        size='small'
      >
        {activityType}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {convertToMomentOnlyDate(activityCreationDate)}
      </StyledTableCell>
      <StyledTableCell
        size='small'
        align={ (subscriptionId && subscriptionId.length > 0) ? 'left' : 'center'}
      >
        {
          (subscriptionId && subscriptionId.length > 0)
          ?
          subscriptionId.map(
            (subscriptionIdItem, ind) => {
              return (
                  <TableNavlinkTextButton
                    key={ind}
                    // buttonText={'French 3 Months Unlimited'}
                    buttonText={subscriptionIdItem.subscriptionTitle}
                    // buttonLink={'/subscription/edit/' + 'subscriptionIdFromUserActivityScreen'}
                    buttonLink={'/subscription/edit/' + subscriptionIdItem._id}
                  />
                )
            }
          )
          // (
          //   <TableNavlinkTextButton
          //     // buttonText={'French 3 Months Unlimited'}
          //     buttonText={subscriptionId.subscriptionTitle}
          //     // buttonLink={'/subscription/edit/' + 'subscriptionIdFromUserActivityScreen'}
          //     buttonLink={'/subscription/edit/' + subscriptionId._id}
          //   />
          // )
          :
          '-'
        }
        
      </StyledTableCell>
      {/* <StyledTableCell
        size='small'
      >
        <Button 
          variant="text"
          style={{
            textTransform: 'none',
            lineHeight: 1.2
          }}
          onClick={() => {
            history.push('/event/edit/' + '_id')
          }}
        >
          French Speaking Club
        </Button>        
        
      </StyledTableCell> */}
      <StyledTableCell
        size='small'
        align={eventTitle ? 'left' : 'center'}
      >
        {
          eventTitle
          ?
          (
            <Button 
              variant="text"
              style={{
                textTransform: 'none',
                lineHeight: 1.2
              }}
              component={NavLink}
              to={'/event/edit/' + eventId._id}
            >
              {/* French Speaking Club */}
              {eventTitle}
            </Button> 
          )
          :
          '-'
        }
               
        
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align={eventId ? 'left' : 'center'}
      >
        {
          eventId
          ?
          <>
            <span style={{whiteSpace: 'nowrap'}}>{convertToMomentOnlyDate(eventId.eventDate)}</span>
            <span>{' '}</span>
            <span style={{whiteSpace: 'nowrap'}}>{convertToMomentOnlyTime(eventId.eventDate)}</span>
          </>
          :
          '-'
        }
        {/* {convertToMomentDateTime(Date.now())} */}
      </StyledTableCell>
      {/* <StyledTableCell
        align='center'
        size='small'
      >
        <Chip className='speaklabs-activity-platform-chip' label="paypal" color="primary" />
        <Chip className='speaklabs-activity-platform-chip' label="admin-panel" color="success" />
        <Chip className='speaklabs-activity-platform-chip' label="subscription" color="error" />
      </StyledTableCell> */}
      <StyledTableCell
        align='center'
        size='small'
      >
        {
          platform.map(
            (platformItem, ind) => {
              switch(platformItem) {
                case clientAppConstants.platformOptionsObject.PAYPAL:
                  return (<Chip key={ind} className='speaklabs-activity-platform-chip' label={platformItem} color="primary" />)
                case clientAppConstants.platformOptionsObject.TINKOFF:
                  return (<Chip key={ind} className='speaklabs-activity-platform-chip' label={platformItem} color="secondary" />)
                case clientAppConstants.platformOptionsObject.ADMIN_PANEL:
                  return (<Chip key={ind} className='speaklabs-activity-platform-chip' label={platformItem} color="success" />)
                case clientAppConstants.platformOptionsObject.SUBSCRIPTION:
                  return (<Chip key={ind} className='speaklabs-activity-platform-chip' label={platformItem} color="error" />)
                default:
                  return <Chip key={ind} className='speaklabs-activity-platform-chip' label="UNDEFINED" color="error" />
              }
            }
          )
        }
      </StyledTableCell>
      {/* <StyledTableCell
        size='small'
        align='right'
      >
        {'$4.00'}
      </StyledTableCell> */}
      <StyledTableCell
        size='small'
        // align='right'
        align={payment ? 'right' : 'center'}
      >
        {/* {'$4.00'} */}
        {
          (payment !== null && payment !== undefined )
          ?
          (`\$${payment.toFixed(2)}`)
          :
          '-'
        }
        
      </StyledTableCell>
      {/* <StyledTableCell
        size='small'
        align='right'
      >
        {'$4.00'}
      </StyledTableCell> */}
      <StyledTableCell
        size='small'
        // align='right'
        align={balance ? 'right' : 'center'}
      >
        {/* {'$4.00'} */}
        {
          (balance !== null && balance !== undefined )
          ?
          (`\$${balance.toFixed(2)}`)
          :
          '-'
        }
        
        
      </StyledTableCell>
      
    </StyledTableRow>
  )
}
