import  React, {useState} from 'react';

// MUI
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


// Components
import { TextButtonForTableCell } from '../../../components/buttons/TextButtonForTableCell';
import { InnerTicketRow } from './InnerTicketRow';

// Helpers
import { convertToMomentDateTime } from '../../../helpers/moment/convertToMomentDateTime';
import { InnerTableHead } from './InnerTableHead';




export const CollapsableEventRow = (props) => {
  const { eventItem } = props;
  const [open, setOpen] = useState(false);


  const NoCollapse = () => (
    <>
      <TableCell
        size={'small'}
      > 
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>

        <TextButtonForTableCell 
          to={'/event/edit/' + 'eventIDFromHostEvent'} 
          buttonText={eventItem.eventTitle}
        />
      </TableCell>

      <TableCell
        size='small'
        align='center'
      >        
        { convertToMomentDateTime( eventItem.eventDate ) }
      </TableCell>

      <TableCell
        size='small'
      >
        {eventItem.isEventOnline ? 'Online' : 'Offline'}
      </TableCell>

      <TableCell
        size='small' align='right'
      >
       {eventItem.maxAttendee ?? '-'}
      </TableCell>
      <TableCell
        size='small' align='right'
      >
       {eventItem.ticketCount ?? '-'}
      </TableCell>
      <TableCell
        size='small' align='right'
      >
       {eventItem.reservationCount ?? '-'}
      </TableCell>

      <TableCell
        size='small' align='right'
      >
       {eventItem.ticketPrice ? '$'+eventItem.ticketPrice.toFixed(2) :  '-'}
      </TableCell>
      <TableCell
        size='small' align='right'
      >
       {
        eventItem.totalTransaction 
          ? 
          (
            <span 
              style={{
                fontSize: 14
              }}
              className='font-weight-bold'
            >
              {'$'+eventItem.totalTransaction.toFixed(2)}
            </span>
          ) 
          :  
          '-'
        }
      </TableCell>

    </>
  )

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <NoCollapse />
      </TableRow>
      <TableRow 
        // className='speaklabs-collapsable-parent-row' 
        // style={{backgroundColor: 'red'}}
      >
        <TableCell 
          style={{ paddingBottom: 0, paddingTop: 0 }} 
          colSpan={9} /* VERY IMPORTANT FIELD :) */
        >
          <Collapse in={open} timeout="auto" unmountOnExit 
            // className='w-100'
          >
            <Box sx={{ margin: 1 }} 
              // className='w-100'
            >
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              {/* {props.collapse} */}
              <CollapsedTable />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}



const CollapsedTable = () => (
  <Table 
    size="small" 
    // aria-label="purchases" 
    aria-label="collapsible table"
    // className='w-100'
  >
    <InnerTableHead />
    <TableBody>

      <InnerTicketRow />
    </TableBody>
  </Table>
)
