

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_ITEM_COUNT } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const setFilteringItemCount = ({
  itemCount
}) => async dispatch => {
  dispatch({
    type: SET_ITEM_COUNT,
    payload: itemCount
  });
}  