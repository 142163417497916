import {  CONTACT_MESSAGES_FETCHED } from "../types";





export const clearContactMessageListInfo = () => async dispatch => {
  dispatch({
    type: CONTACT_MESSAGES_FETCHED,
    payload: null
  });
} 