import React, {useCallback, useEffect, useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchSingleUser } from '../../actions/userActions/fetchSingleUser';
import { fetchInterests } from '../../actions/interestActions/fetchInterests';
import { fetchLanguages } from '../../actions/languageActions/fetchLanguages';


// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';



import {
  // Container,
  CircularProgress,
  // Button,
  TableContainer,
  Table,
  TableBody,
  Paper,
  // Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { red, lightBlue, deepOrange } from '@mui/material/colors';


// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SecurityIcon from '@mui/icons-material/Security';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListIcon from '@mui/icons-material/List';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';


import { UserTopBar } from '../../components/userTopBar/UserTopBar';
import { UserEditTableHead } from './UserEditTableHead';
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow';
import { SetUserHostButton } from './SetUserHostButton';
import { SetUserVerifierButton } from './SetUserVerifierButton';
import { UserEditScreenBottomButtons } from './UserEditScreenBottomButtons';
import UserSubscriptions from "./UserSubscribtions";
import AddSubscriptionModal from "./AddSubscriptionModal";
import { SendNotificationButton } from '../../components/notification/SendNotificationButton';



export const UserEditScreen = ({
  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) => {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);
  const singleUser = useSelector(state => state.userReducer.singleUser);
  const interests = useSelector(state => state.interestReducer.interests);
  const languages = useSelector(state => state.languageReducer.languages);
  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  const [userId, setUserId] = useState(match.params.userId || null);
  const [addSubscriptionOpen, setAddSubscriptionOpen] = useState(false);
  const [editingMode, setEditingMode] = useState(false);



  const fetchUser = useCallback(() => {
      dispatch(
          fetchSingleUser({
              userId,
              cb: apiResponse => {
                  // if ( apiResponse.user ){
                  //   _setUser( apiResponse.user )
                  // }
                  _setLoading(false);
              }
          })
      )
  }, [userId])

    const onSubscriptionClose = useCallback((needRefresh = false)  => {
        setAddSubscriptionOpen(false);
        if(needRefresh){
            fetchUser();
        }
    }, [])

  useEffect(
    () => {
      fetchUser();

      dispatch(
        fetchInterests({
          cb: () => {
            _setLoading(false)
          }
        })
      );

      dispatch(
        fetchLanguages({
          cb: () => {
            _setLoading(false)
          }
        })
      );
    }, []
  );





  const _onSubmitHandler = () => {}


  return (
    <Container
      maxWidth='lg'
      className='w-100 '
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
      id='user-edit-screen-container'
    >
      <Helmet defer={false}><title>
        {
          singleUser 
            ?
            (
              singleUser.nameSurname
                ?
                singleUser.nameSurname + ' - Details'
                :
                singleUser.email + ' - Details'
            )
            : 
            'User Details'
        }
      </title></Helmet>
      <Grid container className='w-100'>
        {/* <ScreenTitle titleText={'User Profile'} /> */}
        {
          singleUser &&   !_loading   && (
            <UserTopBar
              userAvatar={singleUser.userAvatar}
              nameSurname={(
                <Stack direction='row'>
                  { 
                    singleUser.nameSurname ?? singleUser.email 
                  }
                  {
                    singleUser.isHost && (
                      <Tooltip title="Host" arrow>
                        <Avatar sx={{ bgcolor: deepOrange[500] }} className='mx-1'>H</Avatar>
                      </Tooltip>
                    )
                  }
                  {
                    singleUser.isOfflineEventTicketVerifier && (
                      <Tooltip title="Ticket Verifier" arrow>
                        <Avatar sx={{ bgcolor: lightBlue[500] }} className='mx-1'>
                          <SecurityIcon />
                        </Avatar>
                      </Tooltip>
                    )
                  } 
                </Stack>
              )}
            />
          )
        }
        {
          (_loading  ) && <ScreenCircularProgress />
        }
        {
          !_loading  && singleUser && (
            <Grid item className='w-100' sm={12}>
              <form 
                className='w-100' 
                onSubmit={_onSubmitHandler}
                style={{
                  minHeight:`calc( ${clientAppConstants.screenContainerMinHeight} - 145px )`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent:'space-between'
                }}
              >
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  <CustomTable
                  // stickyHeader={false}
                  >
                    <UserEditTableHead />
                    <TableBody>
                      {/* <UserItem />
                      <UserItem />
                      <UserItem /> */}
                      <DoubleColumnTableRow
                        keyTitle='Username'
                        valueText={singleUser.nameSurname}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="nameSurname"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Email'
                        valueText={singleUser.email}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Phone Number'
                        valueText={singleUser.phoneNumber}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="phoneNumber"
                        userId={userId}
                      />                              
                      <DoubleColumnTableRow
                        keyTitle='Registration Date'
                        // valueText={ singleUser.registrationDate'2021-10-30'}
                        valueText={ convertToMomentOnlyDate(singleUser.registrationDate)}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Last Login'
                        valueText={ convertToMomentDateTime(singleUser.lastLogin) }
                      />
                      <DoubleColumnTableRow
                        keyTitle='Account Type'
                        valueText={ singleUser.subscriptions.filter(item => item.isActive).length ? "Active Member" : "Basic Member" }
                      />
                      <DoubleColumnTableRow
                        keyTitle='Birthdate'
                        valueText={ singleUser.dateOfBirth ? convertToMomentOnlyDate( singleUser.dateOfBirth ) : '-' }
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="dateOfBirth"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Login Method'
                        valueText={ singleUser.authLoginType }
                      />
                      <DoubleColumnTableRow
                        keyTitle='Email Verified'
                        valueText={ singleUser.isEmailVerified ? 'YES' : 'NO'}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="isEmailVerified"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Notification Subscription'
                        valueText={ singleUser.isSubscribedToNotifications ? 'YES' : 'NO'}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="isSubscribedToNotifications"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Email Subscription'
                        valueText={  singleUser.isSubscribedToEmails ? 'YES' : 'NO'}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="isSubscribedToEmails"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='About Me'
                        valueComponent={
                          <p
                            style={{
                              display:'block',
                              maxWidth: '700px',
                              // maxLines: 1,
                              // lineHeight: 1.25,
                              // maxHeight: '2.5rem',
                              // textOverflow: 'clip',
                              // overflow: 'hidden'
                            }}
                          >
                            {
                              singleUser.aboutMe ?? '-'
                            }
                          </p>
                          
                        }
                        valueText={singleUser.aboutMe}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="aboutMe"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Interests'
                        valueText={
                          // 'English, Frensh, Russian'
                        ( singleUser.interests && singleUser.interests.length > 0 )
                          ?
                          (() => {
                            let string = '';
                            for (  let i = 0; i < singleUser.interests.length; i++ ) {
                              string = string + singleUser.interests[i].interestTitle;
                              if ( i < singleUser.interests.length - 1 ) {
                                string = string + ', '
                              }
                            }
                            return string;
                          })()
                          :
                          '-'
                        }
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="interests"
                        data={interests?.map(interest => interest.interestTitle)}
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Languages'
                        valueText={
                          // 'English, Frensh, Russian'
                        ( singleUser.languageList && singleUser.languageList.length > 0 )
                          ?
                          (() => {
                            let string = '';
                            for (  let i = 0; i < singleUser.languageList.length; i++ ) {
                              string = string + singleUser.languageList[i].languageTitle;
                              if ( i < singleUser.languageList.length - 1 ) {
                                string = string + ', '
                              }
                            }
                            return string;
                          })()
                          :
                          '-'
                        }
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="languageList"
                        data={languages?.map(language => language.languageTitle)}
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Balance'
                        valueText={`$${singleUser.balance}`}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="balance"
                        userId={userId}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Active Subscriptions'
                        valueText={singleUser.subscriptions.filter(subscription => subscription.isActive).length}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Total Subscriptions'
                        valueText={singleUser.subscriptions.length}
                      />
                      
                      <DoubleColumnTableRow
                        keyTitle='Tickets'
                        valueText={`${singleUser.totalTickets}`}
                      />
                      
                      <DoubleColumnTableRow
                        keyTitle='Total Transaction'
                        valueText={`$${singleUser.totalTransactions}`}
                      />

                      <DoubleColumnTableRow
                        keyTitle='Notes'
                        valueText={singleUser.notes}
                        editingMode={editingMode}
                        setEditingMode={setEditingMode}
                        editingKey="notes"
                        userId={userId}
                      />

                    </TableBody>
                  </CustomTable>
                  {/* End of Table */}
                  
                  




                  <Button
                    onClick={() => setEditingMode(!editingMode)}
                    variant='contained'
                    color={editingMode ? "error" : 'primary'}
                    className="mt-2 btn-lg"
                    endIcon={editingMode ? <ClearIcon /> : <EditIcon />}
                  >
                    {editingMode ? "Disable Editing Mode" : "Enable Editing Mode"}
                  </Button>
                  <div className="mt-2 ml-1">
                    <SendNotificationButton isSingleUser filteringParameters={{email: [singleUser.email]}} userCount={1} />
                  </div>
                </Grid>


                  <UserSubscriptions user={singleUser} onChange={fetchUser}/>
                {/* Bottom Buttons */}
                {
                  singleUser && (
                    <UserEditScreenBottomButtons
                      userId={userId}
                      setAddSubscriptionDialogOpen={() => setAddSubscriptionOpen(true)}
                    />                  
                  )
                }
                {/* End of Bottom Buttons */}
              </form>
            </Grid>
          )
        }


      </Grid>
        {addSubscriptionOpen && <AddSubscriptionModal user={singleUser} onClose={onSubscriptionClose} />}
    </Container>
  )
}
