import React, { useState } from 'react'
import{  NavLink, useHistory } from 'react-router-dom'

// Constants
import { clientAppConstants } from '../../constants';

//-------------------------------------------------------------------------------
// MUI


// Icons



//-------------------------------------------------------------------------------


// Material Components
import {
  // TableRow,
  // TableCell,
  // Button,
  // IconButton,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  // DialogActions,
} from '@material-ui/core';

// MUI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

//  Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReceiptIcon from '@mui/icons-material/Receipt';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { EventTicketsIconButton } from '../../components/buttons/EventTicketsIconButton';
import { Stack } from '@mui/material';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';



export const TicketVerificationItem = ({
  ticketVerificationItem
}) => {


  const {
    eventId,
    classroomId,
    languageId,
    relatedTicketId,
    verifierUserId,
    eventDate,
    verificationDate,
    ticketOwnerId,
    verificationQuantity,
  } = ticketVerificationItem;

  const {
    eventTitle
  } = eventId;

  return (
    <StyledTableRow>
      {/* <StyledTableCell
        size='small'
      >
        Online
      </StyledTableCell> */}
      <StyledTableCell
        size='small'
      >        
        <TableNavlinkTextButton
          buttonLink={'/event/edit/' + eventId._id }
          buttonText={eventTitle}
        />
      </StyledTableCell>
      {/* <StyledTableCell
        align='center'
        size='small'
      >
        English
      </StyledTableCell> */}
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* {convertToMomentOnlyDate(Date.now())} */}
        {convertToMomentDateTime(  verificationDate )}
      </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
        {/* {convertToMomentOnlyDate(Date.now())} */}
        { verificationQuantity.toString( ) }
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        <TableNavlinkTextButton
          buttonLink={'/user/edit/' + verifierUserId._id }
          buttonText={ verifierUserId.nameSurname ?? verifierUserId.email ?? '-' }
        />
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        <TableNavlinkTextButton
          buttonLink={'/user/edit/' + ticketOwnerId._id}
          buttonText={  ticketOwnerId.nameSurname ?? ticketOwnerId.email ?? '-' }
        />
      </StyledTableCell>      
      <StyledTableCell
        align='center'
        size='small'
      >
        <Stack 
          direction='row'
          justifyContent='center'
        >            
          <Tooltip title='Ticket Info' placement='left'   arrow>
            <IconButton component={ NavLink} to={'/ticket/edit/' + relatedTicketId }>
              <ReceiptIcon  color='success'/>
            </IconButton>
          </Tooltip>
        </Stack>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
