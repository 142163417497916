import {useCallback, useEffect, useRef, useState} from "react";
import {FileControl} from "./FileControl";
import {Container} from "@material-ui/core";
import {CardMedia} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {toast} from "react-toastify";
import {GlobalCircularProgress} from "../loader/GlobalCircularProgress";
import axios from "axios";



export const ImageUploader = ({onChange, value: propValue, accept = 'image/*', multiple, ...rest}) => {
    const [value, setValue] = useState(() => {
        if(propValue) {
            return Array.isArray(propValue) ? propValue : [propValue]
        }
        return [];
    });
    const [loading, setLoading] = useState(false);

    const uploadImage = async (images) => {
        try {
            setLoading(true);
            const formData = new FormData();
            (Array.isArray(images) ? images : [images]).forEach(image => {
                formData.append('images', image);
            });
            const response = await axios.post('/api/image/upload', formData);
            setLoading(false);
            return response.data.images;
        } catch (e) {
            toast.error(e.message);
        }
        setLoading(false);
    }

    const handleChange = useCallback(async (nextValue) => {
        const images = await uploadImage(nextValue);
        const newValue = images.map(image => image._id);
        setValue(newValue);
        onChange(multiple ? newValue : newValue[0]);
    }, [multiple, onChange]);



    return (<>
        {Boolean(value.length) && <Container fluid className="mb-2">
            <Grid xs={1} md={3} lg={4} spacing={2}>
                <GlobalCircularProgress show={loading}/>
                {value.map(item => (
                    <div key={item} style={{ paddingLeft: 0 }}>
                        <img src={`/api/image/image/${item}`} className="w-100"/>
                    </div>
                ))}
            </Grid>
        </Container>}
        <FileControl onChange={handleChange} accept={accept} multiple={multiple} {...rest} />
    </>);
}