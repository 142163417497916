


// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_IS_HIDE_ACTIVE_UI_FILTERS } from "../types";





export const setIsHideActiveUiFilters = (  
) => async dispatch => {
  dispatch({
    type: SET_IS_HIDE_ACTIVE_UI_FILTERS,
    payload: null
  });
}  



