import React from 'react'


import { Bar, defaults } from 'react-chartjs-2';



const backgroundColorListGenerator = ( length ) => {
  const backgroundColorOptionList =  [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
  ];
  let returnList = [];
  if ( !length || length <=0 ) {
    return returnList;
  }
  let optionIndex = 0;
  for ( let i = 0; i < length; i++) {
    returnList.push(  backgroundColorOptionList[optionIndex] );
    optionIndex++;
    if ( optionIndex === backgroundColorOptionList.length) {
      optionIndex = 0;
    }
  }
  return returnList;
}


const borderColorListGenerator = ( length ) => {
  const borderColorOptionList =  [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
  ];
  let returnList = [];
  if ( !length || length <=0 ) {
    return returnList;
  }
  let optionIndex = 0;
  for ( let i = 0; i < length; i++) {
    returnList.push(  borderColorOptionList[optionIndex] );
    optionIndex++;
    if ( optionIndex === borderColorOptionList.length) {
      optionIndex = 0;
    }
  }
  return returnList;
}




const data = {
  // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  labels: ['Russia', 'Spain', 'Germany', 'France', 'England', 'New Zaeland', 'Other'],

  datasets: [
    {
      label: '# of Locations',
      data: [250, 185, 129, 90, 81, 25, 125],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};










export const BarChartPopularLocations = ({
  topPopularLocations
}) => {

  let locationList = topPopularLocations.locationList;
  let _data = {
    ...data,
    labels: locationList.map( item => {
      let _title= item.isCity 
        ? (item.cityTitle + ', ' + item.countryEn)
        : item.countryEn;
      return _title;
    }),
    datasets: [
      {
        ...data.datasets[0],
        data: locationList.map( item => item.audienceCount),
        backgroundColor: backgroundColorListGenerator( locationList.length ),
        borderColor: borderColorListGenerator( locationList.length)
      }
    ]
  };


  return (
    <Bar 
      // data={data} 
      data={_data} 
      // options={options} 
      // width={350}
      height={200}
      options={{
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
          legend: {
              position: 'top'
          },
          title: {
            display: true,
            text:'Top Popular Locations'
          },
          // subtitle: {
          //   display: true,
          //   text: 'Custom Chart Subtitle'
          // }
      },
      }} 
    />
  )
}
