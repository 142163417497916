import React from 'react'



// MUI
import Grid from '@mui/material/Grid';

// Components
import { SimpleCustomSelect } from '../../components/form/SimpleCustomSelect';

export const HostSelectionComponent = ({
  formData,
  setFormData,
  hostList
}) => {
  return (
    <>
      {/* Host Selection */}                  
      <Grid item  className='w-100 mt-2 ' 
        /* style={{backgroundColor:'red'}}  */   
        sm={12} md={6} lg={4}
      >
        <SimpleCustomSelect
          // fullWidth={true}

          label='Host'
          htmlId={'speaklabs-classroom-select-host'}
          eventTargetNAme={'level'}
          isNoneExist={ formData.eventHost === '' }
          noneText={'Select'}
          value={ formData.eventHost   }
          // optionList={[{ value: 'Dinner', definition: 'Dinner' }, { value: 'Lunch', definition: 'Lunch' }, { value: 'Supper', definition: 'Supper' }  ]}
          optionList={[
            ...hostList.map(
              hostItem => {
                return {
                  value: hostItem._id,
                  definition: hostItem.nameSurname ?? hostItem.email
                }
              }
            )
          ]}
          onChange={ e => {
            let tempNewId = e.target.value;
            let newHostObj = hostList.find(
              item => item._id === tempNewId
            );
            // eventHostNameSurname: null,
            // eventHostEmail: null,
            setFormData({ 
              ...formData, 
              eventHost:  tempNewId ,
              eventHostDocument: { ...newHostObj }
            });
          } }
          inputPropsId={'event-add-edit-screen-event-select'}
        />                          
      </Grid>
      {/* End of Host Selection */}
    </>
  )
}
