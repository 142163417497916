

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_EMAIL_FILTER_ITEM_CLICK } from "../types";





export const handleEmailItemClick = ({
  emailItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_EMAIL_FILTER_ITEM_CLICK,
    payload: emailItem
  });
}  