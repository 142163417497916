import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { getSingleNotification } from '../../actions/notificationActions/getSingleNotification';



import {
  TableBody,
} from '@material-ui/core';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Icons
import RepeatIcon from '@mui/icons-material/Repeat';

// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow';
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead';


// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';




export const NotificationDetailScreen = ({
  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) => {

  // Global Variables


  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  const [notificationId, setNotificationId] = useState(match.params.notificationId || null);
  const [formData, setFormData] = useState(null);


  

  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      dispatch(
        getSingleNotification({
          notificationId,
          cb: apiResponse => {
            // console.log('fetchSingleTicketDetail -> cb -> apiResponse ', apiResponse)
            if ( apiResponse && apiResponse.notification ) {
              setFormData(apiResponse.notification);
            }
            _setLoading(false);
          }
        })
      )
    }, []
  );




  const _onSubmitHandler = () => { }


  return (
    <Container
      maxWidth='lg'
      className='w-100 pb-4'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      id='notification-detail-screen-container'
    >
      <Helmet defer={false}><title>{'Notification Detail'}</title></Helmet>
      <Grid container className='w-100'>
        <ScreenTitle titleText={'Notification Detail'} />
        
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100' sm={12}>
              <form className='w-100' onSubmit={_onSubmitHandler}>
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  <CustomTable
                  // stickyHeader={false}
                  >
                    <DoubleColumnTableHead />
                    <TableBody>
                      {/* <UserItem />
                      <UserItem />
                      <UserItem /> */}
                      <DoubleColumnTableRow
                        keyTitle='Notification Title'
                        valueText={
                          formData.title
                        }
                      />
                      <DoubleColumnTableRow
                        keyTitle='Notification Message'
                        valueText={formData.message}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Notification Date'
                        valueText={convertToMomentDateTime( formData.dbSentDate)}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Receiver Count'
                        valueText={ formData.recipients }
                      />
                      {
                        formData.notificationType &&  (
                          <DoubleColumnTableRow
                            keyTitle='Notification Type'
                            valueText={(
                              formData.notificationType
                            )}
                          />   
                        )
                      }
                      {
                        formData.eventId &&  formData.eventTitle && (
                          <DoubleColumnTableRow
                            keyTitle='Event Name'
                            valueText={(
                              <TableNavlinkTextButton
                                buttonLink={`/event/edit/`+ formData.eventId}
                                buttonText={formData.eventTitle}
                              />
                            )}
                          />   
                        )
                      }
                      {
                        formData.userId && (
                          <DoubleColumnTableRow
                            keyTitle='User'
                            valueText={(
                              <TableNavlinkTextButton
                                buttonLink={`/user/edit/`+ formData.userId._id}
                                buttonText={formData.userId.nameSurname ?? formData.userId.email ?? 'No Name!'}
                              />
                            )}
                          />    
                        )
                      }
                      
                      

                    </TableBody>
                  </CustomTable>
                  {/* End of Table */}



                </Grid>
              </form>
            </Grid>
          )
        }


      </Grid>

      <Grid container sm={12} className='w-100'>
        <Grid item className='mt-1'>
          <Button 
            variant='contained' 
            endIcon={<RepeatIcon />}
            color='success'
          >
            Resend
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
