import { 
  SET_NAVBAR_ON_OFF, 
  SET_HIDE_NAV_BUTTONS ,
  SET_SCREEN_WIDTH
} from "../actions/types";

const initialState = {
  isNavbarOn: null,
  isHideNavButtons: null,
  screenWidth: window.innerWidth,
  navbarWidth: 180
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NAVBAR_ON_OFF:
      return {
        ...state, 
        isNavbarOn: payload
      }
    case SET_HIDE_NAV_BUTTONS:
      return {
        ...state, 
        isHideNavButtons: payload
      }
    case SET_SCREEN_WIDTH:
      return {
        ...state, 
        screenWidth: payload
      }

    default:
      return state;
  }
}