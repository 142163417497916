


import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  CLASSROOM_FETCH_FOR_FILTERING_STARTED,
  CLASSROOMS_FETCHED_FOR_FILTERING, 
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";


// Fetch All Maritals
export const fetchClassroomsForFilter = ({
  cb  // callBack
}) => async dispatch => {
  try {
    dispatch({
      type: CLASSROOM_FETCH_FOR_FILTERING_STARTED,
      payload: null
    });
    const res = await axios.get(
      urlConstants.fetchClassroomListForFiltering, 
      clientAppConstants.jsonConfig
    );  

    dispatch({
      type: CLASSROOMS_FETCHED_FOR_FILTERING,
      payload: res.data.classroomList
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: CLASSROOMS_FETCHED_FOR_FILTERING,
      payload: []
    });
    if ( cb ) {
      cb([]);
    }
  }
}  // End of Fetch All Maritals