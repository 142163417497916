import React from 'react'
import { CustomTable } from '../../components/table/CustomTable'
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead'
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow'
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton'
import { detectMyTimezoneHelper } from '../../helpers/dateAndTime/detectMyTimezoneHelper'
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime'
import {displayEventDate} from "../../helpers/dateAndTime/eventDate";

export const EventDetailTable = ({
  formData,
  languageTitle
}) => {
  const {
    isEventOnline,
    language,
    level,

    // Registration & Ticket Fields
    isRegistrationPossible,
    isRegistrationFree,
    registrationFee,   
    //-----------------
    isNoTicketEvent,  // this is for offline events, where users pay money to place. we are only providing information about event
    eventOrganiserPrice,  // this is price that people pay at entrance, not related with our app, we are only showing to inform users
    //-----------------   
    ticketPrice,

  
    eventDate,
    timezone,


    isTicketBySubscriptionPossible,


    // duration: '2 hours',
    duration,
    maxAttendee,
    attendeeCount,
    ticketOwnerList,
    ticketCount,
    registratorList,
    ticketList,
    eventHost,  // Host ID
    eventHostDocument,
    // date: Date.now(),
    eventTitle,
    eventBoldText,
    eventRegularText,
    eventCreationDate,

    // OFFLINE EVENT FIELDS
    offlineEventType,
    // offlineEventLocation,
    countryEn,
    cityTitle,
    offlineEventListShortDefinition,
    // Offline E. Image Fields
    offlineEventImageId,
    offlineEventImage,
    isOfflineEventImageBuffer,
    isOfflineEventImageUpdated,
    



    // ONLINE EVENT FIELDS
    videoLink,
    // Online Event Vertical Image Fields
    onlineEventVerticalImageId,
    onlineEventVerticalImage,
    isOnlineEventVerticalImageBuffer,
    isOnlineEventVerticalImageUpdated,
    // Online Event Horizontal Image Fields
    onlineEventHorizontalImageId,
    onlineEventHorizontalImage,
    isOnlineEventHorizontalImageBuffer,
    isOnlineEventHorizontalImageUpdated,


  } = formData;


  return (
    <CustomTable>
      <DoubleColumnTableHead />

      {/* //---------------------------------------------------------------------- */}
      
      
      <DoubleColumnTableRow 
        keyTitle='Event Title'
        valueText={eventTitle ?? '-'}
      />      
      <DoubleColumnTableRow 
        keyTitle='Online-Offline'
        valueText=  { isEventOnline ? 'Online' : 'Offline'}
      />
      {
        isEventOnline === false && (
          <DoubleColumnTableRow 
            keyTitle='Event Type'
            valueText={offlineEventType}
          />
        )
      }
      <DoubleColumnTableRow 
        keyTitle='Event Date'
        valueText={displayEventDate(eventDate, timezone)}
      />
      <DoubleColumnTableRow 
        keyTitle='Language'
        valueText={languageTitle}
      />
      <DoubleColumnTableRow 
        keyTitle='Level'
        valueText={level}
      />
      <DoubleColumnTableRow 
        keyTitle='Event Bold Text'
        valueText={eventBoldText ?? '-'}
      />
      <DoubleColumnTableRow 
        keyTitle='Event Regular Text'
        valueText={eventRegularText ?? '-'}
      />      
      
      
      <DoubleColumnTableRow 
        keyTitle='Duration'
        valueText={ 
          duration %60 === 0 
            ?
            duration/60 + ' Hours'
            :
            (
              duration < 60
              ?
              duration + ' Minutes'
              :
              ( (duration - (duration %60) ) / 60 ) + 'Hours ' + (duration %60)+'Min.'
            ) 
        }
      />      
      {
        eventHostDocument && (
          <DoubleColumnTableRow 
            keyTitle='Host'
            valueComponent={(
              <TableNavlinkTextButton
                buttonLink={'/user/edit/' + eventHostDocument._id}
                buttonText={ eventHostDocument.nameSurname ?? eventHostDocument.email ?? '-' }
              />
            )}
          />
        )
      }
      <DoubleColumnTableRow 
        keyTitle='Max Attendee'
        valueText={maxAttendee}
      />
      <DoubleColumnTableRow 
        keyTitle='Ticket Count'
        valueText={ticketCount ?? '0'}
      />
      <DoubleColumnTableRow 
        keyTitle='Ticket Price'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isNoTicketEvent
            ? 
            ( '$' + eventOrganiserPrice + ' (Organiser P.)' )
            : 
            ( '$' + ticketPrice  )
        }
      />
      {
        (isEventOnline === true ) && (
          <DoubleColumnTableRow 
            keyTitle='Video Link'
            valueText={
              (() => {
                if (videoLink ?? videoLink !== '') {
                  return videoLink;
                }
                return '-'
              })()
            }
          />
        )
      }
      <DoubleColumnTableRow 
        keyTitle='Ticket Via Subs.'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isTicketBySubscriptionPossible ? 'Possible' : 'Not Possible'
        }
      />
      <DoubleColumnTableRow 
        keyTitle='Registration'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isRegistrationPossible
            ? 
            (
              isRegistrationFree
                ?
                'Free'
                :
                ( '$' + registrationFee )
            )
            : 
            'Not Possible'
        }
      />
      {/* <DoubleColumnTableRow 
        keyTitle='Attendee'
        valueText={22}
      /> */}
      {
        ( isEventOnline === false ) && (
          <DoubleColumnTableRow 
            keyTitle='Event Location'
            // valueText='Russia'
            valueText={
              (() => {
                let _str = countryEn ? '' : '-';
                if ( countryEn ) {
                  _str+=  countryEn;
                }
                if ( countryEn && cityTitle ) {
                  _str+= ', ' + cityTitle;
                }
                return _str
              })()
            }
          />
        )
      }
      <DoubleColumnTableRow 
        keyTitle='Event Creation Date'
        valueText={ convertToMomentDateTime(eventCreationDate )}
      />
      
    </CustomTable>
  )
}
