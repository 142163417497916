import { xDaysLater } from "./xDaysLater"



export const nextPossibleDateAtSameDayAndTime = ({
  inputDate,
  period = 7
}) => {
  let returnDate;
  while ( 
    !returnDate 
    || returnDate <= Date.now() 
  ) {
    if ( !returnDate ) {
      returnDate = xDaysLater({
        inputDate,
        postponeDayQuantity: period
      });
    } else {
      returnDate = xDaysLater({
        inputDate: returnDate,
        postponeDayQuantity: period
      });
    }
  }
  
  return returnDate;
}
