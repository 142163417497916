import React from 'react'
import {
    Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from '@material-ui/core/styles';

// Constants


export const CustomPaginationV2 = ({
                                       isScreenLoading,
                                       count,
                                       currentPaginationIndex,

                                       handleOnChange,

                                   }) => {
    // Global Variables

    const usePaginationStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            justifyContent: 'center'
        },
    }));
    const paginationClasses = usePaginationStyles();

    return (
        <Grid item className='w-100'>
            {!isScreenLoading && count !== 0 && (
                <div className={paginationClasses.root}>
                    <Pagination
                        count={count}
                        shape="rounded"
                        page={currentPaginationIndex}
                        onChange={handleOnChange}

                    />
                    {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
                </div>
            )
            }
        </Grid>
    )
}
