import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';




// import { setAuthCredentialsTrue } from './utils/setAuthCredentialsTrue';

// Init App
// import {handleAppInit} from './actions/authActions'


// Screens
import {
  // AddAdminScreen,
  // AddCouponScreen,
  // AddEditCategoryScreen,
  // AddManiScreen,
  // AddSponsorScreen,
  // AdminScreen,
  AdminLoginScreen,
  ChatScreen,
  ChatRoomScreen,
  ClassroomScreen,
  ClassroomAddEditScreen,
  ContactMessagesScreen,
  CountryScreen,
  CountryEditScreen,
  DashboardScreen,
  EmailValidationScreen,
  EventScreen,
  EventAddEditScreen,
  EventTicketsScreen,
  HostEventsScreen,
  HostUserCalculationScreen,
  InterestScreen,
  LanguageScreen,
  NotificationScreen,
  NotificationAddScreen,
  NotificationDetailScreen,
  ResetPasswordScreen,
  TicketVerificationScreen,
  UserScreen,
  UserActivitiesScreen,
  UserEditScreen,
  UserTicketsScreen,
  ActivitiesScreen,
  SubscriptionEditScreen,
  SubscriptionPackageScreen,
  SubscriptionPackageAddEditScreen,
  TicketEditScreen,
  // CategoriesScreen,
  // ContactScreen,
  // CreateNotificationsScreen,
  // CouponScreen,
  // DashboardScreen,
  // EmailValidationScreen,
  // GamblingWheelScreen,
  // JobsScreen,
  // ManilerScreen,
  // MaritalScreen,
  // MyAccountScreen,
  NotFound,
  // NotificationScreen,
  // SponsorScreen,
  // UserEditScreen,
  // UserFavoriteManiScreen,
  // UserLikedManiScreen,
  // UserScreen,
  // UserSeenManiScreen,
  // TemporaryPremiumScreen,
} from './screens';



// Components
import AlertComponent from './layout/Alert';
import {PrivateRoute} from './components/routing/PrivateRoute';



// Redux
import { Provider } from "react-redux";
import store from "./store";
import ContentBody from './layout/ContentBody';


// Middleware
import { axiosResponseMiddleware } from './helpers/middleware/axiosResponseMiddleware';
import {SubscriptionsScreen} from "./screens/subscriptions/SubscriptionsScreen";
import {SubscriptionsAddScreen} from "./screens/subscriptionsAdd/SubscriptionsAddScreen";
import {SocketProvider} from "./context/SocketContext";
import {PromoCodesScreen} from "./screens/promoCodes/PromoCodesScreen";
import {CreateClassRoom} from "./screens/classroom/CreateClassRoom";
import {EditClassRoom} from "./screens/classroom/EditClassRoom";
import {CreateEvent} from "./screens/event/CreateEvent";
import {UpdateEvent} from "./screens/event/UpdateEvent";
import {Tickets} from "./screens/tickets/Tickets";
import {HostsScreen} from "./screens/hosts/HostsScreen";
import {TransactionsScreen} from "./screens/transactions/TransactionsScreen";
import {PublicContactMessagesScreen} from "./screens/publicContactMessages/PublicContactMessagesScreen";


// Init Middleware


axiosResponseMiddleware({store});

function App() {

  useEffect(() => {
    window.process = {
      ...window.process,
    };



  }, []);

  return (
    <Provider store={store}>
      <SocketProvider>
        <Router>
          <ContentBody>
            <AlertComponent />
            {/* <Navbar /> */}
            <Switch>
            <Route exact path='/' component={AdminLoginScreen} />
            <PrivateRoute exact path='/chats' component={ChatScreen} />
            <PrivateRoute exact path='/chat-room/:chatLanguage' component={ChatRoomScreen} />
            <PrivateRoute exact path='/classroom' component={ClassroomScreen} />
            <PrivateRoute exact path='/classroom/add' component={CreateClassRoom} />
            <PrivateRoute exact path='/classroom/view/:classroomId' component={ClassroomAddEditScreen} />
            <PrivateRoute exact path='/classroom/edit/:classroomId' component={EditClassRoom} />
            <PrivateRoute exact path='/contact-messages' component={ContactMessagesScreen} />
            <PrivateRoute exact path='/country' component={CountryScreen} />
            <PrivateRoute exact path='/country/info/:countryId' component={CountryEditScreen} />
            <PrivateRoute exact path='/dashboard' component={DashboardScreen} />
            <PrivateRoute exact path='/interest' component={InterestScreen} />
            <PrivateRoute exact path='/language' component={LanguageScreen} />
            <PrivateRoute exact path='/notification' component={NotificationScreen} />
            <PrivateRoute exact path='/notification/create' component={NotificationAddScreen} />
            <PrivateRoute exact path='/notification/detail/:notificationId' component={NotificationDetailScreen} />
            <PrivateRoute exact path='/event' component={EventScreen} />
            <PrivateRoute exact path='/event/create-from-classroom/:classroomId' component={CreateEvent} />
            <PrivateRoute exact path='/event/view/:eventId' component={EventAddEditScreen} />
            <PrivateRoute exact path='/event/edit/:eventId' component={UpdateEvent} />
            <PrivateRoute exact path='/event/tickets/:eventId' component={EventTicketsScreen} />
            <PrivateRoute exact path='/host/events/:userId' component={HostEventsScreen} />
            <PrivateRoute exact path='/host/calculate-single/:userId' component={HostUserCalculationScreen} />
            <PrivateRoute exact path='/user' component={UserScreen} />
            <PrivateRoute exact path='/hosts' component={HostsScreen} />
            <PrivateRoute exact path='/user/edit/:userId' component={UserEditScreen} />
            {/* <Route exact path='/user/events/:userId' component={UserEventsScreen} /> */}
            <PrivateRoute exact path='/user/tickets/:userId' component={UserTicketsScreen} />
            <PrivateRoute exact path='/tickets' component={Tickets} />
            <PrivateRoute exact path='/user/activities/:userId' component={UserActivitiesScreen} />
            <PrivateRoute exact path='/activities' component={ActivitiesScreen} />
            <PrivateRoute exact path='/ticket/edit/:ticketId' component={TicketEditScreen} />
            <PrivateRoute exact path='/ticket-verification' component={TicketVerificationScreen} />
            <PrivateRoute exact path='/subscription/edit/:subscriptionId' component={SubscriptionEditScreen} />
            <PrivateRoute exact path='/subscription' component={SubscriptionPackageScreen} />
            <PrivateRoute exact path='/subscription/edit-package/:subscriptionClassId' component={SubscriptionPackageAddEditScreen} />
            <PrivateRoute exact path='/subscription/create-package' component={SubscriptionPackageAddEditScreen} />
            <PrivateRoute exact path='/subscriptions' component={SubscriptionsScreen} />
            <PrivateRoute exact path='/subscriptions/add' component={SubscriptionsAddScreen} />
            <PrivateRoute exact path='/promo-codes' component={PromoCodesScreen} />
            <PrivateRoute exact path='/public-contact-form-messages' component={PublicContactMessagesScreen} />
            <PrivateRoute exact path='/transactions' component={TransactionsScreen} />
            <PrivateRoute exact path='/transactions/:userId' component={TransactionsScreen} />


            <Route exact path='/authentication/activate/:emailValidationKey' component={EmailValidationScreen} />
            <Route exact path='/authentication/reset-password/:resetPasswordToken' component={ResetPasswordScreen} />
            {/* <PrivateRoute exact path='/dashboard' component={DashboardScreen} />
            <PrivateRoute exact path='/admin' component={AdminScreen} />
            <PrivateRoute exact path='/admin/add' component={AddAdminScreen} />
            <PrivateRoute exact path='/admin/info/:adminId' component={AddAdminScreen} />
            <PrivateRoute exact path='/categories' component={CategoriesScreen} />
            <Route exact path='/categories/add' component={AddEditCategoryScreen} />
            <PrivateRoute exact path='/categories/edit/:categoryId' component={AddEditCategoryScreen} />
            <PrivateRoute exact path='/mani' component={ManilerScreen} />
            <PrivateRoute exact path='/mani/add' component={AddManiScreen} />
            <PrivateRoute exact path='/mani/edit/:maniId' component={AddManiScreen} />
            <PrivateRoute exact path='/coupon' component={CouponScreen} />
            <PrivateRoute exact path='/coupon/add' component={AddCouponScreen} />
            <PrivateRoute exact path='/coupon/edit/:couponId' component={AddCouponScreen} />
            <PrivateRoute exact path='/job' component={JobsScreen} />
            <PrivateRoute exact path='/marital' component={MaritalScreen} />
            <PrivateRoute exact path='/users' component={UserScreen} />
            <PrivateRoute exact path='/users/edit/:userId' component={UserEditScreen} />
            <PrivateRoute exact path='/users/seen-manis/:userId' component={UserSeenManiScreen} />
            <PrivateRoute exact path='/users/liked-manis/:userId' component={UserLikedManiScreen} />
            <PrivateRoute exact path='/users/favorite-manis/:userId' component={UserFavoriteManiScreen} />
            <PrivateRoute exact path='/notification' component={NotificationScreen} />
            <PrivateRoute exact path='/create-notifications' component={CreateNotificationsScreen} />
            <PrivateRoute exact path='/sponsor' component={SponsorScreen} />
            <PrivateRoute exact path='/sponsor/add' component={AddSponsorScreen} />
            <PrivateRoute exact path='/sponsor/edit/:sponsorId' component={AddSponsorScreen} />
            <PrivateRoute exact path='/contact' component={ContactScreen} />
            <PrivateRoute exact path='/temporary-premium' component={TemporaryPremiumScreen} />
            <PrivateRoute exact path='/my-account' component={MyAccountScreen} />
            <Route exact path='/authentication/activate/:emailValidationKey' component={EmailValidationScreen} /> */}
            {/* <PrivateRoute exact path='/gambling-wheel' component={GamblingWheelScreen} /> */}
            {/* <PrivateRoute exact path='/test-private' component={() => (<h1>PRIVATE ROUTE</h1>)} /> */}
            <Route component={NotFound} />
            </Switch>
          </ContentBody>
        </Router>
      </SocketProvider>
        <div className="App">

        </div>
    </Provider>
  );
}

export default App;
