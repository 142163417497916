import React, {useState, useEffect, useMemo} from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Redux
import store from '../../store'

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchUserList } from '../../actions/userActions/fetchUserList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';
import { resetFilters } from '../../actions/filterActions/resetFilters';




// MUI
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { UserTableHead } from './UserTableHead';
import { UserItem } from './UserItem';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { SendNotificationButton } from '../../components/notification/SendNotificationButton';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { SearchQueryComponent } from '../../components/filter/SearchQueryComponent';
import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';
import { LocationFilterComponent } from '../../components/filter/LocationFilterComponent';
import { InterestFilterComponent } from '../../components/filter/InterestFilterComponent';
import { NotificationFilterComponent } from '../../components/filter/NotificationFilterComponent';
import { UserTypeFilterComponent } from '../../components/filter/UserTypeFilterComponent';
import { EmailFilterComponent } from '../../components/filter/EmailFilterComponent';
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';



const defaultFiltersKeyToKeysMap = {
  searchQuery: 'searchQueryText',
  languages: 'languageList',
  locations: 'locationList',
  interests: 'interestList',
  notification: 'notification',
  userType: 'userType',
  email: 'email',
};


export const UserScreen = () => {
    // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);
  const itemCount = useSelector(state => state.filterReducer.itemCount);

  const userList = useSelector(state => state.userReducer.userList);




  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);


  





  




  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _activityFilterOptions, _setActivityFilterOptions ]  = useState([
    { 
      label: 'Search Query', isChecked: false, 
      // component: SearchQueryComponent, 
      component: null, 
      // defaultFilterValue: _initialFilteringParameters.searchQuery  ,
      defaultFilterKey: 'searchQuery'  ,
    },
    { 
      label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { 
      label: 'Locations', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'locations'  ,
    },
    { 
      label: 'Interests', isChecked: false, 
      component: null,
      // defaultFilterValue: _initialFilteringParameters.interests  ,
      defaultFilterKey: 'interests'  ,
    },
    { 
      label: 'Notifications', isChecked: false, 
      // component: NotificationFilterComponent  ,
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.notification  ,
      defaultFilterKey: 'notification'  ,
    },
    { 
      label: 'User Type', isChecked: false, 
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.userType  ,
      defaultFilterKey: 'userType'  ,
    },
    { 
      label: 'Emails', isChecked: false, 
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.email  ,
      defaultFilterKey: 'email'  ,
    },

  ])


  const filteringParamsForNotification = useMemo(() => {
    return  _activityFilterOptions.reduce((result, item) => {
      const filterKey = defaultFiltersKeyToKeysMap[item.defaultFilterKey];
      if(item.isChecked && filterKey) {
        result[filterKey] = filteringParameters[filterKey];
      }
      return result;
    }, {});

  }, [filteringParameters, _activityFilterOptions]);
  console.log(filteringParamsForNotification, filteringParameters, _activityFilterOptions);


  useEffect(() => {
    fetchUserList({
      limit: pageFilterCount,
      skip: 0,
      filteringParameters : {},
      order,
      cb: ( apiResponse ) => {
        // console.log('UserScreen -> fetchUserList -> cb -> apiResponse -> ', apiResponse)
        _setLoading(false);
      }
    })
    
    return () => {
      dispatch(
        resetFilters()
      );
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      fetchUserList({
        limit: pageFilterCount,
        skip: 0,
        order,
        filteringParameters,
        cb: apiResponse => {
          dispatch(
            setIsFilteringLoading(false)
          );
        }
      })
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])


  





  return (
    <Container
      id='user-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
    >
      <Helmet defer={false}><title>Users</title></Helmet>
      <Grid
        container
        direction='column'
        className='w-100'
        style={{
        }}
      >
        
        <ScreenTitle   
          titleText={(
            <>
              <span>
                {'Users'}
              </span>
              <FilterDialogButton
                filterOptions={ _activityFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  _setActivityFilterOptions(
                    [
                      ..._activityFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._activityFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  
                    // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  
                    // remove component
                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey: _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  let tempActivityFilterOptions = [
                    ..._activityFilterOptions.map(
                      (item, ind) => {
                        return {
                          ...item,
                          isChecked: (ind === inputIndex) 
                            ?
                            !item.isChecked
                            :
                            item.isChecked
                        }
                      }
                    )
                  ];
                  _setActivityFilterOptions(
                    tempActivityFilterOptions
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                    onClick={() => {
                      // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                      dispatch(
                        setIsHideActiveUiFilters()
                      );
                    }}
                  >
                    {
                      isHideActiveUiFilters 
                        ?
                        <VisibilityIcon color='error' />
                        :
                        <VisibilityOffIcon />

                    }
                    
                  </IconButton>
                )
              }
            </>
          )}    
        />

        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>

            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Search Query')
                && _activityFilterOptions.find( item => item.label === 'Search Query').isChecked
                &&  (() => <SearchQueryComponent />)()
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Languages')
                && _activityFilterOptions.find( item => item.label === 'Languages').isChecked
                &&  <LanguageFilterComponent />
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Locations')
                && _activityFilterOptions.find( item => item.label === 'Locations').isChecked
                &&  <LocationFilterComponent />
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Interests')
                && _activityFilterOptions.find( item => item.label === 'Interests').isChecked
                &&  <InterestFilterComponent />
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Notifications')
                && _activityFilterOptions.find( item => item.label === 'Notifications').isChecked
                &&  <NotificationFilterComponent />
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'User Type')
                && _activityFilterOptions.find( item => item.label === 'User Type').isChecked
                &&  <UserTypeFilterComponent />
            }
            {
              _activityFilterOptions 
                && _activityFilterOptions.find( item => item.label === 'Emails')
                && _activityFilterOptions.find( item => item.label === 'Emails').isChecked
                &&  <EmailFilterComponent />
            }



            



            {
              !isHideActiveUiFilters
              &&
              _filterUiElements && _filterUiElements.length > 0 &&
              _filterUiElements.map(
                ( item , ind )=> {
                  return (
                    <React.Fragment key={ind}>
                      { item.component &&  item.component() }
                    </React.Fragment>
                  );
                }
              )
            }

          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}

        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                {/* // Table */}
                <CustomTable 
                >
                  <UserTableHead/>
                  <TableBody>
  
                    {
                      isPaginationLoading !== true && isFilteringLoading !== true &&
                      userList && userList.length > 0 && userList.map(
                        (userItem, ind) => (
                          <UserItem
                            key={ind}
                            userItem={userItem  }
                          />
                        )
                      )
                    }
                  </TableBody>
                </CustomTable>
                {/* // End of Table */}
                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }
                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    store.dispatch(setIsPaginationLoading(true))
                    store.dispatch(setPaginationPage(page))
                    fetchUserList({
                      cb: apiResponse => {
                        dispatch(
                          setIsPaginationLoading(false)
                        );
                      },
                      filteringParameters,
                      limit: pageFilterCount,
                      order: order,
                      skip: ( page - 1  ) * pageFilterCount
                    });  
                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={itemCount}
                  isScreenLoading={false}
        
                />
              </>
            )
        }








      </Grid>
      
      {/* Bottom Buttons */}      
      <Grid
        container
        direction='row'
        className='w-100 mb-2'
        style={{
        }}
      >
        <Grid item>
          <SendNotificationButton
              filteringParameters={filteringParamsForNotification}
              userCount={itemCount}
          />
        </Grid>
      </Grid>
      {/* End of Bottom Buttons */}
      
    </Container>
  )
}
