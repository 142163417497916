import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'



// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




export const TicketVerificationTableHead = () => {


  return (
    <TableHead id='manidar-mani-table-head'>
      <TableRow>
        {/* <StyledTableCell size= 'small' align='left'>Type</StyledTableCell> */}
        <StyledTableCell size= 'small' align='left'>Event</StyledTableCell>
         {/* <StyledTableCell size= 'small' align='center'>Language</StyledTableCell> */}
         <StyledTableCell size= 'small' align='center'>Verif. Date</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>V. Co.</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Verifier</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Ticket Owner</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
