import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  INTEREST_ADDED,
  INTEREST_ADD_STARTED ,
  INTEREST_ADD_ERROR
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";






// Fetch All Maritals
export const addInterest = ({
  formData,
  cb  // callBack
}) => async dispatch => {

  try {
    dispatch({
      type: INTEREST_ADD_STARTED,
    });
    
 
    const res = await axios.post(
      urlConstants.addInterestUrl, 
      formData,
      clientAppConstants.jsonConfig
    ); 
    dispatch(setAlert(res.data.msg, "success", 2500));






    dispatch({
      type: INTEREST_ADDED,
      payload: res.data.interest
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    dispatch({
      type: INTEREST_ADD_ERROR,
    });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
} 