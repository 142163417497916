


// https://stackoverflow.com/questions/54355208/how-to-add-1-hour-to-new-date-object-created-in-javascript

/*
  const myDate = new Date();
  const newDate = new Date(myDate);
  newDate.setHours(newDate.getHours() + 1);
  console.log(myDate, newDate);
*/

export const addSubtractHourToDate = ({
  inputDate,
  hourToAdd
}) => {
  const currentInputDate = new Date(inputDate);
  const newDate = new Date(currentInputDate);
  newDate.setHours(newDate.getHours() + hourToAdd);
  return newDate;
}