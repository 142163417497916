


// This method converts input to an Integer greater than or equal to a minimum value
export const convertToIntegerGreaterThanOrEqualToMinValue = ({ inputValue, minValue }) => {
  console.log(inputValue)
  if ( 
    inputValue === null 
    || inputValue === undefined  
  ) { return minValue; }
  if ( typeof(inputValue) !== 'string' || inputValue === '' || inputValue === ' ' ) {  return minValue; }
  let tempString = inputValue;
  // https://stackoverflow.com/questions/1862130/strip-all-non-numeric-characters-from-string-in-javascript
  tempString = tempString.replace ( /[^0-9]/g, '' );
  // https://stackoverflow.com/questions/4564414/delete-first-character-of-a-string-in-javascript
  while ( tempString.length > 1 && tempString[0] === '0'  ) { 
    tempString = tempString.slice(1);
  }
  if ( tempString === '' ||  tempString === ' ' || parseInt(tempString) <= minValue) {
    return minValue;
  }
  return parseInt(tempString);
} 