import {useForm } from "react-hook-form";
import {zodResolver} from '@hookform/resolvers/zod';
import { yupResolver } from '@hookform/resolvers/yup'

import {useCallback, useEffect, useMemo, useState} from "react";
import {Grid} from "@material-ui/core";
import {z} from "zod";
import {SelectField} from "../../components/form/SelectField";
import axios from "axios";
import {clientAppConstants, urlConstants} from "../../constants";
import {SwitchField} from "../../components/form/SwichField";
import {TextField} from "../../components/form/TextField";
import {FormikImageControl} from "../../components/form/FormikImageControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {detectMyTimezoneHelper} from "../../helpers/dateAndTime/detectMyTimezoneHelper";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import {getClassroomImageUrl} from "../../helpers/image";
import {convertToFormData} from "../../helpers/form/convertToFormData";
import omit from 'lodash/omit';
import moment from "moment";
import * as Yup from "yup";
import {convertToLocalTimezone} from "../../helpers/dateAndTime/eventDate";

const validationSchema = Yup.object().shape({
    isEventOnline: Yup.boolean(),
    language: Yup.string().required(),
    isClassroom: Yup.boolean(),
    eventDate: Yup.string().when('isClassroom', {
        is: false,
        then: (schema) =>  schema.test('minDate', "event date can't be in the past", (value) => {
            const currentDate = moment();
            return currentDate.isBefore(moment(value));
        }),
        otherwise: (schema) => schema.nullable(true)
    }),
    timezone: Yup.number().when('isClassroom', {
        is: true,
        then: schema => schema.nullable(true),
        otherwise: schema => schema.min(-12).max(13).required()
    }),
    maxAttendee: Yup.number().min(1).required(),
    address: Yup.string().when('isEventOnline', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    offlineEventListShortDefinition: Yup.string().when('isEventOnline', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    videoLink: Yup.string().when('isClassroom', {
        is: true,
        then: (schema) => schema.nullable(true),
        otherwise: (schema) => schema.when('isEventOnline', {
            is: true,
            then: (schema) => schema.url().required(),
            otherwise: (schema) => schema.nullable(true),
        }),
    }),
    eventBoldText: Yup.string().required(),
    eventRegularText: Yup.string().required(),
    duration: Yup.number().min(15).max(500).required(),
    freeEvent: Yup.boolean(),
    ticketPrice: Yup.number().when('freeEvent', {
        is: false,
        then: (schema) => schema.min(1).required(),
        otherwise: (schema) => schema.transform(() => 0).nullable(true),
    }),
    eventTitle: Yup.string().min(3).required(),
    classroomId: Yup.string().when('isClassroom', {
        is: true,
        then: (schema) => schema.nullable(true),
        otherwise: (schema) => schema.required(),
    }),
    eventHost: Yup.string().required(),
    level: Yup.string().required(),
    eventInterestId: Yup.string().when('isClassroom', {
        is: true,
        then: (schema) => schema.nullable(true),
        otherwise: (schema) => schema.required(),
    }),
    isTicketBySubscriptionPossible: Yup.boolean(),
    countryId: Yup.string().when('isEventOnline', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    cityId: Yup.string().when('isEventOnline', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    onlineEventVerticalImageId: Yup.mixed().when('isEventOnline', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    onlineEventHorizontalImageId: Yup.mixed().when('isEventOnline', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    offlineEventImageId: Yup.mixed().when('isEventOnline', {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    commission: Yup.object().shape({
        count: Yup.number(),
        type: Yup.string(),
    }),
});


const onlineEventSchema = z.object({
    onlineEventVerticalImageId: z.any(),
    onlineEventHorizontalImageId: z.any(),
    videoLink: z.string().min(3),
});

const offlineEventSchema = z.object({
    offlineEventImageId: z.any(),
    countryId: z.string().min(3),
    cityId: z.string().min(3),
    address: z.string().min(3),
});

const notFreeEventSchema = z.object({
    ticketPrice: z.number().min(1),
});

const eventDateSchema = z.object({
    eventDate: z.string().refine(value => moment(value).isBefore(moment()), "Event date must be in future"),
});



const getUrl = (isClassroom, item, classroomId) => {
    switch (true) {
        case isClassroom && Boolean(item):
            return urlConstants.updateClassroom(item._id);
        case isClassroom && !item:
            return urlConstants.addClassroom;
        case !isClassroom && Boolean(item):
            return urlConstants.updateEvent(item._id);
        case !isClassroom && !item:
            return urlConstants.createEvent(classroomId);
    }
}

const convertServerImages = (event) => {

    if (event.onlineEventVerticalImageId) {
        event.onlineEventVerticalImageId = getClassroomImageUrl(event.onlineEventVerticalImageId);
    }
    if (event.onlineEventHorizontalImageId) {
        event.onlineEventHorizontalImageId = getClassroomImageUrl(event.onlineEventHorizontalImageId);
    }
    if (event.offlineEventImageId) {
        event.offlineEventImageId = getClassroomImageUrl(event.offlineEventImageId);
    }
    return event;
}

export const EventForm = ({
                              isClassroom,
                              baseItem = {},
                              item,
                              onSuccessfulSubmit,
                          }) => {

    const [languageList, setLanguageList] = useState([])
    const [interestList, setInterestList] = useState([])
    const [hostList, setHostList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);

    const defaultValues = useMemo(() => {
        const result = item ? {...convertServerImages({...item}) } : {
            isEventOnline: false,
            freeEvent: false,
            isTicketBySubscriptionPossible: true,
            ticketPrice: 20,
            duration: 45,
            maxAttendee: 30,
            timezone: detectMyTimezoneHelper(),
            commission: {
                type: 'percent',
                amount: 5,
            },
            countryId: '',
            cityId: '',
            eventInterestId: '',
            languageId: '',
            eventHost: '',
            ...convertServerImages(omit(baseItem, ["_id"])),
            isClassroom,
        };
        if(!isClassroom && !item) {
            result.classroomId = baseItem._id;
        }
        if(result.eventDate) {
            result.eventDate = moment.utc(result.eventDate).add(result.timezone, "hours").format('YYYY-MM-DDTHH:mm');
        }
        return result;
    }, []);

    const {control, handleSubmit, watch, setError, formState } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });


    const fetchCityList = useCallback(async (countryId) => {
        setLoading(true);
        try {
            const response = await axios.post(urlConstants.fetchCountryCityList, {
                countryId
            });
            setCityList(response.data.country?.cityList?.map(item => ({
                value: item._id,
                label: item.cityTitle,
            })) ?? []);
        } catch (e) {
            if (e.response.data?.errors?.length) {

            }
            console.log(e);
        }
        setLoading(false);
    }, []);

    const fetchDictionaries = useCallback(async () => {
        setLoading(true);
        try {
            const [languageResponse, interestsResponse, hostResponse, countriesResponse] = await Promise.all([
                axios.get(urlConstants.fetchLanguageUrl),
                axios.get(urlConstants.fetchInterestUrl),
                axios.get(urlConstants.fetchHostList),
                axios.post(urlConstants.fetchCountryList, {
                    limit: 1000
                }),
            ]);

            setHostList(hostResponse.data.hostList.map(item => ({value: item._id, label: item.nameSurname ?? item.email})));
            setLanguageList(languageResponse.data.map(item => ({value: item._id, label: item.languageTitle})));
            setInterestList(interestsResponse.data.map(item => ({value: item._id, label: item.interestTitle})));
            setCountryList(countriesResponse.data.countryList.map(item => ({
                value: item._id,
                label: item.en,
                cityList: item.cityList
            })));
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchDictionaries();
    }, []);


    const onSubmit = useCallback(async (data) => {
        const url = getUrl(isClassroom, item, baseItem._id);
        try {
            setLoading(true);
            if(data.eventDate) {
                data.eventDate = moment.utc(data.eventDate).add(-data.timezone, "hours").format('YYYY-MM-DDTHH:mm:ss');
                data.clientTimeZone = detectMyTimezoneHelper();
            }
            await axios.post(url, convertToFormData(data), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onSuccessfulSubmit();
        } catch (e) {
            if (e.response?.data?.errors?.length) {
                e.response.data.errors.forEach(error => {
                    setError(error.param, {type: 'manual', message: error.msg});
                });
            }
            console.log(e);
        }
        setLoading(false);
    }, []);

    const levelList = useMemo(() => {
        return clientAppConstants.languageLevelOptionList.map((level) => {
            return {
                value: level.value,
                label: level.definition,
            }
        })
    }, []);

    const [isEventOnline, freeEvent, countryId, eventDate, timezone] = watch(["isEventOnline", "freeEvent", "countryId", "eventDate", "timezone"]);


    useEffect(() => {
        console.log('countryId', countryId);
        if (countryId) {
            fetchCityList(countryId);
        }
    }, [countryId]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GlobalCircularProgress show={loading}/>
            <Grid container spacing={2}>
                <Grid container spacing={2}>
                    <Grid md={4} item>
                        <SelectField name="isEventOnline" label="Online" disabled={!isClassroom || item}
                                     control={control}
                                     options={[{value: true, label: "Online"}, {value: false, label: "Offline"}]}/>
                    </Grid>
                    <Grid md={4} item>
                        <SelectField name="language" label="Language" control={control} options={languageList}/>
                    </Grid>
                    <Grid md={4} item>
                        <SelectField name="level" label="Level" control={control} options={levelList}/>
                    </Grid>
                    <Grid md={4} item>
                        <SelectField name="eventHost" label="Host" control={control} options={hostList}/>
                    </Grid>
                    <Grid md={4} item>
                            <SelectField name="eventInterestId" label="Interest" control={control}
                                         options={interestList}/>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2 mb-2">
                    {isEventOnline ? <>
                        <Grid md={4} item>
                            <FormikImageControl name="onlineEventVerticalImageId" label="Vertical image"
                                                control={control}/>
                        </Grid>
                        <Grid md={4} item>
                            <FormikImageControl name="onlineEventHorizontalImageId" label="Horizontal image"
                                                control={control}/>
                        </Grid>
                    </> : <Grid md={4} item><FormikImageControl name="offlineEventImageId" label="Image"
                                                                control={control}/>
                    </Grid>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={4} item>
                        <TextField name="duration" label="Duration" type="number" control={control}/>
                    </Grid>
                    {!isClassroom && <>
                        <Grid md={4} item>
                            <TextField name="eventDate" min={new Date()} label="Event date" type="datetime-local"
                                       control={control}/>
                            In your timezone {eventDate && moment.utc(eventDate).add(detectMyTimezoneHelper() - timezone, "hours").format('DD.MM.YYYY HH:mm')}
                            <div>In UTC timezone {eventDate && moment.utc(eventDate).add(-timezone, "hours").format('DD.MM.YYYY HH:mm')}</div>
                        </Grid>
                        <Grid md={4} item>
                            <TextField name="timezone" label="Timezone" type="number" control={control}/>
                        </Grid>
                    </>}
                    <Grid md={4} item>
                        <TextField name="maxAttendee" label="Max attendee" type="number" control={control}/>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid md={12} item><TextField name="eventTitle" label="Title" control={control}/></Grid>
                    <Grid md={12} item>
                        {isEventOnline ?
                            (<>

                                {!isClassroom ? <TextField name="videoLink" label="Video link" control={control}/> : null}
                            </>) :
                            <TextField name="offlineEventListShortDefinition" multiline
                                       label="Event List Definition Text"
                                       control={control}/>}
                    </Grid>

                    <Grid md={12} item><TextField name="eventBoldText" label="Bold text" multiline
                                                  control={control}/></Grid>
                    <Grid md={12} item><TextField name="eventRegularText" label="Regular text" multiline
                                                  control={control}/></Grid>

                    {!isEventOnline && (
                        <>
                            <Grid md={12} item>
                                <h4>Location information</h4>
                            </Grid>
                            <Grid md={4} item><SelectField name="countryId" label="Country" control={control}
                                                           options={countryList}/></Grid>
                            <Grid md={4} item><SelectField name="cityId" label="City" control={control}
                                                           disabled={!cityList.length}
                                                           options={cityList}/></Grid>
                            <Grid md={4} item><TextField name="address" label="Address" control={control}/></Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={12} item>
                        <h4>Ticket</h4>
                    </Grid>
                    <Grid md={4} item><SwitchField name="freeEvent" label="Free event" control={control}/></Grid>
                    {!freeEvent && <>
                        <Grid md={4} item><SwitchField name="isTicketBySubscriptionPossible"
                                                       label="Ticket by subscription" control={control}/></Grid>
                        <Grid md={12} item/>
                        <Grid md={4} item><TextField name="ticketPrice" label="Ticket price" type="number"
                                                     control={control}/></Grid>
                        <Grid md={12} item>
                            <h4>Host Commission</h4>
                        </Grid>
                        <Grid md={4} item><TextField name="commission.amount" label="Amount" type="number"
                                                     control={control}/></Grid>
                        <Grid md={4} item><SelectField name="commission.type" label="Type" control={control}
                                                       options={[{value: 'fixed', label: 'fixed'}, {
                                                           value: 'percent',
                                                           label: 'percent'
                                                       },]}/></Grid>
                    </>}
                </Grid>
                <Grid container spacing={2}>
                    <Box m={2}>
                        <Button type="submit" variant="contained" color="primary"> Submit </Button>
                    </Box>
                </Grid>
            </Grid>


        </form>
    );
}