import React, { useState } from 'react'
import{  NavLink } from 'react-router-dom'



// Material Components
import {
  IconButton,
} from '@material-ui/core';


// MUI
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';


// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { xDaysLater } from '../../helpers/common/xDaysLater';

// Constants
import {  urlConstants } from '../../constants';



export const UserItem = ({
  userItem,
}) => {
  const {
    _id,
    email,
    authLoginType,
    dateOfBirth,
    nameSurname,
    userAvatar,
    phoneNumber,
    totalPaymentValue,
    paymentCount
  } = userItem;
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <StyledTableRow>


      {/* <StyledTableCell
        size='small'
      >
        {
          nameSurname ?? '-'
        }
      </StyledTableCell> */}
      <StyledTableCell
        size='small'
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          alignContent='center'
        >
            <Avatar
              sizes= { 'small' }
              style={{ marginLeft: '.1rem', marginRight:'.5rem' }}
              // className={classes.largeAvatar}
              alt={_id} 
              sx={{ width: 40, height: 40 }}
              src= {
                userAvatar && urlConstants.getSingleUserAvatar( userAvatar )
                  // ? urlConstants.getSingleUserAvatar( userAvatar )
                  // :
                  // (
                  //   userAvatarImage || socialAuthImageUrl
                  // )
              }
            />
            <Stack direction={'column'}>
              <span>{nameSurname ?? '-'}</span>
              <span>{email ?? '-'}</span>
            </Stack>
        </Stack>
      </StyledTableCell>




      {/* <StyledTableCell
        size='small'
      >
        {email}
      </StyledTableCell> */}
      <StyledTableCell
        align='left'
        size='small'
      >
        {authLoginType}
      </StyledTableCell>
        <StyledTableCell
            align='center'
            size='small'
        >{phoneNumber ?? '-'}</StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {
          dateOfBirth 
          ?
          convertToMomentOnlyDate(  dateOfBirth )
          : 
          '-'
        }
      </StyledTableCell>
        <StyledTableCell
            align='right'
            size='small'
        >
            <span className='mr-2'>${userItem.balance}</span>
        </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
        <span className='mr-2'>${totalPaymentValue ?? 0}</span>
      </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
        {paymentCount ?? 0}
      </StyledTableCell>
      
      <StyledTableCell
        align='center'
        size='small'
      >
        <IconButton
          size="small"
          component={NavLink}
          // to={`/event/edit/${_id}`}
          to={`/user/edit/${_id}`}
        >
          <MoreHorizIcon
          />
        </IconButton>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
