import React , { useState }  from 'react'
import {  useDispatch } from "react-redux";





// MUI
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';


// Icons
import SendIcon from '@mui/icons-material/Send';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

// Components
import { StyledTableCell, StyledTableRow } from '../../components/table/StyledTableComponents'
import { CustomDialog } from '../../components/CustomDialog';


// Actions
import { updateCity } from '../../actions/countryActions.js/updateCity';
import { deleteCity } from '../../actions/countryActions.js/deleteCity';




export const CityItemComponent = ({
  cityItem,
  cbHandleUpdateCity,
  cbHandleDeleteCity,
}) => {
  const {
    _id,
    // countryEn,
    cityTitle,
  } = cityItem;

  const dispatch = useDispatch();
  
  const _initialFormData = {  
    newCityTitle : ''
  };
  const [formData, setFormData ] = useState({..._initialFormData});
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ _isUpdating, _setIsUpdating ] = useState(false);
  const [ _isShowUpdateTextField, _setIsShowUpdateTextField ] = useState(false);


  const _cbUpdateCity = (responseFromApi) => {
    if ( responseFromApi && responseFromApi.city ) {
      cbHandleUpdateCity( responseFromApi.city )
    }
    _setIsUpdating(false);
    setFormData({_initialFormData});
    _setIsShowUpdateTextField(false);
  }

  return (
    <StyledTableRow>
      <StyledTableCell size={'small'} className='text-bold'>
        {cityTitle}
      </StyledTableCell>


      <StyledTableCell size={'small'}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ isDeleting || _isUpdating  }
          // onClick={handleClose}
        ></Backdrop>
        {
          isDeleting
            ?
            (
              <p>Is Deleting...</p>
            )
            :
            (
              <>
                {
                  _isShowUpdateTextField && (
                    <div className="mr-2" style={{display:'inline'}}>
                      <TextField
                        placeholder='Update City!'
                        label='Update City'
                        // required={true}
                        // disabled={_loading}
                        // fullWidth={true}
                        // variant='outlined'
                        // id='title'
                        // name='title'
                        // https://stackoverflow.com/questions/48343802/controlled-textfield-loosing-focus-after-every-character-from-keyboard
                        autoFocus={true}  // to prevent losing focus  
                        value={formData.newCityTitle}
                        size='medium'
                        type='text'
                        onBlur={() => {}}
                        // autoComplete={false}
                        onChange={ e => {
                          setFormData({...formData, newCityTitle: e.target.value});
                        }}
                      />
                      <IconButton
                        disabled={_isUpdating}
                        onClick={() => {                            
                          _setIsUpdating(true);
                          dispatch(
                            updateCity({
                              cityId:  _id,
                              formData: { ...formData, cityTitle: formData.newCityTitle },
                              cb: _cbUpdateCity
                            })
                          );
                          _setIsShowUpdateTextField( false );
                        }}
                      >
                        <SendIcon color='primary'/>
                      </IconButton>
                      <IconButton
                        disabled={_isUpdating}
                        onClick={() => {
                          setFormData({...formData, newCityTitle: ''});
                          _setIsShowUpdateTextField( false );
                        }}
                      >
                        <HighlightOffIcon color='inherit'/>
                      </IconButton>
                    </div>
                  )
                }
                {
                  !_isShowUpdateTextField && (
                    <IconButton
                      disabled={_isUpdating}
                      onClick={() => {
                        _setIsShowUpdateTextField( !_isShowUpdateTextField );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                }
                <IconButton
                  onClick={() => setIsModalOpen(true)}
                  disabled={_isUpdating}
                  color="secondary"       
                >
                  <DeleteForeverIcon />
                </IconButton>

              </>
            )
        }
        <CustomDialog
          ariaLabelledBy={`interest-id`}
          ariaDescribedBy={`interest-id-2`}
          cancelButtonText={ 'Cancel' }
          confirmButtonText={ 'OK' }
          dialogTitle={'Delete Confirmation!'}
          dialogContentText={'Are you sure you want to delete?'}
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleCancel={() => {
            setIsModalOpen(false);
            // and whatever
          }}
          handleConfirm={() => {
            setIsModalOpen(false);
            // and whatever
            setIsDeleting(true);
            dispatch(
              deleteCity({
                cityId: _id,
                cb: apiResponse => {
                  if ( apiResponse ) {
                    cbHandleDeleteCity( apiResponse.city  )
                  }
                  setIsDeleting(false)
                }
              })
            )
          }}
        />
      </StyledTableCell>



    </StyledTableRow>
  )
}
