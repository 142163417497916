import {useCallback, useMemo, useState} from "react";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import {classRoomOnlineSchema} from "../../validations/event";
import {TextField} from "../../components/form/TextField";
import {Button, Dialog} from "@material-ui/core";
import axios from "axios";
import {urlConstants} from "../../constants";
import {toast} from "react-toastify";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import {SwitchField} from "../../components/form/SwichField";

const updateValidationSchema = z.object({
    discount: z.number().min(0).max(100),
    validUntil: z.string().optional(),
    disabled: z.boolean().optional(),
});

const createValidationSchema = z.object({
    code: z.string().min(3),
    discount: z.number().min(0).max(100),
    validUntil: z.string().optional(),
    disabled: z.boolean().optional(),
});

export const PromoCodeForm = ({
    item,
    onSuccessSubmit,
    onClose,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const initialFormState = useMemo(() => item ? {
        ...item,
        validUntil: item.validUntil ? new Date(item.validUntil).toISOString().split('T')[0] : null,
    } : {
        code: '',
        discount: 0,
        disabled: false,
    }, [item]);

    const validationSchema = useMemo(() => item ? updateValidationSchema : createValidationSchema, [item]);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: initialFormState,
        resolver: zodResolver(validationSchema),

    });

    console.log(errors);

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true);
        try {
            const url = item ? urlConstants.updatePromoCode(item._id) : urlConstants.createPromoCode;
            const response = await axios.post(url, { ...values });
            onSuccessSubmit(response.promoCode)
        } catch (e) {
            console.error(e);
            toast(e.message)
        }
        setIsLoading(false);
    }, [])

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" >
        <form onSubmit={handleSubmit(onSubmit)} className="p-3" style={{ width: '300px'}}>
            <GlobalCircularProgress show={isLoading}/>
            <h3>{item ? "Update promo code" : "Create promo code"}</h3>
            <div className="mb-2">
                <TextField name="code" disabled={Boolean(item)} label="Code" control={control}/>
            </div>
            <div className="mb-2">
                <TextField name="discount"  disabled={Boolean(item)} label="Discount (%)" type="number" control={control}/>
            </div>
            <div className="mb-2">
                <TextField name="validUntil" label="Valid until" type="date" control={control}/>
            </div>
            <div className="mb-2">
                <SwitchField name="disabled" label="Disabled" control={control}/>
            </div>
            <div className="mb-2">
                <Button type="submit" variant="contained" color="primary">Submit</Button>
            </div>
        </form>
        </Dialog>
    )
}