import React from 'react'
import{  NavLink } from 'react-router-dom'


// MUI
import Button from '@mui/material/Button';


export const TextButtonForTableCell = ({
  to,
  buttonText
}) => {
  return (
    <Button 
      variant="text"
      style={{
        textTransform: 'none',
        lineHeight: 1.2,
        textAlign: 'center'
      }}
      // onClick={() => {
      //   history.push('/event/edit/' + '_id')
      // }}
      component={ NavLink}
      to={to}
    >
      {buttonText}
    </Button> 
  )
}
