import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {
  SET_USER_AS_HOST_LOADING,
  SET_USER_AS_HOST_SUCCESS,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const setUserAsHost = ({
  userId,
  willUserBeHost,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const formData = {
      userId,
      willUserBeHost
    };
    dispatch({
      type: SET_USER_AS_HOST_LOADING,
      payload: true
    });


    const res = await axios.post(
      urlConstants.setUserAsHost, 
      formData,
      clientAppConstants.jsonConfig
    );  
    dispatch(setAlert(res.data.msg, "success", 2500));

    dispatch({
      type: SET_USER_AS_HOST_SUCCESS,
      payload: res.data.isHost
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {    
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: SET_USER_AS_HOST_LOADING,
      payload: false
    });
    if ( cb ) {
      cb(null);
    }
  }
}  