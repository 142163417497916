import React from 'react'
import { Bar, defaults } from 'react-chartjs-2';


const data = {
  // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  labels: ['16-25', '26-30', '31-40', '41-50', '50+', 'Unselected'],

  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const BarChartAgeDistribution = ({
  audienceAccordingToAge
}) => {

  const {
    totalVotes ,
    age1625 ,
    age2630 ,
    age3140 ,
    age4150 ,
    age50Plus ,
    unSelected ,
  } = audienceAccordingToAge;
  let _data =  {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        data : [
          age1625 ,
          age2630 ,
          age3140 ,
          age4150 ,
          age50Plus ,
          unSelected ,
        ]
      }
    ]
  };  
  return (
    <Bar 
    // data={data} 
    data={_data} 
      // options={options} 
      // width={350}
      height={200}
      options={{
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
          legend: {
              position: 'top'
          },
          title: {
            display: true,
            text:'Audience According to Age'
          },
          // subtitle: {
          //   display: true,
          //   text: 'Custom Chart Subtitle'
          // }
      },
      }} 
    />
  )
}




