import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOKEN_FAIL,
  APP_INITIALISED,
  ADMIN_LOADED,
  AUTH_ERROR,
  SET_AUTH_LOADING,
} from "../../actions/types";
import { setAlert } from "../alertActions/setAlert";




export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};