import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux"



import {
  Grid
} from '@material-ui/core';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components

// Actions
import { fetchLocationsForFilter } from '../../actions/filterActions/fetchLocationsForFilter';
import { setLocationsFilter } from '../../actions/filterActions/setLocationsFilter';
import { handleLocationItemClick } from '../../actions/filterActions/handleLocationItemClick'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export const LocationFilterComponent = () => {
  // Global Variables
  const locationList = useSelector(state => state.filterReducer.filteringParameters.locationList);
  const isLocationsLoading = useSelector(state => state.filterReducer.isLocationsLoading);
  const fetchedLocations = useSelector(state => state.filterReducer.fetchedLocations);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();


  // const _dummyLocations = [
  //   { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  // ]
  const [ _languageOptions, _setLanguageOptions] = useState([])
  const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  const [  _isLocationOptionsOpen, _setIsLocationOptionsOpen ] = useState(false)



  return (
    <Grid item  className={'pb-1 pr-1 pt-2' +   isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      
      <Autocomplete
      
        multiple
        id={'speaklabs-language-filter-component'}
        sx={{ width: 300 }}
        open={_isLocationOptionsOpen}
        disableCloseOnSelect
        onOpen={() => {
          _setIsLocationOptionsOpen(true)
          if ( fetchedLocations.length === 0 ) {
            dispatch(
                fetchLocationsForFilter({})
            );
          } 
        }}
        onClose={() => {
          _setIsLocationOptionsOpen(false)
        }}
        onChange={(e, newVal )=> {
          dispatch(
            setLocationsFilter({
              newLocationFilterList: newVal
            })
          );
        }}
        value={ locationList }
        getOptionLabel={(option) => {
          return option.label;
        }}
        options={fetchedLocations.map(
          locationItem => {
            return {
              ...locationItem,
              label: locationItem.en

            }
          }
        )}
        loading={isLocationsLoading}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={ e => {
                dispatch(
                  handleLocationItemClick({
                    locationItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  locationList.findIndex(
                    item => item.label === option.label
                  ) > -1
                } 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Locations'}

            InputProps={(() => { 
              let xxx = params;
              return {
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLocationsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),

                startAdornment: (
                  <React.Fragment>
                    {(() => {
                      return params.InputProps.startAdornment;
                    })()}
                  </React.Fragment>
                ),
              }
            })()}
          />
        )}
      />
    </Grid>
  );
}