import React from 'react'

import {
  Container,
  // CircularProgress,
  Button,
  TableContainer,
  Table,
  TableBody,
  Paper,
  Grid
} from '@material-ui/core';

import CircularProgress from '@mui/material/CircularProgress';

export  const ScreenCircularProgress = () => {
  return (
    <div
      className='flexrow justify-content-center w-100'
      style={{
        minHeight: 180,
        paddingTop: 120
      }}
    >
      <CircularProgress />
    </div>
  );
}

