import {
  CONTACT_MESSAGES_FETCHED,
  SET_CONTACT_MESSAGES_LOADING,
  CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED,
  SET_IS_DISMISSING_ALL_CONTACT_MESSAGES ,
  ALL_CONTACT_MESSAGES_DISMISSED ,
} from "../actions/types";


const initialState = {
  contactMessageList: [],
  isLoading: true,
  isContactMessagesFetched: false,
  isDismissingAllContactMessages: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case CONTACT_MESSAGES_FETCHED:      
      return {
        ...state,
        contactMessageList: [...payload],
        isLoading: false,
        isContactMessagesFetched: true,
      };
    case SET_CONTACT_MESSAGES_LOADING:      
      return {
        ...state,
        contactMessageList: payload === true ? [] : state.contactMessageList,
        isLoading: payload
      }; 
    case CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED:      
      return {
        ...state,
        contactMessageList: state.contactMessageList.map(
          item => {
            if ( item._id === payload._id ) {
              return {
                ...item,
                isContactMessageDismissed: payload.isContactMessageDismissed
              };
            }
            return item;
          }
        ),
      }; 
    case SET_IS_DISMISSING_ALL_CONTACT_MESSAGES:      
      return {
        ...state,
        isDismissingAllContactMessages: payload
      }; 
    case ALL_CONTACT_MESSAGES_DISMISSED:      
      return {
        ...state,
        contactMessageList: state.contactMessageList.map(
          item => {
            return {
              ...item,
              isContactMessageDismissed: true
            };
          }
        ),
        isDismissingAllContactMessages: false
      }; 
    default:
      return state;
  }
}