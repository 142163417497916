import React from 'react'
import { styled } from '@mui/material/styles';


export const StyledSpan1  = styled('span')(({ theme }) => ({
  // ...theme.typography.button,
  ...theme.typography.fontWeightMedium,
  // backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));



