






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_USER_TYPES_FILTER } from "../types";





export const setUserTypeFilter = ({  
  newUserTypeFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_USER_TYPES_FILTER,
    payload: {
      isReset,
      newUserTypeFilterList: [ ...newUserTypeFilterList ]
    }
  });
}  




