import {
  // CLASSROOMS_FETCHED,
  // SET_CLASSROOMS_LOADING,
  SUBSCRIPTION_CLASSES_FETCHED,
  SET_SUBSCRIPTION_CLASS_LOADING ,
} from "../actions/types";


const initialState = {
  subscriptionClassList: [],
  isLoading: true,
  isSubscriptionClassesFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case SUBSCRIPTION_CLASSES_FETCHED:      
      return {
        ...state,
        subscriptionClassList: [...payload],
        isLoading: false,
        isSubscriptionClassesFetched: true,
      };
    case SET_SUBSCRIPTION_CLASS_LOADING:      
      return {
        ...state,
        subscriptionClassList: payload === true ? [] : state.subscriptionClassList,
        isLoading: payload
      }; 

    

    default:
      return state;
  }
}