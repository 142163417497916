import * as React from 'react';


// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

// Components
import { MuiStyledTableCell } from '../../../components/table/collapsableTableComponents/CustomCollapsableTable';




export const HostCalculationMuiTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <MuiStyledTableCell size= 'small' align='center'>Event</MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='center'>E. Date</MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='center'>
          Type
        </MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='right'>
          <Tooltip arrow 
            title='Max. Attendee' 
            // placement='bottom'
          >
            <span>Max.</span>
          </Tooltip>
        </MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='right'>
          <Tooltip arrow 
            title='Ticket Count' 
            // placement='bottom'
          >
            <span>Tickets</span>
          </Tooltip>
        </MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='right'>
          <Tooltip arrow 
            title='Reservation Count' 
            // placement='bottom'
          >
            <span>Res.</span>
          </Tooltip>
        </MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='right'>
          <Tooltip arrow 
            title='Ticket Price' 
            // placement='bottom'
          >
            <span>Price</span>
          </Tooltip>
        </MuiStyledTableCell>
        <MuiStyledTableCell size= 'small' align='right'>Total</MuiStyledTableCell>
      </TableRow>
    </TableHead>
  )
}
