import React from 'react'
import { CustomTable } from '../../components/table/CustomTable'
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead'
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow'
import { StyledTableCell } from '../../components/table/StyledTableComponents'
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton'
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime'

export const SubscriptionPackageDetailTable = ({
  formData,
  languageList,
}) => {
  const {
    subscriptionTitle,
    subscriptionExplanation,
    price,
    validPeriod,
    isAllLanguages,
    language,
    onlineOffline,  // 'All' || 'Onnline'  || ''Offline'
    isLimitlessTicket,
    ticketCount,
    isActive,
  } = formData
  return (
    <CustomTable>
      <DoubleColumnTableHead />
      
      <DoubleColumnTableRow 
        keyTitle='Title'
        valueText={subscriptionTitle}
      />
      <DoubleColumnTableRow 
        keyTitle='Explanation'
        valueText={ subscriptionExplanation }
      />
      <DoubleColumnTableRow 
        keyTitle='Price'
        valueText={'$' +  price }
      />
      <DoubleColumnTableRow 
        keyTitle='Valid Period'
        valueText={validPeriod.count + ' ' + validPeriod.type}
      />
      {/* <DoubleColumnTableRow 
        keyTitle='Online-Offline Event'
        valueText={onlineOffline}
      /> */}
      {/* <DoubleColumnTableRow 
        keyTitle='Languages'
        valueText={
          isAllLanguages 
            ? 'All Languages'
            : languageList.find(
              item => item._id === language
            ).languageTitle
        }
      /> */}
      <DoubleColumnTableRow 
        keyTitle='Tickets'
        valueText={
          isLimitlessTicket 
            ? 'Limitless Ticket'
            : ticketCount + 'ea.'
        }
      />

      <DoubleColumnTableRow 
        keyTitle='Is Active'
        valueText={
          isActive ? 'Active' : 'Passive'
        }
      />
      
    </CustomTable>
  )
}
