import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux"



import {
  Grid
} from '@material-ui/core';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components

// Actions
import { fetchLanguagesForFilter } from '../../actions/filterActions/fetchLanguagesForFilter';
import { setLanguagesFilter } from '../../actions/filterActions/setLanguagesFilter';
import { handleLanguageItemClick } from '../../actions/filterActions/handleLanguageItemClick'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export const LanguageFilterComponent = () => {
  // Global Variables
  const languageList = useSelector(state => state.filterReducer.filteringParameters.languageList);
  const isLanguagesLoading = useSelector(state => state.filterReducer.isLanguagesLoading);
  const fetchedLanguages = useSelector(state => state.filterReducer.fetchedLanguages);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();


  // const _dummyLanguages = [
  //   { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  // ]
  const [ _languageOptions, _setLanguageOptions] = useState([])
  const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)



  return (
    <Grid item  className={'pb-1 pr-1 pt-2' +   isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      
      <Autocomplete
      
        multiple
        id={'speaklabs-language-filter-component'}
        sx={{ width: 300 }}
        open={_isLanguageOptionsOpen}
        disableCloseOnSelect
        onOpen={() => {
          _setIsLanguageOptionsOpen(true)
          if ( fetchedLanguages.length === 0 ) {
            dispatch(
              fetchLanguagesForFilter({})
            );
          } 
        }}
        onClose={() => {
          _setIsLanguageOptionsOpen(false)
        }}
        onChange={(e, newVal )=> {
          dispatch(
            setLanguagesFilter({
              newLanguageFilterList: newVal
            })
          );
        }}
        value={ languageList }
        getOptionLabel={(option) => {
          return option.label;
        }}
        options={fetchedLanguages.map(
          languageItem => {
            return {
              ...languageItem,
              label: languageItem.languageTitle

            }
          }
        )}
        loading={isLanguagesLoading}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={ e => {
                dispatch(
                  handleLanguageItemClick({
                    languageItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  languageList.findIndex(
                    item => item.label === option.label
                  ) > -1
                } 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Languages'}

            InputProps={(() => { 
              let xxx = params;
              return {
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLanguagesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),

                startAdornment: (
                  <React.Fragment>
                    {(() => {
                      return params.InputProps.startAdornment;
                    })()}
                  </React.Fragment>
                ),
              }
            })()}
          />
        )}
      />
    </Grid>
  );
}