import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux"



import {
  Grid
} from '@material-ui/core';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { fetchInterestsForFilter } from '../../actions/filterActions/fetchInterestsForFilter';
import { setInterestsFilter } from '../../actions/filterActions/setInterestsFilter';
import { handleInterestItemClick } from '../../actions/filterActions/handleInterestItemClick';
import { fetchHostsForFilter } from "../../actions/filterActions/fetchHostsForFilter";
import { setHostsFilter } from "../../actions/filterActions/setHostsFilter";
import { handleHostItemClick } from "../../actions/filterActions/handleHostItemClick";
import { fetchClassroomsForFilter } from '../../actions/filterActions/fetchClassroomsForFilter';
import { setClassroomsFilter } from '../../actions/filterActions/setClassroomsFilter';
import { handleClassroomItemClick } from '../../actions/filterActions/handleClassroomItemClick';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;








export const ClassroomFilterComponent = () => {
  // Global Variables
  const isClassroomsLoading = useSelector(state => state.filterReducer.isClassroomsLoading);
  const classroomList = useSelector(state => state.filterReducer.filteringParameters.classroomList);
  const fetchedClassrooms = useSelector(state => state.filterReducer.fetchedClassrooms);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();

  const [  _isClassroomOptionsOpen, _setIsClassroomOptionsOpen ] = useState(false)

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName}
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      {/* <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isClassroomOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setClassroomFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _classroomOptions.length === 0 ) {
            _setIsClassroomOptionsLoading(true)
            _setIsClassroomOptionsOpen(true)
            setTimeout(() => {
              _setClassroomOptions( [ ..._dummyClassrooms ] );
              _setIsClassroomOptionsLoading(false)
            }, 750)
          } else {
            _setIsClassroomOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsClassroomOptionsOpen(false)
        }}
        loading={  _isClassroomOptionsLoading }
        options = {[ ..._classroomOptions ]}
        htmlId='event-screen-classroom-autocomplete'
        autoCompleteLabel='Classrooms'
      /> */}
      <Autocomplete      
        multiple
        id={'speaklabs-classroom-filter-component'}
        sx={{ width: 300 }}
        open={_isClassroomOptionsOpen}
        disableCloseOnSelect
        onOpen={() => {
          _setIsClassroomOptionsOpen(true)
          if ( fetchedClassrooms.length === 0 ) {
            dispatch(
              fetchClassroomsForFilter({})
            );
          } 
        }}
        onClose={() => {
          _setIsClassroomOptionsOpen(false)
        }}
        onChange={(e, newVal )=> {
          dispatch(
            setClassroomsFilter({
              newClassroomFilterList: newVal
            })
          );
        }}
        value={ classroomList }
        getOptionLabel={(option) => {
          return option.label;
        }}
        options={fetchedClassrooms.map(
          classroomItem => {
            return {
              ...classroomItem,
              label: classroomItem.eventTitle 

            }
          }
        )}
        loading={isClassroomsLoading}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={ e => {
                dispatch(
                  handleClassroomItemClick({
                    classroomItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  classroomList.findIndex(
                    item => item.label === option.label
                  ) > -1
                } 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Classrooms'}

            InputProps={(() => { 
              let xxx = params;
              return {
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isClassroomsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),

                startAdornment: (
                  <React.Fragment>
                    {(() => {
                      return params.InputProps.startAdornment;
                    })()}
                  </React.Fragment>
                ),
              }
            })()}
          />
        )}
      />
    </Grid>
  );
}