export const getClassroomImage = (id) => {
    return `/api/image/classroom/${id}`;
}

export const getChatImage = (id) => {
    return `/api/image/chat/${id}`;
}

export const getAvatarImage = (id) => {
    return `/api/image/avatar/${id}`;
}