import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { setUserTypeFilter } from '../../actions/filterActions/setUserTypeFilter';
import { handleUserItemClick } from '../../actions/filterActions/handleUserItemClick';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const UserTypeFilterComponent = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const userType = useSelector(state => state.filterReducer.filteringParameters.userType);
  const dispatch = useDispatch();

  const _dummyUserTypeOptions = [
    { label: 'Hosts' },  { label: 'Ticket Verifiers' }, 
  ]
  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      
      <Autocomplete
        multiple
        id={ 'speaklabs-user-type-autocomplete' }
        options={_dummyUserTypeOptions}
        onChange={(e, newVal )=> {
          dispatch(
            setUserTypeFilter({
              newUserTypeFilterList: newVal
            })
          )
        }}
        value={userType}
        // filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        // defaultValue={[top100Films[13]]}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleUserItemClick({
                    userItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                // checked={selected} 
                checked={
                  userType.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params

              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  // <>YARRAK AMK</>
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'User Type'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  )
};