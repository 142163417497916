import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  INTEREST_UPDATED
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";






export const updateCity = ({
  cityId,
  formData,
  cb  // callBack
}) => async dispatch => {

  try {    
 
    const res = await axios.put(
      urlConstants.updateCityUrl(cityId), 
      formData,
      clientAppConstants.jsonConfig
    ); 
    if ( cb ) {      
      cb(res.data);      
    }
    // dispatch({
    //   type: INTEREST_UPDATED,
    //   payload: res.data.interest
    // });
    dispatch(setAlert(res.data.msg, "success", 2500));

    
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
} 