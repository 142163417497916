import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';

import {
  Container,
  // CircularProgress,
  // Button,
  // TableContainer,
  // Table,
  TableBody,
  // Paper,
  Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import { CustomTable } from '../../components/table/CustomTable';
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import { TicketVerificationTableHead } from './TicketVerificationTableHead';
import { ScreenTitle } from '../../components/title/ScreenTitle';

// Filter
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';
import { ClassroomFilterComponent } from '../../components/filter/ClassroomFilterComponent';
import { SinceDateFilterComponent } from '../../components/filter/SinceDayFilterComponent';
import { UntilDateFilterComponent } from '../../components/filter/UntilDateFilterComponent';


import { TicketVerificationItem } from './TicketVerificationItem';
import { CustomPagination } from '../../components/CustomPagination';


// Actions
import { fetchTicketVerificationList } from '../../actions/ticketActions/fetchTicketVerificationList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';

// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';



export const  TicketVerificationScreen = ({

}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);


  const ticketVerificationList = useSelector(state => state.ticketReducer.ticketVerificationList);

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);





  // const _dummyClassrooms = [
  //   { label: 'French Speaking Club' },  { label: 'English Dancing' }, { label: 'Russian Karaoke' },
  // ]
  // const [ _classroomOptions, _setClassroomOptions] = useState([])
  // const [ _isClassroomOptionsLoading, _setIsClassroomOptionsLoading ]= useState(false)
  // const [  _isClassroomOptionsOpen, _setIsClassroomOptionsOpen ] = useState(false)


  // const _dummyLanguages = [
  //   { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  // ]
  // const [ _languageOptions, _setLanguageOptions] = useState([])
  // const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  // const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)


  // const _dummyInterests = [
  //   { label: 'Speaking Club' },  { label: 'Dinner' },  { label: 'Language Trip' },  { label: 'Karaoke' },
  // ]
  // const [ _interestOptions, _setInterestOptions] = useState([])
  // const [ _isInterestOptionsLoading, _setIsInterestOptionsLoading ]= useState(false)
  // const [  _isInterestOptionsOpen, _setIsInterestOptionsOpen ] = useState(false)

  
  // const _dummyHosts = [
  //   { label: 'George Best' },  { label: 'Grigory Leps' },  { label: 'Tom Cruise' },  { label: 'Maria Sharapova' },
  // ]
  // const [ _hostOptions, _setHostOptions] = useState([])
  // const [ _isHostOptionsLoading, _setIsHostOptionsLoading ]= useState(false)
  // const [  _isHostOptionsOpen, _setIsHostOptionsOpen ] = useState(false)

  // // Filter Parameters for User
  // const _initialFilteringParameters = {
  //   onlineOffline: [],
  //   classroom: [],
  //   searchQuery:  '',
  //   languages: [],
  //   interests: [],
  //   hosts: [],
  //   interests: [],
  //   notification: [],
  //   email: [],
  //   // registrator: [],
  //   // approver: [],
  //   // status: [],
  //   orderBy: null, // 'Goruntulenme' etc
  //   // order: 'asc'  // 'asc' || 'desc'
  // };
  // const _initialOrder = 'asc';
  // const [_filteringParameters, _setFilteringParameters] = useState({ ..._initialFilteringParameters });
  // const [order, setOrder] = useState(_initialOrder);  // 'asc' || 'desc' || null
  // const _setOnlineOfflineFilter = (newValue) => {
  //   _setFilteringParameters({ ..._filteringParameters, onlineOffline: [...newValue] });
  // }
  // const _setClassroomFilter = (newValue) => {
  //   _setFilteringParameters({ ..._filteringParameters, classroom: [...newValue] });
  // }
  // const _setLanguageFilter = (newValue) => {
  //   _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  // }
  // const _setInterestFilter = (newValue) => {
  //   _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  // }
  // const _setHostFilter = (newValue) => {
  //   _setFilteringParameters({ ..._filteringParameters, hosts: [...newValue] });
  // }



  


  useEffect(() => {
    dispatch(
      fetchTicketVerificationList({
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('EventScreen -> fetchTicketVerificationList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
    }
    // eslint-disable-next-line
  }, [])



  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      dispatch(
        fetchTicketVerificationList({
          limit: pageFilterCount,
          skip: 0,
          order,
          filteringParameters,
          cb: apiResponse => {
            dispatch(
              setIsFilteringLoading(false)
            );
          }
        })
      )
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])


  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Classrooms', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { label: 'Since-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ])


  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }




  return (
    <Container
      id='ticket-verification-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>Ticket Verifications</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Ticket Verifications'}</span> 
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  // _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  // _setFilteringParameters({..._initialFilteringParameters});
                  // setOrder(_initialOrder);  
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                >
                  {
                    isHideActiveUiFilters 
                      ?
                      <VisibilityIcon color='error' />
                      :
                      <VisibilityOffIcon />

                  }
                  
                </IconButton>
                )
              }

            </>
          )}  
        />
        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>


            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Languages')
                && _screenFilterOptions.find( item => item.label === 'Languages').isChecked
                // && !_isHideActiveUiFilters
                // &&  LanguageFilterComponent()
                &&  <LanguageFilterComponent />
            }            
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Classrooms')
                && _screenFilterOptions.find( item => item.label === 'Classrooms').isChecked
                // && !_isHideActiveUiFilters
                &&  <ClassroomFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Since-Date')
                && _screenFilterOptions.find( item => item.label === 'Since-Date').isChecked
                // && !_isHideActiveUiFilters
                &&  <SinceDateFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Until-Date')
                && _screenFilterOptions.find( item => item.label === 'Until-Date').isChecked
                // && !_isHideActiveUiFilters
                &&  <UntilDateFilterComponent />
            }


            
            {
              !_isHideActiveUiFilters
              &&
              _filterUiElements && _filterUiElements.length > 0 &&
              _filterUiElements.map(
                ( item , ind )=> {
                  return (
                    <React.Fragment key={ind}>
                      { item.component &&  item.component() }
                    </React.Fragment>
                  );
                }
              )
            }  

          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}



        {/* Table */}
        {/* //---------------------------------------------------------- */}
        {
          (
            isPaginationLoading !== true && isFilteringLoading !== true &&
            ticketVerificationList && ticketVerificationList.length === 0
          ) 
            ? 
            (
              <Grid item className='w-100 py-5'>
                <h4 className='text-center'>No Events!</h4>
              </Grid>
            )
            :
            (
              <CustomTable 
                // stickyHeader={false}
              >
                <TicketVerificationTableHead/>
                <TableBody>
                  {/* <TicketVerificationItem />
                  <TicketVerificationItem />
                  <TicketVerificationItem /> */}
                  {
                    isPaginationLoading !== true && isFilteringLoading !== true &&
                    ticketVerificationList && ticketVerificationList.map(
                      (ticketVerificationItem, ind) => (
                        <TicketVerificationItem
                          key={ind}
                          ticketVerificationItem={ticketVerificationItem  }
                        />
                      )
                    )
                  }
                </TableBody>
              </CustomTable>
            )
        }
        {/* End of Table */}
        {/* //---------------------------------------------------------- */}

        {
          (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
        }



        <CustomPagination
          currentPaginationIndex={paginationPage}
          handleOnChange={( e, page ) => {
            if( page === paginationPage  || isPaginationLoading  ) {
              return;
            }
            dispatch(
              setIsPaginationLoading(true)
            );
            dispatch(
              setPaginationPage(page)
            );
            dispatch(
              fetchTicketVerificationList({
                cb: apiResponse => {
                  dispatch(
                    setIsPaginationLoading(false)
                  );
                },
                filteringParameters,
                limit: pageFilterCount,
                order: order,
                skip: ( page - 1  ) * pageFilterCount
              })
            );

          }}
          isPaginationLoading={ isPaginationLoading }
          itemCount={60}
          isScreenLoading={false}
        />
        

        {/* <CustomPagination
          currentPaginationIndex={1}
          handleOnChange={() => {}  }
          isPaginationLoading={ false }
          itemCount={60}
          isScreenLoading={false}

        /> */}

        {/* <p>sdfsdf</p> */}

      </Grid>
    </Container>
  )
}

