import {
  INTERESTS_FETCHED,
  INTEREST_ADDED,
  INTEREST_ADD_STARTED,
  INTEREST_ADD_ERROR,
  INTEREST_UPDATED,
  INTEREST_DELETED
} from "../actions/types";


const initialState = {
  interests: [],
  loading: true,
  isInterestsFetched: false,
  isAddingInterest: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INTERESTS_FETCHED:      
      return {
        ...state,
        interests: [...payload],
        loading: false,
        isInterestsFetched: true,
      };
    case INTEREST_ADD_STARTED:
      return {
        ...state,
        isAddingInterest: true,
      };
    case INTEREST_ADDED:
      return {
        ...state,
        interests: [...state.interests, payload],
        loading: false,
        isAddingInterest: false,
      };
    case INTEREST_ADD_ERROR:
      return {
        ...state,
        isAddingInterest: false,
      };
    case INTEREST_UPDATED:
      return {
        ...state,
        interests: state.interests.map( interestItem =>
          interestItem._id === payload._id ? payload : interestItem
        ),
        loading: false,
      }
    case INTEREST_DELETED:
      return {
        ...state,
        interests: state.interests.filter( interestItem => interestItem._id !== payload._id   ),
        loading: false,
      }
    default:
      return state;
  }
}