import React, {useContext, useState, useEffect} from 'react';
import {  useSelector, useDispatch } from "react-redux";

import {makeStyles} from '@material-ui/core/styles';


// MUI
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

// Components
import Navbar from './Navbar/Navbar';

// Actions
import { setNavbarOnOff } from '../actions/decorationActions/setNavbarOnOff';
import { setHideNavButtons } from '../actions/decorationActions/setHideNavButtons';
import { setScreenWidth } from '../actions/decorationActions/setScreenWidth';




// const drawerWidth = 240;
const drawerWidth = 180;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



const ContentBody = ({
  children
}) =>  {

  // Global Variables
  const isNavbarOn = useSelector(state => state.decorationReducer.isNavbarOn);
  const isHideNavButtons = useSelector(state => state.decorationReducer.isHideNavButtons);
  const screenWidth = useSelector(state => state.decorationReducer.screenWidth);
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user =  useSelector(state => state.authReducer.user); 
  // const userType =  useSelector(state => state.authReducer.userType); 

  const dispatch = useDispatch();

  const userType = 'admin';
  const isAuthenticated = true;
  const user = 'SOME DATA'


  let desktopPublic =  {
    paddingTop: 47,
    width: '100vw',
    minHeight: '90vh'
  };
  let desktopPrivate =  {
    top: 64,
    // width: 'calc(100vw-180px) !important',
    paddingTop: 47,
    paddingLeft: 180,
    minHeight: '90vh',
    overflow: 'scroll'
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    content: {
      flexGrow: 1,
      // backgroundColor: '#ccc',
      paddingLeft: 180
    },
    contentDrawerOff: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      // paddingLeft: 180
    },
  }));
  const classes = useStyles();

  //  let _innerWidth = window.innerWidth

  let _limitWidth = 900;

  //  const [ _innerWidth, _setInnerWidth ] = useState( window.innerWidth  )
   const handleWindowResize = () => {
    // _setInnerWidth( window.innerWidth );
    dispatch(
      setScreenWidth(  window.innerWidth )
    );
    
   }

  useEffect(() => {
    if ( window.innerWidth >= _limitWidth  ) {
      dispatch(
        setNavbarOnOff(  true )
      );
    }
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);



  useEffect(() => {
    if ( screenWidth >= _limitWidth ) {
      dispatch(
        setHideNavButtons( true )
      );
      dispatch(
        setNavbarOnOff( true   )
      );
    } else {  // smaller screens
      dispatch(
        setHideNavButtons( false )
      );
      dispatch(
        setNavbarOnOff( false   )
      );
    }
  }, [screenWidth])


  // const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (    
    (
      userType === "admin" 
          ||  userType === "editor" || userType === "writer" ||  userType === "superAdmin"
    ) && isAuthenticated
    && user
      ?   (
        <div
          className={ isNavbarOn ? classes.content : classes.contentDrawerOff }
          // style={desktopPrivate}
        >
          {/* //----------------------------------------------------------- */}
          {
            !isHideNavButtons && (
              <div id='speaklabs-sticky-navbar-button'>

                <IconButton
                  
                  style={{
                    // position: 'absolute',
                    // left: '1rem'
                    // left: open ?  'calc( 180px + 2rem )' : '2rem'
                  }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {
                    // if ( open) {
                    //   handleDrawerClose()
                    // } else {
                    //   handleDrawerOpen()
                    // }
                    if ( isNavbarOn ) {
                      dispatch(
                        setNavbarOnOff( false )
                      )
                    } else  {
                      dispatch(
                        setNavbarOnOff( true )
                      )
                    } 
                    
                  }}
                  edge="start"
                  // sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )
          }
          <Drawer            
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            // variant="persistent"
            variant= { screenWidth > _limitWidth ? 'permanent' :  "persistent"}
            anchor="left"
            open={isNavbarOn}
          >
            <Navbar 
              handleClose={() => {
                // handleDrawerClose()
              }}
            />
          </Drawer>
          {/* //----------------------------------------------------------- */}
          {children}
        </div>
      )
      : (
        <div
          // style={desktopPublic}
        >
          {children}
        </div>
      )
  );  

  return (
    <div
      // style={desktopPublic}
    >
      
    {children}
    </div>
  );
}




export default ContentBody;