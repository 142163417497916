// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// // import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const initialState = {};

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;



// // TWISER dan

// import { createStore } from 'redux'

// import { composeWithDevTools } from 'redux-devtools-extension'
// import rootReducer from "./reducers";

// const initialState = {};

// const store = createStore(rootReducer, initialState, composeWithDevTools())

// export default store

// // End of TWISER dan



import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'

const store = createStore(rootReducer, applyMiddleware(thunk))
window.__STORE = store;
export default store