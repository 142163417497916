import {
  ACTIVITIES_FETCHED,
  SET_ACTIVITIES_LOADING,
  USER_ACTIVITIES_FETCHED ,
  SET_USER_ACTIVITIES_LOADING ,
  RESET_ACTIVITY_REDUCER,
} from "../actions/types";


const initialState = {
  activityList: [],
  isLoading: true,
  isActivitiesFetched: false,
  userActivityList: [],
  isUserActivitiesFetched: false,
  user: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case ACTIVITIES_FETCHED:      
      return {
        ...state,
        activityList: [...payload],
        isLoading: false,
        isActivitiesFetched: true,
      };
    case SET_ACTIVITIES_LOADING:      
      return {
        ...state,
        activityList: payload === true ? [] : state.activityList,
        isLoading: payload
      }; 

    case USER_ACTIVITIES_FETCHED:      
      return {
        ...state,
        userActivityList: payload.activityList,
        isLoading: false,
        isUserActivitiesFetched: true,
        user: payload.user,
      };
    case SET_USER_ACTIVITIES_LOADING:      
      return {
        ...state,
        userActivityList: payload === true ? [] : state.userActivityList,
        isLoading: payload
      }; 

    case RESET_ACTIVITY_REDUCER:      
      return {
        ...state,
        ...initialState
      }; 

    

    default:
      return state;
  }
}