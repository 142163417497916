

// https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript

/*
  var offset = new Date().getTimezoneOffset();
  console.log(offset);
  // if offset equals -60 then the time zone offset is UTC+01 
*/

export const detectMyTimezoneHelper = () => {
  let offset = new Date().getTimezoneOffset();
  // console.log(offset);
  let myTimezone = (offset / (-60));
  return myTimezone;
}