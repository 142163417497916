import {useCallback, useRef, useState} from "react";
import Button from "@mui/material/Button";

let fileIdCounter = 0;
export const FileControl = ({onChange, className, onBlur, onFocus, multiple, label, buttonProps, value: fileValue, ...rest}) => {
    const inputRef = useRef(null);
    const [id] = useState(() => {
         return 'file-id-' + (fileIdCounter++);
    })
    const handleChange = useCallback((e) => {
        let value;
        if(multiple) {
            value = e.target.files;
        } else if (e.target.files.length) {
            value = e.target.files[0];
        }
        onChange(value);
        onBlur?.(e);
    }, [onChange, onBlur]);

    const onButtonClick = useCallback((e) => {
        e.target = inputRef.current;
        inputRef.current?.click();
        onFocus?.(e);
    }, [onFocus, inputRef]);

    return (<Button variant="outlined" color="primary" {...buttonProps} style={{ flexShrink: 0, flexGrow: 0  }} onClick={onButtonClick}>
        <label htmlFor={id}>
        {label}
        <input {...rest} id={id} className={`${className} app-input-file`} multiple={multiple} type="file" onChange={handleChange} ref={inputRef} style={{ visibility: 'hidden'}} />
        </label>
    </Button>);
}