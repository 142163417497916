import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  LOCATION_FETCH_FOR_FILTERING_STARTED, LOCATIONS_FETCHED_FOR_FILTERING } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const fetchLocationsForFilter = ({
  cb  // callBack
}) => async dispatch => {
  try { 
    dispatch({
      type: LOCATION_FETCH_FOR_FILTERING_STARTED,
      payload: null
    });

    const res = await axios.post(
      urlConstants.fetchCountryList,
      {noLimit:true},
      clientAppConstants.jsonConfig
    ); 

    dispatch({
      type: LOCATIONS_FETCHED_FOR_FILTERING,
      payload: res.data.countryList
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: LOCATIONS_FETCHED_FOR_FILTERING,
      payload: []
    });
    if ( cb ) {
      cb([]);
    }
  }
}  