import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  ACTIVITIES_FETCHED,
  SET_ACTIVITIES_LOADING,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const fetchActivityList = ({
  limit,
  skip,
  filteringParameters,
  order,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const formData = {
      limit,
      skip,
      filteringParameters,
      order,
    }
    dispatch({
      type: SET_ACTIVITIES_LOADING,
      payload: true
    });


    const res = await axios.post(
      urlConstants.fetchActivityList, 
      formData ?? {},
      clientAppConstants.jsonConfig
    );  
    dispatch(setFilteringItemCount({itemCount: res.data.activityCount}));

    dispatch({
      type: ACTIVITIES_FETCHED,
      payload: res.data.activityList
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: SET_ACTIVITIES_LOADING,
      payload: false,
    });
    if ( cb ) {
      cb([]);
    }
  }
}  