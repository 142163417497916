import React, { useState } from 'react'
import{  NavLink } from 'react-router-dom'



// Material Components
import {
  TableRow,
  TableCell,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

// MUI

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';



export const SubscriptionItem = ({
  subscriptionClassItem
}) => {
  const {
      _id,
      subscriptionTitle,
      subscriptionExplanation,
      price,
      validPeriod,
      isAllLanguages,
      language,
      onlineOffline,  // 'All' || 'Onnline'  || ''Offline'
      isLimitlessTicket,
      ticketCount,
      isActive,
  } = subscriptionClassItem;
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        {/* French 3 Months Unlimited */}
        {subscriptionTitle}
      </StyledTableCell>
      <StyledTableCell
        size='small'
        align='right'
      >
        {'$' + price.toFixed(2)}
      </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
        { 78 }
      </StyledTableCell>      
      <StyledTableCell
        align='right'
        size='small'
      >
        { 2560 }
      </StyledTableCell>  


      <StyledTableCell
        align='center'
        size='small'
      >
        { isActive ? 'Active' : 'P' }
      </StyledTableCell>  

      <StyledTableCell
        align='center'
        size='small'
      >
        <IconButton
          size='small'
          component={NavLink}
          // to={`/event/edit/${_id}`}
          to={`/subscription/edit-package/${_id}`}
        >
          <MoreHorizIcon
          />
        </IconButton>

        
      </StyledTableCell>
    </StyledTableRow>
  )
}
