






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  RESET_ALL_FILTERS } from "../types";





export const resetFilters = () => async dispatch => {
  dispatch({
    type: RESET_ALL_FILTERS,
    payload: null
  });
}  




