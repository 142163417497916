import React from 'react'
import{  NavLink ,withRouter, useHistory} from 'react-router-dom'


// Constants
import { clientAppConstants } from '../../constants'

// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

export const TableNavlinkTextButton = ({
  buttonText,
  buttonLink
}) => {
  return (
    <Button 
      variant="text"
      // style={{
      //   textTransform: 'none',
      //   lineHeight: 1.2
      // }}
      style={{
        textTransform: 'none',
        lineHeight: 1.2,
        fontSize: '12.5px',

        // color:'teal',
        color: clientAppConstants.linkButtonTextColor,
        // color: 'red'
      }}
      component={NavLink}
      to={buttonLink}
      // onClick={() => {
      //   history.push('/event/edit/' + '_id')
      // }}
    >
      {buttonText}
    </Button> 
  )
}
