import {CircularProgress} from "@material-ui/core";

export const GlobalCircularProgress = ({ show }) => {
    return (
        <div className={`global-loader ${show ?  '' : 'global-loader--hidden'}`} >
        <div
        className='flexrow justify-content-center w-100'
        style={{
            minHeight: 180,
            paddingTop: 120
        }}
        >
        <CircularProgress />
        </div>
        </div>
    );
}