import React from 'react'

import { NavLink} from 'react-router-dom'

// MUI
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


// Components
import { MuiStyledTableCell } from '../../../components/table/collapsableTableComponents/CustomCollapsableTable';
import { TextButtonForTableCell } from '../../../components/buttons/TextButtonForTableCell';
import { InnerTicketRow } from './InnerTicketRow';

// Helpers
import { convertToMomentDateTime } from '../../../helpers/moment/convertToMomentDateTime';




export const InnerTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left"><span className='ml-3'>User</span></TableCell>
        <TableCell align="left" >Subscription</TableCell>
        <TableCell align="center" >Platform</TableCell>
        <TableCell align="center" >
          <Tooltip title='Ticket or Reservation' arrow>
            <span>T/R</span>
          </Tooltip>
        </TableCell>
        <TableCell align="center" >Act.</TableCell>
        <TableCell align="right" >Price</TableCell>
      </TableRow>
    </TableHead>
  )
}
