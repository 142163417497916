import React, {useState} from 'react'

// DatePicker Library imports
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  // necessary css for library


// Custom CSS
import './CustomDateTime2.css'

// helpers
import { getRoundedDate } from '../../helpers/common/getRoundedDate';

import { parseISO } from 'date-fns' 



/* 
  some possibly necessary links:
  https://www.npmjs.com/package/react-datepicker
  https://github.com/Hacker0x01/react-datepicker
  https://reactdatepicker.com/#example-specific-time-range
  https://www.positronx.io/react-datepicker-tutorial-with-react-datepicker-examples/
  https://codesource.io/complete-react-datepicker-tutorial-with-examples/


*/



export const CustomDateTime2 = ({
  selectedValue,
  onChange,
  isDontSetInnerSetState = false,
  required = false,
}) => {
  // const [startDate, setStartDate] = useState(
  //   setHours(setMinutes(new Date(), 30), 16)
  // );

  

  const [startDate, setStartDate] = useState(
    // new Date(
    //   Date.now()
    // ).setMinutes( Date.now(), 15)
    // getRoundedDate(15, new Date())
    null
  )
  return (
    <span className='custom-datetime-2'>

      <DatePicker
        required={required}
        selected={ selectedValue ??  startDate }
        onChange={(date) =>{ 
          // setStartDate(date)
          if ( !isDontSetInnerSetState ) {
            setStartDate(
              getRoundedDate(15, date)
            )
          }
          if ( onChange ) {
            onChange(
              getRoundedDate(15, date)
            );
          }
        }}
        showTimeSelect
        // excludeTimes={[
        //   setHours(setMinutes(new Date(), 0), 17),
        //   setHours(setMinutes(new Date(), 30), 18),
        //   setHours(setMinutes(new Date(), 30), 19),
        //   setHours(setMinutes(new Date(), 30), 17),
        // ]}
        timeIntervals={15}
        // dateFormat="MMMM d, yyyy h:mm aa"
        dateFormat="yyyy-MM-dd hh:mm aa"
      />
    </span>
  );
}
