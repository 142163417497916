import React from 'react'





// MUI
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


// Components
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'



export const NotificationScreenTableHead = () => {


  return (
    <TableHead id='manidar-mani-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>
          <Tooltip arrow title='Notification Title'>
            <span>Title</span>          
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Event</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>User</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='center'>Languages</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='center'>Interests</StyledTableCell> */}
        <StyledTableCell size= 'small' align='left'>Receiver</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
