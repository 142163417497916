import React, {useEffect} from 'react'

// import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';


import {
  // Container,
  // CircularProgress,
  // Button,
  // TableContainer,
  // Table,
  // TableBody,
  // Paper,
  // Grid
} from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Icons

// Style
import './ChatRoomScreen.css';

// Components
import { ChatScreenAppbar } from "./ChatScreenAppbar";
import { ChatMessagesContainer } from './ChatMessagesContainer';
import { ChatSendMessageComponent } from './ChatSendMessageComponent';
import {useChat} from "../../hooks/useChat";
import {useParams} from "react-router-dom/cjs/react-router-dom";





export const ChatRoomScreen = () => {
    const params = useParams();
  const { messages, message, setMessage, handleSend, messagesEnd } = useChat(params.chatLanguage);

  console.log('message', message);

    return (
    <Container
      id='speaklabs-chat-room-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        // minHeight: clientAppConstants.screenContainerMinHeight,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between',
        paddingLeft: 0,
        paddingRight: 0,
      }}
      // className='px-0'
    >
      <Helmet defer={false}><title>English Chat</title></Helmet>
      <Grid
        container
        direction='column'
        className='w-100'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ChatScreenAppbar />
          <ChatMessagesContainer messages={messages} >
              <div ref={messagesEnd} />
          </ChatMessagesContainer>
        <ChatSendMessageComponent message={message} setMessage={setMessage} handleSend={handleSend} />
      </Grid>
    </Container>
  )
}
