import Container from "@mui/material/Container";
import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {CustomTable} from "../../components/table/CustomTable";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import {IconButton, TableBody, TableHead, TableRow} from "@material-ui/core";
import axios from "axios";
import {CustomPaginationV2} from "../../components/CustomPaginationV2";
import Grid from "@mui/material/Grid";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {PromoCodeForm} from "./PromoCodeForm";
import { Edit } from "@material-ui/icons";
import moment from "moment";

export const PromoCodesScreen = () => {



    const [promoCodes, setPromoCodes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);
    const [openForm, setOpenForm] = React.useState(false);
    const [editedItem, setEditedItem] = React.useState(null);

    const fetchPromoCodes = useCallback(async (page) => {
        try {
            setIsLoading(true);
            const response = await axios.get(urlConstants.fetchPromoCodeList, {
                params: {
                    page: page,
                    limit: 10
                }
            });
            setPromoCodes(response.data.items);
            setCount(response.data.count);
        } catch (e) {
            console.log(e);
        }
         setIsLoading(false);
    }, []);


    useEffect(() => {
        console.log("first render ");
    }, []);

    const onSuccessSubmit = useCallback((promoCode) => {
        setOpenForm(false);
        setEditedItem(null);
        fetchPromoCodes(page);
    }, [page]);

    const onEdit = useCallback((promoCode) => {
        setEditedItem(promoCode);
        setOpenForm(true);
    }, []);

    const onCloseForm = useCallback(() => {
        setOpenForm(false);
        setEditedItem(null);
    }, []);

    useEffect(() => {
        fetchPromoCodes(page);
    }, [page]);

    console.log(openForm);
    return (<Container
        id='user-screen-container'
        maxWidth='lg'
        style={{
            backgroundColor: clientAppConstants.pageContainerBackgroundColor,
            // paddingTop: 64,
            minHeight: clientAppConstants.screenContainerMinHeight,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-between'
        }}
    >
        <Helmet defer={false}><title>Promo Codes</title></Helmet>
        <Grid
            container
            direction='column'
            className='w-100'
        >
            <ScreenTitle
                titleText="Promo Codes"
                />
            <GlobalCircularProgress show={isLoading}/>
            <Box justifyContent="flex-end" className="d-flex w-100" mt={2} mb={2}>
                <Button color="primary" variant="contained" onClick={() => setOpenForm(true)}>Create</Button>
            </Box>
                 <CustomTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell size= 'small' align='left'>Promo Code</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Discount</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Valid until</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Disabled</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Usage</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                     <TableBody>

                            {promoCodes.map((promoCode) => (
                                <TableRow
                                    key={promoCode.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell size= 'small' align='left'>{promoCode.code}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='left'>{promoCode.discount}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{promoCode.validUntil ? moment(promoCode.validUntil).format("YYYY-MM-DD") : ''}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{promoCode.disabled ? "Disabled" : ""}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{promoCode.usage}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>
                                        <IconButton size="small" onClick={() => onEdit(promoCode)}>
                                            <Edit fontSize="small" />
                                        </IconButton>
                                    </StyledTableCell>
                                </TableRow>
                            ))}
                     </TableBody>
                 </CustomTable>
                <CustomPaginationV2
                currentPaginationIndex={page}
                rowsPerPage={10}
                count={Math.ceil(count / 10)}
                handleOnChange={(event, newPage) => setPage(newPage)}
                />
            {openForm && <PromoCodeForm item={editedItem} onSuccessSubmit={onSuccessSubmit} onClose={onCloseForm}/>}
        </Grid>
    </Container>)
}
