import axios from "axios";


// const setAuthToken = (token) => {
//   if (token) {
//     axios.defaults.headers.common["token"] = token;
//   } else {
//     delete axios.defaults.headers.common["token"];
//   }
// };

// export default setAuthToken;

const setAuthToken = (auth) => {

  const { token, userType } = JSON.parse(auth);
  if (token && userType) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common["x-auth-token"] = token;
    axios.defaults.headers.common["userType"] = userType;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
    delete axios.defaults.headers.common["userType"];
  }
};

export default setAuthToken;