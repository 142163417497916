import {
  CLASSROOMS_FETCHED,
  SET_CLASSROOMS_LOADING,
} from "../actions/types";


const initialState = {
  classroomList: [],
  isLoading: true,
  isClassroomsFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case CLASSROOMS_FETCHED:      
      return {
        ...state,
        classroomList: [...payload],
        isLoading: false,
        isClassroomsFetched: true,
      };
    case SET_CLASSROOMS_LOADING:      
      return {
        ...state,
        classroomList: payload === true ? [] : state.classroomList,
        isLoading: payload
      }; 

    

    default:
      return state;
  }
}