import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'


import {

  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// Styles
import {  withStyles , makeStyles} from '@material-ui/core/styles';



export const UserActivityTableHead = () => {


  return (
    <TableHead id='user-activity-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>Definition</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Date</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='left'>Date</StyledTableCell> */}
        <StyledTableCell size= 'small' align='left'>Subscription Name</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Event Title</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Event Date</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Platform</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Payment</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Balance</StyledTableCell>
         {/* <StyledTableCell size= 'small' align='center'>Language</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Max. Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Ticket</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Registration</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell> */}
      </TableRow>
    </TableHead>
  )
}
