import * as Yup from "yup";
import {transaction, urlConstants} from "../../constants";
import {useCallback} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {TextField} from "../../components/form/TextField";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {AsyncAutocompleteField} from "../../components/form/AyncAutocompleteField";
import {SelectField} from "../../components/form/SelectField";
import {ImageUploaderControl} from "../../components/form/ImageUloaderControl";
import Dialog from "@mui/material/Dialog";
import React from "react";
import Button from "@mui/material/Button";

const transactionValidationSchema = Yup.object().shape({
    eventId:  Yup.string().transform(value => {
        if (value === null) {
            return undefined;
        }
        return value.value;
    }).when("type", {
        is: transaction.type.INCOME,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(true),
    }),
    userId: Yup.string().transform(value => {
        if (value === null) {
            return undefined;
        }
        return value.value;
    }).required(),
    type: Yup.string().required().test("type", `type should be on of ${Object.values(transaction.type).join(", ")}`, (value) => {
        return Object.values(transaction.type).includes(value);
    }),
    status: Yup.string().required().test("status", `status should be on of ${Object.values(transaction.type).join(", ")}`, (value) => {
        return Object.values(transaction.status).includes(value);
    }),
    platform: Yup.string().required().test("platform", `status should be on of ${Object.values(transaction.platform).join(", ")}`, (value) => {
        return Object.values(transaction.platform).includes(value);
    }),
    amount: Yup.number().min(1).required(),
    description: Yup.string().nullable(true),
    imageId: Yup.string().nullable(true),
});
export const CreateTransaction = ({
                               onSuccessSubmit,
                               onClose,
                           }) => {
    const initialValues = {
        userId: "",
        type: transaction.type.PAYOUT,
        status: transaction.status.SUCCESS,
        platform: transaction.platform.MANUAL,
        amount: 0,
        description: "",
    }
    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true);
        try {
            await axios.post(urlConstants.createTransaction, values);
            toast.success("Transaction created successfully");
            onSuccessSubmit();
        } catch (e) {
            console.error(e);
            toast.error(e.message);
        }
        setIsLoading(false);

    }, []);

    const {control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(transactionValidationSchema),
    });

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" >
        <form onSubmit={handleSubmit(onSubmit)} className="p-3">
            <h3>Create Transaction</h3>
            <div className="mb-2">
                <AsyncAutocompleteField
                    name="eventId"
                    control={control}
                    label="Event"
                    url={urlConstants.eventAutocomplete}
                />
            </div>
            <div className="mb-2">
                <AsyncAutocompleteField
                    name="userId"
                    control={control}
                    label="User"
                    url={urlConstants.userAutocomplete}
                />
            </div>
            <div className="mb-2">
                <SelectField
                    name="type"
                    control={control}
                    label="Type"
                    options={Object.values(transaction.type).map((type) => ({value: type, label: type}))}
                />
            </div>
            <div className="mb-2">
                <SelectField
                    name="status"
                    control={control}
                    label="Status"
                    options={Object.values(transaction.status).map((type) => ({value: type, label: type}))}
                />
            </div>
            <div className="mb-2">
                <SelectField
                    name="platform"
                    control={control}
                    label="Platform"
                    options={Object.values(transaction.platform).map((type) => ({value: type, label: type}))}
                    disabled={true}
                />
            </div>
            <div className="mb-2">
                <TextField
                    name="amount"
                    control={control}
                    label="Amount"
                    type="number"
                />
            </div>
            <div className="mb-2">
                <TextField
                    name="description"
                    control={control}
                    label="Description"
                    type="text"
                    multiline={true}
                />
            </div>
            <div className="mb-2">
                <ImageUploaderControl
                    name="imageId"
                    control={control}
                    label="Upload Image"
                />
            </div>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>Create</Button>
        </form>
        </Dialog>
    );
}