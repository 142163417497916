import React from 'react'
import axios from "axios";
import { NavLink } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { urlConstants } from '../../constants'
import {useHistory} from "react-router-dom";

export const ClassroomAddEditScreenBottomButtons = ({
  classroomId
}) => {
  const history = useHistory();
  
  const handleClick = () => {
    const confirm = window.confirm("Are you sure you want to hide this classroom?");
    if(confirm) {
      hideClassroom();
    }
  }

  const hideClassroom = async () => {
    try {
      const _url = urlConstants.hideClassroom;
      await axios.post(_url, {classroomId});
      history.push("/classroom");
    } catch(err) {
      console.log(err);
    }
  };

  return (
   
    <Grid item sm={12} className='mt-2 w-100'>
      <Grid container sm={12} className='w-100'>        

        <Grid item className='pr-2'>
            <Button
              variant='contained'
              color='info'
              component={NavLink}
              to={ '/event/create-from-classroom/' + classroomId }
            >
              Create Event From Classroom
            </Button>
            
                       
        </Grid>

        <Grid item className='pr-2'>
            <Button
              variant='contained'
              color='error'
              onClick={handleClick}
            >
              Hide Classroom
            </Button>
            
                       
        </Grid>

      </Grid>
    </Grid>
  )
}
