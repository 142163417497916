

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_LOCATION_FILTER_ITEM_CLICK } from "../types";

// Actions



export const handleLocationItemClick = ({
  locationItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_LOCATION_FILTER_ITEM_CLICK,
    payload: locationItem
  });
}  