import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';

import {
  Container,
  // CircularProgress,
  // Button,
  // TableContainer,
  // Table,
  // TableBody,
  // Paper,
  Grid
} from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';

// MUI
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { AsyncAutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AsyncAutoCompleteMultipleCheckbox';
import { AutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AutoCompleteMultipleCheckbox';
import { CustomDateTime2 } from '../../components/form/CustomDateTime2';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { createNotification } from '../../actions/notificationActions/createNotification';


export const  NotificationAddScreen = ({

}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();


  const _initialFormData = {
    notificationTitle: '',
    notificationMessage: '',
  };
  const [ formData, setFormData] = useState({ ..._initialFormData });









  const _dummyLanguages = [
    { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  ]
  const [ _languageOptions, _setLanguageOptions] = useState([])
  const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)


  const _dummyInterests = [
    { label: 'Speaking Club' },  { label: 'Dinner' },  { label: 'Language Trip' },  { label: 'Karaoke' },
  ]
  const [ _interestOptions, _setInterestOptions] = useState([])
  const [ _isInterestOptionsLoading, _setIsInterestOptionsLoading ]= useState(false)
  const [  _isInterestOptionsOpen, _setIsInterestOptionsOpen ] = useState(false)

  


  // Filter Parameters for User
  const _initialFilteringParameters = {
    // onlineOffline: [],
    // classroom: [],
    // searchQuery:  '',
    languages: [],
    interests: [],
    // hosts: [],
    // interests: [],
    // notification: [],
    // email: [],
    // registrator: [],
    // approver: [],
    // status: [],
    orderBy: null, // 'Goruntulenme' etc
    // order: 'asc'  // 'asc' || 'desc'
  };
  const _initialOrder = 'asc';
  const [_filteringParameters, _setFilteringParameters] = useState({ ..._initialFilteringParameters });
  const [order, setOrder] = useState(_initialOrder);  // 'asc' || 'desc' || null


  const _setLanguageFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  }
  const _setInterestFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  }







  
  const LanguageFilterComponent = () => (
    <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName() }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isLanguageOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setLanguageFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _languageOptions.length === 0 ) {
            _setIsLanguageOptionsLoading(true)
            _setIsLanguageOptionsOpen(true)
            setTimeout(() => {
              _setLanguageOptions( [ ..._dummyLanguages ] );
              _setIsLanguageOptionsLoading(false)
            }, 750)
          } else {
            _setIsLanguageOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsLanguageOptionsOpen(false)
        }}
        loading={  _isLanguageOptionsLoading }
        options = {[ ..._languageOptions ]}
        htmlId='event-screen-language-autocomplete'
        autoCompleteLabel='Languages'
      />
    </Grid>
  );


  const InterestFilterComponent = () => (
    <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName() }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isInterestOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setInterestFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _interestOptions.length === 0 ) {
            _setIsInterestOptionsLoading(true)
            _setIsInterestOptionsOpen(true)
            setTimeout(() => {
              _setInterestOptions( [ ..._dummyInterests ] );
              _setIsInterestOptionsLoading(false)
            }, 750)
          } else {
            _setIsInterestOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsInterestOptionsOpen(false)
        }}
        loading={  _isInterestOptionsLoading }
        options = {[ ..._interestOptions ]}
        htmlId='user-screen-language-autocomplete'
        autoCompleteLabel='Interests'
      />
    </Grid>
  );


  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { 
      label: 'Interests', isChecked: false, 
      component: null,
      defaultFilterValue: _initialFilteringParameters.interests  ,
      defaultFilterKey: 'interests'  ,
    },    
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }




  return (
    <Container
      id='notification-create-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
    >
      <Helmet defer={false}><title>Create Notification</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Create Notification'}</span> 
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  _setFilteringParameters({..._initialFilteringParameters});
                  setOrder(_initialOrder);  
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component
                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                    _setFilteringParameters({
                      ..._filteringParameters,
                      [_clickedOption.defaultFilterKey]: _clickedOption.defaultFilterValue
                    });
                  }
                  _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                    onClick={() => {
                      _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    }}
                  >
                    {
                      _isHideActiveUiFilters 
                        ?
                        <VisibilityIcon color='error' />
                        :
                        <VisibilityOffIcon />

                    }
                    
                  </IconButton>
                )
              }

            </>
          )}  
        />
        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>

          {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Languages')
                && _screenFilterOptions.find( item => item.label === 'Languages').isChecked
                // && !_isHideActiveUiFilters
                &&  LanguageFilterComponent()
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Interests')
                && _screenFilterOptions.find( item => item.label === 'Interests').isChecked
                // && !_isHideActiveUiFilters
                &&  InterestFilterComponent()
            }           


            
            {
              !_isHideActiveUiFilters
              &&
              _filterUiElements && _filterUiElements.length > 0 &&
              _filterUiElements.map(
                ( item , ind )=> {
                  return (
                    <React.Fragment key={ind}>
                      { item.component &&  item.component() }
                    </React.Fragment>
                  );
                }
              )
            }

            




          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}


        {/* Table */}
        <Grid item sm={12} className='w-100 pb-2'>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Notification Title"
            type="text"
            fullWidth
            // multiline
            variant="standard"
            onChange={e => {
              setFormData({
                ...formData,
                notificationTitle:  e.target.value
              });
            }}
          />
        </Grid>
        <Grid item sm={12} className='w-100 pb-2'>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Notification Message"
            type="text"
            fullWidth
            multiline
            variant="standard"
            onChange={e => {
              setFormData({
                ...formData,
                notificationMessage:  e.target.value
              });
            }}
          />
        </Grid>
        {/* End of Table */}





      </Grid>

      <Grid container sm={12} className='w-100 mb-2'>
        <Grid item className='mt-1'>
          <Button 
            variant='contained'
            // component={NavLink}
            color='secondary'
            // to={'/notification/create'}
            onClick={() => {
              dispatch(
                createNotification({
                  formData,
                  cb: apiResponse => {
                    console.log('NotificationAddScreen -> createNotification -> apiResponse -> ', apiResponse)
                  }
                })
              )
            }}
          >
            Send Notification
          </Button>

        </Grid>
      </Grid>
    </Container>
  )
}

