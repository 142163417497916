import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  LANGUAGE_UPDATED
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";






export const updateLanguage = ({
  languageId,
  formData,
  cb  // callBack
}) => async dispatch => {

  try {    
 
    const res = await axios.put(
      urlConstants.updateLanguageUrl(languageId), 
      formData,
      clientAppConstants.jsonConfig
    ); 
    if ( cb ) {      
      cb(res.data);      
    }
    dispatch({
      type: LANGUAGE_UPDATED,
      payload: res.data.language
    });
    dispatch(setAlert(res.data.msg, "success", 2500));

    
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
} 