import React from 'react'

// MUI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';

// Components
import { ChatMessageItem } from './ChatMessageItem';

// Dummy Data
import { dummyChatMessages } from './dummyChatMessages';



// Constants
import { clientAppConstants } from '../../constants';


export const ChatMessagesContainer = ({ messages, children }) => {
  return (
    <Grid 
      id='speaklabs-chat-messages-container'
      item
      className='w-100'
      style={{
        // overflow: 'scroll',
        height: clientAppConstants.chatScreenChatContainerHeight,
        backgroundColor: '#e0d8d0'
      }}
    >
      {
          messages.map(
          (chatItem) => (
            <ChatMessageItem
              key={chatItem.chatMessageId}
              chatItem={chatItem}
            />
          )
        )
      }
      {children}
      
    </Grid>
  )
}
