import React from "react";
import Helmet from 'react-helmet';


const NotFound = () => {
  return (
    <div 
      style={{
      minHeight: '90vh'
      }}
      className='flexcol justify-content-center'
    >
      <Helmet defer={false}><title>404! Not Found</title></Helmet>
      <h1 className='text-center'>404 NOT FOUND!</h1>
    </div>
  );
};

export default NotFound;