import React from 'react'
import { 
  StyledTableCell ,
} from '../../components/table/StyledTableComponents'


import {

  TableHead,
  TableRow,
} from '@material-ui/core';

// MUI
import Tooltip from '@mui/material/Tooltip';



export const SubscriptionTicketsTableHead = () => {


  return (
    <TableHead id='speaklabs-event-tickets-table-head'>
      <TableRow>
        {/* <StyledTableCell size= 'small' align='left'>User</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Definition</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Date</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Date</StyledTableCell> */}
        <StyledTableCell size= 'small' align='center'>Event</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='center'>Online-Offline</StyledTableCell> */}
        <StyledTableCell size= 'small' align='center'>Host</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>
          E. Date
        </StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Type</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Language</StyledTableCell>
        <StyledTableCell size= 'small' align='right'>Status</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Actions</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='left'>Balance</StyledTableCell> */}
         {/* <StyledTableCell size= 'small' align='center'>Language</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Max. Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Ticket</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Registration</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell> */}
      </TableRow>
    </TableHead>
  )
}
