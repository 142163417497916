import {useController} from "react-hook-form";
import {useCallback, useMemo} from "react";
import {FormHelperText, Select} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {AsyncAutocomplete} from "./autoComplete/AsyncAutocomplete";

let id = 0;
export const AsyncAutocompleteField = ({
                              name,
                              control,
                              variant = 'outlined',
                              size = 'small',
                              label,
                              ...props
                          }) => {
    const {field, fieldState} = useController({name, control});
    const inputId = useMemo(() => `input-${name}-${id++}`, [name]);
    const onChange = useCallback((e, value) => {
        console.log(name, value.value, value);
        field.onChange(value);
    }, [field.onChange]);
    return (
        <FormControl error={Boolean(fieldState.error)} className="w-100 select-field-wrapper">
            {label && <InputLabel className="form-control-label" id={inputId}>{label}</InputLabel>}
            <AsyncAutocomplete
                ct
                {...field}
                {...props}
                onChange={onChange}
                error={Boolean(fieldState.error)}
                variant={variant}
                size={size}
                id={inputId}
                className="w-100"
            />
            <FormHelperText>{fieldState.error ? fieldState.error.message : undefined}</FormHelperText>
        </FormControl>
    );
}