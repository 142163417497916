import * as React from 'react';

// MUI
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';



export const CustomMuiTable = (props) => {
  const {tableClassname} = props;
  const _tableClassName =  tableClassname ?? ''
  return (
    <Grid item className={'w-100 ' }  >
      <TableContainer component={Paper}>
        <Table className={_tableClassName}>
        { props.children }
        </Table>
      </TableContainer>
    </Grid>
  )
}
