import axios from "axios";

// Redux
import store from '../../store'


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  USERS_TICKET_LIST_FETCHED, 
  SET_USERS_LOADING ,
  SET_ITEM_COUNT,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const fetchUserTicketList = async  ({
  userId,
  limit,
  skip,
  filteringParameters,
  order,
  cb,  // callBack
}) =>   {
  try { 
    const formData = {
      limit,
      skip,
      filteringParameters,
      order,
    }
    // dispatch({
    //   type: SET_USERS_LOADING,
    //   payload: true
    // });
    store.dispatch(
      {
          type: SET_USERS_LOADING,
          payload: true
      }
    )


    const res = await axios.post(
      // urlConstants.apiUrl +  
      urlConstants.getUserTicketList( userId ), 
      formData ?? {},
      clientAppConstants.jsonConfig
    )
    
    store.dispatch(
      {
          type: SET_ITEM_COUNT,
          payload: res.data.ticketCount
      }
    );
    store.dispatch(
      {
        type: USERS_TICKET_LIST_FETCHED,
        // payload: res.data.ticketList
        payload: res.data
      }
    );
    if ( cb ) {      
      cb(res.data);      
    }
    


  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => store.dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
}  