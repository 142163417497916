import {
  EVENTS_FETCHED,
  SET_EVENTS_LOADING,
} from "../actions/types";


const initialState = {
  eventList: [],
  isLoading: true,
  isEventsFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case EVENTS_FETCHED:      
      return {
        ...state,
        eventList: [...payload],
        isLoading: false,
        isEventsFetched: true,
      };
    case SET_EVENTS_LOADING:      
      return {
        ...state,
        eventList: payload === true ? [] : state.eventList,
        isLoading: payload
      }; 

    

    default:
      return state;
  }
}