import React, {createContext, useMemo, useRef} from 'react';
import * as io from 'socket.io-client'

const socket =  io('/', {path: '/speaklabs-socket-path', query: {token: localStorage.getItem('token'), isAdmin: true }});
const SocketContext = createContext(socket);
const SocketProvider = ({ children }) => {

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };