import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  SET_NOTIFICATIONS_LOADING
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";






// Fetch All Maritals
export const createNotification = ({
  formData,
  cb  // callBack
}) => async dispatch => {

  try {
    // dispatch({
    //   type: LANGUAGE_ADD_STARTED,
    // });
    
 
    const res = await axios.post(
      urlConstants.createNotificationUrl, 
      formData,
      clientAppConstants.jsonConfig
    ); 
    dispatch(setAlert(res.data.msg, "success", 2500));






    // dispatch({
    //   type: LANGUAGE_ADDED,
    //   payload: res.data.language
    // });
    if ( cb ) {      
      cb(res.data.notificationObject);      
    }
  } catch (err) {
    // dispatch({
    //   type: LANGUAGE_ADD_ERROR,
    // });
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
} 