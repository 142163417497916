// import React from 'react'

// import {
//   // Container,
//   // CircularProgress,
//   // Button,
//   TableContainer,
//   Table,
//   // TableBody,
//   Paper,
//   Grid
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';


// export const  CustomTable = (props) =>  {
//   const  stickyHeader = props.stickyHeader === false ? false : true;
  
//   const useStyles = makeStyles({
//     table: {
//       minWidth: 700
//     }
//   });
//   const classes = useStyles();
//   // console.log(children)
//   // console.log(props.children)

//   return (
//     <Grid item className='w-100'>
//       <TableContainer component={Paper}>
//         <Table stickyHeader={stickyHeader} className={classes.table}>
//           {/* {props.children} */}
//           {/* <>{props.children}</> */}
//           {/* <>{ ...props }</> */}
//           { props.children }
//           {/* { children.map( (item, ind) => <Fragment key={ind}>{item}</Fragment> ) } */}
//         </Table>
//       </TableContainer>
//     </Grid>
//   )
// }



import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export const  CustomTable = (props) =>  {
  const  stickyHeader = props.stickyHeader === false ? false : true;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
      { props.children }
      </Table>
    </TableContainer>
  )
}