import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const SortControl = ({ field, sort, onChange }) => {

    const isSorted = sort.field === field;

    return (
        <IconButton
            size="small"
            onClick={() => {
                onChange({
                    field,
                    order: isSorted && sort.order === -1 ? 1 : -1
                });
            }}
        >
            {!isSorted ? <ArrowDropDownIcon fontSize="small" color="disabled" /> : (
                <>
                    {sort.order === 1 ? <ArrowDropDownIcon fontSize="small"  /> : <ArrowDropUpIcon fontSize="small"  />}
                </>
            )}

        </IconButton>
    );
}