import React from 'react'

// MUI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';



// Components
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';

// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { colors } from '@material-ui/core';
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';

export const SubsciptionInformationComponent = ({formData}) => {
  const {
    price,
    userId, // Object
    purchaseDate,
  } = formData
  return (
    <Grid item sm={12} className='w-100'>
      <Paper 
        className='p-1'
      >
      <Grid 
        container 
        sm={12} 
        className='w-100'
        alignContent='center'
        alignItems='center'
      >
        <Grid item >
          <p>
            User:
          </p>
        </Grid>
        <Grid 
          item 
          className='pr-2 flexrow' 
        >
          <TableNavlinkTextButton
            buttonLink={ '/user/edit/' + userId._id }
            buttonText={  userId?.nameSurname  ?? userId?.email ?? '-' }
          />
        </Grid>

        <Grid 
          item 
          className='mr-1 flexrow' 
        >
          <p
            style={{color: 'green'}}
          >
            {'Price: ' + '$' +  (price ? price.toFixed(2) : '')}
          </p>
        </Grid>

        <Grid 
          item 
          className='px-2' 
        >
          <Chip className='speaklabs-smaller-custom-chip' label={'paypal'} color={'primary'} />
        </Grid>

        <Grid item className='pr-2'>
          <p style={{textDecoration: 'underline'}}>
            {
              'Purchase Date: '
              +
              convertToMomentDateTime( purchaseDate )
            }
          </p>
        </Grid>

        <Grid item className='pr-2'>
          <p 
            style={{
              textDecoration: 'underline',
              color: 'rgb( 29, 180, 29)'
            }}
          >
            {
              'Valid Till: '
              +
              convertToMomentOnlyDate( Date.now() )
            }
          </p>
        </Grid>

        

      </Grid>
      </Paper>
    </Grid>
  )
}
