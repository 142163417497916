import React, { useState } from 'react'

// MUI
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';



// Icons
import SendIcon from '@mui/icons-material/Send';

// Components
import { CustomDialog } from '../../components/CustomDialog';

export const SetUserHostButton = ({
  disabled,
  isSettingUserAsHost,
  singleUser,
  handleClick,
}) => {
  const [ _isDialogOpen, _setIsDialogOpen ] = useState(false);
  return (
    <>
    
      <LoadingButton
        onClick={() => {
          _setIsDialogOpen(true);
        }}
        disabled={disabled}
        // type='submit'
        variant='contained'
        color='warning'
        loading={isSettingUserAsHost}
        loadingPosition='end'
                endIcon={<SendIcon />}        
      >
        {'Set User Host'}
      </LoadingButton>
      <CustomDialog
        ariaLabelledBy={`speaklabs-set-user-host-button`}
        ariaDescribedBy={`speaklabs-set-user-host-button-2`}
        cancelButtonText={ 'Cancel' }
        confirmButtonText={ 'OK' }
        dialogTitle={'Set Host Confirmation!'}
        dialogContentText={
          singleUser.isHost
            ?
            'Are you sure you want you want to Remove ' + singleUser.nameSurname ?? singleUser.email + ' From Hosts?'
            :
            'Are you sure you want you want to Set ' + (singleUser.nameSurname ?? singleUser.email) + ' as Host?'
        }
        isModalOpen={_isDialogOpen}
        onClose={() => _setIsDialogOpen(false)}
        handleCancel={() => {
          _setIsDialogOpen(false);
          // and whatever
        }}
        handleConfirm={() => {
          handleClick();
          _setIsDialogOpen(false);
          // and whatever
        }}
      />
    </>
  )
}
