import React from 'react'
import { NavLink } from 'react-router-dom'



// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


// Icons
import AddIcon from '@mui/icons-material/Add';


export const ClassroomScreenBottomButtons = () => {
  return (
   
    // <Grid item sm={12} className='mt-2 w-100'>
      <Grid container sm={12} className='w-100 mt-1 mb-1'>        

        <Grid item className='pr-2'>
          <Button
            variant='contained'
            color='success'
            component={NavLink}
            to={'/classroom/add/'}
            endIcon={<AddIcon  />}
          >
            Create Classroom
          </Button>                        
                       
        </Grid>

      </Grid>
    // </Grid>
  )
}
