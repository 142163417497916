


import { combineReducers } from "redux";
// Reducers
import activityReducer from "./activityReducer";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import classroomReducer from "./classroomReducer";
import contactMessageReducer from "./contactMessageReducer";
import countryReducer from "./countryReducer";
import decorationReducer from "./decorationReducer";
import eventReducer from "./eventReducer";
import filterReducer from "./filterReducer";
import interestReducer from "./interestReducer";
import languageReducer from "./languageReducer";
import notificationReducer from "./notificationReducer";
import statisticReducer from "./statisticReducer";
import subscriptionClassReducer from "./subscriptionClassReducer";
import ticketReducer from "./ticketReducer";
import userReducer from "./userReducer";

// import categoryReducer from "./categoryReducer";
// import authReducer from "./authReducer";
// import maniReducer from "./maniReducer";
// import jobReducer from "./jobReducer";
// import maritalReducer from "./maritalReducer";
// import cityReducer from "./cityReducer";
// import couponReducer from "./couponReducer";
// import wheelReducer from "./wheelReducer";
// import notificationsReducer from "./notificationsReducer";
// import userReducer from "./userReducer";
// import contactReducer from "./contactReducer";
// import sponsorReducer from "./sponsorReducer";
// import adminReducer from "./adminReducer";
// import publicUserReducer from "./publicUserReducer";


// import productReducer from "./productReducer";
// import featureReducer from "./featureReducer";
// import supplierReducer from "./supplierReducer";



export default combineReducers({
  // categoryReducer,
  // authReducer,
  activityReducer,
  alertReducer,
  authReducer,
  classroomReducer,
  contactMessageReducer,
  countryReducer,
  decorationReducer,
  eventReducer,
  filterReducer,
  interestReducer,
  languageReducer,
  notificationReducer,
  statisticReducer,
  subscriptionClassReducer,
  ticketReducer,
  userReducer,
  // maniReducer,
  // jobReducer,
  // maritalReducer,
  // cityReducer,
  // couponReducer,
  // wheelReducer,
  // notificationsReducer,
  // userReducer,
  // contactReducer,
  // sponsorReducer,
  // adminReducer,
  // publicUserReducer,
  // productReducer,
  // featureReducer,
  // supplierReducer
});