import axios from "axios";

// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Actions
import { setAlert } from "../alertActions/setAlert";
import { fetchSingleUser } from '../../actions/userActions/fetchSingleUser';

export const updateSingleUser = ({userId, editingKey, newValue}) => async dispatch => {
  try {
    if(newValue === "NO") newValue = false;
    else if(newValue === "YES") newValue = true;
    else if(editingKey === "interests") newValue = newValue.filter(interest => interest !== "-")
    const formData = {userId, [editingKey]: newValue};
    const res = await axios.post(
      urlConstants.updateSingleUser, 
      formData,
      clientAppConstants.jsonConfig
    );  
    dispatch(setAlert(res.data.msg, "success", 2500));
    dispatch(fetchSingleUser({userId}));
  } catch (err) {    
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
  }
}  