// import React from 'react'

// import {
//   Container,
//   CircularProgress,
//   Button,
//   TableContainer,
//   Table,
//   TableBody,
//   Paper,
//   Grid
// } from '@material-ui/core';


// // CSS
// import './ScreenTitle.css'


// export const ScreenTitle = ({
//   titleText
// }) => {
//   return (
//     <Grid item className='w-100  speaklabs-screen-title'>
//       <h2 className='text-center  mt-1 mb-1'>{titleText}</h2>
//     </Grid>
//   )
// }







import React from 'react'


// MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// Icons


// Style
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

// CSS
import './ScreenTitle.css'


let theme = createTheme();
theme = responsiveFontSizes(theme);


export const ScreenTitle = ({
  titleText,
  titleComponent,
}) => {
  return (

    <Box sx={{ width: '100%' }} className='my-2'> 
      <Paper className='p-1'>
        <ThemeProvider theme={theme}>
          {
            titleText && (
              <Typography variant="h5" className='text-center'>
              {
                titleText
              }                  
              </Typography>
            )
          }          
          {
            titleComponent && titleComponent
          }
        </ThemeProvider>
      </Paper>
    </Box>
  )
}