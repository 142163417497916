import {SelectField} from "../../components/form/SelectField";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import {z} from "zod";
import {Button, Dialog} from "@material-ui/core";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import {useCallback, useEffect, useState} from "react";
import {urlConstants} from "../../constants";
import axios from "axios";
import {toast} from "react-toastify";

const validationSchema = z.object({
    subscriptionClassId: z.string().min(2),
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';


export default function AddSubscriptionModal({ onClose, user }) {

    const [subscriptionClasses, setSubscriptionClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const fetchSubscriptionClasses = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await axios.post(urlConstants.fetchSubscriptionClassList);
            setSubscriptionClasses(response.data.subscriptionClassList.map((item) => ({
                label: item.subscriptionTitle,
                value: item._id,
            })));
        } catch (e) {
            console.error(e);
            toast.error(e.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchSubscriptionClasses();
    }, []);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: { subscriptionClassId: '' },
        resolver: zodResolver(validationSchema),

    });

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true);
        try {
            const response = await axios.post(urlConstants.addSubscription, { ...values, userId: user._id });
            onClose(true);
        } catch (e) {
            console.error(e);
            toast(e.message)
        }
        setIsLoading(false);
    }, []);

    return (
        <div>
            <Dialog open={true} onClose={onClose} maxWidth="md" >
                <form onSubmit={handleSubmit(onSubmit)} className="p-3" style={{ width: '300px' }}>
                    <GlobalCircularProgress show={isLoading} />
                    <h3>Add subscription</h3>
                    <div className="mb-2">
                        <SelectField control={control} options={subscriptionClasses} name="subscriptionClassId" label="Subscription Class"  />
                    </div>
                    <Button color="primary" variant="contained" type="submit">Add</Button>
                    </form>
            </Dialog>
        </div>
    )
};