import {useController} from "react-hook-form";
import {Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {useMemo} from "react";
import {FormHelperText} from "@material-ui/core";

let id = 0;
export const SwitchField = ({
                                name,
                                control,
                                size = 'small',
                                label,
                                ...props
                            }) => {
    const {field, fieldState} = useController({name, control});
    const inputId = useMemo(() => `input-${name}-${id++}`, [name]);
    return (
        <FormControl error={Boolean(fieldState.error)} className="w-100">
            {label && <InputLabel className="form-control-label" id={inputId}>{label}</InputLabel>}
            <Switch  {...field}
                     {...props}
                     error={Boolean(fieldState.error)}
                     size={size}
                     checked={field.value}
            />
            <FormHelperText>{fieldState.error ? fieldState.error.message : undefined}</FormHelperText>
        </FormControl>
    );
}