import React, {useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Redux
import store from '../../store'

// Constants
import { clientAppConstants } from '../../constants';



// MUI
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { UserTicketTableHead } from './UserTicketTableHead';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { UserTicketItem } from './UserTicketItem';
import { CustomPagination } from '../../components/CustomPagination';
import { AsyncAutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AsyncAutoCompleteMultipleCheckbox';
import { AutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AutoCompleteMultipleCheckbox';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { TicketReservationFilterComponent } from '../../components/filter/TicketReservationFilterComponent';
import { OnlineOfflineFilter } from '../../components/filter/OnlineOfflineFilter';
import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';
import { ClassroomFilterComponent } from '../../components/filter/ClassroomFilterComponent';
import { SinceDateFilterComponent } from '../../components/filter/SinceDayFilterComponent';
import { UntilDateFilterComponent } from '../../components/filter/UntilDateFilterComponent';
import { PlatformFilterComponent } from '../../components/filter/PlatformFilterComponent';

// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';

// WHY USING IF NOT USING ?
// import { UserEventMuiTableHead } from './UserEventMuiTableHead';



// Actions
import { fetchUserList } from '../../actions/userActions/fetchUserList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
// import { setFilteringOrder } from '../../actions/filterActions/setFilteringOrder';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setSearchQueryText } from '../../actions/filterActions/setSearchQueryText';
import { setLanguagesFilter } from '../../actions/filterActions/setLanguagesFilter';
import { setInterestsFilter } from '../../actions/filterActions/setInterestsFilter';
import { setNotificationsFilter } from '../../actions/filterActions/setNotificationsFilter';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';
import { setEmailFilter } from '../../actions/filterActions/setEmailFilter';
import { setUserTypeFilter } from '../../actions/filterActions/setUserTypeFilter';
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { fetchUserTicketList } from '../../actions/userActions/fetchUserTicketList';
import { clearUserTicketListInfo } from '../../actions/userActions/clearUserTicketListInfo';




export const  UserTicketsScreen = ({

  // Location, History, etc
  match, // location -> match.params.eventId
}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const userTicketList = useSelector(state => state.userReducer.userTicketList);
  const userNameSurname = useSelector(state => state.userReducer.userNameSurname);
  const userEmail = useSelector(state => state.userReducer.userEmail);

  const [userId, setUserId] = useState(match.params.userId || null);





  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true);




  const _dummyActivityTypeOptions = [
    { label: 'Refund' },  
    { label: 'Event Cancelled' },  
    { label: 'Ticket Purchase' },  
    { label: 'Refund From Balance' },  
    { label: 'Subscription Purchase' },  
    { label: 'Ticket  Cancel+Refund' },  
    { label: 'Reservation' },  
    { label: 'Add Balance' },  
  ]



  const _dummyClassrooms = [
    { label: 'French Speaking Club' },  { label: 'English Dancing' }, { label: 'Russian Karaoke' },
  ]
  const [ _classroomOptions, _setClassroomOptions] = useState([])
  const [ _isClassroomOptionsLoading, _setIsClassroomOptionsLoading ]= useState(false)
  const [  _isClassroomOptionsOpen, _setIsClassroomOptionsOpen ] = useState(false)


  const _dummyLanguages = [
    { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  ]
  const [ _languageOptions, _setLanguageOptions] = useState([])
  const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)
  
  const _dummyHosts = [
    { label: 'George Best' },  { label: 'Grigory Leps' },  { label: 'Tom Cruise' },  { label: 'Maria Sharapova' },
  ]
  const [ _hostOptions, _setHostOptions] = useState([])
  const [ _isHostOptionsLoading, _setIsHostOptionsLoading ]= useState(false)
  const [  _isHostOptionsOpen, _setIsHostOptionsOpen ] = useState(false)

  // Filter Parameters for User
  const _initialFilteringParameters = {
    onlineOffline: [],
    ticketReservation : [],
    classroom: [],
    languages: [],
    hosts: [],
    interests: [],
    notification: [],
    email: [],
    platform: [],
    // registrator: [],
    // approver: [],
    // status: [],
    orderBy: null, // 'Goruntulenme' etc
    // order: 'asc'  // 'asc' || 'desc'
  };
  const _initialOrder = 'asc';
  const [_filteringParameters, _setFilteringParameters] = useState({ ..._initialFilteringParameters });
  // const [order, setOrder] = useState(_initialOrder);  // 'asc' || 'desc' || null
  const _setOnlineOfflineFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, onlineOffline: [...newValue] });
  }
  const _setTicketReservationFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, ticketReservation: [...newValue] });
  }
  const _setPlatformFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, platform: [...newValue] });
  }
  const _setClassroomFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, classroom: [...newValue] });
  }
  const _setLanguageFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  }
  const _setHostFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, hosts: [...newValue] });
  }












  const PlatformFilter = () => (
    <Grid item className='pb-1 pr-1 pt-2' 
      sm={12} 
      md={6} lg={4}
      >
      <AutoCompleteMultipleCheckbox 
        options={[ ...clientAppConstants.platformOptions ]}
        autoCompleteLabel='Platform'
        placeHolder=''
        htmlId='activity-screen-platform-autocomplete'
        handleAutoCompleteOnChange={
          ( e, newValue ) => {
            _setPlatformFilter( newValue );
        }}
        />
    </Grid>
  );

  const HostFilterComponent = () => (
    <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName()}
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isHostOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setHostFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _languageOptions.length === 0 ) {
            _setIsHostOptionsLoading(true)
            _setIsHostOptionsOpen(true)
            setTimeout(() => {
              _setHostOptions( [ ..._dummyHosts ] );
              _setIsHostOptionsLoading(false)
            }, 750)
          } else {
            _setIsHostOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsHostOptionsOpen(false)
        }}
        loading={  _isHostOptionsLoading }
        options = {[ ..._hostOptions ]}
        htmlId='user-screen-host-autocomplete'
        autoCompleteLabel='Hosts'
      />
    </Grid>
  )









  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Online-Offline', isChecked: false, 
      component: null, 
      defaultFilterKey: 'onlineOffline'  ,
    },
    { label: 'Ticket-Reservation', isChecked: false, 
      component: null, 
      defaultFilterKey: 'ticketReservation'  ,
    },
    { label: 'Classrooms', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { label: 'Platform', isChecked: false, 
      component: null, 
      defaultFilterKey: 'platform'  ,
    },
    // { label: 'Hosts', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.hosts  ,
    //   defaultFilterKey: 'hosts'  ,
    // },
    { label: 'Since-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }




  useEffect(() => {
    fetchUserTicketList({
      userId,
      limit: pageFilterCount,
      skip: 0,
      filteringParameters : {},
      order,
      cb: ( apiResponse ) => {
        // console.log('UserScreen -> fetchUserList -> cb -> apiResponse -> ', apiResponse)
        _setLoading(false);
      }
    })
    
    return () => {
      dispatch(
        clearUserTicketListInfo()
      );
      dispatch(
        resetFilters()
      );
    }
  }, [])



  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      fetchUserTicketList({
        userId,
        limit: pageFilterCount,
        skip: 0,
        order,
        filteringParameters,
        cb: apiResponse => {
          dispatch(
            setIsFilteringLoading(false)
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])





  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>
        {
          (() => {
           
            if ( !userEmail && !userNameSurname ) {
              return 'User Tickets';
            }
            if ( userNameSurname ) {
              return 'Tickets - ' + userNameSurname;
            }
            if ( userEmail ) {
              return 'Tickets - ' + userEmail;
            }
          })() 
        }
      </title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <Button 
                size="small"
                style={{
                  // color: 'black'
                  fontSize: '1.2rem'
                }}
                component={NavLink}
                color={  'primary'  }
                to={'/user/edit/' + userId}
              >
                {
                  (() => {
                  
                    if ( !userEmail && !userNameSurname ) {
                      return 'User ';
                    }
                    if ( userNameSurname ) {
                      return  userNameSurname + ' ';
                    }
                    if ( userEmail ) {
                      return   userEmail + ' ';
                    }
                  })() 
                }
              </Button>
              <span>{'/ Tickets'}</span>
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  // _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  // _setFilteringParameters({..._initialFilteringParameters});
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                >
                  {
                    isHideActiveUiFilters 
                      ?
                      <VisibilityIcon color='error' />
                      :
                      <VisibilityOffIcon />

                  }
                  
                </IconButton>
                )
              }
            </>
          )}
        />
        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container >
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Online-Offline')
                && _screenFilterOptions.find( item => item.label === 'Online-Offline').isChecked
                // && !_isHideActiveUiFilters
                &&  <OnlineOfflineFilter />
                // &&  NotificationFilterComponent()
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Ticket-Reservation')
                && _screenFilterOptions.find( item => item.label === 'Ticket-Reservation').isChecked
                // && !_isHideActiveUiFilters
                &&  <TicketReservationFilterComponent />
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Classrooms')
                && _screenFilterOptions.find( item => item.label === 'Classrooms').isChecked
                // && !_isHideActiveUiFilters
                &&  <ClassroomFilterComponent />
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Languages')
                && _screenFilterOptions.find( item => item.label === 'Languages').isChecked
                // && !_isHideActiveUiFilters
                &&  <LanguageFilterComponent />
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Platform')
                && _screenFilterOptions.find( item => item.label === 'Platform').isChecked
                &&  <PlatformFilterComponent />
            }


            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Since-Date')
                && _screenFilterOptions.find( item => item.label === 'Since-Date').isChecked
                &&  <SinceDateFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Until-Date')
                && _screenFilterOptions.find( item => item.label === 'Until-Date').isChecked
                &&  <UntilDateFilterComponent />
            }  

            

          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}

        <Grid item className='pb-1' />
        
        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                {/* Table */}
                {
                  (
                    isPaginationLoading !== true && isFilteringLoading !== true &&
                    userTicketList && userTicketList.length === 0
                  ) 
                    ? 
                    (
                      <Grid item className='w-100 py-5'>
                        <h4 className='text-center'>No Tickets!</h4>
                      </Grid>
                    )
                    :
                    (
                      <CustomTable 
                      >
                        <UserTicketTableHead/>
                        <TableBody>
                          {
                            
                            isPaginationLoading !== true && isFilteringLoading !== true &&
                            userTicketList && userTicketList.length > 0 && userTicketList.map(
                              (ticketItem, ind) => (
                                <UserTicketItem
                                  key={ind}
                                  ticketItem={ticketItem}
                                />

                              )
                            )
                          }
                        </TableBody>
                      </CustomTable>
                    )
                }
                {/* End of Table */}  
                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }
                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    store.dispatch(setIsPaginationLoading(true))
                    store.dispatch(setPaginationPage(page))
                    fetchUserTicketList({
                      cb: apiResponse => {
                        dispatch(
                          setIsPaginationLoading(false)
                        );
                      },
                      userId,
                      filteringParameters,
                      limit: pageFilterCount,
                      order: order,
                      skip: ( page - 1  ) * pageFilterCount
                    })

                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>

            )
        }





       

        

        {/* <p>sdfsdf</p> */}

      </Grid>
    </Container>
  )
}


