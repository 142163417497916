
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'

// Constants
import { clientAppConstants } from '../../constants';

// Actions



// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


// Icons
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListIcon from '@mui/icons-material/List';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Components
import { SetUserHostButton } from './SetUserHostButton';
import { SetUserVerifierButton } from './SetUserVerifierButton';
import { setUserAsHost } from '../../actions/userActions/setUserAsHost';
import { setUserAsVerifier } from '../../actions/userActions/setUserAsVerifier';




export const UserEditScreenBottomButtons = ({
  userId, setAddSubscriptionDialogOpen,
}) => {
  // Global Variables
  const singleUser = useSelector(state => state.userReducer.singleUser);
  const isHost = useSelector(state => state.userReducer.singleUser.isHost);
  const isSettingUserAsHost = useSelector(state => state.userReducer.isSettingUserAsHost);
  const isOfflineEventTicketVerifier = useSelector(state => state.userReducer.singleUser.isOfflineEventTicketVerifier);
  const isSettingUserAsVerifier = useSelector(state => state.userReducer.isSettingUserAsVerifier);
  const dispatch = useDispatch();

  const _isButtonsDisabled = () => {
    if ( isSettingUserAsHost  || isSettingUserAsVerifier || !singleUser ) {
      return true;
    }
    return false;
  }


  const _buttonItemGridClassName = 'pr-2 pb-2'
  return (
    // <Grid item sm={12} className='mt-2 w-100'>
      <Grid container sm={12} className='w-100 pt-2'>

        <Grid item className='pr-2'>
          <SetUserHostButton
            disabled={_isButtonsDisabled()}
            isSettingUserAsHost={isSettingUserAsHost}
            singleUser={singleUser}
            handleClick={() => {
              // setIsUserHost(true)
              dispatch(
                setUserAsHost({
                  userId: singleUser._id,
                  willUserBeHost: isHost ? false : true,                  
                })
              )
            }}
          />                        
        </Grid>

        {
          isHost && (
            <Grid item className={_buttonItemGridClassName}>
              <Button
                disabled={_isButtonsDisabled()}
                variant='contained'
                color='warning'
                component={NavLink}
                to={'/host/events/' + userId }
              >
                Host Events
              </Button>                        
            </Grid>
          )
        }

        {
          isHost && (
            <Grid item className={_buttonItemGridClassName}>
              <Button
                disabled={_isButtonsDisabled()}
                variant='contained'
                color='error'
                endIcon={<AttachMoneyIcon />}
                component={NavLink}
                to={'/host/calculate-single/' + userId }

              >
                Host Calculation
              </Button>                        
            </Grid>
          )
        }


        <Grid item className={_buttonItemGridClassName}>
          <SetUserVerifierButton
            disabled={_isButtonsDisabled()}
            isSettingUserAsVerifier={isSettingUserAsVerifier}
            singleUser={singleUser}
            handleClick={() => {
              // setIsUserOfflineEventTicketVerifier(true)
              dispatch(
                setUserAsVerifier({
                  userId: singleUser._id,
                  willUserBeVerifier: isOfflineEventTicketVerifier ? false : true,
                })
              );
            }}
          />
        </Grid>

        <Grid item className={_buttonItemGridClassName}>
          <Button
            disabled={_isButtonsDisabled()}
            variant='contained'
            color='info'
            component={NavLink}
            to={'/user/activities/' + userId }
            endIcon={<ListIcon  />}
          >
            Activities
          </Button>                        
        </Grid>

        <Grid item className={_buttonItemGridClassName}>
          <Button
            disabled={_isButtonsDisabled()}
            variant='contained'
            color='success'
            component={NavLink}
            to={'/user/tickets/' + userId }
            endIcon={<ReceiptIcon  />}
          >
            Tickets
          </Button>                        
        </Grid>

          <Grid item className={_buttonItemGridClassName}>
              <Button
                  disabled={_isButtonsDisabled()}
                  variant='contained'
                  color='info'
                  onClick={setAddSubscriptionDialogOpen}
                  endIcon={<AttachMoneyIcon />}
              >
                  Add subscription
              </Button>
          </Grid>

      </Grid>
    // </Grid>
  )
}
