import React, {useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchSubscriptionClassList } from '../../actions/subscriptionClassActions/fetchSubscriptionClassList';
import { resetFilters } from '../../actions/filterActions/resetFilters';


import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';

// MUI
import Button from '@mui/material/Button';


// Icons
import AddIcon from '@mui/icons-material/Add';

// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { SubscriptionItem } from './SubscriptionItem';
import { SubscriptionTableHead } from './SubscriptionTableHead';
import {Subscriptions} from "./Subscriptions";




export const  SubscriptionPackageScreen = ({

}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const subscriptionClassList = useSelector(state => state.subscriptionClassReducer.subscriptionClassList);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true);


  useEffect(() => {
    dispatch(
      fetchSubscriptionClassList({
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('SubscriptionPackageScreen -> fetchClassroomList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
    }
  }, [])


  return (
    <Container
      id='speaklabs-subscription-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
    >
      <Helmet defer={false}><title>Subscriptions</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   titleText='Subscription Packages'    />

        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                <Grid item className='pb-2' />
                {/* Table */}
                <CustomTable 
                  // stickyHeader={false}
                >
                  <SubscriptionTableHead/>
                  <TableBody>
                    {/* <SubscriptionItem />
                    <SubscriptionItem />
                    <SubscriptionItem /> */}
                    {
                      isPaginationLoading !== true && isFilteringLoading !== true &&
                      subscriptionClassList && subscriptionClassList.length > 0 && subscriptionClassList.map(
                        (subscriptionClassItem, ind ) => {
                          return (
                            <SubscriptionItem 
                              subscriptionClassItem={subscriptionClassItem}
                              key={ind}
                            />
                          )
                        }
                      )
                    }
                  </TableBody>
                </CustomTable>
                {/* End of Table */}

                <CustomPagination
                  currentPaginationIndex={1}
                  handleOnChange={() => {} /* dont forget to update CustomGrid also  */ }
                  isPaginationLoading={ false }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>
            )
        }

      </Grid>

      <Grid container sm={12} className='w-100 pb-1'>
        <Grid item>
          <Button
            variant='contained'
            endIcon={<AddIcon />}
            component={NavLink}
            to={'/subscription/create-package'}
          >
            Create Subscription
          </Button>

        </Grid>
      </Grid>
        <Grid container sm={12} className='w-100 pb-1'>
            <Subscriptions />
        </Grid>
    </Container>
  )
}


