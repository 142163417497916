import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOKEN_FAIL,
  APP_INITIALISED,
  ADMIN_LOADED,
  AUTH_ERROR,
  SET_AUTH_LOADING,
} from "../../actions/types";
import { clientAppConstants, urlConstants } from "../../constants";

// Constants


//  Actions
import { setAlert } from "../alertActions/setAlert";
import { loadAdmin } from "./loadAdmin";




export const login = ({
  formData,
  cb,
}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const body = JSON.stringify( formData );
  try {
    const res = await axios.post( urlConstants.adminLoginUrl, body, config); 
    dispatch( {
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    dispatch( loadAdmin() );
  } catch (err) {
    const errors = err.response.data.errors;
    if ( errors ) {
      errors.forEach( error => dispatch( setAlert(error.msg, 'error'))
      );
    }
    if( errors ) {
      console.log('Client -> authActions -> login errors ->', errors)
    }    
    dispatch( {
      type: LOGIN_FAIL
    });    
  }
} 