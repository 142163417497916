import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';








import { SET_ALERT, REMOVE_ALERT } from "../types";
const notify = () => toast("Wow so easy!");

// Set Alert
export const setAlert = (msg, type, timeout = 5000) =>  async dispatch  => {
  notify();
  const id = uuidv4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, type, id }
  });
  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};