import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOKEN_FAIL,
  APP_INITIALISED,
  ADMIN_LOADED,
  SET_AUTH_LOADING,
  AUTH_ERROR,
} from "../actions/types";
import setAuthToken from "../helpers/auth/setAuthToken";
import axios from "axios";

const initialState = {
  token: localStorage.getItem("token"),
  userType: localStorage.getItem('auth')
    ? localStorage.getItem('auth').userType
    : null,
  appInitialised: false,
  isAuthenticated: false,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      axios.defaults.headers.common['xAuth'] = payload.token;
      localStorage.setItem(
        "auth",
        JSON.stringify({
          token: payload.token,
          userType: payload.userType
        })
      );
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...initialState,
        appInitialised: true,
        isAuthenticated: false,
        loading: false,
        user: null,
        token: null,
        userType: null,
      };
    case AUTH_ERROR:
      return {
        ...initialState,
        appInitialised: true,
        loading: false,
      };
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...initialState,
        appInitialised: true,
        loading: false,
      };
    case TOKEN_FAIL:
      console.log('authReducer -> TOKEN_FAIL ->')
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
      };
    case APP_INITIALISED:
      if ( localStorage.auth ) {
        axios.defaults.headers.common['xAuth'] = localStorage.getItem('token');
        console.log("authReducer -> APP_INITIALISED -> localStorage.token exists");        
        return {
          ...state,
          // isAuthenticated: true,  // 2021.03.02 21:11
          appInitialised: true,
        };
      }
      console.log(
        "authReducer -> APP_INITIALISED -> localStorage.token DOESNT EXIST"
      ); 
      return {
        ...state,
        appInitialised: true,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: payload
      }
    case ADMIN_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload.admin,
        userType: payload.admin.role,
        loading: false
      };

    default:
      return state;
  }
}