import {useCallback, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import omit from "lodash/omit";
import {isUndefined} from "lodash";
import {filterTypes} from "../constants";

export const useFiltersWithPagination = (filterConfig = []) => {
    const filterUpdateTimeoutRef = useRef();
    const paramsUpdateTimeoutRef = useRef();
    const firstCallRef = useRef(true);
    const history = useHistory();
    const [filters, setFilters] = useState(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchFilters = {};
        searchParams.forEach((value, key) => {
            let parsedValue;
            const filter = filterConfig.find((filter) => filter.name === key);
            if (filter) {
                switch (filter.type) {
                    case filterTypes.DATE:
                        try {
                            parsedValue = JSON.parse(value);
                        } catch (e) {
                            parsedValue = {};
                        }
                        break;
                    case filterTypes.SELECT:
                        if (value) {
                            parsedValue = value.split(',');
                        }
                    default:
                        parsedValue = value;
                }

                if (!isUndefined(value)) {
                    searchFilters[key] = value;
                }
            }
        });

        return searchFilters;
    });
    const [page, setPage] = useState(() => {
        const searchParams = new URLSearchParams(window.location.search);
        return !isNaN(+searchParams.get("page")) && +searchParams.get("page") > 0 ? +searchParams.get("page") : 1;
    });
    const [sort, setSort] = useState({ field: "_id", order: -1});
    const [requestParams, setRequestParams] = useState({
        sort,
        filters,
        page,
    });

    const updateRequestParams = useCallback((newParams) => {
        if (paramsUpdateTimeoutRef.current) {
            clearTimeout(paramsUpdateTimeoutRef.current);
        }
        paramsUpdateTimeoutRef.current = setTimeout(() => {
            setRequestParams(newParams);

            const searchParams = new URLSearchParams({});
            if (newParams.filters) {
                Object.entries(newParams.filters).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        searchParams.set(key, value.join(','));
                    }else if(typeof value === "object") {
                        searchParams.set(key, JSON.stringify(value));
                    } else {
                        searchParams.set(key, value);
                    }
                });
            }

            if (newParams.page < 1) {
                newParams.page = 1;
            }

            history.replace({
                search: searchParams.toString(),
                pathname: history.location.pathname,
            });
        }, 50);
    }, [])

    useEffect(() => {
        if(!firstCallRef.current) {
            updateRequestParams({
                filters,
                page,
                sort,
            });
        }
    }, [page, sort])

    useEffect(() => {
        if(!firstCallRef.current) {

            if (filterUpdateTimeoutRef.current) {
                clearTimeout(filterUpdateTimeoutRef.current);
            }
            filterUpdateTimeoutRef.current = setTimeout(() => {
                setPage(1);
                updateRequestParams({
                    filters,
                    page: 1,
                    sort,
                })
            }, 1000);
        }
        firstCallRef.current = false;

    }, [filters]);

    return {
        page,
        setPage,
        sort,
        setSort,
        filters,
        setFilters,
        requestParams,
    }
}