import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  EVENTS_FETCHED, 
  SET_EVENTS_LOADING 
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const fetchEventList = ({
  limit,
  skip,
  filteringParameters,
  order,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const formData = {
      limit,
      skip,
      filteringParameters,
      order,
    }
    dispatch({
      type: SET_EVENTS_LOADING,
      payload: true
    });


    const res = await axios.post(
      urlConstants.fetchEventList, 
      formData ?? {},
      clientAppConstants.jsonConfig
    );  
    dispatch(setFilteringItemCount({itemCount: res.data.eventCount}));

    dispatch({
      type: EVENTS_FETCHED,
      payload: res.data.eventList
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
}  