

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_NOTIFICATION_FILTER_ITEM_CLICK } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const handleNotificationItemClick = ({
  notificationItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_NOTIFICATION_FILTER_ITEM_CLICK,
    payload: notificationItem
  });
}  