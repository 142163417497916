

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_PAGINATION_PAGE } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const setPaginationPage = (  
  newPaginationPage
) => async dispatch => {
  dispatch({
    type: SET_PAGINATION_PAGE,
    payload: newPaginationPage
  });
}  