import * as React from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';


// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export const AsyncAutoCompleteField = ({
  options,  // this is API Response, a List of Objects
  onSearchTextChange,
  searchTextValue,
  textfieldLabel='Please Set TextField Label',
  loading,
  open,
  setOpen,
  onClose,
  selectedItem,   // if you click an Option, then that option will become selectedItem
  autoCompleteOnChange = () => {}
}) => {
  // const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  // const loading = open && options.length === 0;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  React.useEffect(() => {
    let active = true;

    // if (!loading) {
    //   return undefined;
    // }

    // (async () => {
    //   await sleep(1e3); // For demo purposes.

    //   if (active) {
    //     setOptions([...topFilms]);
    //   }
    // })();

    return () => {
      active = false;
    };
  }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        // setOpen(true);
        // onSearchTextChange()
      }}
      onClose={(e) => {
        const _inputVal = e.target.value;
        // setOpen(false);
        onClose()
      }}
      onClick={(e) => {
        const whatIsThis = e;
        console.log('Autocomplete -> onClick -> e -> ', e)
      }}
      autoSelect={true}
      onSelect={(e) => {
        const whatIsThis = e;
        console.log('Autocomplete -> onSelect -> e -> ', e)
      }}
      onChange={autoCompleteOnChange}
      // renderOption={ ( option,  { selected } ) => {}   }
      // renderOption={(option, { selected }) => {
      //   return (
      //     <div key={option.key} style={{onmouseenter: "{ backgroundColor: 'red'; }"}}>
      //       <Checkbox
      //         icon={icon}
      //         checkedIcon={checkedIcon}
      //         style={{ marginRight: 8 }}
      //         checked={selected}
      //       />
      //       {/* {option.toString()} */}
      //       {option.key}
      //     </div>
      //   )
      // }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={textfieldLabel}
          onChange={onSearchTextChange}
          value={searchTextValue }
          // InputProps={{
          //   ...params.InputProps,
          //   endAdornment: (
          //     <React.Fragment>
          //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
          //       {params.InputProps.endAdornment}
          //     </React.Fragment>
          //   ),
          // }}
          // value={searchTextValue}
          // InputProps={{
          //   ...params.InputProps,
          //   // startAdornment: (
          //   //   <p>{searchTextValue}</p>
          //   // ),
          //   endAdornment: (
          //     <React.Fragment>
          //       {loading ? <CircularProgress color="inherit" size={20} /> : null}
          //       { selectedText && <Chip label={selectedText} onDelete={() => {}}/> }
          //     </React.Fragment>
          //   ),
          // }}
          InputProps={(() => { 
            let xxx = params;
            return {
              ...params.InputProps,
              // startAdornment: (
              //   <p>{searchTextValue}</p>
              // ),
              value: searchTextValue,
              inputProps: { 
                ...params.inputProps,
                value: searchTextValue
              },
              // endAdornment: (
              //   <React.Fragment>
              //     {loading ? <CircularProgress color="inherit" size={20} /> : null}
              //     { selectedText && <Chip label={selectedText} onDelete={() => {}}/> }
              //   </React.Fragment>
              // ),
              startAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {/* { selectedItem && <Chip label={selectedItem} onDelete={() => {}}/> } */}
                  { selectedItem &&  selectedItem}
                </React.Fragment>
              ),
            }
          })()}
        />
      )}
    />
  );
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
];
