
// Actions
import { setActivityTypeFilter } from "../../actions/filterActions/setActivityTypeFilter"
import { setClassroomsFilter } from "../../actions/filterActions/setClassroomsFilter"
import { setEmailFilter } from "../../actions/filterActions/setEmailFilter"
import { setHostsFilter } from "../../actions/filterActions/setHostsFilter"
import { setInterestsFilter } from "../../actions/filterActions/setInterestsFilter"
import { setIsDismissedFilter } from "../../actions/filterActions/setIsDismissedFilter"
import { setLanguagesFilter } from "../../actions/filterActions/setLanguagesFilter"
import { setNotificationsFilter } from "../../actions/filterActions/setNotificationsFilter"
import { setOnlineOfflineFilter } from "../../actions/filterActions/setOnlineOfflineFilter"
import { setPlatformFilter } from "../../actions/filterActions/setPlatformFilter"
import { setSearchQueryText } from "../../actions/filterActions/setSearchQueryText"
import { setSinceDateForFilter } from "../../actions/filterActions/setSinceDateForFilter"
import { setTicketReservationFilter } from "../../actions/filterActions/setTicketReservationFilter"
import { setUntilDateForFilter } from "../../actions/filterActions/setUntilDateForFilter"
import { setUserTypeFilter } from "../../actions/filterActions/setUserTypeFilter"


export const handleRemoveFilterItemHelper = ({
  dispatch,
  clickedOptionDefaultFilterKey,
}) => {
  if ( clickedOptionDefaultFilterKey === 'searchQuery') {
    dispatch(  // if removing -> reset search query text
      setSearchQueryText({ isReset: true })
    )
  }
  if ( clickedOptionDefaultFilterKey === 'onlineOffline') {
    dispatch(  // if removing -> 
      setOnlineOfflineFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'isDismissed') {
    dispatch(  // if removing -> 
      setIsDismissedFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'sinceDate') {
    dispatch(  // if removing -> 
      setSinceDateForFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'untilDate') {
    dispatch(  // if removing -> 
      setUntilDateForFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'hosts') {
    dispatch(  // if removing -> 
      setHostsFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'classroom') {
    dispatch(  // if removing -> 
      setClassroomsFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'languages') {
    dispatch(  // if removing -> 
      setLanguagesFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'interests') {
    dispatch(  // if removing -> 
      setInterestsFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'notification') {
    dispatch(  // if removing -> 
      setNotificationsFilter({isReset: true})
    );
  }
  if ( clickedOptionDefaultFilterKey === 'userType') {
    dispatch(  // if removing -> 
      setUserTypeFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'email') {
    dispatch(  // if removing -> 
      setEmailFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'activityType') {
    dispatch(  // if removing -> 
      setActivityTypeFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'platform') {
    dispatch(  // if removing -> 
      setPlatformFilter({
        isReset: true
      })
    );
  }
  if ( clickedOptionDefaultFilterKey === 'ticketReservation' ) {
    dispatch(  // if removing -> 
      setTicketReservationFilter  ({
        isReset: true
      })
    );
  }
}