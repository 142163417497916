import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux"



import {
  Grid
} from '@material-ui/core';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { fetchInterestsForFilter } from '../../actions/filterActions/fetchInterestsForFilter';
import { setInterestsFilter } from '../../actions/filterActions/setInterestsFilter';
import { handleInterestItemClick } from '../../actions/filterActions/handleInterestItemClick';
import { fetchHostsForFilter } from "../../actions/filterActions/fetchHostsForFilter";
import { setHostsFilter } from "../../actions/filterActions/setHostsFilter";
import { handleHostItemClick } from "../../actions/filterActions/handleHostItemClick";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const HostFilterComponent = () => {
  // Global Variables
  const isHostsLoading = useSelector(state => state.filterReducer.isHostsLoading);
  const hostList = useSelector(state => state.filterReducer.filteringParameters.hostList);
  const fetchedHosts = useSelector(state => state.filterReducer.fetchedHosts);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();

  const [  _isHostOptionsOpen, _setIsHostOptionsOpen ] = useState(false)

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName}
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      {/* <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isHostOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setHostFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _languageOptions.length === 0 ) {
            _setIsHostOptionsLoading(true)
            _setIsHostOptionsOpen(true)
            setTimeout(() => {
              _setHostOptions( [ ..._dummyHosts ] );
              _setIsHostOptionsLoading(false)
            }, 750)
          } else {
            _setIsHostOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsHostOptionsOpen(false)
        }}
        loading={  _isHostOptionsLoading }
        options = {[ ..._hostOptions ]}
        htmlId='event-screen-host-autocomplete'
        autoCompleteLabel='Hosts'
      /> */}
      <Autocomplete      
        multiple
        id={'speaklabs-hosts-filter-component'}
        sx={{ width: 300 }}
        open={_isHostOptionsOpen}
        disableCloseOnSelect
        onOpen={() => {
          _setIsHostOptionsOpen(true)
          if ( fetchedHosts.length === 0 ) {
            dispatch(
              fetchHostsForFilter({})
            );
          } 
        }}
        onClose={() => {
          _setIsHostOptionsOpen(false)
        }}
        onChange={(e, newVal )=> {
          dispatch(
            setHostsFilter({
              newHostFilterList: newVal
            })
          );
        }}
        value={ hostList }
        getOptionLabel={(option) => {
          return option.label;
        }}
        options={fetchedHosts.map(
          hostItem => {
            return {
              ...hostItem,
              label: hostItem.nameSurname ?? hostItem.email

            }
          }
        )}
        loading={isHostsLoading}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={ e => {
                dispatch(
                  handleHostItemClick({
                    hostItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  hostList.findIndex(
                    item => item.label === option.label
                  ) > -1
                } 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Hosts'}

            InputProps={(() => { 
              let xxx = params;
              return {
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isHostsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),

                startAdornment: (
                  <React.Fragment>
                    {(() => {
                      return params.InputProps.startAdornment;
                    })()}
                  </React.Fragment>
                ),
              }
            })()}
          />
        )}
      />
    </Grid>
  );
}