import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'

// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export const UserEditTableHead = () => {


  return (
    <TableHead id='user-edit-screen-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>Property</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Value</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
