import React, {useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Redux
import store from '../../store'

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { getNotificationList } from '../../actions/notificationActions/getNotificationList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';

import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';

// MUI
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { NotificationScreenTableHead } from './NotificationScreenTableHead';
import { NotificationItem } from './NotificationItem';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';


// Filter Components
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { SinceDateFilterComponent } from '../../components/filter/SinceDayFilterComponent';
import { UntilDateFilterComponent } from '../../components/filter/UntilDateFilterComponent';
import { SearchQueryComponent } from '../../components/filter/SearchQueryComponent';
import { ClassroomFilterComponent } from '../../components/filter/ClassroomFilterComponent';


// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';


export const  NotificationScreen = ({

}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const notificationList = useSelector(state => state.notificationReducer.notificationList);
  // const isLoadingNotifications = useSelector(state => state.notificationReducer.isLoading);

  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true);









  
  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { 
      label: 'Search Query', isChecked: false, 
      // component: SearchQueryComponent, 
      component: null, 
      // defaultFilterValue: _initialFilteringParameters.searchQuery  ,
      defaultFilterKey: 'searchQuery'  ,
    },
    { label: 'Classrooms', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Since-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ]);



  useEffect(() => {
    getNotificationList({
      limit: pageFilterCount,
      skip: 0,
      filteringParameters : {},
      order,
      cb: ( apiResponse ) => {
        // console.log('UserScreen -> fetchUserList -> cb -> apiResponse -> ', apiResponse)
        _setLoading(false);
      }
    })
    
    return () => {
      dispatch(
        resetFilters()
      );
    }
    // eslint-disable-next-line
  }, [])



  useEffect(() => {
    if ( !_loading ) {
      // console.log('filter useEffect')
      // console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      getNotificationList({
        limit: pageFilterCount,
        skip: 0,
        order,
        filteringParameters,
        cb: apiResponse => {
          dispatch(
            setIsFilteringLoading(false)
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])




  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
    >
      <Helmet defer={false}><title>Notifications</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Notifications'}</span> 
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  // _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  // _setFilteringParameters({..._initialFilteringParameters});
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                  >
                    {
                      isHideActiveUiFilters 
                        ?
                        <VisibilityIcon color='error' />
                        :
                        <VisibilityOffIcon />

                    }
                    
                  </IconButton>
                )
              }

            </>
          )}  
        />

        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container >
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Search Query')
                && _screenFilterOptions.find( item => item.label === 'Search Query').isChecked
                &&  (() => <SearchQueryComponent />)()
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Classrooms')
                && _screenFilterOptions.find( item => item.label === 'Classrooms').isChecked
                // && !_isHideActiveUiFilters
                &&  <ClassroomFilterComponent />
            }            


            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Since-Date')
                && _screenFilterOptions.find( item => item.label === 'Since-Date').isChecked
                &&  <SinceDateFilterComponent />
            }

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Until-Date')
                && _screenFilterOptions.find( item => item.label === 'Until-Date').isChecked
                &&  <UntilDateFilterComponent />
            } 
          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}



        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                {/* Table */}
                <CustomTable 
                  // stickyHeader={false}
                >
                  <NotificationScreenTableHead/>
                  <TableBody>
                    {/* <NotificationItem />
                    <NotificationItem />
                    <NotificationItem /> */}
                    {
                      notificationList && notificationList.length > 0 && notificationList.map(
                        (notificationItem, ind ) => (
                          <NotificationItem 
                            notificationItem={notificationItem}
                            key={ind}
                          />
                        )
                      )
                    }
                  </TableBody>
                </CustomTable>
                {/* End of Table */}

                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }

                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    store.dispatch(setIsPaginationLoading(true))
                    store.dispatch(setPaginationPage(page))
                    getNotificationList({
                      cb: apiResponse => {
                        dispatch(
                          setIsPaginationLoading(false)
                        );
                      },
                      filteringParameters,
                      limit: pageFilterCount,
                      order: order,
                      skip: ( page - 1  ) * pageFilterCount
                    })

                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>
            )
        }


      </Grid>

      <Grid container  className='w-100 mb-2'>
        <Grid item className='mt-1'>
          <Button 
            variant='contained'
            component={NavLink}
            color='secondary'
            to={'/notification/create'}
          >
            Send Notification
          </Button>

        </Grid>
      </Grid>
    </Container>
  )
}


