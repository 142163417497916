import {filterTypes} from "../../constants";
import styles from "./filters.module.css";
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from "@mui/material/FormControl";
import {InputLabel, Select} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

const ClearButton = ({onClick}) => {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={onClick}
                onMouseDown={onClick}
                edge="end"
            >
                <ClearIcon/>
            </IconButton>
        </InputAdornment>
    );
}

export const FilterStrategy = ({ filter, value, onChange }) => {
    switch (filter.type) {
        case filterTypes.NUMBER:
            return (
                <div className={styles.filters}>
                    <FormControl variant="outlined">
                        <InputLabel>{filter.label} from</InputLabel>
                        <OutlinedInput type="number"
                                       size="small"
                                       value={value?.from}
                                       onChange={(e) => {
                                           onChange(filter.name, {
                                               ...value,
                                               from: +e.target.value
                                           });
                                       }}
                                       endAdornment={value ? <ClearButton onClick={() => {
                                           onChange(filter.name, {
                                               ...value,
                                               from: null
                                           });
                                       }}/> : undefined}/>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel>{filter.label} to</InputLabel>
                        <OutlinedInput type="number"
                                       size="small"
                                       value={value?.to}
                                       onChange={(e) => {
                                           onChange(filter.name, {
                                               ...value,
                                               to: +e.target.value
                                           });
                                       }}
                                       endAdornment={value ? <ClearButton onClick={() => {
                                           onChange(filter.name, {
                                               ...value,
                                               to: null
                                           });
                                       }}/> : null} />
                    </FormControl>
                </div>
            );
        case filterTypes.DATE:
            return (
                <div className={styles.filters}>
                    <FormControl variant="outlined">
                        <InputLabel>{filter.label} from</InputLabel>
                        <OutlinedInput type="date"
                                       size="small"
                                       value={value?.from}
                                       onChange={(e) => {
                                           onChange(filter.name, {
                                               ...value,
                                               from: e.target.value
                                           });
                                       }}
                                       endAdornment={value?.from ? <ClearButton onClick={() => {
                                           onChange(filter.name, {
                                               ...value,
                                               from: null
                                           });
                                       }}/> : null}/>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel>{filter.label} to</InputLabel>
                        <OutlinedInput type="date"
                                       size="small"
                                       value={value?.to}
                                       onChange={(e) => {
                                           onChange(filter.name, {
                                               ...value,
                                               to: e.target.value
                                           });
                                       }}
                                       endAdornment={value?.to ? <ClearButton onClick={() => {
                                           onChange(filter.name, {
                                               ...value,
                                               to: null
                                           });
                                       }}/> : null}/>
                    </FormControl>
                </div>
            );
        case filterTypes.SEARCH:
            console.log(filter.name, value);
            return (
                <FormControl variant="outlined">
                    <InputLabel>{filter.label}</InputLabel>
                    <OutlinedInput type="text"
                                   size="small"
                                   value={value}
                                   onChange={(e) => {
                                       onChange(filter.name, e.target.value);
                                   }}
                                   endAdornment={value ? <ClearButton onClick={() => {
                                       onChange(filter.name, '');
                                   }}/> : null}/>
                </FormControl>);
        case filterTypes.SELECT:
            return (
                <FormControl variant="outlined">
                    <InputLabel>{filter.label}</InputLabel>
                    <Select
                        value={value || []}
                        size="small"
                        variant="outlined"
                        className={styles.select}
                        label={filter.label}
                        multiple={!filter.single}
                        onChange={(e) => {
                            onChange(filter.name, e.target.value);
                        }}
                        endAdornment={value && value.length ? <ClearButton onClick={() => {
                                onChange(filter.name, filter.single ? "" : []);
                            }}/> : null}
                    >
                        {filter.options.map(item => (
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>);
        case filterTypes.BOOLEAN:
            return (
                <FormControl variant="outlined">
                    <InputLabel>{filter.label}</InputLabel>
                    <Switch size="small" checked={value} onChange={(e) => {
                        onChange(filter.name, e.target.checked);
                    }} />
                </FormControl>);
        default:
            return null;
    }
};
export const FiltersV2 = ({filtersConfig, filters, onFiltersChange }) => {
    const onFilterChange = (name, value) => {
        onFiltersChange((prevFilters) => {
            return  {
                ...prevFilters,
                [name]: value,
            };
        });
    };

    return (
        <div className={styles.filters}>
            {filtersConfig.map((filter) => {
                return (
                    <FilterStrategy key={filter.name} filter={filter} onChange={onFilterChange} value={filters[filter.name]} />
                );
            })}
        </div>
    );
}
