import React from 'react'
import { CustomTable } from '../../components/table/CustomTable'
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead'
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow'
import { StyledTableCell } from '../../components/table/StyledTableComponents'
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton'
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime'

export const ClassroomDetailTable = ({
  formData,
  languageTitle,
}) => {
  const {
    isEventOnline,
    level,

    timezone,

    isTicketBySubscriptionPossible,

    // Registration & Ticket Fields
    isRegistrationPossible,
    isRegistrationFree,
    registrationFee,   
    //-----------------
    isNoTicketEvent,  // this is for offline events, where users pay money to place. we are only providing information about event
    eventOrganiserPrice,  // this is price that people pay at entrance, not related with our app, we are only showing to inform users
    //-----------------   
    ticketPrice,

  


    // duration: '2 hours',
    duration,
    maxAttendee,
    eventHostDocument,
    eventTitle,
    eventBoldText,
    eventRegularText,

    // OFFLINE EVENT FIELDS
    offlineEventType,
    countryEn, // Country in English Lang
    cityTitle,
    address,



  } = formData;

  return (
    <CustomTable>
      <DoubleColumnTableHead />
      
      <DoubleColumnTableRow 
        keyTitle='Event Title'
        valueText={eventTitle ?? '-'}
      />
      <DoubleColumnTableRow 
        keyTitle='Online-Offline'
        valueText=  { isEventOnline ? 'Online' : 'Offline'}
      />
      <DoubleColumnTableRow 
        keyTitle='Event Bold Text'
        valueText={eventBoldText ?? '-'}
      />
      <DoubleColumnTableRow 
        keyTitle='Event Regular Text'
        valueText={eventRegularText ?? '-'}
      />      
      <DoubleColumnTableRow 
        keyTitle='Language'
        valueText={languageTitle}
      />
      <DoubleColumnTableRow 
        keyTitle='Level'
        valueText={level}
      />
      <DoubleColumnTableRow 
        keyTitle='Timezone'
        valueText={
          (() => {
            let _timezone = parseInt(timezone.toString());
            if( _timezone === 0 ) {  // 0
              return 'GMT+00';            
            }
            if ( (_timezone > 0) && (_timezone < 10 ) ) {  // 0  < timezone < 10
              return 'GMT+0' + _timezone; 
            }
            if ( (_timezone > 10 )  ) {  //  timezone > 10
              return 'GMT+' + _timezone; 
            }
            if ( (_timezone < 0) && (_timezone > -10 ) ) {  // 0  > timezone > -10
              return 'GMT' + _timezone ; 
            }
            if ( (_timezone < -10 )  ) {  //  timezone < -10
              return 'GMT' + _timezone ; 
            }
            return 'Invalid timezone'
          })()
        }
      />
      <DoubleColumnTableRow 
        keyTitle='Duration'
        valueText={ 
          duration %60 === 0 
            ?
            duration/60 + ' Hours'
            :
            (
              duration < 60
              ?
              duration + ' Minutes'
              :
              ( (duration - (duration %60) ) / 60 ) + 'Hours ' + (duration %60)+'Min.'
            ) 
        }
      />
      {
        isEventOnline === false && (
          <DoubleColumnTableRow 
            keyTitle='Event Type'
            valueText={offlineEventType}
          />
        )
      }
      {
        eventHostDocument && (
          <DoubleColumnTableRow 
            keyTitle='Host'
            valueComponent={(
              <TableNavlinkTextButton
                buttonLink={'/user/edit/' + eventHostDocument._id}
                buttonText={ eventHostDocument.nameSurname ?? eventHostDocument.email ?? '-' }
              />
            )}
          />
        )
      }
      <DoubleColumnTableRow 
        keyTitle='Max Attendee'
        valueText={maxAttendee}
      />
      <DoubleColumnTableRow 
        keyTitle='Ticket Price'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isNoTicketEvent
            ? 
            ( '$' + eventOrganiserPrice + ' (Organiser P.)' )
            : 
            ( '$' + ticketPrice  )
        }
      />
      <DoubleColumnTableRow 
        keyTitle='Ticket Via Subs.'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isTicketBySubscriptionPossible ? 'Possible' : 'Not Possible'
        }
      />
      <DoubleColumnTableRow 
        keyTitle='Registration'
        valueText={
          // isNoTicketEvent
          // ticketPrice
          // eventOrganiserPrice
          isRegistrationPossible
            ? 
            (
              isRegistrationFree
                ?
                'Free'
                :
                ( '$' + registrationFee )
            )
            : 
            'Not Possible'
        }
      />
      {/* <DoubleColumnTableRow 
        keyTitle='Attendee'
        valueText={22}
      /> */}
      {
        isEventOnline === false && (
          <>
            <DoubleColumnTableRow 
              keyTitle='City'
              valueText={cityTitle + ', ' + countryEn }
            />
            <DoubleColumnTableRow 
              keyTitle='Address'
              valueText={ address }
            />
          </>
        )
      }
    </CustomTable>
  )
}



