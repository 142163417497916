import React from 'react'


// MUI
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';


// Components
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';


// Helpers
import { convertInputToCurrencyText } from '../../helpers/common/convertInputToCurrencyText';



export const TicketSetComponent = ({
  formData,
  setFormData,
  registrationEventState
}) => {
  return (
    <>
        <Grid item sm={12} className='mt-2'>
            <h4 className='text-center mbe-0 mbs-0'>Ticket</h4>
        </Grid>
      {
        formData.isEventOnline === false && (
          <Grid 
            item  
            className='w-100 mt-2 pr-3'     
            style={{
              // backgroundColor: 'pink'
            }}
            sm={12} md={6} lg={4}
          >
            <Stack 
              direction='column' 
              // alignItems='center' 
              className='mr-2'
            >
              <FormCustomTitle
                isLineHeightOne={true}
                formText={'No Ticket Event'}
              />
              <Switch
                onChange={(e, checked) => {
                  console.log('HELLLOO');
                  
                  setFormData({
                    ...formData,
                    ticketPrice: checked ? null : registrationEventState.ticketPrice,
                    isNoTicketEvent: checked,
                  })
                }}
                checked={formData.isNoTicketEvent}
              />
            </Stack>
            <Stack className='w-100' direction='row'> 
            </Stack>
          </Grid>
        )
      }
      {
        (  
          ( formData.isEventOnline === false && formData.isNoTicketEvent )  === false
        )  && (
          <Grid 
            item  
            className='w-100 mt-2 pr-3'     
            style={{
              // backgroundColor: 'pink'
            }}
            sm={12} md={6} lg={4}
          >
            <Stack 
              direction='column' 
              // alignItems='center'  
              className='mr-2'
            >
              <TextField
                type='number'
                  variant='standard'
                  label='Ticket Price'
                  placeholder='Ticket Price'
                  required={true}
                  fullWidth
                  value={formData.ticketPrice}
                  onBlur={() => {
                    setFormData({
                      ...formData,
                      ticketPrice: convertInputToCurrencyText({
                        inputValue: formData.ticketPrice.toString()
                      })                      
                    })                    
                  }}                  
                  onChange={e => {
                    setFormData({
                      ...formData,
                      ticketPrice:  e.target.value
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Tab' && !e.shiftKey ) {
                      setFormData({
                        ...formData,
                        ticketPrice: convertInputToCurrencyText({
                          inputValue: formData.ticketPrice.toString()
                        })                      
                      })
                    } 
                  }}
                />
            </Stack>
          </Grid>
        )
      }
      
      { 
        ( formData.isEventOnline === false )
        && formData.isNoTicketEvent   && (
          <Grid 
            item  
            className='w-100 mt-2 pr-3'     
            style={{
              // backgroundColor: 'pink'
            }}
            sm={12} md={6} lg={4}
          >
            <Stack 
              direction='column' 
              // alignItems='center'  
              className='mr-2'
            >
              <TextField
                type='number'
                  variant='standard'
                  label='Event Organiser Price'
                  placeholder='Event Organiser Price'
                  required={true}
                  fullWidth
                  value={formData.eventOrganiserPrice}
                  onBlur={() => {
                    setFormData({
                      ...formData,
                      eventOrganiserPrice: convertInputToCurrencyText({
                        inputValue: formData.eventOrganiserPrice.toString()
                      })                      
                    })                    
                  }}                  
                  onChange={e => {
                    setFormData({
                      ...formData,
                      eventOrganiserPrice:  e.target.value
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Tab' && !e.shiftKey ) {
                      setFormData({
                        ...formData,
                        eventOrganiserPrice: convertInputToCurrencyText({
                          inputValue: formData.eventOrganiserPrice.toString()
                        })                      
                      })
                    } 
                  }}
                />
            </Stack>
          </Grid>
        )
      }


      <Grid 
        item  
        className='w-100 mt-2 pr-3'     
        style={{
          // backgroundColor: 'pink'
        }}
        sm={12} md={6} lg={4}
      >
        <Stack 
          direction='column' 
          // alignItems='center' 
          className='mr-2'
        >
          <FormCustomTitle
            isLineHeightOne={true}
            formText={'Ticket By Subs. Possible'}
          />
          <Switch
            onChange={(e, checked) => {              
              setFormData({
                ...formData,
                isTicketBySubscriptionPossible: checked,
              })
            }}
            checked={formData.isTicketBySubscriptionPossible}
          />
        </Stack>
        <Stack className='w-100' direction='row'> 
        </Stack>
      </Grid>
    </>
  )
}
