import axios from "axios";

// Redux
import store from '../../store'


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  SET_ITEM_COUNT,
  SET_NOTIFICATIONS_LOADING,
  NOTIFICATION_LIST_fETCHED,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const getNotificationList = async  ({
  limit,
  skip,
  filteringParameters,
  order,
  cb,  // callBack
}) =>   {
  try { 
    const formData = {
      limit,
      skip,
      filteringParameters,
      order,
    }
    // dispatch({
    //   type: SET_CONTACT_MESSAGES_LOADING,
    //   payload: true
    // });
    store.dispatch(
      {
          type: SET_NOTIFICATIONS_LOADING,
          payload: true
      }
    )


    const res = await axios.post(
      // urlConstants.apiUrl +  
      urlConstants.getNotificationListUrl, 
      formData ?? {},
      clientAppConstants.jsonConfig
    )
    
    store.dispatch(
      {
          type: SET_ITEM_COUNT,
          payload: res.data.notificationCount
      }
    );
    store.dispatch(
      {
        type: NOTIFICATION_LIST_fETCHED,
        payload: res.data.notificationList
      }
    );
    if ( cb ) {      
      cb(res.data);      
    }
    


  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => store.dispatch(setAlert(error.msg, "error", 2500)) );
    }
    store.dispatch(
      {
          type: SET_NOTIFICATIONS_LOADING,
          payload: false
      }
    )
    if ( cb ) {
      cb([]);
    }
  }
}  