import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux"



import {
  Grid
} from '@material-ui/core';

// MUI
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { fetchInterestsForFilter } from '../../actions/filterActions/fetchInterestsForFilter';
import { setInterestsFilter } from '../../actions/filterActions/setInterestsFilter';
import { handleInterestItemClick } from '../../actions/filterActions/handleInterestItemClick';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;



export const InterestFilterComponent = () => {
  // Global Variables
  const isInterestsLoading = useSelector(state => state.filterReducer.isInterestsLoading);
  const interestList = useSelector(state => state.filterReducer.filteringParameters.interestList);
  const fetchedInterests = useSelector(state => state.filterReducer.fetchedInterests);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();

  const [  _isInterestOptionsOpen, _setIsInterestOptionsOpen ] = useState(false)


  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <Autocomplete      
        multiple
        id={'speaklabs-interests-filter-component'}
        sx={{ width: 300 }}
        open={_isInterestOptionsOpen}
        disableCloseOnSelect
        onOpen={() => {
          _setIsInterestOptionsOpen(true)
          if ( fetchedInterests.length === 0 ) {
            dispatch(
              fetchInterestsForFilter({})
            );
          } 
        }}
        onClose={() => {
          _setIsInterestOptionsOpen(false)
        }}
        onChange={(e, newVal )=> {
          dispatch(
            setInterestsFilter({
              newInterestFilterList: newVal
            })
          );
        }}
        value={ interestList }
        getOptionLabel={(option) => {
          return option.label;
        }}
        options={fetchedInterests.map(
          interestItem => {
            return {
              ...interestItem,
              label: interestItem.interestTitle

            }
          }
        )}
        loading={isInterestsLoading}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={ e => {
                dispatch(
                  handleInterestItemClick({
                    interestItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  interestList.findIndex(
                    item => item.label === option.label
                  ) > -1
                } 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Interests'}

            InputProps={(() => { 
              let xxx = params;
              return {
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isInterestsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  </React.Fragment>
                ),

                startAdornment: (
                  <React.Fragment>
                    {(() => {
                      return params.InputProps.startAdornment;
                    })()}
                  </React.Fragment>
                ),
              }
            })()}
          />
        )}
      />
    </Grid>
  );
}