


import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   USERS_FETCHED, SET_USERS_LOADING } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const addClassroom = ({
  formData,
  eventId = null,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const _formData = new FormData();
    _formData.append('jsonText', JSON.stringify({
      ...formData,
      offlineEventImage: undefined,
      onlineEventVerticalImage: undefined,
      onlineEventHorizontalImage: undefined,
    }))
    if ( formData.isEventOnline ) {  // ONLINE
      _formData.append("images[]", formData.onlineEventVerticalImage);
      _formData.append("images[]", formData.onlineEventHorizontalImage);
    } else {  // OFFLINE
      _formData.append("images[]", formData.offlineEventImage);
    }
    // dispatch({
    //   type: SET_USERS_LOADING,
    //   payload: true
    // });


    const res = await axios.post(
      urlConstants.addClassroom, 
      _formData,
      clientAppConstants.multipartFormDataConfig
    );  
    dispatch(setAlert(res.data.msg, "success", 2500));
    // dispatch(setFilteringItemCount({itemCount: res.data.userCount}));

    // dispatch({
    //   type: USERS_FETCHED,
    //   payload: res.data.userList
    // });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
}  