import React, {useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchTicketListOfEvent } from '../../actions/eventActions/fetchTicketListOfEvent';
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';

import {
  Container,
  TableBody,
  // Paper,
  Grid
} from '@material-ui/core';

// MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { EventTicketsTableHead } from './EventTicketsTableHead';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { EventTicketItem } from './EventTicketItem';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { TicketReservationFilterComponent } from '../../components/filter/TicketReservationFilterComponent';
import { PlatformFilterComponent } from '../../components/filter/PlatformFilterComponent';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { EventInformationComponent } from './EventInformationComponent';


// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';



export const  EventTicketsScreen = ({

  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);
  
  const dispatch = useDispatch();
  
  
  const [eventId, setEventId] = useState(match.params.eventId || null);
  const [_loading, _setLoading] = useState(true)
  const [ formData, setFormData ] = useState(null);

  useEffect(() => {
    dispatch(
      fetchTicketListOfEvent({
        eventId,
        cb: apiResponse => {
          console.log('fetchTicketListOfEvent -> cb -> apiResponse -> ', apiResponse)
          if ( apiResponse ) {
            setFormData( apiResponse.event )
          }
          _setLoading(false);
        }
      })
    );
    return () => {
      dispatch(
        resetFilters()
      );
    }
  }, []);



  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      // dispatch(
      //   setPaginationPage(1)
      // );
      dispatch(
        fetchTicketListOfEvent({
          // limit: pageFilterCount,
          // skip: 0,
          eventId,
          isOnlyTicketList: true,
          order,
          filteringParameters,
          cb: apiResponse => {
            console.log(formData);
            if ( apiResponse && apiResponse.ticketList ) {
              setFormData({
                ...formData,
                ticketList: apiResponse.ticketList
              })
            }
            dispatch(
              setIsFilteringLoading(false)
            );
          }
        })
      )
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])
  




  const _isFilteringActive = (() => {
    if ( !filteringParameters ) {
      return false;
    }
    if( 
      (
        filteringParameters.platformList &&
        filteringParameters.platformList.length > 0
      )
      || (
        filteringParameters.ticketReservation &&
        filteringParameters.ticketReservation.length > 0
      )
      // || filteringParameters.orderBy !== null
    ) {
      return true;
    }
    return false;
  })()









  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Ticket-Reservation', isChecked: false, 
      component: null, 
      defaultFilterKey: 'ticketReservation'  ,
    },
    { label: 'Platform', isChecked: false, 
      component: null, 
      defaultFilterKey: 'platform'  ,
    },
  ]) 




  return (
    <Container
      id='event-tickets-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      // className='flexcol justify-content-space-between'
    >
      <Helmet defer={false}>
        <title>
          {  
            ( ()=>{
              if ( !formData ) {
                return 'Tickets';
              }
              return 'Tickets - ' + formData.eventTitle;
            })()
          }        
        </title>
      </Helmet>

      {
        _loading
        ?
        <ScreenCircularProgress />
        :
        (
          <>
            <Grid
              container
              direction='column'
              // justify='space-between'
              style={{
                // minHeight: clientAppConstants.screenContainerMinHeight
              }}
            >

              

              <ScreenTitle   
                titleText={(
                  <>
                    <Button 
                      size="small"
                      style={{
                        // color: 'black'
                        fontSize: '1.2rem'
                      }}
                      component={NavLink}
                      color={  'primary'  }
                      to={'/event/edit/' + eventId}
                    >
                      {formData.eventTitle}
                    </Button>
                    <span>{'/ Tickets'}</span>
                    
                    <FilterDialogButton
                      filterOptions={ _screenFilterOptions }
                      handleResetFilters={() => {
                        _setFilterUiElements([]);
                        // _setIsHideActiveUiFilters(false);
                        _setScreenFilterOptions(
                          [
                            ..._screenFilterOptions.map(
                              item => ({
                                ...item,
                                isChecked: false
                              })
                            )
                          ]
                        );
                        // _setFilteringParameters({..._initialFilteringParameters});
                        // setOrder(_initialOrder);  
                        dispatch(
                          resetFilters()
                        );
                      }}

                      handleClickOption={(inputIndex) => {
                        const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                        if ( _clickedOption.isChecked === false ) {  // add component
                          _setFilterUiElements([
                            ... _filterUiElements,
                            { 
                              label: _clickedOption.label, 
                              component: _clickedOption.component 
                            }
                          ])
                        } else {  // remove component

                          handleRemoveFilterItemHelper({
                            clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                            dispatch
                          });


                          _setFilterUiElements([
                            ... _filterUiElements.filter(
                              item => item.label !== _clickedOption.label
                            )
                          ]);
                        }
                        _setScreenFilterOptions(
                          [
                            ..._screenFilterOptions.map(
                              (item, ind) => {
                                return {
                                  ...item,
                                  isChecked: (ind === inputIndex) 
                                    ?
                                    !item.isChecked
                                    :
                                    item.isChecked
                                }
                              }
                            )
                          ]
                        );
                      }}
                    />
                    {
                      _filterUiElements.length > 0 && (
                        <IconButton
                        onClick={() => {
                          // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                          dispatch(
                            setIsHideActiveUiFilters()
                          );
                        }}
                      >
                        {
                          isHideActiveUiFilters 
                            ?
                            <VisibilityIcon color='error' />
                            :
                            <VisibilityOffIcon />

                        }
                        
                      </IconButton>
                      )
                    }
                  </>
                )}
              />


              <EventInformationComponent formData={formData} />        


              {/* FILTERING COMPONENTS */}
              <Grid item  >
                <Grid container className='pb-1'  >

                  {
                    _screenFilterOptions 
                      && _screenFilterOptions.find( item => item.label === 'Ticket-Reservation')
                      && _screenFilterOptions.find( item => item.label === 'Ticket-Reservation').isChecked
                      &&  <TicketReservationFilterComponent />
                  }

                  {
                    _screenFilterOptions 
                      && _screenFilterOptions.find( item => item.label === 'Platform')
                      && _screenFilterOptions.find( item => item.label === 'Platform').isChecked
                      &&  <PlatformFilterComponent />
                  }



                  




                </Grid>
              </Grid>
              {/* End of FILTERING COMPONENTS */}

              <Grid item className='pb-1' />


              

              {/* Table */}
              {
                isFilteringLoading === false && (
                  <CustomTable 
                  >
                    <EventTicketsTableHead/>
                    <TableBody>
                      {/* <UserTicketItem />
                      <UserTicketItem />
                      <UserTicketItem /> */}
                      {
                        formData && formData.ticketList && formData.ticketList.map(
                          (ticketItem, ind) => (
                            <EventTicketItem
                              key={ind}
                              ticketItem={ticketItem}
                            />

                          )
                        )
                      }
                    </TableBody>
                  </CustomTable>
                )
              }
              {/* End of Table */}


              {
                ( isFilteringLoading) && <ScreenCircularProgress />
              }





            

              


            </Grid>
            
            {
              _isFilteringActive && (
                <Grid container sm={12} className={'w-100 mb-2'}>
                  <Grid item sm={12} className={'w-100'}>
                    <Paper className='pr-1'>
                      <Stack 
                        direction='row'
                        justifyContent='end'
                      >
                        <p
                          // style={{
                          //   fontWeight: 'bold',                      
                          // }}
                          className='font-weight-bold mr-1'
                        >
                          Filtered Transaction Total:
                        </p>
                        <p
                          className='font-weight-bold mr-1'
                        >
                          {'$' + '75.00'}
                        </p>
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>
              )
            }
          </>
        )
      }
    </Container>
  )
}


