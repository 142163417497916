import React, { useEffect,  useState } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import {   Redirect } from 'react-router-dom';
import moment from 'moment';
import Helmet from 'react-helmet';


// Actions
// import {
//   createAdmin,
//   fetchSingleAdmin,
//   updateAdmin
// } from '../../actions/adminActions';
// import {
//   addSponsor,
//   fetchSingleSponsor,
//   updateSponsor,
// } from '../../actions/sponsorActions';


import './EventAddEditScreen.css'



import {
  Container,
  Grid,

  // Checkbox,
} from '@material-ui/core';


// MUI
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import {   makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ModeEditIcon from '@mui/icons-material/ModeEdit';


// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { AsyncAutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AsyncAutoCompleteMultipleCheckbox';
import { SimpleCustomSelect } from '../../components/form/SimpleCustomSelect';
import SimpleNativeSelect from '../../components/form/SimpleNativeSelect';
import {MobileDateTimePicker} from '../../components/form/CustomDateTimePicker';
import { AsyncAutoCompleteField } from '../../components/form/AsyncAutoCompleteField';
import { CustomDateTimePicker } from '../../components/form/CustomDateTimePicker';
import { CustomDateTime2 } from '../../components/form/CustomDateTime2';
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import CustomTextAreaAutoSize from '../../components/form/CustomTextAreaAutoSize';
import { SelectedHostChip } from './SelectedHostChip';
import { SubscriptionPackageDetailTable } from './SubscriptionPackageDetailTable';
  

// import DatePicker from '../../components/datePicker/DatePicker'
// import SelectedCategoryList from './SelectedCategoryList';


// Constants
import { clientAppConstants } from '../../constants';
// import { setAlert } from '../../actions/alertActions';





// Helpers
import { convertToIntegerGreaterThanOrEqualToMinValue } from '../../helpers/common/convertToIntegerGreaterThanOrEqualToMinValue';
import { convertInputToCurrencyText } from '../../helpers/common/convertInputToCurrencyText';
import { fetchSubscriptionClassAddEditEntities } from '../../actions/subscriptionClassActions/fetchSubscriptionClassAddEditEntities';
import { addSubscriptionClass } from '../../actions/subscriptionClassActions/addSubscriptionClass';
import { updateSubscriptionClass } from '../../actions/subscriptionClassActions/updateSubscriptionClass';

// import { uint8ArrayToImageSource } from '../../utils/helpers';



export const SubscriptionPackageAddEditScreen = ({
    // Location, History, etc
    match, // location -> match.params.eventId
    history,
}) => {
    // Global Variables
    // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    // const appInitialised = useSelector(state => state.authReducer.appInitialised);
    // const userType = useSelector(state => state.authReducer.userType);

    const dispatch = useDispatch();

    const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
    const [subscriptionClassId, setSubscriptionClassId ] = useState( match.params.subscriptionClassId || null );
    const [formData, setFormData] = useState(null);

    const [ _languageList, _setLanguageList ] = useState([])

    const [ _isAddingOrUpdating, _setIsAddingOrUpdating] = useState(false);


    const [isHostLoading, setIsHostLoading] = useState(false);
    const [ hostOptions, setHostOptions ] = useState([]);
    const [openHostAutocomplete, setOpenHostAutocomplete] = useState(false)
    const [ hostSearchText, setHostSearchText ] = useState('');
    const [ selectedHost, setSelectedHost ] = useState(null)




    const [ _isEditMode, _setIsEditMode ] = useState(
      subscriptionClassId ?   false  :    true
    );


    const _initialAddEventFormState = {
      subscriptionTitle: '',
      subscriptionExplanation: '',
      price: '0.00',
      validPeriod: { count: 1, type: 'month' },
      isAllLanguages: true,
      language: '',
      languages: [ // if isAllLanguages NOT true, then select languages

      ],

      onlineOffline: 'All',
      isLimitlessTicket: false,
      ticketCount: 0,

      isActive: true,
    }

    useEffect(
      () => {
        setFormData({..._initialAddEventFormState});
        dispatch(
          fetchSubscriptionClassAddEditEntities({
            subscriptionClassId,
            cb: _cbFetchSubscriptionClassAddEditEntities
          })
        );
        
      }, []
    );

    const _cbFetchSubscriptionClassAddEditEntities = apiResponse => {
      if ( apiResponse ) {
        const {
          languageList,
          subscriptionClass
        } = apiResponse;
        if ( languageList) {
          _setLanguageList(languageList);                  
        }
        if ( subscriptionClass) {
          setFormData({
            ...formData,
            ...subscriptionClass,
            price: subscriptionClass.price.toFixed(2)
          })
        }

      }
      _setLoading(false);
    }


    const _cbAddSubscriptionClass = apiResponse => {
      if ( apiResponse ) {
        const {
          subscriptionClass,                
        } = apiResponse;
        if ( subscriptionClass ) {
          setSubscriptionClassId(subscriptionClass._id);
          setFormData({
            ...formData,
            ...subscriptionClass,
            price: subscriptionClass.price.toFixed(2)
          })
        }
      }
      _setIsAddingOrUpdating(false);
    }

    const _cbUpdateSubscriptionClass = apiResponse => {
      if ( apiResponse ) {
        const {
          subscriptionClass,                
        } = apiResponse;
        if ( subscriptionClass ) {
          setSubscriptionClassId(subscriptionClass._id);
          setFormData({
            ...formData,
            ...subscriptionClass,
            price: subscriptionClass.price.toFixed(2)
          })
        }
      }
      _setIsAddingOrUpdating(false);
    }


    const _onSubmitHandler = e => {
      e.preventDefault();
      if ( !subscriptionClassId ) {
        _setIsAddingOrUpdating(true);
        dispatch(
          addSubscriptionClass({
            formData,
            cb: _cbAddSubscriptionClass
          })
        );
      }
      if ( subscriptionClassId ) {
        _setIsAddingOrUpdating(true);
        dispatch(
          updateSubscriptionClass({
            subscriptionClassId,
            formData,
            cb: _cbUpdateSubscriptionClass
          })
        );
      }
    }

    const _dummyHosts0 = [

      {name: 'Emrah Ozyazgan',},
      {name:'Alex Kostin'},
      {name:'Sofia Kostin'},
      {name:'Great Palace'},
      {name:'Alex Sharapovin'},
      {name:'Guzel Zaharova'},
      {name:'Great Palace'},
    ];


    const _dummyLanguages = [
      { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
    ]
    const [ _languageOptions, _setLanguageOptions] = useState([])
    const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
    const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)
    const _setLanguages = (newValue) => {
      setFormData({ ...formData, languages: [...newValue] });
    }
    

  return (
    <Container
      maxWidth='lg'
      className='w-100 '
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight:clientAppConstants.screenContainerMinHeight
      }}
      id='event-add-edit-screen-container'
    >
      {/* <Helmet defer={false}><title>{sponsorId ? 'Sponsor Guncelle' : 'Sponsor Ekle'}</title></Helmet> */}
      <Grid container className='w-100'>
        <ScreenTitle 
          // titleText={ subscriptionId ? 'Update Subscription' : 'Add Subscription' } 
          titleText={(
            <>
              <span>
                {subscriptionClassId 
                  ? 
                  (
                    _isEditMode
                      ?
                      'Update Subscription' 
                      :
                      'Subscription Details'
                  )
                  : 
                  'Add Subscription'
                }
              </span>
              {
                subscriptionClassId && (
                  <IconButton
                    onClick={() => {
                      _setIsEditMode( !_isEditMode );
                    }}
                  >
                    <ModeEditIcon 
                      style={{
                        color: _isEditMode ? 'yellow' : 'green'
                      }}
                    />
                  </IconButton>
                )
              }
            </>
          )}
        />
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100'sm={12}>
              {
                _isEditMode 
                  ?                   
                  (
                    <form 
                      className='w-100' 
                      onSubmit={_onSubmitHandler}
                      style={{
                        minHeight:`calc( ${clientAppConstants.screenContainerMinHeight} - 58px )`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent:'space-between'
                      }}
                    >
                      <Grid container className='w-100'sm={12}>

                        {/* Subscription Title */}                  
                        <Grid item  className='w-100 mt-2 '     sm={12}>
                          <TextField
                            variant='standard'
                            label='Title'
                            placeholder='Please enter Subscription Title'
                            required={true}
                            fullWidth
                            value={formData.subscriptionTitle}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                subscriptionTitle: e.target.value
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Subscription Title */}


                        {/* Subscription Explanation */}                  
                        <Grid item  className='w-100 mt-2 '     sm={12}>
                          <TextField
                            variant='standard'
                            label='Explanation'
                            placeholder='Please enter Subscription Explanation'
                            required={true}
                            fullWidth
                            value={formData.subscriptionExplanation}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                subscriptionExplanation: e.target.value
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Subscription Explanation */}


                        {/* Price */}                  
                        <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                          <TextField
                            // type='number'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachMoneyIcon />
                                </InputAdornment>
                              ),
                            }}
                            variant='standard'
                            onBlur={() => {
                              setFormData({
                                ...formData,
                                price: convertInputToCurrencyText({
                                  inputValue: formData.price
                                })
                              });
                            }}
                            label='Price'
                            placeholder='Please Enter Price'
                            required={true}
                            fullWidth
                            value={formData.price}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                price:  e.target.value
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Price */}

                        {/* Valid Period */}                  
                        <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                          <TextField
                            // type='number'
                            variant='standard'
                            onBlur={() => {
                              setFormData({
                                ...formData,
                                validPeriod: {
                                    count: +formData.validPeriod.count ,
                                    type: formData.validPeriod.type
                                }
                              });
                            }}
                            label='Valid Period'
                            placeholder='Please Enter Valid Period'
                            required={true}
                            fullWidth
                            value={ formData.validPeriod.count.toString() }
                            onChange={e => {
                              setFormData({
                                ...formData,
                                // price: convertToIntegerGreaterThanOrEqualToMinValue({ inputValue: e.target.value})
                                  validPeriod: {
                                      count: e.target.value,
                                      type: formData.validPeriod.type
                                  }
                              });
                            }}
                          />
                        </Grid>
                          <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                              <SimpleCustomSelect
                                  label='Valid Period Type'
                                  // fullWidth={true}
                                  htmlId='add-event-screen-online-offline'
                                  noneText={'Select'}
                                  value={ formData.validPeriod.type   }
                                  optionList={[{ value: 'month', definition: 'month' }, { value: 'day', definition: 'day' }  ]}
                                  onChange={ e => {
                                      const newValue = e.target.value;
                                      setFormData({
                                          ...formData,
                                          validPeriod: {
                                              count: formData.validPeriod.count,
                                              type: newValue
                                          }
                                      });
                                  }}
                                  inputPropsId={'event-add-edit-screen-event-select'}
                              />
                          </Grid>
                        {/* End of Valid Period */}

                        {/* Online Offline */}                  
                        {/* <Grid item className='w-100 pt-1 ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            fullWidth
                            label='Online-Offline'
                            // fullWidth={true}
                            htmlId='add-event-screen-online-offline'
                            isNoneExist={ false }
                            // noneText={'Select'}
                            value={ formData.onlineOffline   }
                            optionList={[
                              { value: 'All', definition: 'All' }, 
                              { value: 'Online', definition: 'Online' }, 
                              { value: 'Offline', definition: 'Offline' }  
                            ]}
                            onChange={ e => {
                              setFormData({ ...formData, onlineOffline:  e.target.value }) ;
                            } }
                            inputPropsId={'subscription-add-edit-screen-online-offline-select'}
                          />
                        </Grid> */}
                        {/* End of Online Offline */}

                        


                        
                        {/* Language */}                        
                        {/* <Grid item className='w-100  ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            // fullWidth={true}
                            required={ formData.isAllLanguages === false }
                            // disabled={ classroomId }
                            label='Language'
                            htmlId={'select-language'}
                            eventTargetNAme={'isEventOnline'}
                            isNoneExist={ formData.language === '' }
                            noneText={'Select'}
                            value={ formData.language   }
                            // optionList={[{ value: 'English', definition: 'English' }, { value: 'French', definition: 'French' }  ]}
                            optionList={[
                              ..._languageList.map(
                                languageItem => {
                                  return {
                                    value: languageItem._id,
                                    definition: languageItem.languageTitle
                                  }
                                }
                              )
                            ]}
                            onChange={ e => {

                              setFormData({ 
                                ...formData, 
                                language:  e.target.value ,
                                isAllLanguages: false
                              }) ;
                            } }
                            inputPropsId={'subscription-add-edit-screen-event-select'}
                          />
                        </Grid> */}
                        {/* End of Language */}
                        {/* End of Language */}
                        
                        {/* Is All Languages */}                        
                        {/* <Grid item 
                          className='w-100  ' 
                          sm={12} md={6} lg={4}
                        >
                          <Stack 
                            direction='row' 
                            alignItems='center'   
                            className='hover-cursor-pointer mt-2'
                            onClick={() => {
                              setFormData({
                                ...formData,
                                isAllLanguages: !formData.isAllLanguages,
                                language: ''
                              })
                            }}
                          >
                            <Switch 
                              checked={(() => {
                                return formData.isAllLanguages 
                              })()} 
                            />
                            <FormCustomTitle
                              formText='Is All Languages?'
                              
                            />
                          </Stack>
                        </Grid> */}
                        {/* End of Is All Languages */}


                        
                        <Grid item 
                          className='w-100  ' 
                          sm={12} md={6} lg={4}
                        >
                          {/* <Stack 
                            direction='row' 
                            alignItems='center'   
                            className='hover-cursor-pointer mt-2'
                            onClick={() => {
                              setFormData({
                                ...formData,
                                isLimitlessTicket: !formData.isLimitlessTicket,
                                ticketCount: 0
                              })
                            }}
                          >
                            <Switch 
                              checked={(() => {
                                return formData.isLimitlessTicket 
                              })()} 
                            />
                            <FormCustomTitle
                              formText='Tickets Limitless?'
                              
                            />
                          </Stack> */}
                        </Grid>

                        {
                          !formData.isLimitlessTicket && (
                            <Fade 
                              in={!formData.isLimitlessTicket}
                            >
                              <Grid item 
                                className='w-100  pt-1' 
                                sm={12} md={6} lg={4}
                              >
                                <TextField
                                  // type='number'
                                  // className='w-100'
                                  variant='standard'
                                  onBlur={() => {
                                    setFormData({
                                      ...formData,
                                      ticketCount: convertToIntegerGreaterThanOrEqualToMinValue({
                                        inputValue: formData.ticketCount,
                                        minValue: 1
                                      })
                                    });
                                  }}
                                  label='Ticket Count'
                                  placeholder='Please Enter Ticket Count'
                                  required={true}
                                  fullWidth
                                  value={ formData.ticketCount.toString() }
                                  onChange={e => {
                                    setFormData({
                                      ...formData,
                                      // price: convertToIntegerGreaterThanOrEqualToMinValue({ inputValue: e.target.value})
                                      ticketCount:  e.target.value
                                    });
                                  }}
                                />
                              </Grid>
                            </Fade>
                          )
                        }


                        {/* Is Active */}
                        <Grid item className='w-100 mt-2'>

                          <Stack 
                            direction='column' 
                            // alignItems='center' 
                            className='mr-2'
                          >
                            <FormCustomTitle
                              isLineHeightOne={true}
                              formText={'Is Active'}
                            />
                            <Switch
                              onChange={(e, checked) => {
                                console.log('HELLLOO');
                                
                                setFormData({
                                  ...formData,
                                  isActive: checked,
                                })
                              }}
                              checked={formData.isActive}
                            />
                          </Stack>
                        </Grid>
                        {/* End of Is Active */}


                      </Grid>
                      <Grid container className='w-100 pb-1' sm={12}>
                        <Grid item className='pr-2'>
                          <Button 
                          type='submit'
                            variant='contained'
                            onClick={() => {}}
                          >  
                            { subscriptionClassId ? 'Update Subscription' : 'Create Subscription' }
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )
                  :
                  ( 
                    <Grid item className='w-100'sm={12}>
                      <Grid 
                        container 
                        className='w-100'
                        sm={12}
                        style={{
                          minHeight: `calc( ${clientAppConstants.screenContainerMinHeight} - 57px  )`,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent:'space-between'
                        }}
                      >
                        <SubscriptionPackageDetailTable 
                          formData={formData}
                          languageList={_languageList}
                        />
                        {/* <EventAddEditScreenBottomButtons /> */}
                      </Grid>
                    </Grid>
                  )
              
              }
            </Grid>
          )
        }

                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={ _isAddingOrUpdating }
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
      </Grid>
    </Container>
  )
}
