import React, {useState, useEffect} from 'react';

import {  useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Actions
// import { 
//   validateEmailByCryptoToken
// } from '../../actions/publicUserActions';



import {
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';

// Constants
import { clientAppConstants } from '../../constants';
import { validateEmailViaToken } from '../../actions/publicUserActions/validateEmailViaToken';




export const EmailValidationScreen = ({
  match, // location
}) => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);

  
  const dispatch = useDispatch();


  const [emailValidationKey, setEmailValidationKey ] = useState( match.params.emailValidationKey || null ); 


  const [ _loading, _setLoading ] = useState(true);
  const [ _responseObject, _setResponseObject ] = useState(
    null
    //      isEmailValidated: true,
    //      email: ,
    //      nameSurname: user.nameSurname
  );
  const [isEmailValidated, setIsEmailValidated] = useState(false);





  useEffect(() => {
    if (   emailValidationKey  ) {
      dispatch(
        validateEmailViaToken({
          token: emailValidationKey,
          cb: apiResponse => {
            if ( apiResponse ) {
              _setResponseObject({
                email: apiResponse.email
              });
              setIsEmailValidated( apiResponse.isEmailValidated );
              _setLoading(false);
            }
            _setLoading(false);
          }
        })
      );  
      _setLoading(false);  
    }
  }, []);


  if ( !emailValidationKey ) {
    return (<h2>Invalid Token!</h2>);
  }

  return (
    <Container
      maxWidth='lg'
      style={{
        // backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight:clientAppConstants.screenContainerMinHeight
      }}
      id='email-validate-screen-container'    
    >
      <Helmet defer={false}><title>Email Verification</title></Helmet>
      <Grid 
        container 
        direction='column'
        justify='center'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
        className='w-100'
      >
        {
          _loading 
            ? 
            (
              <Grid item>
                <div 
                  className='flexrow justify-content-center'
                  style={{
                    minHeight: 180,
                    paddingTop: 120
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            )
            : 
            isEmailValidated
              ?
              (
                <>
                  <Grid item className='w-100'>
                    <h2 className='text-center'>{`${_responseObject.to} Your Account Has Been Validated`}</h2>
                    
                  </Grid>
                  <Grid item className='w-100 text-center'>
                    {`Congratulations ${_responseObject.email}! Your Email Account Has Been Validated Successfully.` }
                  </Grid>
                </>
              )
              :
              (
                <>
                  <Grid item className='w-100'>
                    <h2 className='text-center'>Invalid Token</h2>
                    
                  </Grid>
                  <Grid item className='w-100 text-center'>
                    Token Error! Please Check your URL.
                  </Grid>
                </>
              )
        }
        
      </Grid>
    </Container>
  )
}
