

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_LANGUAGE_FILTER_ITEM_CLICK } from "../types";

// Actions



export const handleLanguageItemClick = ({
  languageItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_LANGUAGE_FILTER_ITEM_CLICK,
    payload: languageItem
  });
}  