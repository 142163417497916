import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'


// import {

//   TableHead,
//   TableRow,
//   TableCell,
// } from '@material-ui/core';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// // Styles
// import {  withStyles , makeStyles} from '@material-ui/core/styles';


// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export const DoubleColumnTableHead = ({
  firstColumnText = 'Property',
  secondColumnText='Value'
}) => {


  return (
    <TableHead id='speaklabs-double-column-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>{firstColumnText}</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>{secondColumnText}</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

