import React, { useEffect,  useState } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';


// Actions
import { fetchClassroomAddEditEntities } from '../../actions/classroomActions/fetchClassroomAddEditEntities';
import { addClassroom } from '../../actions/classroomActions/addClassroom';
import { updateClassroom } from '../../actions/classroomActions/updateClassroom';







import {
  Container,
} from '@material-ui/core';

// MUI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


// Icons
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

// Styles
import './ClassroomAddEditScreen.css'


// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { SimpleCustomSelect } from '../../components/form/SimpleCustomSelect';
import CustomTextAreaAutoSize from '../../components/form/CustomTextAreaAutoSize';
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import { RegistrationSetComponent } from '../../components/classroom/RegistrationSetComponent';
import { ClassroomImageSelectorComponent } from '../../components/classroom/ClassroomImageSelectorComponent';
import { TicketSetComponent } from '../../components/classroom/TicketSetComponent';
import { ClassroomImageShowComponent } from '../../components/classroom/ClassroomImageShowComponent';
import { HostSelectionComponent } from '../../components/classroom/HostSelectionComponent';
import { ClassroomDetailTable } from './ClassroomDetailTable';
import { ClassroomAddEditScreenBottomButtons } from './ClassroomAddEditScreenBottomButtons';




// Constants
import { clientAppConstants } from '../../constants';
// import { setAlert } from '../../actions/alertActions';





// Helpers
import { convertToIntegerGreaterThanOrEqualToMinValue } from '../../helpers/common/convertToIntegerGreaterThanOrEqualToMinValue';
import { uint8ArrayToImageSource } from '../../helpers/file/uint8ArrayToImageSource';
import { convertInputToCurrencyText } from '../../helpers/common/convertInputToCurrencyText';
import { detectMyTimezoneHelper } from '../../helpers/dateAndTime/detectMyTimezoneHelper';
import {useHistory} from "react-router-dom";





export const ClassroomAddEditScreen = ({
    // Location, History, etc
    match, // location -> match.params.classroomId
    history,
}) => {
    // Global Variables

    const dispatch = useDispatch();

    const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
    const [classroomId, setClassroomId ] = useState( match.params.classroomId || null );
    const [formData, setFormData] = useState(null);

    const [ _languageList, _setLanguageList ] = useState([])
    const [ _interestList, _setInterestList ] = useState([])
    const [ _hostList, _setHostList ] = useState([])
    const [ _countryList, _setCountryList ] = useState([])
    const [ _cityList, _setCityList ] = useState([])


    const [ _cityListForSelectedCountry, _setCityListForSelectedCountry ] = useState([])


    const [ _isAddingOrUpdating, _setIsAddingOrUpdating] = useState(false);





    const [ _isEditMode, _setIsEditMode ] = useState(
      match.params.classroomId ? false : true
    );

    const _initialOfflineEventState = {
      offlineEventImageId: null,
      offlineEventImage: null,
      isOfflineEventImageBuffer: false,
      isOfflineEventImageUpdated: false,

      offlineEventListShortDefinition: '',  // this is short def. on Mobile, where we list event list...

      eventInterestId: '',  // interestId
      offlineEventType: '',  
      offlineEventLocation: '',


      countryId: '', 
      cityId: '',
      countryEn: '', // Country in English Lang
      cityTitle: '',
      address: '',
    }


    const _initialOnlineEventState = {
      onlineEventVerticalImageId: null,
      onlineEventVerticalImage: null,
      isOnlineEventVerticalImageBuffer: false,
      isOnlineEventVerticalImageUpdated: false,
      //---------------------------------
      onlineEventHorizontalImageId: null,
      onlineEventHorizontalImage: null,
      isOnlineEventHorizontalImageBuffer: false,
      isOnlineEventHorizontalImageUpdated: false,
    }



    const _registrationEventState = {
      isRegistrationPossible: false,
      isRegistrationFree: true,
      registrationFee: '2.00',
      //-----------------
      isNoTicketEvent: false,  // this is for offline events, where users pay money to place. we are only providing information about event
      eventOrganiserPrice: '2.00',  // this is price that people pay at entrance, not related with our app, we are only showing to inform users
      //-----------------
      ticketPrice: '2.00',
    };


    const _initialAddEventFormState = {
      isEventOnline: null,
      language: '',
      level: '',

    
      ..._initialOfflineEventState,

      ..._initialOnlineEventState,


      ..._registrationEventState,



      timezone: detectMyTimezoneHelper(),
      clientTimeZone: detectMyTimezoneHelper(),


      isTicketBySubscriptionPossible: true,

      


      // duration: '2 hours',
      duration: 45,
      maxAttendee: 30,
      attendeeCount: 0,
      ticketOwnerList: [],
      registratorList: [],
      ticketList: [
        { ticketId: '', userId: ''   },
      ],
      eventHost: '',  // host ID
      eventHostDocument: null,
      // date: Date.now(),
      eventTitle: '',
      eventBoldText: '',
      eventRegularText: '',
      
    }

    useEffect(
      () => {
        setFormData({..._initialAddEventFormState});
        dispatch(
          fetchClassroomAddEditEntities({
            classroomId,
            cb: apiResponse => {
              if ( apiResponse ) {
                const {
                  classroom,                  
                } = apiResponse;
                _setHostList(apiResponse.hostList);
                _setInterestList(apiResponse.interestList);
                _setLanguageList(apiResponse.languageList);
                _setCountryList(apiResponse.countryList ?? []);
                _setCityList(apiResponse.cityList ?? []);
                if ( classroom ) {
                  if (classroom.isEventOnline === false) {
                    _setCityListForSelectedCountry(
                      apiResponse.cityList.filter(
                        item => item.countryId === classroom.countryId
                      )
                    )
                  }
                  setFormData({
                    ..._initialAddEventFormState,
                    //----------------------------------------------------------------
                    ...classroom,
                    //----------------------------------------------------------------

                    eventHostDocument: classroom.eventHost ?? null,
                    eventHost: classroom.eventHost ? classroom.eventHost._id : '',


                    ticketPrice: classroom.ticketPrice 
                      ?  convertInputToCurrencyText({inputValue: classroom.ticketPrice.toFixed(2) })
                      :  _registrationEventState.ticketPrice,
                  })
                }
              }
              _setLoading(false);
            }
          })
        )
        
      }, []
    );


    

    const _cbAddClassroom = () => {
      dispatch(
        addClassroom({
          formData,
          cb: apiResponse => {
            console.log('ClassroomAddScreen -> _onSubmitHandler -> apiResponse -> ', apiResponse)
            if ( apiResponse ) {
              const {
                classroom,
                onlineEventVerticalImage,
                onlineEventHorizontalImage,
                offlineEventImage,
                eventHostDocument,
              } = apiResponse;
              if ( classroom ) {
                setClassroomId( classroom._id );
                setFormData({
                  ...formData,
                  eventHostDocument, 

                })
              }
              console.log('HELLO')
            }
            _setIsAddingOrUpdating(false);  
          }
        })
      )
    }

    const _cbUpdateClassroom = () => {
      dispatch(
        updateClassroom({
          formData,
          classroomId,
          cb: apiResponse => {
            if (
              apiResponse 
              ) {
              const {
                classroom,
                onlineEventVerticalImage,
                onlineEventHorizontalImage,
                offlineEventImage,
                eventHostDocument,
              } = apiResponse;
              setFormData({
                ...formData,
                
                //----------------------------------------------------------------
                ...classroom,
                //----------------------------------------------------------------
                ticketPrice: classroom.ticketPrice 
                  ?  convertInputToCurrencyText({inputValue: classroom.ticketPrice.toFixed(2) })
                  :  _registrationEventState.ticketPrice,
                registrationFee: classroom.registrationFee
                  ?  convertInputToCurrencyText({inputValue: classroom.registrationFee.toFixed(2) })
                  :  _registrationEventState.registrationFee ,
                eventOrganiserPrice: classroom.eventOrganiserPrice
                  ?  convertInputToCurrencyText({inputValue: classroom.eventOrganiserPrice.toFixed(2) })
                  :  _registrationEventState.eventOrganiserPrice ,
                  

                // Online Vertical Image
                onlineEventVerticalImage: onlineEventVerticalImage 
                  ?
                  uint8ArrayToImageSource( onlineEventVerticalImage.image.data  )
                  :
                  formData.onlineEventVerticalImage,
                onlineEventVerticalImageId: onlineEventVerticalImage 
                  ? onlineEventVerticalImage._id 
                  : formData.onlineEventVerticalImageId,
                isOnlineEventVerticalImageBuffer: true,
                isOnlineEventVerticalImageUpdated: false,

                // Online Horizontal Image
                onlineEventHorizontalImage: onlineEventHorizontalImage 
                  ?
                  uint8ArrayToImageSource( onlineEventHorizontalImage.image.data  )
                  :
                  formData.onlineEventHorizontalImage,
                onlineEventHorizontalImageId: 
                  onlineEventHorizontalImage 
                  ? onlineEventHorizontalImage._id 
                  : formData.onlineEventHorizontalImageId,
                isOnlineEventHorizontalImageBuffer: true,
                isOnlineEventHorizontalImageUpdated: false,
                // Offline Event Image                    
                offlineEventImageId: classroom.offlineEventImageId,
                offlineEventImage: offlineEventImage 
                  ?
                  uint8ArrayToImageSource( offlineEventImage.image.data  )
                  :
                  formData.offlineEventImage,
                offlineEventImageId: offlineEventImage ? offlineEventImage._id : null,
                isOfflineEventImageBuffer: true,
                isOfflineEventImageUpdated: false,
                // End of Offline Event Image

                eventHostDocument : classroom.eventHost
                  ?
                  ( 
                    eventHostDocument ?? formData.eventHostDocument
                  )
                  :
                  _initialAddEventFormState.eventHostDocument
                  ,
              })
            }
            _setIsAddingOrUpdating(false);
          }

        })
      )
    }


    const _onSubmitHandler = (e) => {
      e.preventDefault();
      try {
        _setIsAddingOrUpdating(true);     
        if( classroomId )  { // Update Classroom
          _cbUpdateClassroom();
        } else {  // New Classroom
          _cbAddClassroom()
        }
      } catch (error) {
        console.log('ERROR')
        _setIsAddingOrUpdating(false);  
      }
    }


    

  return (
    <Container
      maxWidth='lg'
      className='w-100 '
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight:clientAppConstants.screenContainerMinHeight,

      }}
      id='classroom-add-edit-screen-container'
    >
      <Helmet defer={false}><title>{classroomId ? 'Update Classroom' : 'Add Classroom'}</title></Helmet>
      <Grid container className='w-100'>
        <ScreenTitle titleText={(
          <>
            <span>
              { 
                classroomId 
                  ? (
                    _isEditMode ? 'Update Classroom' : 'Classroom Details'
                  )
                  : 'Add Classroom'
              }
            </span>
            {
              classroomId && (
                <IconButton
                  onClick={() => {
                      history.push(`/classroom/edit/${classroomId}`)
                  }}
                >
                  <ModeEditIcon 
                    style={{
                      color: _isEditMode ? 'yellow' : 'green'
                    }}
                  />
                </IconButton>
              )
            }
          </>
        )} />
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100'sm={12}>
              {
                _isEditMode 
                  ?                   
                  (

                    <form 
                      className='w-100' 
                      onSubmit={_onSubmitHandler}
                      style={{
                        minHeight:`calc( ${clientAppConstants.screenContainerMinHeight} - 53px )`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent:'space-between'
                      }}
                    >                      
                      <Grid container className='w-100'sm={12}>

                        
                        {/* Show Images */}
                        <ClassroomImageShowComponent
                        formData={formData}
                        />
                        {/* End of Show Images */}

                        
                        {/* Online - Offline */}                        
                        <Grid item className='w-100  ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            label='Online-Offline'
                            // fullWidth={true}
                            disabled={ classroomId }
                            htmlId='add-event-screen-online-offline'
                            isNoneExist={ formData.isEventOnline === null }
                            noneText={'Select'}
                            value={ formData.isEventOnline   }
                            optionList={[{ value: true, definition: 'Online' }, { value: false, definition: 'Offline' }  ]}
                            onChange={ e => {
                              const newValue = e.target.value;
                              setFormData({ 
                                ...formData, 
                                isEventOnline:  newValue === 'true' ? true : false,  
                                isTicketBySubscriptionPossible:  _initialAddEventFormState.isTicketBySubscriptionPossible, 
                                ..._initialOfflineEventState  ,
                                ..._initialOnlineEventState,   
                                ..._registrationEventState,                        
                              });
                            }}
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />
                        </Grid>
                        {/* End of Online - Offline */}


                        {/* Image Selecting */}                        
                        <ClassroomImageSelectorComponent
                          classroomId={classroomId}
                          formData={formData}
                          setFormData={setFormData}
                        />
                        {/* End of Image Selecting */}


                        {/* Language */}                        
                        <Grid item className='w-100  ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            // fullWidth={true}
                            required={true}
                            disabled={ classroomId }
                            label='Language'
                            htmlId={'select-language'}
                            eventTargetNAme={'isEventOnline'}
                            isNoneExist={ formData.language === '' }
                            noneText={'Select'}
                            value={ formData.language   }
                            // optionList={[{ value: 'English', definition: 'English' }, { value: 'French', definition: 'French' }  ]}
                            optionList={[
                              ..._languageList.map(
                                languageItem => {
                                  return {
                                    value: languageItem._id,
                                    definition: languageItem.languageTitle
                                  }
                                }
                              )
                            ]}
                            onChange={ e => {

                              setFormData({ ...formData, language:  e.target.value }) ;
                            } }
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />
                        </Grid>
                        {/* End of Language */}



                        {/* Level */}                        
                        <Grid item
                          className='w-100  '
                          sm={12} md={6} lg={4} alignContent='center'
                          alignItems='center'  justifyContent='center'
                          // style={{backgroundColor:'red'}}
                        >
                          <SimpleCustomSelect
                            // fullWidth={true}

                            label='Level'
                            htmlId={'select-language-level'}
                            eventTargetNAme={'level'}
                            isNoneExist={ formData.level === '' }
                            noneText={'Select'}
                            value={ formData.level   }
                            optionList={ [ ...clientAppConstants.languageLevelOptionList] }
                            onChange={ e => {

                              setFormData({ ...formData, level:  e.target.value }) ;
                            } }
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />
                        </Grid>
                        {/*End of  Level */}


                        {/* Timezone */}   
                        {
                          formData.isEventOnline === false && (
                            <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                              <TextField
                              type='number'
                                variant='standard'
                                label='Timezone'
                                placeholder='Please enter Timezone'
                                required={true}
                                fullWidth
                                value={formData.timezone}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    // maxAttendee: convertToIntegerGreaterThanOrEqualToMinValue({ timezone: e.target.value})
                                    timezone: e.target.value
                                  });
                                }}
                              />
                            </Grid>
                          )
                        }               
                        {/* End of Timezone */}

                        

                        {/* Duration */}
                        <Grid item  className='w-100 mt-2 pr-3'
                          // style={{backgroundColor: 'pink'}}
                          sm={12} md={6} lg={4}
                        >
                          {/* <TextField
                            variant='standard'
                            label='Duration'
                            placeholder='Please enter Duration'
                            required={true}
                            fullWidth
                            value={formData.duration}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                duration: e.target.value
                              });
                            }}
                          /> */}
                          <FormCustomTitle
                            formText={'Duration: ' + formData.duration + 'min.'}
                          />
                          <Slider
                            className={'mt-1'}
                            step={15}
                            min={15}
                            max= { 600 }
                            value={formData.duration}
                            onChange={ (e, newValue ) => {
                              setFormData({
                                ...formData,
                                duration: newValue
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Duration */}


                        {/* Event Type */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={classroomId}
                                label='Event Type'
                                htmlId={'select-event-type'}
                                eventTargetNAme={'event-type'}
                                isNoneExist={ formData.eventInterestId === '' }
                                noneText={'Select'}
                                // value={ formData.offlineEventType   }
                                value={ formData.eventInterestId   }
                                optionList={[
                                  ..._interestList.map(
                                    interestItem => {
                                      return {
                                        value: interestItem._id,
                                        definition: interestItem.interestTitle
                                      }
                                    }
                                  )
                                ]}
                                onChange={ e => {
                                  let newInterestId = e.target.value;
                                  let tempInterestTitle = _interestList.find(
                                    item => item._id === newInterestId
                                  ).interestTitle;
                                  setFormData({ 
                                    ...formData, 
                                    eventInterestId: newInterestId,
                                    offlineEventType: tempInterestTitle
                                  });
                                } }
                                inputPropsId={'event-add-edit-screen-event-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of Event Type */}


                        {/* Host Selection */}                  
                        <HostSelectionComponent
                          formData={formData}
                          setFormData={setFormData}
                          hostList={_hostList}
                        />  
                        {/* End of Host Selection */}

                        

                        {/* Max Attendee */}                  
                        <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                          <TextField
                          type='number'
                            variant='standard'
                            label='Max Attendee'
                            placeholder='Please enter Duration'
                            required={true}
                            fullWidth
                            value={formData.maxAttendee}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                maxAttendee: convertToIntegerGreaterThanOrEqualToMinValue({ inputValue: e.target.value})
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Max Attendee */}



                        { formData.isEventOnline === false && (
                            <Grid item sm={12} className='mt-2'>
                              <h4 className='text-center mbe-0 mbs-0 pt-2'>Location Information</h4>
                            </Grid>
                          ) 
                        }


                        {/* Country */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={classroomId}
                                label='Country'
                                htmlId={'select-country'}
                                eventTargetNAme={'country'}
                                isNoneExist={ formData.countryId === '' }
                                noneText={'Select'}
                                value={ formData.countryId   }
                                optionList={[
                                  ..._countryList.map(
                                    countryItem => {
                                      return {
                                        value: countryItem._id,
                                        definition: countryItem.en
                                      }
                                    }
                                  )
                                ]}
                                onChange={ e => {
                                  let newCountryId = e.target.value;
                                  setFormData({ 
                                    ...formData, 
                                    countryId: newCountryId,
                                    countryEn: _countryList.find(
                                      item => item._id === newCountryId
                                    ).en ,
                                    cityId: '',
                                    cityTitle: '' 
                                  }) ;
                                  _setCityListForSelectedCountry(
                                    [..._cityList.filter( item => item.countryId === newCountryId )]
                                  );
                                } }
                                inputPropsId={'speaklabs-classroom-event-country-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of  Country */}


                        {/* City Selection */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={classroomId}
                                label='City'
                                htmlId={'select-city'}
                                eventTargetNAme={'city'}
                                isNoneExist={ formData.cityId === '' }
                                noneText={'Select'}
                                value={ formData.cityId   }
                                optionList={[
                                  ..._cityListForSelectedCountry.map(
                                    cityItem => {
                                      return {
                                        value: cityItem._id,
                                        definition: cityItem.cityTitle
                                      }
                                    }
                                  )
                                ]}
                                onChange={ e => {
                                  let newCityId = e.target.value;
                                  setFormData({ 
                                    ...formData, 
                                    cityId: newCityId,
                                    cityTitle: _cityList.find(
                                      item => item._id === newCityId
                                    ).en  
                                  }) ;
                                } }
                                inputPropsId={'speaklabs-classroom-event-city-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of  City Selection */}



                        {/* Address */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item className='w-100 mt-2'     sm={12} >
                              <CustomTextAreaAutoSize 
                                title='Address'
                                value={formData.address}
                                placeholder={'Address'}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    address: e.target.value
                                  });
                                }}
                                required
                              />
                            </Grid>
                          )
                        }
                        {/* End of Address */}

                        


                        


                        {/* Registration */} 
                        <RegistrationSetComponent
                          formData={formData}
                          registrationEventState={ _registrationEventState }
                          setFormData={setFormData}
                        />
                        {/* End of Registration  */} 




                        {/* TICKET */}
                        <TicketSetComponent
                          formData={formData}
                          setFormData={setFormData}
                          registrationEventState={_registrationEventState}
                        />
                        {/* End of TICKET */}

                        


                        {/* Event Title */}                  
                        <Grid item  className='w-100 mt-2 '     sm={12}>
                          <TextField
                            variant='standard'
                            label='Title'
                            placeholder='Please enter Event Title'
                            required={true}
                            fullWidth
                            value={formData.eventTitle}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventTitle: e.target.value
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Event Title */}


                        {/* Event List Definition Text */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item className='w-100 mt-2'     sm={12} >
                              <CustomTextAreaAutoSize 
                                title='Event List Definition Text'
                                value={formData.offlineEventListShortDefinition}
                                placeholder={'Event Bold Text'}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    offlineEventListShortDefinition: e.target.value
                                  });
                                }}
                                required
                              />
                            </Grid>
                          )
                        }
                        {/* End of Event List Definition Text */}



                        {/* Event Bold Text */}
                        <Grid item className='w-100 mt-2'     sm={12} >
                          <CustomTextAreaAutoSize 
                            title='Event Bold Text'
                            value={formData.eventBoldText}
                            placeholder={'Event Bold Text'}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventBoldText: e.target.value
                              });
                            }}
                            required
                          />
                        </Grid>
                        {/* End of Event Bold Text */}


                        {/* Event Regular Text */}
                        <Grid item className='w-100 mt-2'     sm={12} >
                          <CustomTextAreaAutoSize 
                            title='Event Regular Text'
                            placeholder={'Event Regular Text'}
                            value={formData.eventRegularText}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventRegularText: e.target.value
                              });
                            }}
                            required
                          />
                        </Grid>
                        {/* End of Event Regular Text */}


                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={ _isAddingOrUpdating }
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>



                      </Grid>
                      <Grid container className='w-100 pt-2'>
                        <Button
                          type='submit'
                          variant='contained'
                          color='success'
                          endIcon={<SendIcon />}
                          
                        >
                          {classroomId ? 'Update Classroom' : 'Add Classroom'}
                        </Button>
                      </Grid>
                    </form>
                  )
                  :(   /* NOT _isEditMode  */
                    <Grid item className='w-100'sm={12}>
                      <Grid container className='w-100'sm={12}>
                        <ClassroomDetailTable 
                          formData={formData}
                          languageTitle={
                            _languageList.find(
                              item => item._id === formData.language
                            ).languageTitle
                          }
                        />
                        <ClassroomAddEditScreenBottomButtons 
                          classroomId={classroomId}
                        />
                      </Grid>
                    </Grid>
                  )                  
              }
            </Grid>
          )
        }


      </Grid>
    </Container>
  )
}
