import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  // COUNTRIES_FETCHED,
  // SET_COUNTRIES_LOADING,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const fetchCountryCityList = ({
  // limit,
  // skip,
  // filteringParameters,
  // order,
  countryId,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const formData = {
      // limit,
      // skip,
      // filteringParameters,
      // order,
      countryId,
    }
    // dispatch({
    //   type: SET_COUNTRIES_LOADING,
    //   payload: true
    // });


    const res = await axios.post(
      urlConstants.fetchCountryCityList, 
      formData ?? {},
      clientAppConstants.jsonConfig
    ); 
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
}  