

import React, {useCallback} from 'react'

// MUI
import Button from '@mui/material/Button';


// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import {useDispatch} from "react-redux";
import {cancelEvent} from "../../../actions/eventActions/cancelEvent";

export const CancelEventButton = ({ eventId }) => {

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(cancelEvent({ eventId }));
  }, [eventId]);

  return (
    <Button
      endIcon={<CancelIcon/>}
      onClick={onClick}
      variant='contained'
      color='error'
    >
      Cancel Event
    </Button>
  )
}
