import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchEventList } from '../../actions/eventActions/fetchEventList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';





import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';

// MUI
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';
import { InterestFilterComponent } from '../../components/filter/InterestFilterComponent';
import { HostFilterComponent } from '../../components/filter/HostFilterComponent';
import { OnlineOfflineFilter } from '../../components/filter/OnlineOfflineFilter';
import { ClassroomFilterComponent } from '../../components/filter/ClassroomFilterComponent';
import { SinceDateFilterComponent } from '../../components/filter/SinceDayFilterComponent';
import { UntilDateFilterComponent } from '../../components/filter/UntilDateFilterComponent';
import { EventTableHead } from './EventTableHead';
import { EventItem } from './EventItem';


// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';






const  EventScreen = ({

}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const eventList = useSelector(state => state.eventReducer.eventList);

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);


  useEffect(() => {
    dispatch(
      fetchEventList({
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('EventScreen -> fetchEventList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
    }
    // eslint-disable-next-line
  }, [])



  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      dispatch(
        fetchEventList({
          limit: pageFilterCount,
          skip: 0,
          order,
          filteringParameters,
          cb: apiResponse => {
            dispatch(
              setIsFilteringLoading(false)
            );
          }
        })
      )
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])








  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Online-Offline', isChecked: false,  
      component: null, 
      defaultFilterKey: 'onlineOffline'  ,
    },
    { label: 'Classrooms', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { 
      label: 'Interests', isChecked: false, 
      component: null,
      // defaultFilterValue: _initialFilteringParameters.interests  ,
      defaultFilterKey: 'interests'  ,
    },
    { label: 'Hosts', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.hosts  ,
      defaultFilterKey: 'hosts'  ,
    },
    { label: 'Since-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }




  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>Events</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Events'}</span> 
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  // _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  // _setFilteringParameters({..._initialFilteringParameters});
                  // setOrder(_initialOrder);  
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                >
                  {
                    isHideActiveUiFilters 
                      ?
                      <VisibilityIcon color='error' />
                      :
                      <VisibilityOffIcon />

                  }
                  
                </IconButton>
                )
              }

            </>
          )}  
        />
        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>

          {
            _screenFilterOptions 
              && _screenFilterOptions.find( item => item.label === 'Online-Offline')
              && _screenFilterOptions.find( item => item.label === 'Online-Offline').isChecked
              // && !_isHideActiveUiFilters
              &&  <OnlineOfflineFilter />
              // &&  NotificationFilterComponent()
          }

          {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Languages')
                && _screenFilterOptions.find( item => item.label === 'Languages').isChecked
                // && !_isHideActiveUiFilters
                // &&  LanguageFilterComponent()
                &&  <LanguageFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Interests')
                && _screenFilterOptions.find( item => item.label === 'Interests').isChecked
                // && !_isHideActiveUiFilters
                // &&  InterestFilterComponent()
                &&  <InterestFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Classrooms')
                && _screenFilterOptions.find( item => item.label === 'Classrooms').isChecked
                // && !_isHideActiveUiFilters
                &&  <ClassroomFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Hosts')
                && _screenFilterOptions.find( item => item.label === 'Hosts').isChecked
                // && !_isHideActiveUiFilters
                &&  <HostFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Since-Date')
                && _screenFilterOptions.find( item => item.label === 'Since-Date').isChecked
                // && !_isHideActiveUiFilters
                &&  <SinceDateFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Until-Date')
                && _screenFilterOptions.find( item => item.label === 'Until-Date').isChecked
                // && !_isHideActiveUiFilters
                &&  <UntilDateFilterComponent />
            }


            
            {
              !_isHideActiveUiFilters
              &&
              _filterUiElements && _filterUiElements.length > 0 &&
              _filterUiElements.map(
                ( item , ind )=> {
                  return (
                    <React.Fragment key={ind}>
                      { item.component &&  item.component() }
                    </React.Fragment>
                  );
                }
              )
            }

            




          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}


        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                
                {/* Table */}
                {
                  (
                    isPaginationLoading !== true && isFilteringLoading !== true &&
                    eventList && eventList.length === 0
                  ) 
                    ? 
                    (
                      <Grid item className='w-100 py-5'>
                        <h4 className='text-center'>No Events!</h4>
                      </Grid>
                    )
                    :
                    (
                      <CustomTable 
                        // stickyHeader={false}
                      >
                        <EventTableHead/>
                        <TableBody>                    
                          {/* <EventItem />
                          <EventItem />
                          <EventItem /> */}
                          {
                            isPaginationLoading !== true && isFilteringLoading !== true &&
                            eventList && eventList.map(
                              (eventItem, ind) => (
                                <EventItem
                                  key={ind}
                                  eventItem={eventItem  }
                                />
                              )
                            )
                          }
                        </TableBody>
                      </CustomTable>
                    )
                }
                {/* End of Table */}

                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }

                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    dispatch(
                      setIsPaginationLoading(true)
                    );
                    dispatch(
                      setPaginationPage(page)
                    );
                    dispatch(
                      fetchEventList({
                        cb: apiResponse => {
                          dispatch(
                            setIsPaginationLoading(false)
                          );
                        },
                        filteringParameters,
                        limit: pageFilterCount,
                        order: order,
                        skip: ( page - 1  ) * pageFilterCount
                      })
                    );

                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>
            )
        }

        {/* Table */}



      </Grid>
    </Container>
  )
}

export default EventScreen;
