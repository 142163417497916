import React from 'react'

// MUI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';



// Components
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';

// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';

export const EventInformationComponent = ({
  formData
}) => {
  const {
    isEventOnline,
    eventDate,
    eventHost,
    countryEn,
    cityTitle,
    maxAttendee,
    ticketCount,
  } = formData;
  return (
    <Grid item sm={12} className='w-100'>
      <Paper 
        className='p-1'
      >
      <Grid container sm={12} className='w-100'>

          <Grid item className='pr-2'>
            <p>
              {
                isEventOnline  ?  'Online Event' : 'Offline Event'
              }
            </p>
          </Grid>
          <Grid item className='pr-2'>
            <p style={{textDecoration: 'underline'}}>
              {
                convertToMomentDateTime( eventDate )
              }
            </p>
          </Grid>
          <Grid item >
            <p>
              Host:
            </p>
          </Grid>
          <Grid 
            item 
            className='pr-2 flexrow' 
            // style={{backgroundColor: 'yellow'}}
            // alignItems='center'
            // alignContent='center'
            // justifyContent='center'
            // direction='row'

          >
            <TableNavlinkTextButton
              buttonLink={'/user/edit/' + eventHost._id}
              // buttonText='Anna Teacher'
              buttonText={ eventHost.nameSurname ?? eventHost.email }
            />
          </Grid>
          
          {
            isEventOnline === false && (
              <Grid 
                item 
                className='mr-1 flexrow' 
                // style={{backgroundColor: 'yellow'}}
                // alignItems='center'
                // alignContent='center'
                // justifyContent='center'
                // direction='row'

              >
                <p>
                  {/* {'Location: ' + cityTitle+ ', ' + countryEn} */}
                  {'Location: ' + cityTitle}
                </p>
              </Grid>
            )
          }

          <Grid 
            item 
            className='mr-1 flexrow' 
            // style={{backgroundColor: 'yellow'}}
            // alignItems='center'
            // alignContent='center'
            // justifyContent='center'
            // direction='row'

          >
            <p
              style={{color: 'green'}}
            >
              {'Total Transaction: ' + '$' +  '85.00'}
            </p>
          </Grid>

          <Grid 
            item 
            className='mr-1 flexrow' 
            // style={{backgroundColor: 'yellow'}}
            // alignItems='center'
            // alignContent='center'
            // justifyContent='center'
            // direction='row'

          >
            <p
              // style={{color: 'green'}}
            >
              {'Max Attendee: ' + maxAttendee}
            </p>
          </Grid>

          <Grid 
            item 
            className='mr-1 flexrow' 
            // style={{backgroundColor: 'yellow'}}
            // alignItems='center'
            // alignContent='center'
            // justifyContent='center'
            // direction='row'

          >
            <p
              style={{color: 'rgb( 222, 56, 94 )'}}
            >
              {'Attendee: ' + ticketCount ?? '0'}
            </p>
          </Grid>

      </Grid>
      </Paper>
    </Grid>
  )
}
