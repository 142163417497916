import React from 'react'

import { NavLink} from 'react-router-dom'

// MUI
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


// Components
import { MuiStyledTableCell } from '../../../components/table/collapsableTableComponents/CustomCollapsableTable';
import { TextButtonForTableCell } from '../../../components/buttons/TextButtonForTableCell';

// Helpers
import { convertToMomentDateTime } from '../../../helpers/moment/convertToMomentDateTime';




export const InnerTicketRow = () => {
  return (
    <TableRow >
      <TableCell component="th" scope="row">
        <TextButtonForTableCell 
          to={'/user/edit/' + 'userIdFromSomewhere'} 
          buttonText={'Famous User'}
        />
      </TableCell>
      <TableCell align='left'>
        <TextButtonForTableCell 
          to={'/subscription/edit/' + 'subscriptionIdFromSomewhere'} 
          buttonText={'60 Days Multi Subscription'}
        />
      </TableCell>
      <TableCell align="center">{'paypal'}</TableCell>
      <TableCell align="center">{'R'}</TableCell>
      <TableCell align="center">
        <IconButton component={ NavLink} to={'/ticket/edit/' + 'ticketId'}>
          <MoreHorizIcon />
        </IconButton>
      </TableCell>
      <TableCell align="right">
        {'$' + '4.00'}
      </TableCell>
    </TableRow>
  )
}
