






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_TICKET_RESERVATION_FILTER } from "../types";





export const setTicketReservationFilter = ({  
  newTicketReservationFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_TICKET_RESERVATION_FILTER,
    payload: {
      isReset,
      newTicketReservationFilterList: [ ...newTicketReservationFilterList ]
    }
  });
}  




