import {convertToMomentDateTime} from "../moment/convertToMomentDateTime";
import {detectMyTimezoneHelper} from "./detectMyTimezoneHelper";
import {addSubtractHourToDate} from "./addSubtractHourToDate";


export const convertToLocalTimezone = (eventDate, timezone) => {
    let date = new Date(Date.parse(eventDate));
    if(timezone !== detectMyTimezoneHelper()) {
        date = addSubtractHourToDate({
            inputDate: new Date(Date.parse(eventDate)),
            hourToAdd: ( timezone -  detectMyTimezoneHelper() )
        })
    }
    return date;
}
export const displayEventDate = (eventDate, timezone) => {
    const date = convertToLocalTimezone(eventDate, timezone);

    let sign = '';
    if( timezone && parseInt(timezone.toString()) >= 0  ) {
        sign = '+';
    }

    return convertToMomentDateTime( date ) + ' GMT' + sign +  timezone
}
