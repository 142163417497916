

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_SEARCH_QUERY_TEXT } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const setSearchQueryText = ({
  isReset = false,  
  newSearchTextValue
}) => async dispatch => {
  dispatch({
    type: SET_SEARCH_QUERY_TEXT,
    payload: {
      isReset,
      newSearchTextValue
    }
  });
}  

