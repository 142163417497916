import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchClassroomList } from '../../actions/classroomActions/fetchClassroomList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { resetFilters } from '../../actions/filterActions/resetFilters';


import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { ClassroomTableHead } from './ClassroomTableHead';
import { ClassroomItem } from './ClassroomItem';
import { CustomPagination } from '../../components/CustomPagination';
import { ClassroomScreenBottomButtons } from './ClassroomScreenBottomButtons';




export const  ClassroomScreen = ({

}) =>  {

  // Global Variables
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const classroomList = useSelector(state => state.classroomReducer.classroomList);

  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true);


  useEffect(() => {
    dispatch(
      fetchClassroomList({
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('ClassroomScreen -> fetchClassroomList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
    }
  }, [])




  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
    >
      <Helmet defer={false}><title>Events</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   titleText='Classrooms'    />
        {/* <p>sdfsdf</p> */}

        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                {/* Table */}
                <CustomTable 
                  // stickyHeader={false}
                >
                  <ClassroomTableHead/>
                  <TableBody>
                    {
                      isPaginationLoading !== true && isFilteringLoading !== true &&
                      classroomList && classroomList.length > 0 && classroomList.map(
                        (classroomItem, ind ) => {
                          return (
                            <ClassroomItem 
                              classroomItem={classroomItem}
                              key={ind}
                            />
                          )
                        }
                      )
                    }
                    
                  </TableBody>
                </CustomTable>
                {/* End of Table */}
                {
                  ( !_loading && !isPaginationLoading && !isFilteringLoading 
                    && classroomList && classroomList.length === 0) && (
                      <Grid item sm={12} className='w-100 py-6' >
                        <h4 className='text-center'>No Classrooms!</h4>
                      </Grid>
                  )
                }

                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }

                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    dispatch(
                      setIsPaginationLoading(true)
                    );
                    dispatch(
                      setPaginationPage(page)
                    );
                    dispatch(
                      fetchClassroomList({
                        cb: apiResponse => {
                          dispatch(
                            setIsPaginationLoading(false)
                          );
                        },
                        filteringParameters,
                        limit: pageFilterCount,
                        order: order,
                        skip: ( page - 1  ) * pageFilterCount
                      })
                    );

                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>
            )
        }
        



      </Grid>
      <ClassroomScreenBottomButtons />
      
    </Container>
  )
}


