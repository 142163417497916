import Container from "@mui/material/Container";
import {clientAppConstants, filterTypes, transaction, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useCallback, useEffect, useMemo} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {CustomTable} from "../../components/table/CustomTable";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import { TableBody, TableHead, TableRow} from "@material-ui/core";
import axios from "axios";
import {CustomPaginationV2} from "../../components/CustomPaginationV2";
import Grid from "@mui/material/Grid";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import Box from "@mui/material/Box";
import {FiltersV2} from "../../components/filterV2/Filters";
import Button from "@mui/material/Button";
import {useFiltersWithPagination} from "../../hooks/useFiltersWithPagination";
import {TableNavlinkTextButton} from "../../components/table/TableNavlinkTextButton";
import moment from "moment";
import Chip from "@mui/material/Chip";
import {omit} from "lodash";
import {SortControl} from "../../components/table/SortControl";
import {useParams} from "react-router-dom";
import {AsyncAutocomplete} from "../../components/form/autoComplete/AsyncAutocomplete";
import {ImageUploader} from "../../components/form/ImageUploader";
import {CreateTransaction} from "./CreateTransaction";


const filterConfig = [

    {
        name: "status",
        label: "Status",
        type: filterTypes.SELECT,
        options: Object.entries(transaction.status).map(([key, value]) => ({ value: value, label: value })),
    },
    {
        name: "type",
        label: "Type",
        type: filterTypes.SELECT,
        options: Object.entries(transaction.type).map(([key, value]) => ({ value: value, label: value })),
    },
    {
        name: "platform",
        label: "Platform",
        type: filterTypes.SELECT,
        options: Object.entries(transaction.platform).map(([key, value]) => ({ value: value, label: value })),
    },

    {
        name: "createdAt",
        label: "Created at",
        type: filterTypes.DATE,
    },

];

const chipColorStatus = {
    [transaction.status.SUCCESS]: "success",
    [transaction.status.PENDING]: "info",
    [transaction.status.FAILED]: "error",
};

const chipColorType = {
    [transaction.type.INCOME]: "info",
    [transaction.type.PAYOUT]: "success",
};

const chipColorPlatform = {
    [transaction.platform.SYSTEM]: "info",
    [transaction.platform.MANUAL]: "warning",
    [transaction.platform.STRIPE]: "success",
};
export const TransactionsScreen = () => {
    const { userId } = useParams();
    const [transactions, setTransactions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const { page, filters, setFilters, setPage, requestParams, sort, setSort } = useFiltersWithPagination(filterConfig);
    const [openCreateTransactionDialog, setOpenCreateTransactionDialog] = React.useState(false);

    const fetchTransctions = useCallback(async (params) => {
        try {
            setIsLoading(true);
            const fetchParams =  {
                ...omit(params, ["filters"]),
                ...(params.filters || {}),
                limit: 10,
            };
            if(userId) {
                fetchParams.userId = userId;
            }

            const response = await axios.get(urlConstants.fetchTransactions, {
                params: fetchParams,
            });
            setTransactions(response.data.transactions);
            setCount(response.data.count);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }, []);

    const [user, setUser] = React.useState(null);



    useEffect(() => {
        fetchTransctions(requestParams);
    }, [JSON.stringify(requestParams)]);

    const title = useMemo(() => {
        let result = "Transactions";
        if(userId && transactions) {
            const userTransaction = transactions.find(transaction => transaction.userId._id === userId);
            if(userTransaction) {
                result = `${userTransaction.userId.nameSurname || userTransaction.userId.email} transactions`;
            }
        }
        return result;
    }, [transactions, userId])

    return (<Container
        id='user-screen-container'
        maxWidth='lg'
        style={{
            backgroundColor: clientAppConstants.pageContainerBackgroundColor,
            // paddingTop: 64,
            minHeight: clientAppConstants.screenContainerMinHeight,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-between'
        }}
    >
        <Helmet defer={false}><title>{title}</title></Helmet>
        <Grid
            container
            direction='column'
            className='w-100'
        >
            <ScreenTitle
                titleText={title}
            />
            <GlobalCircularProgress show={isLoading}/>
            <Box  className="d-flex w-100" mt={2} mb={2} justifyContent="flex-end">
                <Button size="small" color="primary" variant="contained" onClick={() => setOpenCreateTransactionDialog(true)}>Create transaction</Button>
            </Box>
            <Box  className="d-flex w-100" mt={2} mb={2}>
                <FiltersV2 filtersConfig={filterConfig}  filters={filters} onFiltersChange={setFilters} />
            </Box>
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size= 'small' align='left'>Event <SortControl sort={sort} onChange={setSort} field="eventId"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>Host <SortControl sort={sort} onChange={setSort} field="userId"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Type <SortControl sort={sort} onChange={setSort} field="type"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Platform <SortControl sort={sort} onChange={setSort} field="platform"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Status <SortControl sort={sort} onChange={setSort} field="status"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Amount <SortControl sort={sort} onChange={setSort} field="amount"/></StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Description</StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Created at <SortControl sort={sort} onChange={setSort} field="createdAt"/></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {transactions.map((transaction) => (
                        <TableRow
                            key={transaction._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell size= 'small' align='left'>
                                {transaction.eventId ? (<TableNavlinkTextButton
                                    buttonLink={`/event/view/${transaction.eventId._id}`}
                                    buttonText={transaction.eventId.eventTitle}
                                />) : "-"}
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>
                                <TableNavlinkTextButton
                                    buttonLink={`/user/edit/${transaction.userId._id}`}
                                    buttonText={transaction.userId.nameSurname || transaction.userId.email}
                                />
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                <Chip className='speaklabs-activity-platform-chip' label={transaction.type} color={chipColorType[transaction.type]} />
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                <Chip className='speaklabs-activity-platform-chip' label={transaction.platform} color={chipColorPlatform[transaction.platform]} />
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                <Chip className='speaklabs-activity-platform-chip' label={transaction.status} color={chipColorStatus[transaction.status]} />
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                ${transaction.amount}
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                {transaction.description}
                            </StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm')}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
            <CustomPaginationV2
                currentPaginationIndex={page}
                rowsPerPage={10}
                count={Math.ceil(count / 10)}
                handleOnChange={(event, newPage) => setPage(newPage)}
            />
            {openCreateTransactionDialog && <CreateTransaction onClose={() => {
                setOpenCreateTransactionDialog(false);
            }}
                onSuccessSubmit={() => {
                    setOpenCreateTransactionDialog(false);
                    fetchTransctions(requestParams);
                }}
            />}
        </Grid>
    </Container>)
}
