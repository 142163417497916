import React, {  useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


// Actions
import { loadAdmin } from "../../actions/authActions/loadAdmin";


// Components
import { ScreenCircularProgress } from "../loader/ScreenCircularProgress";



export const PrivateRoute = ({ 
  // isAuthenticated,
  // loading,
  // user,
  // userType,
  // loadAdmin,
  component: Component, 
  ...rest 
}) => {
  // const authContext1 = useContext(authContext);
  // const { isAuthenticated, loading, user, userType, loadAdmin } = authContext1;

  // Global Variables
  const isNavbarOn = useSelector(state => state.decorationReducer.isNavbarOn);
  const isHideNavButtons = useSelector(state => state.decorationReducer.isHideNavButtons);

  const loading = useSelector(state => state.authReducer.loading);
  const userType =  useSelector(state => state.authReducer.userType); 
  const user =  useSelector(state => state.authReducer.user); 
  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  const appInitialised = useSelector(state => state.authReducer.appInitialised);

  const dispatch = useDispatch();




  useEffect(() => {
    
    if( 
      !appInitialised
      &&
      (
        !isAuthenticated 
        || !user 
        || userType !== 'admin'
        || userType !== 'editor'
        || userType !== 'writer'
      )
    ) {
      // eslint-disable-next-line
      dispatch(
        loadAdmin()
      );
    }

    // eslint-disable-next-line
  }, [])


  return (
    <Route
      {...rest}
      render={props =>  loading
        ?
        <ScreenCircularProgress />
        :
        (  !isAuthenticated && !loading ) 
          ? 
          (
          <Redirect to='/' />
          ) 
          : 
          (
            <Component {...props} />
          )
      }
    />
  );
};


// const mapStateToProps = (state) => ({
//   isAuthenticated: state.authReducer.isAuthenticated,
//   loading: state.authReducer.loading,
//   user: state.authReducer.user,
//   userType: state.authReducer.userType,
// });

// export default connect(mapStateToProps, { loadAdmin  })(PrivateRoute);

