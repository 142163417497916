import TableRow from "@mui/material/TableRow";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import TableHead from "@mui/material/TableHead";
import React, {useEffect, useState} from "react";
import TableBody from "@mui/material/TableBody";
import axios from "axios";
import {filterTypes, urlConstants} from "../../constants";
import TableCell from "@mui/material/TableCell";
import {CustomTable} from "../../components/table/CustomTable";
import moment from "moment";
import Box from "@mui/material/Box";
import {FiltersV2} from "../../components/filterV2/Filters";
import {useFiltersWithPagination} from "../../hooks/useFiltersWithPagination";
import {omit} from "lodash";

const filterConfig = [
    {
        name: 'isActive',
        label: 'Active',
        type: filterTypes.SELECT,
        single: true,
        options: [
            {value: 'true', label: 'Active'},
            {value: 'false', label: 'Inactive'},
        ],
    }
];

export const Subscriptions = () => {

    const [subscriptions, setSubscriptions] = useState([]);
    const { filters, setFilters, requestParams } = useFiltersWithPagination(filterConfig);

    const fetchSubscriptions = async (params) => {
        const fetchParams =  {
            ...omit(params, ["filters"]),
            ...(params.filters || {}),
        };
        const response = await axios.get(urlConstants.getSubscriptions, {
            params: fetchParams,
        });
        setSubscriptions(response.data.subscriptions);
    }

    useEffect(() => {
        fetchSubscriptions(requestParams);
    }, [JSON.stringify(requestParams)]);

    console.log('subscriptions', subscriptions)

    return (
        <div className='w-100'>
            <h4>Subscriptions</h4>
            <Box  className="d-flex w-100" mt={2} mb={2}>
                <FiltersV2 filtersConfig={filterConfig}  filters={filters} onFiltersChange={setFilters} />
            </Box>
            <CustomTable>
            <TableHead id='manidar-mani-table-head'>
                <TableRow>
                    <StyledTableCell size= 'small' align='left'>email</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>title</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>price</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Purchase date</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>platform</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Valid period</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>is Active</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Promocode</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Finish Date</StyledTableCell>
                </TableRow>
            </TableHead>
                <TableBody>
                    {subscriptions.map(item => (
                        <TableRow key={item.subscriptionId}>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.subscriptionTitle}</TableCell>
                            <TableCell>{item.price}</TableCell>
                            <TableCell>{item.purchaseDate ? moment(item.purchaseDate).format("YYYY-MM-DD HH:mm")  : '' }</TableCell>
                            <TableCell>{item.paymentPlatformId}</TableCell>
                            <TableCell>{item.validPeriod.count} {item.validPeriod.type}</TableCell>
                            <TableCell>{item.isActive ? "active" : 'inactive'}</TableCell>
                            <TableCell>{item.promoCode ?? '-'}</TableCell>
                            <TableCell>{item.finishDate ? moment(item.finishDate).format("YYYY-MM-DD HH:mm") : '-'}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </div>
    );
}
