import * as React from 'react';

// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';

import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const  CopyExistingEventButton = ({
  handleClickButton
})  =>  {
  return (
    <span className='pt-2'>
      <PopupState variant="popover" popupId="speaklabs-copy-existing-event-button-popover">
        {(popupState) => (
          <div>
            <IconButton variant="contained" {...bindTrigger(popupState)}>
              <ContentCopyIcon  color='primary'/>
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
              <Stack direction='column' className='p-1'>
                <Button 
                  type='text' 
                  onClick={() => handleClickButton('Next Possible Time') }                
                >
                  Next Possible Time
                </Button>
                
                <Button 
                  type='text'
                  onClick={() => handleClickButton('14 Day Period') }
                >
                  14 Day Period
                </Button>
                <Button 
                  type='text'
                  onClick={() => handleClickButton('28 Day Period') }
                >
                  28 Day Period
                </Button>
              </Stack>

            </Popover>
          </div>
        )}
      </PopupState>
    </span>
  );
}


//--------------------------------------------------------------
