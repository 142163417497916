
import React from 'react'


// MUI
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';


// Components
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';


// Helpers
import { convertInputToCurrencyText } from '../../helpers/common/convertInputToCurrencyText';




export const RegistrationSetComponent = ({
  formData,
  setFormData,
  registrationEventState
}) => {
  return (
    <>
      <Grid item sm={12}>
        <h4 className='text-center mbe-0 mbs-0 pt-2'>Registration</h4>
      </Grid>
      <Grid 
        item  
        className='w-100 mt-2 pr-3'     
        style={{
          // backgroundColor: 'pink'
        }}
        sm={12} md={6} lg={4}
      >
        <Stack 
          direction='column' 
          // alignItems='center' 
          className='mr-2'
        >
          <FormCustomTitle
            isLineHeightOne={true}
            formText={'Reg. Possible'}
          />
          <Switch
            onChange={(e, checked) => {
              setFormData({
                ...formData,
                isRegistrationPossible: checked,
              })
            }}
            checked={formData.isRegistrationPossible}
          />
        </Stack>
        <Stack className='w-100' direction='row'> 
        </Stack>
      </Grid>
      {/* IS FREE */} 
      {
        formData.isRegistrationPossible && (
          <Grid 
            item  
            className='w-100 mt-2 pr-3'     
            style={{
              // backgroundColor: 'pink'
            }}
            sm={12} md={6} lg={4}
          >
            <Stack 
              direction='column' 
              // alignItems='center'  
              className='mr-2' 
            >
              <FormCustomTitle
                isLineHeightOne={true}
                formText={'Reg. Free'}
              />
              <Switch
                onChange={(e, checked) => {
                  console.log('HELLLOO');
                  
                  setFormData({
                    ...formData,
                    isRegistrationFree: checked,
                    registrationFee: registrationEventState.registrationFee
                  })
                }}
                checked={formData.isRegistrationFree}
              />
            </Stack>
          </Grid>
        )
      }
      {/* REG. PRICE */}
      {
        formData.isRegistrationPossible 
        && !formData.isRegistrationFree  && (
          <Grid 
            item  
            className='w-100 mt-2 pr-3'     
            style={{
              // backgroundColor: 'pink'
            }}
            sm={12} md={6} lg={4}
          >
            <Stack 
              direction='column' 
              // alignItems='center'  
              className='mr-2'
            >
              <TextField
                type='number'
                  variant='standard'
                  label='Registration Fee'
                  placeholder='Registration Fee'
                  required={true}
                  fullWidth
                  value={formData.registrationFee}
                  onBlur={() => {
                    setFormData({
                      ...formData,
                      registrationFee: convertInputToCurrencyText({
                        inputValue: formData.registrationFee.toString()
                      })                      
                    })                    
                  }}                  
                  onChange={e => {
                    setFormData({
                      ...formData,
                      registrationFee:  e.target.value
                    });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Tab' && !e.shiftKey ) {
                      setFormData({
                        ...formData,
                        registrationFee: convertInputToCurrencyText({
                          inputValue: formData.registrationFee.toString()
                        })                      
                      })
                    } 
                  }}
                />
            </Stack>
          </Grid>
        )
      }
      
    </>
  )
}
