
import React, { useEffect, useState } from 'react'
import {  useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchCountryCityList } from '../../actions/countryActions.js/fetchCountryCityList';
import { addCity } from '../../actions/countryActions.js/addCity';


import {
  TableBody,
} from '@material-ui/core';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';


// Icons
import SendIcon from '@mui/icons-material/Send';

// Styles
// import'./InterestScreen.css'

// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead';
import { CityItemComponent } from './CityItemComponent';


// Helpers






export const CountryEditScreen = ({
  match
}) => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const isInterestsLoading = useSelector(state => state.interestReducer.loading);
  // const isAddingInterest = useSelector(state => state.interestReducer.isAddingInterest);
  // const interests = useSelector(state => state.interestReducer.interests);
  // const user = useSelector(state => state.authReducer.user);

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  const [countryId, setCountryId ] = useState(match.params.countryId || null);
  const [isAddingCity, setIsAddingCity ] = useState(false);

  const _initialFormData = { 
    country: null ,
    cityList: [],
    cityTitle: '',
  }
  const [formData, setFormData] = useState({ ..._initialFormData });


  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      dispatch(
        fetchCountryCityList  ({
          countryId,
          cb: apiResponse => {
            if( apiResponse ) {
              setFormData({
                ...formData,
                country: apiResponse.country,
                cityList: apiResponse.country.cityList ?? []
              });
            }
            _setLoading(false)
          }
        })
      );
      // _setLoading(false);
    }, []
  );



  const _cbHandleDeleteCity = cityInfo => {
    setFormData({
      ...formData,
      cityList: [
        ...formData.cityList.filter( item => item._id !== cityInfo._id )
      ]
    })
  }

  const _cbHandleUpdateCity = cityInfo => {
    setFormData({
      ...formData,
      cityList: [
        ...formData.cityList.map( item => {
          if ( item._id === cityInfo._id ) {
            return {
              ...cityInfo
            };
          }
          return item;
        })
      ]
    })
  }





  const _onSubmitHandler = () => { }


  return (
    <Container
      maxWidth='lg'
      className='w-100 pb-1'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      id='speaklabs-interest-screen-container'
    >
      <Helmet defer={false}><title>{'City List'}</title></Helmet>
      <Grid container className='w-100 pb-2'>
        <ScreenTitle 
          titleText={
            _loading 
              ? 
              'City List' 
              :
              (
                formData.country 
              )
                ?
                formData.country.en + ' City List'
                : 
                'City List' 
          } 
        />

        {
          ( _loading 
            // || isInterestsLoading 
          ) && <ScreenCircularProgress />
        }
        {
          !(
              _loading 
              // || isInterestsLoading 
            ) && (
            <Grid item className='w-100' sm={12}>
              <form className='w-100' onSubmit={_onSubmitHandler}>
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  {
                    formData && formData.cityList && formData.cityList.length > 0 
                      ?
                      (
                        <CustomTable
                        // stickyHeader={false}
                        >
                          <DoubleColumnTableHead 
                            firstColumnText='City'
                            secondColumnText='Actions'
                          />
                          <TableBody>
                            
                            {
                              formData.cityList && formData.cityList.map(
                                (cityItem, ind) => (
                                  <CityItemComponent
                                    cityItem={cityItem}
                                    cbHandleDeleteCity={_cbHandleDeleteCity}
                                    cbHandleUpdateCity={_cbHandleUpdateCity}
                                    key={ind}
                                  />
                                ) 
                              )
                            }
                            
                            

                          </TableBody>
                        </CustomTable>
                      )
                      :
                      (
                        <Grid item className='w-100 py-5'>
                          <h4 className='text-center'>No Cities!</h4>
                        </Grid>
                      )
                  }                  
                  {/* End of Table */}



                </Grid>
              </form>
            </Grid>
          )
        }


      </Grid>

      <Grid container sm={12} className='w-100'>
        <Grid item sm={12}>
          <TextField
            placeholder='Enter new City Name!'
            label='City'
            required={true}
            disabled={_loading}
            fullWidth={true}
            variant='outlined'
            id='title'
            name='title'
            value={formData.cityTitle}
            size='medium'
            type='text'
            autoComplete={'off'}
            onChange={e => setFormData({...formData, cityTitle: e.target.value})}
          />
        </Grid>
        <Grid item className='mt-1'>
          <Button 
            variant='contained' 
            endIcon={<SendIcon />}
            color='success'
            disabled={
              formData.cityTitle === ''
            }
            onClick={() => {
              setIsAddingCity(true);
              dispatch(
                addCity({
                  countryId,
                  formData,
                  cb: apiResponse => {
                    if ( apiResponse ) {
                      setFormData({
                        ...formData,
                        cityList: [
                          ...formData.cityList,
                          apiResponse
                        ],
                        cityTitle: ''
                      })
                    }
                    setIsAddingCity(false);
                  }
                })
              );
            }}
          >
            Create
          </Button>
        </Grid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ isAddingCity  }
          // onClick={handleClose}
        ></Backdrop>
      </Grid>
    </Container>
  )
}









