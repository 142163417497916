import axios from "axios";

// Redux
import store from '../../store'


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  SET_IS_DISMISSING_ALL_CONTACT_MESSAGES ,
  ALL_CONTACT_MESSAGES_DISMISSED ,
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const dismissAllContactMessages = async  ({

  cb,  // callBack
}) =>   {
  try { 
    const formData = {
      // newIsDismissedValue
    }


    store.dispatch(
      {
          type: SET_IS_DISMISSING_ALL_CONTACT_MESSAGES,
          payload: true
      }
    );




    const res = await axios.post(
      // urlConstants.apiUrl +  
      urlConstants.dismissAllContactMessagestUrl, 
      formData ?? {},
      clientAppConstants.jsonConfig
    )

    store.dispatch(setAlert(res.data.msg, "success", 2500));

    store.dispatch(
      {
          type: ALL_CONTACT_MESSAGES_DISMISSED,
          payload: null
      }
    );

    if ( cb ) {      
      cb(res.data);      
    }
    


  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => store.dispatch(setAlert(error.msg, "error", 2500)) );
    }
    store.dispatch(
      {
          type: SET_IS_DISMISSING_ALL_CONTACT_MESSAGES,
          payload: false
      }
    );
    if ( cb ) {
      cb([]);
    }
  }
}  