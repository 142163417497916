


import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   USERS_FETCHED, SET_USERS_LOADING } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const updateClassroom = ({  
  formData,
  eventId = null,
  classroomId,
  cb,  // callBack
}) => async dispatch => {
  try { 
    let _isImagesUpdated = (() => {
      if ( 
        formData.isOfflineEventImageUpdated
        || formData.isOnlineEventVerticalImageUpdated
        || formData.isOnlineEventHorizontalImageUpdated
      ) {
        return true;
      }
      return false;
    })();

    const _formData = new FormData();
    _formData.append('jsonText', JSON.stringify({
      ...formData,
      offlineEventImage: undefined,
      onlineEventVerticalImage: undefined,
      onlineEventHorizontalImage: undefined,
    }))

    if ( _isImagesUpdated ) {
      if ( formData.isEventOnline ) {  // ONLINE
        if ( formData.isOnlineEventVerticalImageUpdated ) {
          _formData.append("images[]", formData.onlineEventVerticalImage);
        }
        if ( formData.isOnlineEventHorizontalImageUpdated ) {
          _formData.append("images[]", formData.onlineEventHorizontalImage);
        }
      } else {  // OFFLINE
        _formData.append("images[]", formData.offlineEventImage);
      }
    }
    // dispatch({
    //   type: SET_USERS_LOADING,
    //   payload: true
    // });

    let _url = _isImagesUpdated 
      ? urlConstants.updateClassroom(classroomId)
      : urlConstants.updateClassroomNoImage(classroomId);

    const res = await axios.put(
      _url, 
      _isImagesUpdated 
        ? _formData
        : {
          ...formData,
          offlineEventImage: undefined,
          onlineEventVerticalImage: undefined,
          onlineEventHorizontalImage: undefined,
        }
      ,
      _isImagesUpdated ? clientAppConstants.multipartFormDataConfig : clientAppConstants.jsonConfig
    );  
    dispatch(setAlert(res.data.msg, "success", 2500));
    // dispatch(setFilteringItemCount({itemCount: res.data.userCount}));

    // dispatch({
    //   type: USERS_FETCHED,
    //   payload: res.data.userList
    // });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
}  