export const convertToFormData = (data) => {
    const fromData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
    if(value && typeof value === 'object' && !(value instanceof Date) && !(value instanceof File)){
        fromData.append(key, JSON.stringify(value));
    } else if (typeof value === 'string' || value instanceof File) {
        fromData.append(key, value);
    } else {
        fromData.append(key, JSON.stringify(value));
    }
    });
    return fromData;
}