import React from 'react'


// MUI
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DashboardStat1 } from './DashboardStat1';
import { DashboardStat2 } from './DashboardStat2';
import { DashboardStat3 } from './DashboardStat3';




export const StatisticsComponent = ({
  percentageOfSubscribers
}) => {
  return (
    <Paper className='p-3 '>
      <h4 className='text-center mbe-0 mbs-0'>Statistics</h4>
        <Stack
            direction='row'
            alignContent='end'
            alignItems='flex-end'
            justifyContent='space-between'
            className='p-2'
        >

        </Stack>
      <Stack 
        direction='row'  
        alignContent='end' 
        alignItems='flex-end' 
        justifyContent='space-between'
        className='p-2'
      >
          <DashboardStat3
              tooltipExplanation={'Quantity of Last Month Logged People Compared with Previous 30 Days ' }
              title='Users count'
              percentageText={percentageOfSubscribers.totalUserCount}
          />

          <DashboardStat2
              tooltipExplanation={'Quantity of Last Month Logged People Compared with Previous 30 Days ' }
              currentQuantity={percentageOfSubscribers.lastMothLogin}
              totalQuantity={percentageOfSubscribers.totalUserCount}
              percentage={(percentageOfSubscribers.lastMothLogin / percentageOfSubscribers.totalUserCount * 100).toFixed(2)}
          />
        {/* <DashboardStat1 
          title={'Percentage of Subscribers'}
          currentQuantity
        /> */}
      </Stack>      
      <Stack 
        direction='row'  
        alignContent='end' 
        alignItems='flex-end' 
        justifyContent='space-between'
        className='p-2'
      >
        <DashboardStat2 
          title={'New Comers Percentage'}
          currentQuantity={percentageOfSubscribers.newComers}
          totalQuantity={percentageOfSubscribers.totalUserCount}
          percentage={(percentageOfSubscribers.newComers / percentageOfSubscribers.totalUserCount * 100).toFixed(2)}
          tooltipExplanation='Quantity of Users who bought their first ticket in last one month'
        />
          <DashboardStat2
              title={'Percentage of Subscribers'}
              currentQuantity={percentageOfSubscribers.subscriberCount}
              percentage={
                  (percentageOfSubscribers.subscriberCount / percentageOfSubscribers.totalUserCount * 100).toFixed(2)
              }
              totalQuantity={ percentageOfSubscribers.totalUserCount}
              tooltipExplanation='Quantity of Active Subscribers in Comparison with Total Users'

          />
      </Stack>
    </Paper>
  )
}
