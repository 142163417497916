






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_EMAILS_FILTER } from "../types";





export const setEmailFilter = ({  
  newEmailFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_EMAILS_FILTER,
    payload: {
      isReset,
      newEmailFilterList: [ ...newEmailFilterList ]
    }
  });
}  




