import React, {useState, useEffect} from 'react';

import {  useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Actions
import { setAlert } from '../../actions/alertActions/setAlert';




// import {
//   // Container,
//   // Grid,
//   // CircularProgress,
// } from '@material-ui/core';

// MUI
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { resetPasswordViaToken } from '../../actions/publicUserActions/resetPasswordViaToken';
import { checkResetPasswordToken } from '../../actions/publicUserActions/checkResetPasswordToken';




export const ResetPasswordScreen = ({
  match, // location
}) => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);

  
  const dispatch = useDispatch();


  const [resetPasswordToken, setResetPasswordToken ] = useState( match.params.resetPasswordToken || null ); 

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });
  const {
    password, 
    password2
  } = formData;


  const [ _loading, _setLoading ] = useState(true);
  const [ _responseObject, _setResponseObject ] = useState(
    null
    //      isEmailValidated: true,
    //      to: email.to,
    //      nameSurname: user.nameSurname
  );
  const [isPasswordResetted, setIsPasswordResetted] = useState(false);
  const [isResetPasswordTokenVerified, setIsResetPasswordTokenVerified] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    if ( password !== password2 ) {
      dispatch(
        setAlert('Passwords Do Not Match!', 'error')
      )
      return;
    }

    dispatch(
      resetPasswordViaToken({
        formData,
        cb: ( apiResponse ) => {
          console.log('resetPasswordViaToken -> apiResponse -> ', apiResponse)
        },
        token: resetPasswordToken
      })
    );
    // if ( !_isConfirmedFormRead ) {
    //   setAlert('Lutfen Formu Onaylayiniz!', 'error');
    //   return;
    // }
    // login({formData});
    // console.log('SUCCESS');
  };





  useEffect(() => {
    if (   resetPasswordToken  ) {
      dispatch(
        checkResetPasswordToken({
          token: resetPasswordToken,
          cb: apiResponse => {
            if ( apiResponse ) {
              if ( apiResponse.isResetPasswordTokenVerified) {
                setIsResetPasswordTokenVerified(true)
              }
              _setResponseObject({
                to: apiResponse.email
              });
              // setIsPasswordResetted( apiResponse.isEmailValidated );
              _setLoading(false);
            }
            _setLoading(false);
          }
        })
      );    
    }
  }, []);


  if ( !resetPasswordToken ) {
    return (<h2>Invalid Token!</h2>);
  }


  return (
    <Container
      maxWidth='lg'
      style={{
        // backgroundColor:'#ccc',
        // paddingTop: 64,
        minHeight:'90vh'
      }}    
    >
      <Helmet defer={false}><title>Login</title></Helmet>
      <div 
        className='flexrow justify-content-center' 
        style={{minHeight:'90vh'}}
      >
        <div 
          className='flexcol h-100 justify-content-center'
          style={{minHeight:'90vh'}}
        >
          {
            _loading 
              ? 
              <CircularProgress></CircularProgress>
              : 
              ( 
                isResetPasswordTokenVerified
              
              )
                ?
                (
                  isPasswordResetted
                  ?
                  (
                    <>
                      <Grid item className='w-100'>
                        <h2 className='text-center'>{`${_responseObject.to} Email Updated`}</h2>
                        
                      </Grid>
                      <Grid item className='w-100 text-center'>
                        {`Congratulations! ${_responseObject.to}! Your Password Has Been Updated!` }
                      </Grid>
                    </>
                  )
                  :
                  (
                    // Update Password
                    <>
                    <form className='col s12' onSubmit={e => onSubmit(e)}>
                      <Grid item className='w-100'>
                        <h2 className='text-center'>Update Password</h2>
                        
                      </Grid>
                      <Grid item className='w-100 text-center mb-1 pb-1'>
                        <TextField
                          placeholder='Enter Password'
                          label='Password'
                          required
                          id='password'
                          name='password'
                          value={password}
                          type='password'
                          onChange={e => setFormData({ ...formData, password: e.target.value})}
                          variant='outlined'
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item className='w-100 text-center mb-2 pb-2'>
                        <TextField
                          placeholder='Enter Password'
                          label='Confirm Password'
                          required
                          id='password2'
                          name='password2'
                          value={password2}
                          type='password'
                          onChange={e => setFormData({ ...formData, password2: e.target.value})}
                          variant='outlined'
                          fullWidth={true}
                        />
                      </Grid>
                      <Button
                        variant='contained'
                        // color='secondary'
                        color= 'warning'
                        fullWidth
                        type='submit'
                        
                      >
                        Reset Password
                      </Button>
                      </form>
                    </>
                  )
                )
                :
                (
                  <>
                    <Grid item className='w-100'>
                      <h2 className='text-center'>Invalid Token!</h2>
                      
                    </Grid>
                    <Grid item className='w-100 text-center'>
                      Token Error!. Please Check URL!
                    </Grid>
                  </>
                )
          }
        </div>
      </div>
    </Container>
  )

  return (
    <Container
      maxWidth='lg'
      style={{
        // backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight:clientAppConstants.screenContainerMinHeight
      }}
      id='reset-password-screen-container'    
    >
      <Helmet defer={false}><title>Reset Password</title></Helmet>
      <Grid 
        container 
        direction='column'
        justify='center'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
        className='w-100'
      >
        {
          _loading 
            ? 
            (
              <Grid item>
                <div 
                  className='flexrow justify-content-center'
                  style={{
                    minHeight: 180,
                    paddingTop: 120
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            )
            : 
            ( 
              isResetPasswordTokenVerified
            
            )
              ?
              (
                isPasswordResetted
                ?
                (
                  <>
                    <Grid item className='w-100'>
                      <h2 className='text-center'>{`${_responseObject.to} Email Updated`}</h2>
                      
                    </Grid>
                    <Grid item className='w-100 text-center'>
                      {`Congratulations! ${_responseObject.to}! Your Password Has Been Updated!` }
                    </Grid>
                  </>
                )
                :
                (
                  // Update Password
                  <>
                    <Grid item className='w-100'>
                      <h2 className='text-center'>Update Password</h2>
                      
                    </Grid>
                    <Grid item className='w-100 text-center'>
                      <TextField
                        placeholder='Enter Password'
                        label='Password'
                        required
                        id='password'
                        name='password'
                        value={password}
                        type='password'
                        onChange={e => setFormData({ ...formData, password: e.target.value})}
                        variant='outlined'
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item className='w-100 text-center'>
                      <TextField
                        placeholder='Enter Password'
                        label='Confirm Password'
                        required
                        id='password2'
                        name='password2'
                        value={password2}
                        type='password'
                        onChange={e => setFormData({ ...formData, password2: e.target.value})}
                        variant='outlined'
                        fullWidth={true}
                      />
                    </Grid>
                  </>
                )
              )
              :
              (
                <>
                  <Grid item className='w-100'>
                    <h2 className='text-center'>Hatali Token</h2>
                    
                  </Grid>
                  <Grid item className='w-100 text-center'>
                    Token Hatasi. Lutfen URL'yi Kontrol Ediniz!
                  </Grid>
                </>
              )

        }
        
      </Grid>
    </Container>
  )
}
