import React from 'react'


// MUI
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';

export const CustomSearchTextField = ({
  label,
  htmlId,
  onChange,
  searchTextValue,
}) => {
  return (
    // <Box sx={{ '& > :not(style)': { m: 1 } }}>
    //   <FormControl variant="standard">
    //     <InputLabel htmlFor={htmlId}>
    //       {label}
    //     </InputLabel>
    //     <Input
    //       variant="outlined"
    //       id={htmlId}
    //       startAdornment={
    //         <InputAdornment position="start">
    //           <SearchIcon />
    //         </InputAdornment>
    //       }
    //     />
    //   </FormControl>
      <TextField
        id={htmlId}
        label={label}
        // value={searchTextValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        variant="outlined"
      />
    //   {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    //     <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
    //     <TextField id="input-with-sx" label="With sx" variant="standard" />
    //   </Box>
    // </Box> */}
  )
}
