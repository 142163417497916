import {
  SET_NOTIFICATIONS_LOADING ,
  NOTIFICATION_LIST_fETCHED ,
} from "../actions/types";


const initialState = {
  notificationList: [],
  isLoading: true,
  isNotificationssFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case NOTIFICATION_LIST_fETCHED:      
      return {
        ...state,
        notificationList: [...payload],
        isLoading: false,
        isNotificationssFetched: true,
      };
    case SET_NOTIFICATIONS_LOADING:      
      return {
        ...state,
        notificationList: payload === true ? [] : state.notificationList,
        isLoading: payload
      }; 
    
    default:
      return state;
  }
}