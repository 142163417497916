import {useController} from "react-hook-form";
import {useCallback, useMemo} from "react";
import {FormHelperText, Select} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

let id = 0;
export const SelectField = ({
                              name,
                              control,
                              variant = 'outlined',
                              size = 'small',
                              label,
                                widthEmptyItem = false,
                                options = [],
                              ...props
                          }) => {
    const {field, fieldState} = useController({name, control});
    const inputId = useMemo(() => `input-${name}-${id++}`, [name]);
    const onChange = useCallback((e) => {
        switch (e.target.type) {
            case 'number':
                if (e.target.value) {
                    field.onChange(+e.target.value);
                } else {
                    field.onChange(0);
                }
                break;
            default:
                field.onChange(e.target.value);
        }
    }, [field.onChange]);
    return (
        <FormControl error={Boolean(fieldState.error)} className="w-100 select-field-wrapper">
            {label && <InputLabel className="form-control-label" id={inputId}>{label}</InputLabel>}
            <Select
                ct
                {...field}
                {...props}
                onChange={onChange}
                error={Boolean(fieldState.error)}
                variant={variant}
                size={size}
                id={inputId}
                className="w-100"

            >
                {widthEmptyItem && <MenuItem value="">
                    <em>None</em>
                </MenuItem>}
                {options.map(option => {
                    return (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    )
                })}
            </Select>
            <FormHelperText>{fieldState.error ? fieldState.error.message : undefined}</FormHelperText>
        </FormControl>
    );
}