import React, { useState } from 'react'
import{  NavLink ,withRouter, useHistory} from 'react-router-dom'




// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// CSS
import './UserTicketItem.css'

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { EventItemTicketButton } from './EventItemTicketButton';
 

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';



export const UserTicketItem = ({
  ticketItem
}) => {

  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        <Button 
          variant="text"
          style={{
            textTransform: 'none',
            lineHeight: 1.2,
            // color:'teal',
            color: clientAppConstants.linkButtonTextColor,
          }}
          component={NavLink}
          to={'/event/edit/' + ticketItem.eventId}
        >
          {ticketItem.eventId?.eventTitle}
        </Button>
      </StyledTableCell>

      <StyledTableCell
        size='small'
      >
        {ticketItem.eventId?.isEventOnline ? 'Online' : 'Offline'}
      </StyledTableCell>

      <StyledTableCell
        size='small'
      >
       {ticketItem.subscriptionId?.subscriptionTitle ?? '-'}
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        {ticketItem.isReservation && ticketItem.isCanceled ? 'Reservation'  : 'Ticket' }
          {ticketItem.isCanceled && "Canceled"}
      </StyledTableCell>

        <StyledTableCell
            size='small'
        >
            {convertToMomentDateTime(ticketItem.ticketPurchaseDate)}
        </StyledTableCell>

      <StyledTableCell
        size='small'
      >
        {convertToMomentDateTime(ticketItem.eventId?.eventDate)}
      </StyledTableCell>

      <StyledTableCell
        align='center'
        size='small'
      >
        {
          // ticketItem.purchasePlatform.map(
          //   (platformItem, ind ) => {
          //     let _color;
          //     switch (platformItem) {
          //       case 'paypal':
          //         _color = "primary";
          //         break;
          //       case 'admin-panel':
          //         _color = "success";
          //         break;
          //       case 'subscription':
          //         _color = "error";
          //         break;
          //       default:
          //         _color = 'error';
                  
          //     }
          //     return (
          //       <Chip className='speaklabs-activity-platform-chip' label={platformItem} color={_color} />
          //     )
          //   }
          // )
            
          (( ) => {
            let purchasePlatform =  ticketItem.purchasePlatform;
            let _color;
            switch (purchasePlatform) {
              case 'paypal':
                _color = "primary";
                break;
              case 'admin-panel':
                _color = "success";
                break;
              case 'subscription':
                _color = "error";
                break;
              default:
                _color = 'error';
                
            }
            return (
              <Chip className='speaklabs-activity-platform-chip' label={purchasePlatform} color={_color} />
            )
          })()
        }
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='right'
      >
        {ticketItem.ticketPrice ? ('$' + ticketItem.ticketPrice.toFixed(2) ) : '-'}
      </StyledTableCell>

      {/* Ticket Count */}
      <StyledTableCell
        size='small'
        align='right'
      >
        {ticketItem.ticketStatus}
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        {/* <EventItemTicketButton

        /> */}
        <IconButton component={ NavLink} to={'/ticket/edit/' + ticketItem._id}>
          <MoreHorizIcon />
        </IconButton>
      </StyledTableCell>
          
      
      
    </StyledTableRow>
  )
}
