import Grid from "@mui/material/Grid";
import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import {CustomTable} from "../../components/table/CustomTable";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";
import {toast} from "react-toastify";
import axios from "axios";
import {urlConstants} from "../../constants";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";

export default function UserSubscriptions({ user, onChange }) {
    const [isLoading, setIsLoading] = React.useState(false);

    const toggleActiveSubscription = async (subscription) => {
        try {
            setIsLoading(true);
            const response = await axios.post(urlConstants.toggleSubscriptionActive(subscription._id), {
                isActive: !subscription.isActive,
                userId: user._id,
            });
            onChange();
        } catch (e) {
            toast.error(e.message);
        }
        setIsLoading(false);
    }

    return (
        <Grid item className='w-100 pb-1'>
            <GlobalCircularProgress show={isLoading} />
        <h4>Subscriptions</h4>
        <CustomTable>
            <TableHead id='user-edit-screen-subscriptions'>
                <TableRow>
                    <StyledTableCell size= 'small' align='left'>Purchase Date</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Purchase Platform</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Valid Till</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Valid Period</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Is Active</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Online Offline</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Title</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Explanation</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>price</StyledTableCell>
                    <StyledTableCell size= 'small' align='left'>Finish Date</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {user.subscriptions?.length ? user.subscriptions?.map(item => (
                    <TableRow key={item._id}>
                        <StyledTableCell size= 'small' align='left'>{moment(item.purchaseDate).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.purchasePlatform}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{moment(item.validTill).format("YYYY-MM-DD")}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.validPeriod.count} {item.validPeriod.type}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'><Switch checked={item.isActive} onChange={() => toggleActiveSubscription(item)} /></StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.onlineOffline ? "Yes" : "No"}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.subscriptionTitle}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.subscriptionExplanation}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>${item.price}</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>{item.finishDate ? moment(item.finishDate).format("YYYY-MM-DD") : "-"}</StyledTableCell>
                    </TableRow>
                )) : <TableRow>
                    <StyledTableCell colSpan={10} align='center'>No subscriptions</StyledTableCell>
                </TableRow>}
            </TableBody>
        </CustomTable>
        </Grid>
    );
}