



// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_INTEREST_FILTER_ITEM_CLICK } from "../types";

// Actions




export const handleInterestItemClick = ({
  interestItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_INTEREST_FILTER_ITEM_CLICK,
    payload: interestItem
  });
}  






