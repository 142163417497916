import React from 'react'

// MUI
import Button from '@mui/material/Button';


// Icons
import CancelIcon from '@mui/icons-material/Cancel';

export const CancelTicketButton = () => {
  return (
    <Button
      endIcon={<CancelIcon/>}
      onClick={() => {

      }}
      variant='contained'
      color='error'
    >
      Cancel Ticket
    </Button>
  )
}