import {useCallback, useEffect, useRef, useState} from "react";
import {FileControl} from "./FileControl";
import {Container} from "@material-ui/core";
import {CardMedia} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";



export const ImageControl = ({onChange, value: propValue, accept = 'image/*', multiple, ...rest}) => {
    const [value, setValue] = useState();
    const timeoutRef = useRef();
    const handleChange = useCallback((nextValue) => {
        setValue(multiple ? nextValue : [nextValue]);
        onChange(nextValue);
    }, [multiple, onChange]);

    const [bigPreview, setBigPreview] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [previews, setPreviews] = useState([]);


    useEffect(() => {
        if(typeof propValue === 'string'){
            setPreviews([propValue]);
        } else if (Array.isArray(propValue)) {
            setPreviews(propValue);
        }
    }, [propValue]);

    useEffect(() => {
        const fileReaders = [];
        let isCancel = false;
        if (value) {
            setPreviews([]);
            value.forEach(item => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setPreviews(current => [...current, result])
                    }
                }
                fileReader.readAsDataURL(item);
                fileReaders.push(fileReader);
            })

        }
        return () => {
            isCancel = true;
            fileReaders?.forEach(fileReader => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort();
                }
            });
        }

    }, [value]);

    const onHideModal = useCallback(() => {
        setShowModal(false);
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setBigPreview('');
        }, 500);
    }, []);

    const onShowModal = (img) => {
        setBigPreview(img);
        setShowModal(true);
    }


    return (<>
        {Boolean(previews.length) && <Container fluid className="mb-2">
            <Grid xs={1} md={3} lg={4} spacing={2}>
                {previews.map(item => (
                    <div key={item} style={{ paddingLeft: 0 }}>
                        <img src={item} className="w-100"/>
                    </div>
                ))}
            </Grid>
        </Container>}
    <FileControl onChange={handleChange} accept={accept} multiple={multiple} {...rest} />
    </>);
}