import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchEventList } from '../../actions/eventActions/fetchEventList';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';





import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';

// MUI
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { SearchQueryComponent } from '../../components/filter/SearchQueryComponent';
import { CountryTableHead } from './CountryTableHead';
import { CountryItem } from './CountryItem';


// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';
import { fetchCountryList } from '../../actions/countryActions.js/fetchCountryList';






export const  CountryScreen = ({

}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const countryList = useSelector(state => state.countryReducer.countryList);

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);


  useEffect(() => {
    dispatch(
      fetchCountryList({
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('CountryScreen -> fetchCountryList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
    }
  }, [])



  useEffect(() => {
    if ( !_loading ) {
      console.log('filter useEffect')
      console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      dispatch(
        fetchCountryList({
          limit: pageFilterCount,
          skip: 0,
          order,
          filteringParameters,
          cb: apiResponse => {
            dispatch(
              setIsFilteringLoading(false)
            );
          }
        })
      )
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])








  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { 
      label: 'Search Query', isChecked: false, 
      // component: SearchQueryComponent, 
      component: null, 
      // defaultFilterValue: _initialFilteringParameters.searchQuery  ,
      defaultFilterKey: 'searchQuery'  ,
    },    
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);




  return (
    <Container
      id='country-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>Countries</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Countries'}</span> 
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  // _setIsHideActiveUiFilters(false);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  // _setFilteringParameters({..._initialFilteringParameters});
                  // setOrder(_initialOrder);  
                  dispatch(
                    resetFilters()
                  );
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    // _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                >
                  {
                    isHideActiveUiFilters 
                      ?
                      <VisibilityIcon color='error' />
                      :
                      <VisibilityOffIcon />

                  }
                  
                </IconButton>
                )
              }

            </>
          )}  
        />
        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>

            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Search Query')
                && _screenFilterOptions.find( item => item.label === 'Search Query').isChecked
                // && !_isHideActiveUiFilters
                &&  <SearchQueryComponent textfieldLabel='Search Countries' />
            }

          


            
            {
              !_isHideActiveUiFilters
              &&
              _filterUiElements && _filterUiElements.length > 0 &&
              _filterUiElements.map(
                ( item , ind )=> {
                  return (
                    <React.Fragment key={ind}>
                      { item.component &&  item.component() }
                    </React.Fragment>
                  );
                }
              )
            }

            




          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}


        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                <CustomTable 
                  // stickyHeader={false}
                >
                  <CountryTableHead/>
                  <TableBody>                    
                    {/* <EventItem />
                    <EventItem />
                    <EventItem /> */}
                    {
                      isPaginationLoading !== true && isFilteringLoading !== true &&
                      countryList && countryList.map(
                        (countryItem, ind) => (
                          <CountryItem
                            key={ind}
                            countryItem={countryItem  }
                          />
                        )
                      )
                    }
                  </TableBody>
                </CustomTable>
                {/* End of Table */}

                {
                  (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                }

                <CustomPagination
                  currentPaginationIndex={paginationPage}
                  handleOnChange={( e, page ) => {
                    if( page === paginationPage  || isPaginationLoading  ) {
                      return;
                    }
                    dispatch(
                      setIsPaginationLoading(true)
                    );
                    dispatch(
                      setPaginationPage(page)
                    );

                    dispatch(
                      fetchCountryList({
                        limit: pageFilterCount,
                        order,
                        filteringParameters,
                        limit: pageFilterCount,
                        skip: ( page - 1  ) * pageFilterCount,
                        cb: apiResponse => {
                          dispatch(
                            setIsPaginationLoading(false)
                          );
                        }
                      })
                    );

                  }}
                  isPaginationLoading={ isPaginationLoading }
                  itemCount={60}
                  isScreenLoading={false}

                />
              </>
            )
        }

        {/* Table */}

        {/* <p>sdfsdf</p> */}

      </Grid>
    </Container>
  )
}


