import Container from "@mui/material/Container";
import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {CustomTable} from "../../components/table/CustomTable";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import {IconButton, TableBody, TableHead, TableRow} from "@material-ui/core";
import axios from "axios";
import {CustomPaginationV2} from "../../components/CustomPaginationV2";
import Grid from "@mui/material/Grid";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import moment from "moment";

export const PublicContactMessagesScreen = () => {



    const [publicContactMessages, setPublicContactMessages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = React.useState(0);


    const fetchPublicContactMessages = useCallback(async (page) => {
        try {
            setIsLoading(true);
            const response = await axios.get(urlConstants.fetchPublicContactMessagesUrl, {
                params: {
                    page: page,
                    limit: 20
                }
            });
            setPublicContactMessages(response.data.items);
            setCount(response.data.count);
        } catch (e) {
            console.log(e);
        }
         setIsLoading(false);
    }, []);


    useEffect(() => {
        console.log("first render ");
    }, []);


    useEffect(() => {
        fetchPublicContactMessages(page);
    }, [page]);

    return (<Container
        id='user-screen-container'
        maxWidth='lg'
        style={{
            backgroundColor: clientAppConstants.pageContainerBackgroundColor,
            // paddingTop: 64,
            minHeight: clientAppConstants.screenContainerMinHeight,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-between'
        }}
    >
        <Helmet defer={false}><title>Contact form messages</title></Helmet>
        <Grid
            container
            direction='column'
            className='w-100'
        >
            <ScreenTitle
                titleText="Contact form messages"
                />
            <GlobalCircularProgress show={isLoading}/>
                 <CustomTable>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell size= 'small' align='left'>Name</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>Email</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Target by</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Message</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>Created at</StyledTableCell>
                        </TableRow>
                    </TableHead>
                     <TableBody>

                            {publicContactMessages.map((message) => (
                                <TableRow
                                    key={message._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell size= 'small' align='left'>{message.name}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='left'>{message.email}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{message.targetBy}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{message.message}</StyledTableCell>
                                    <StyledTableCell size= 'small' align='center'>{message.createdAt ? moment(message.createdAt).format("YYYY-MM-DD HH:mm") : ''}</StyledTableCell>
                                </TableRow>
                            ))}
                     </TableBody>
                 </CustomTable>
                <CustomPaginationV2
                currentPaginationIndex={page}
                rowsPerPage={10}
                count={Math.ceil(count / 10)}
                handleOnChange={(event, newPage) => setPage(newPage)}
                />
        </Grid>
    </Container>)
}
