import React from 'react'
import{  NavLink } from 'react-router-dom'


// MUI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Icons
import ReceiptIcon from '@mui/icons-material/Receipt';

export const EventTicketsIconButton = ({
  eventId
}) => {
  return (
    <Tooltip title='Tickets & Reservations' placement='left'   arrow>
      <IconButton size='small' component={ NavLink} to={'/event/tickets/' + eventId}>
        <ReceiptIcon   color='success'/>
      </IconButton>
    </Tooltip>
  )
}
