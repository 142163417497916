import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components


// Actions
import { setActivityTypeFilter } from '../../actions/filterActions/setActivityTypeFilter';
import { handleActivityTypeItemClick } from '../../actions/filterActions/handleActivityTypeItemClick';
import { setIsDismissedFilter } from '../../actions/filterActions/setIsDismissedFilter';
import { handleIsDismissedItemClick } from '../../actions/filterActions/handleIsDismissedItemClick';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;









export const IsDismissedFilterComponent = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const isDismissed = useSelector(state => state.filterReducer.filteringParameters.isDismissed);
  const dispatch = useDispatch();

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      >
      {/* <AutoCompleteMultipleCheckbox 
        options={[ ...clientAppConstants.onlineOfflineOptions]}
        autoCompleteLabel='Online-Offline'
        placeHolder=''
        htmlId='event-screen-online-offline-autocomplete'
        handleAutoCompleteOnChange={
          ( e, newValue ) => {
            _setOnlineOfflineFilter( newValue );
        }}
      /> */}
      <Autocomplete
        multiple
        id={ 'speaklabs-is-dismissed-autocomplete' }
        options={[...clientAppConstants.isDismissedOptions]}
        onChange={(e, newVal )=> {
          dispatch(
            setIsDismissedFilter({
              newIsDismissedFilterList: newVal
            })
          )
        }}
        value={isDismissed}
        // filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        // defaultValue={[top100Films[13]]}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleIsDismissedItemClick({
                    isDismissedItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                // checked={selected} 
                checked={
                  isDismissed.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params

              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  // <>YARRAK AMK</>
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'Is Dismissed'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  );
}



























