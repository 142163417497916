import React from 'react'
import { 
  StyledTableCell ,
} from '../../components/table/StyledTableComponents'




// MUI
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export const EventTicketsTableHead = () => {


  return (
    <TableHead id='speaklabs-event-tickets-table-head'>
      <TableRow>
        {/* <StyledTableCell size= 'small' align='left'>User</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Definition</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Date</StyledTableCell> */}
        {/* <StyledTableCell size= 'small' align='left'>Date</StyledTableCell> */}
        <StyledTableCell size= 'small' align='left'>User</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='left'>Online-Offline</StyledTableCell> */}
        <StyledTableCell size= 'small' align='left'>Subscription Name</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>
          <Tooltip title='Ticket or Reservation'>
            <span>Ticket</span>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Platform</StyledTableCell>
        <StyledTableCell size= 'small' align='right'>Payment</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Status</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Actions</StyledTableCell>
        {/* <StyledTableCell size= 'small' align='left'>Balance</StyledTableCell> */}
         {/* <StyledTableCell size= 'small' align='center'>Language</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Max. Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Ticket</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Registration</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell> */}
      </TableRow>
    </TableHead>
  )
}
