
// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_IS_FILTERING_LOADING } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const setIsFilteringLoading = (  
  newLoadingValue
) => async dispatch => {
  dispatch({
    type: SET_IS_FILTERING_LOADING,
    payload: newLoadingValue
  });
}  





