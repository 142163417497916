import * as React from 'react';

// MUI
// import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


import FilterListIcon from '@mui/icons-material/FilterList';
// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';




export const FilterDialog = ({
  filterOptions,
  handleClickOption,
  handleResetFilters,
  handleClose,
  open
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog 
    fullScreen={fullScreen}
      onClose={handleClose} 
      open={open}
      // https://stackoverflow.com/questions/39322715/material-ui-dialog-is-not-closing-when-overlay-is-clicked?rq=1
      // onBackdropClick={handleClose}
    >
      {/* <DialogTitle>
        <Stack className='w-100' direction="row">
          <p>Filters</p>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle> */}
      <DialogTitle>
      Filters
        {/* <Stack className='w-100' direction="row">
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Stack> */}
        {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color='error'/>
        </IconButton>
      ) : null}
      </DialogTitle>
      <DialogContent>
        {
          filterOptions.map(
            (filterItem, ind) => (
              <div 
                key={ ind }
                onClick={() => {
                  handleClickOption( ind );
                }}
                className='hover-cursor-pointer-change-bg-color'
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  // checked={selected} 
                  checked={filterItem.isChecked} 
                  // onChange={ e =>     {
                  //   handleClickOption( ind );
                  // }}
                />
                {/* {option.label} */}
                {filterItem.label}
              </div>
            )
          )
        }
        <Button 
          variant="contained" 
          size="small"
          className='w-100 mt-2'
          onClick={() => {
            if ( handleResetFilters ) handleResetFilters();
            handleClose();
          }}
        >
          Reset Filters
        </Button>

      </DialogContent>

      {/* <List sx={{ pt: 0 }}>
        {emails.map((email) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={email}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem>
      </List> */}

    </Dialog>
  )
}


export const  FilterDialogButton = ({
  filterOptions,
  handleClickOption,
  handleResetFilters,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    
      <>
        <Tooltip 
          title="Filter" 
          arrow 
          placement="right"
        >
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <FilterDialog
          filterOptions={filterOptions}
          handleClickOption={handleClickOption}
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          handleClose={handleClose}
          handleResetFilters={handleResetFilters}
        />
      </>
  );
}


