import React, {useState, useEffect} from 'react'
import {  useSelector, useDispatch } from "react-redux";



// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import { flexbox } from '@mui/system';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';


// Styles
import './CreateEventDialog.css';

// Components
import { CustomDateTime2 } from '../../form/CustomDateTime2';
import { FormCustomTitle } from '../../typography/FormCustomTitle';
import { SimpleCustomSelect } from '../../form/SimpleCustomSelect';
import { CopyExistingEventButton } from './CopyExistingEventButton';

import AlertComponent from '../../../layout/Alert'; 

// Helpers
import { convertToMomentDateTime } from '../../../helpers/moment/convertToMomentDateTime';
import { xDaysLater } from '../../../helpers/common/xDaysLater';
import { nextPossibleDateAtSameDayAndTime } from '../../../helpers/common/nextPossibleDateAtSameDayAndTime';
import { removeItemAtIndex } from '../../../helpers/common/removeItemAtIndex';
import { copyEvent } from '../../../actions/eventActions/copyEvent';
import { detectMyTimezoneHelper } from '../../../helpers/dateAndTime/detectMyTimezoneHelper';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const CreateEventDialog = ({
  latestEvent,
  isFetchingLatestEventDate,
  eventInformation,
  eventId,
  isOpen,
  handleClose
}) => {
  // Global Variables
    // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    // const appInitialised = useSelector(state => state.authReducer.appInitialised);
    // const userType = useSelector(state => state.authReducer.userType);

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let _dummyRepeatingOptions = [
    { value: '1 Time-val', definition: '1 Time' },
    { value: '7 Days-val', definition: '7 Days' },
    { value: '14 Days-val', definition: '14 Days' },
    { value: '28 Days-val', definition: '28 Days' },
  ]


  const _initialFormData = {
    // repeatingOptions: _dummyRepeatingOptions[0].value,
    repeatingOptions: null,
    // latestEvent: xDaysLater({
    //   inputDate: Date.now(),
    //   postponeDayQuantity: -4
    // }),
    clientTimeZone: detectMyTimezoneHelper(),
    latestEvent: null,
    customDate: null,
    isFirstEventCustomDate: true,
    dateList: [
      // {
      //   eventDate: Date.now(),
      //   isCopyCustomDate: true
      // }

    ],

  }
  const [ formData, setFormData  ] = useState( { ..._initialFormData } );

  const [ _isCopyingEvent, _setIsCopyingEvent] = useState(false);

  useEffect(() => {

    setFormData({
      ...formData,
      latestEvent: latestEvent ?  latestEvent.eventDate : null
    })
  }, [latestEvent])

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleClose}
      // fullScreen={fullScreen}
      fullScreen
      TransitionComponent={Transition}
      className='speaklabs-create-event-dialog'
    >
      {/* <DialogTitle 
        fullScreen
        style={{
          backgroundColor: 'grey',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        Create Event HHH
      </DialogTitle> */}
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Stack 
            direction='row'
            justifyContent='space-between'
            className='w-100'
            alignItems='center'
          >
            <IconButton
              edge="start"
              color="inherit"
              // onClick={handleClose}
              onClick={() => {
                setFormData({ ..._initialFormData})
                handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sound
            </Typography> */}
            <span 
              style={{
                fontSize: '1.25rem'
              }}
            >
              Copy Event
            </span>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
            <Button 
              onClick={() => {
                _setIsCopyingEvent(true);
                dispatch(
                  copyEvent({
                    eventId,
                    formData,
                    cb: apiResponse => {
                      console.log('Hello');

                      _setIsCopyingEvent(false);
                      // handleClose();
                    }
                  })
                );
                
              }}
              variant='text'
              color='inherit'
              endIcon={<SendIcon />}
            >
              Copy Event
            </Button>
          </Stack>
        </Toolbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={ _isCopyingEvent || isFetchingLatestEventDate }
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </AppBar>

      

      <DialogContent>
        {/* <DialogContentText>
          Create Event
        </DialogContentText> */}
        <Grid container className='w-100 pt-1' sm={12}>
          <AlertComponent />
          {
            formData.latestEvent && (
              <Grid item className='pr-4 pt-1'>
                <Stack direction='row'>
                  <Stack direction='column'>
                    <FormCustomTitle
                      formText='Latest Event:'
                    />
                    {/* <span className='pt-1'>{ convertToMomentDateTime(formData.latestEvent) }</span> */}
                    {
                      isFetchingLatestEventDate && <span className='pt-1'>Initialising...</span>
                    }
                    <span className='pt-1'>{ 
                      latestEvent && latestEvent.eventDate && convertToMomentDateTime(latestEvent.eventDate) 
                    }</span>
                  </Stack>

                  {/* Copy Button Here */}
                  <CopyExistingEventButton 
                    handleClickButton={
                      (inputVal) => {
                        let copiedEventsFromLatestEvent = [ ...formData.dateList.filter( item => item.isCopyCustomDate === false )];
                        let latestAddedNewEvent = copiedEventsFromLatestEvent.length === 0
                          ?
                          null
                          :
                          copiedEventsFromLatestEvent[copiedEventsFromLatestEvent.length - 1 ] 
                        
                        // formData.dateList.find(
                        //   item => item.isCopyCustomDate === false
                        // );
                        let _period = 7;
                        switch (inputVal) {
                          case 'Next Possible Time':
                            _period = 7;
                            break;
                          case '14 Day Period':
                            _period = 14;
                            break;
                          case '28 Day Period':
                            _period = 28;
                            break;
                          default:
                            break;
                        }
                        setFormData({
                          ...formData,
                          dateList: [
                            ...formData.dateList,
                            {
                              isCopyCustomDate: false,
                              eventDate: nextPossibleDateAtSameDayAndTime({
                                period: _period,
                                inputDate: latestAddedNewEvent
                                  ?
                                  latestAddedNewEvent.eventDate
                                  :
                                  formData.latestEvent
                              })
                            }
                          ]
                        });
                      }
                    }
                  />
                  {/* End of Copy Button Here */}

                </Stack>
              </Grid>
            )
          }
          <Grid item className='pr-2 pt-1'>
            <Stack 
              direction='row'
              alignItems='flex-end'
              spacing={1}
              // alignContent='center'
            >
              <Stack direction='column'>
                <FormCustomTitle
                  formText='Custom Date'
                />
                <CustomDateTime2  
                  isDontSetInnerSetState= {true}
                  selectedValue={ formData.customDate }
                  onChange={ selectedDate => setFormData({ ...formData, customDate: selectedDate }) }
                />
              </Stack>
              {
                formData.customDate && (
                  <Button
                    onClick={() => {
                      setFormData({
                        ...formData,
                        dateList: [
                          ...formData.dateList,
                          {
                            eventDate: formData.customDate,
                            isCopyCustomDate: true
                          }
                        ],
                        customDate: null
                      });
                    }}
                  >
                    Create
                  </Button>
                )
              }
              {/* {
                formData.customDate === null
                && formData.dateList 
                && formData.dateList.length > 0
                && formData.dateList.find( item => item.isCopyCustomDate === true )
                && (
                  
                  <CopyExistingEventButton 
                    handleClickButton={
                      (inputVal) => {
                        let copiedEventsFromCustomEvent = [ ...formData.dateList.filter( item => item.isCopyCustomDate === true )];
                        let latestNewCustomEvent = copiedEventsFromCustomEvent.length === 0
                          ?
                          null
                          :
                          copiedEventsFromCustomEvent[copiedEventsFromCustomEvent.length - 1 ] 
                        
                        // formData.dateList.find(
                        //   item => item.isCopyCustomDate === true
                        // );
                        let _period = 7;
                        switch (inputVal) {
                          case 'Next Possible Time':
                            _period = 7;
                            break;
                          case '14 Day Period':
                            _period = 14;
                            break;
                          case '28 Day Period':
                            _period = 28;
                            break;
                          default:
                            break;
                        }
                        setFormData({
                          ...formData,
                          dateList: [
                            ...formData.dateList,
                            {
                              isCopyCustomDate: true,
                              eventDate: nextPossibleDateAtSameDayAndTime({
                                period: _period,
                                inputDate: copiedEventsFromCustomEvent.eventDate
                              })
                            }
                          ]
                        });
                      }
                    }
                  />
                  
                )
              } */}
            </Stack>
          </Grid>
          
        </Grid>
        <Grid container sm={12} direction='column'>
          {
            formData.dateList.length > 0 && (
              <Grid item sm={12}><h3 className='text-center'>New Event Dates</h3></Grid>
            )
          }
          <Grid item sm={12}>

            {
              formData.dateList.map(
                (dateItem, ind) => {
                  return (
                    <Stack 
                      key={ind} 
                      className='my-1'
                      direction='row' 
                      alignItems='center'
                      // spacing={3}
                    >
                      <span style={{width: 110}}>
                        New Event {ind + 1}:
                      </span>
                      <CustomDateTime2  
                        selectedValue={ dateItem.eventDate }
                        onChange={ selectedDate => {
                          setFormData({ 
                            ...formData, 
                            dateList:  [...formData.dateList].map(
                              ( item, index ) => index === ind 
                                ? 
                                { ...item, eventDate: selectedDate } 
                                : 
                                item
                            )
                          });
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          setFormData({ 
                            ...formData, 
                            // dateList:  formData.dateList.splice(ind, 1)
                            dateList: removeItemAtIndex({ 
                              array: formData.dateList ,
                              index: ind
                            })  
                          });
                        }}
                      >
                        <DeleteForeverIcon color='error'/>
                      </IconButton>
                    </Stack>
                  )
                }
              )
            }
          </Grid>
        </Grid>
        
        
      </DialogContent>
      {/* <DialogActions>
        <Button 
          onClick={() => {
            setFormData({ ..._initialFormData})
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleClose}
          variant='contained'
          color='primary'
          endIcon={<SendIcon />}
        >
          Create Event
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}
