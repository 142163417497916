import * as React from 'react';

// MUI
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';


// Resource:
//  https://mui.com/components/date-range-picker/
export const MuiBasicDateRangePicker =({
  startText='Since',
  endText='Until',
  value= [ null, null ],
  onChange,
  isSmallSize =  false,
}) => {
  // const [value, setValue] = React.useState([null, null]);

  if (isSmallSize) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          label="Advanced keyboard"
          value={value}
          onChange={(newValue) => onChange(newValue)}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <input ref={startProps.inputRef} {...startProps.inputProps} />
              <Box sx={{ mx: 1 }}> to </Box>
              <input ref={endProps.inputRef} {...endProps.inputProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    );
  }

  return (
    <span 
      id='speaklabs-date-range-picker'
    >

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          
          startText={startText}
          endText={endText}
          value={value}
          // onChange={(newValue) => {
          //   setValue(newValue);
          // }}
          onChange={onChange}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </span>
  );
}
