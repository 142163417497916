




// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   SET_CLASSROOMS_FILTER  } from "../types";





export const setClassroomsFilter = ({  
  newClassroomFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_CLASSROOMS_FILTER,
    payload: {
      isReset,
      newClassroomFilterList: [ ...newClassroomFilterList ]
    }
  });
}  




