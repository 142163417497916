import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchUserActivityList } from '../../actions/activityActions/fetchUserActivityList';
import { resetActivityReducer } from '../../actions/activityActions/resetActivityReducer';
// Filter Actions
import { resetFilters } from '../../actions/filterActions/resetFilters';
import { setIsHideActiveUiFilters } from '../../actions/filterActions/setIsHideActiveUiFilters';
import { setIsFilteringLoading } from '../../actions/filterActions/setIsFilteringLoading';
import { setPaginationPage } from '../../actions/filterActions/setPaginationPage';
import { setIsPaginationLoading } from '../../actions/filterActions/setIsPaginationLoading';


import {
  Container,
  TableBody,
  Grid
} from '@material-ui/core';

// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import { CustomTable } from '../../components/table/CustomTable';
import { UserActivityTableHead } from './UserActivityTableHead';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { UserActivityItem } from './UserActivityItem';
import { CustomPagination } from '../../components/CustomPagination';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';

import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';


import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';
import { InterestFilterComponent } from '../../components/filter/InterestFilterComponent';
import { HostFilterComponent } from '../../components/filter/HostFilterComponent';
import { OnlineOfflineFilter } from '../../components/filter/OnlineOfflineFilter';
import { ClassroomFilterComponent } from '../../components/filter/ClassroomFilterComponent';
import { SinceDateFilterComponent } from '../../components/filter/SinceDayFilterComponent';
import { UntilDateFilterComponent } from '../../components/filter/UntilDateFilterComponent';
import { ActivityTypeFilterComponent } from '../../components/filter/ActivityTypeFilterComponent';
import { PlatformFilterComponent } from '../../components/filter/PlatformFilterComponent';


// Helpers
import { handleRemoveFilterItemHelper } from '../../helpers/filter/handleRemoveFilterItemHelper';



export const  UserActivitiesScreen = ({
  // Location, History, etc
  match, // location -> match.params.eventId
}) =>  {

  // Global Variables
  const isHideActiveUiFilters = useSelector(state => state.filterReducer.isHideActiveUiFilters);
  const order = useSelector(state => state.filterReducer.order);
  const paginationPage = useSelector(state => state.filterReducer.paginationPage);
  const isPaginationLoading = useSelector(state => state.filterReducer.isPaginationLoading);
  const isFilteringLoading = useSelector(state => state.filterReducer.isFilteringLoading);
  const pageFilterCount = useSelector(state => state.filterReducer.pageFilterCount);
  const filteringParameters = useSelector(state => state.filterReducer.filteringParameters);

  const userActivityList = useSelector(state => state.activityReducer.userActivityList);
  const user = useSelector(state => state.activityReducer.user);

  const [userId, setUserId] = useState(match.params.userId || null);



  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);

  


  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  
  const[ _screenFilterOptions, _setScreenFilterOptions ]  = useState([
    { label: 'Online-Offline', isChecked: false,  
      component: null, 
      defaultFilterKey: 'onlineOffline'  ,
    },
    // { label: 'Activity-Type', isChecked: false, component: ActivityType,
    //   defaultFilterValue: _initialFilteringParameters.activityType  ,
    //   defaultFilterKey: 'activityType'  ,
    // },
    { label: 'Activity-Type', isChecked: false,  
      component: null, 
      defaultFilterKey: 'activityType'  ,
    },
    { label: 'Classrooms', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      // defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { label: 'Platform', isChecked: false, 
      component: null, 
      defaultFilterKey: 'platform'  ,
    },
    { label: 'Hosts', isChecked: false, component: null  ,
    // defaultFilterValue: _initialFilteringParameters.hosts  ,
      defaultFilterKey: 'hosts'  ,
    },
    { label: 'Since-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: null  ,
    // defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ])


  useEffect(() => {
    dispatch(
      fetchUserActivityList({
        userId,
        limit: pageFilterCount,
        skip: 0,
        filteringParameters : {},
        // order,
        cb: apiResponse => {
          _setLoading(false);
          console.log('EventScreen -> fetchEventList -> apiResponse -> ', apiResponse)
        }
      })
    )
    return () => {
      dispatch(
        resetFilters()
      );
      dispatch(
        resetActivityReducer()
      );
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if ( !_loading ) {
      // console.log('filter useEffect')
      // console.log('filteringParameters -> ', filteringParameters)
      dispatch(
        setIsFilteringLoading(true)
      );
      dispatch(
        setPaginationPage(1)
      );
      dispatch(
        fetchUserActivityList({
          userId,
          limit: pageFilterCount,
          skip: 0,
          order,
          filteringParameters,
          cb: apiResponse => {
            dispatch(
              setIsFilteringLoading(false)
            );
          }
        })
      )
    }
    // eslint-disable-next-line
  }, [filteringParameters, order])





  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>User Activities</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              {
                user 
                ? 
                  (

                    <Button 
                      size="small"
                      style={{
                        // color: 'black'
                        fontSize: '1.2rem'
                      }}
                      component={NavLink}
                      color={  'primary'  }
                      to={'/user/edit/' + user._id}
                    >
                      {
                      // 'Alex Potkin'
                      user 
                        ?
                        (
                          user.nameSurname ?? user.email
                        )
                        :
                        'Loading...'
                      }
                    </Button>
                  )
                  :
                  <p>User</p>
                
              }
              <span>{'/ Activities'}</span>
              <FilterDialogButton
                filterOptions={ _screenFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  ); 
                  dispatch(
                    resetFilters()
                  );
                }}

                
                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._screenFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component

                    handleRemoveFilterItemHelper({
                      clickedOptionDefaultFilterKey : _clickedOption.defaultFilterKey,
                      dispatch
                    });


                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                  }
                  _setScreenFilterOptions(
                    [
                      ..._screenFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                  onClick={() => {
                    dispatch(
                      setIsHideActiveUiFilters()
                    );
                  }}
                >
                  {
                    isHideActiveUiFilters 
                      ?
                      <VisibilityIcon color='error' />
                      :
                      <VisibilityOffIcon />

                  }
                  
                </IconButton>
                )
              }
            </>
          )}    
        />

        {/* FILTERING COMPONENTS */}
        <Grid item>
          <Grid container className='pb-1'>
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Online-Offline')
                && _screenFilterOptions.find( item => item.label === 'Online-Offline').isChecked
                &&  <OnlineOfflineFilter />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Activity-Type')
                && _screenFilterOptions.find( item => item.label === 'Activity-Type').isChecked
                &&  <ActivityTypeFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Classrooms')
                && _screenFilterOptions.find( item => item.label === 'Classrooms').isChecked
                &&  <ClassroomFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Languages')
                && _screenFilterOptions.find( item => item.label === 'Languages').isChecked
                // &&  LanguageFilterComponent()
                &&  <LanguageFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Platform')
                && _screenFilterOptions.find( item => item.label === 'Platform').isChecked
                &&  <PlatformFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Hosts')
                && _screenFilterOptions.find( item => item.label === 'Hosts').isChecked
                &&  <HostFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Since-Date')
                && _screenFilterOptions.find( item => item.label === 'Since-Date').isChecked
                &&  <SinceDateFilterComponent />
            }
            {
              _screenFilterOptions 
                && _screenFilterOptions.find( item => item.label === 'Until-Date')
                && _screenFilterOptions.find( item => item.label === 'Until-Date').isChecked
                &&  <UntilDateFilterComponent />
            }           
            
            
          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}

        {
          _loading 
            ?
            (
              <ScreenCircularProgress />
            )
            :
            (
              <>
                {
                  (
                    _loading !== true && isPaginationLoading !== true && isFilteringLoading !== true &&
                    userActivityList && userActivityList.length === 0
                  )
                  ?
                  (
                    <Grid item className='w-100 py-5'>
                        <h4 className='text-center'>No User Activity!</h4>
                    </Grid>
                  )
                  :
                  (
                    <>
                      {/* Table */}
                      <CustomTable 
                        // stickyHeader={false}
                      >
                        <UserActivityTableHead/>
                        <TableBody>
                          {
                            isPaginationLoading !== true && isFilteringLoading !== true &&
                            userActivityList && userActivityList.map(
                              (userActivityItem, ind) => (
                                <UserActivityItem
                                  key={ind}
                                  userActivityItem={userActivityItem  }
                                />
                              )
                            )
                          }
                        </TableBody>
                      </CustomTable>
                      {/* End of Table */}

                      {
                        (isPaginationLoading || isFilteringLoading) && <ScreenCircularProgress />
                      }

                      <CustomPagination
                        currentPaginationIndex={paginationPage}
                        handleOnChange={( e, page ) => {
                          if( page === paginationPage  || isPaginationLoading  ) {
                            return;
                          }
                          dispatch(
                            setIsPaginationLoading(true)
                          );
                          dispatch(
                            setPaginationPage(page)
                          );
                          dispatch(
                            fetchUserActivityList({
                              userId,
                              cb: apiResponse => {
                                dispatch(
                                  setIsPaginationLoading(false)
                                );
                              },
                              filteringParameters,
                              limit: pageFilterCount,
                              order: order,
                              skip: ( page - 1  ) * pageFilterCount
                            })
                          );

                        }}
                        isPaginationLoading={ isPaginationLoading }
                        itemCount={60}
                        isScreenLoading={false}

                      />
                    </>
                  )
                }

              </>
            )
        }

      </Grid>
    </Container>
  )
}


