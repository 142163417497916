import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'




// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




export const ContactMessagesTableHead = () => {


  return (
    <TableHead id='contact-message-screen-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'><span className='ml-3'>User</span></StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Message</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Dismissed</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
