import {Button, Container, Grid, TableBody} from "@material-ui/core";
import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import {ScreenTitle} from "../../components/title/ScreenTitle";

import React, {useCallback, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {fetchSubscriptionClassList} from "../../actions/subscriptionClassActions/fetchSubscriptionClassList";
import {SimpleCustomSelect} from "../../components/form/SimpleCustomSelect";
import axios from "axios";

export const SubscriptionsAddScreen = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        await axios.post(urlConstants.addSubscription, formData, clientAppConstants.jsonConfig);
        setFormData({});
    }, [formData]);
    const subscriptionClassList = useSelector(state => state.subscriptionClassReducer.subscriptionClassList);
    const order = useSelector(state => state.filterReducer.order);

    useEffect(() => {
        dispatch(
            fetchSubscriptionClassList({
                limit: 100,
                skip: 0,
                filteringParameters : {},
                order,
                cb: apiResponse => {
                    setLoading(false);
                    console.log('SubscriptionPackageScreen -> fetchClassroomList -> apiResponse -> ', apiResponse)
                }
            })
        )
    }, [])
    return (
        <Container
            id='speaklabs-subscription-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Create subscription</title></Helmet>
            <div

            >
                <ScreenTitle   titleText='Subscriptions'    />
                <form
                    className='w-100'
                    onSubmit={onSubmit}
                >
                    <div className='w-100 pt-1 '>
                        <TextField
                            variant='standard'
                            label='User email'
                            placeholder='User email'
                            required={true}
                            fullWidth
                            value={formData.email}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    email: e.target.value
                                });
                            }}
                        />
                    </div>
                    <div className='w-100 pt-1 '>
                        <TextField
                            variant='standard'
                            label='Subscription ID/Token'
                            placeholder='Subscription ID/Token'
                            required={true}
                            fullWidth
                            value={formData.token}
                            onChange={e => {
                                setFormData({
                                    ...formData,
                                    token: e.target.value
                                });
                            }}
                        />
                    </div>
                    <div className='w-100 pt-1 '>
                        <SimpleCustomSelect
                            fullWidth
                            label='Platform'
                            // fullWidth={true}
                            htmlId='add-event-screen-online-offline'
                            isNoneExist={ false }
                            required={true}
                            // noneText={'Select'}
                            value={ formData.platform   }
                            optionList={[
                                { value: 'paypal', definition: 'paypal' },
                                { value: 'stripe', definition: 'stripe' },
                                { value: 'tinkoff', definition: 'tinkoff' },
                                { value: 'robokassa', definition: 'robokassa' },
                                { value: 'inapp', definition: 'inapp' },
                                { value: 'unknown', definition: 'unknown' },
                                { value: 'panel', definition: 'panel' },
                                { value: 'subscription', definition: 'subscription' },
                            ]}
                            onChange={ e => {
                                setFormData({ ...formData, platform:  e.target.value }) ;
                            } }
                            inputPropsId={'subscription-add-edit-screen-online-offline-select'}
                        />
                    </div>
                    <div className='w-100 pt-1 '>
                        <SimpleCustomSelect
                            fullWidth
                            label='Subscription Class'
                            // fullWidth={true}
                            htmlId='add-event-screen-online-offline'
                            isNoneExist={ false }
                            required={true}
                            // noneText={'Select'}
                            value={ formData.subscriptionClassId   }
                            optionList={subscriptionClassList.map(item => ({
                                value: item._id,
                                definition: item.subscriptionTitle,
                            }))}
                            onChange={ e => {
                                setFormData({ ...formData, subscriptionClassId:  e.target.value }) ;
                            } }
                            inputPropsId={'subscription-add-edit-screen-online-offline-select'}
                        />
                    </div>
                    <div className='w-100 pt-1 '>
                        <Button color="primary" variant="contained" type="submit">Submit</Button>
                    </div>
                </form>
            </div>
        </Container>
    );
}