

import {  USERS_TICKET_LIST_FETCHED } from "../types";





export const clearUserTicketListInfo = () => async dispatch => {
  dispatch({
    type: USERS_TICKET_LIST_FETCHED,
    payload: null
  });
}  




