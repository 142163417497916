

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_LOCATIONS_FILTER } from "../types";





export const setLocationsFilter = ({  
  newLocationFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_LOCATIONS_FILTER,
    payload: {
      isReset,
      newLocationFilterList: [ ...newLocationFilterList ]
    }
  });
}  




