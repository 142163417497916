import React from 'react'

// MUI
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';




export const DashboardStat2 = ({
  // percentage= -23.50,
  // changeNumber= 450,
  // title='Last 30 Days Login',
  // value,
  //---------
  percentage= 23.50,
  totalQuantity= 1800,
  title='Last 30 Days Login',
  currentQuantity= 450,
  tooltipExplanation
}) => {
  return (
    <Tooltip title={ tooltipExplanation ? tooltipExplanation : title } arrow>
      <Stack direction='column'>
        <Typography 
          // variant="h6" 
          fontSize={15}
          fontWeight={'500'}
          color="primary" 
          noWrap
        >
          {title}
        </Typography>
        <Stack 
          direction='row'  
          alignContent='end' 
          alignItems='flex-end' 
          justifyContent='flex-start'
        >
          <Typography fontSize={28} lineHeight={1}>
            %{percentage}
          </Typography>

          <Typography color={ "warning.main" } fontSize={14}>
            {/* &nbsp;{percentage ? "+" : "-"} */}
            &nbsp;{ '(' +  currentQuantity +  ' of ' + totalQuantity  + ')' }
          </Typography>


        </Stack>

      </Stack>
    </Tooltip>
  )
}
