import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { 
  INTEREST_DELETED
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";






export const deleteCity = ({
  cityId,
  cb  // callBack
}) => async dispatch => {

  try {

    
 
    const res = await axios.delete(
      urlConstants.deleteCitytUrl(cityId), 
      clientAppConstants.jsonConfig
    ); 
    if ( cb ) {      
      cb(res.data);      
    }
    // dispatch({
    //   type: INTEREST_DELETED,
    //   payload: res.data.interest
    // });
    dispatch(setAlert(res.data.msg, "success", 2500));




    
  } catch (err) {

    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
} 