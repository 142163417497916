import {z} from "zod";

export const eventValidationSchema = z.object({
    classroomId: z.string(),
    eventCreationDate: z.date(),
    eventDate: z.date(),
    timezone: z.number(),
    isEventOnline: z.boolean(),
    onlineEventVerticalImageId: z.optional(z.string()),
    onlineEventHorizontalImageId: z.optional(z.string()),
    offlineEventImageId: z.optional(z.string()),
    language: z.string(),
    level: z.string(),
    duration: z.number().min(0),
    maxAttendee: z.number().min(0),
    eventHost: z.optional(z.string()),
    eventTitle: z.string(),
    eventBoldText: z.optional(z.string()),
    eventRegularText: z.optional(z.string()),
    offlineEventListShortDefinition: z.optional(z.string()),
    eventInterestId: z.string(),
    freeEvent: z.boolean(),
    isTicketBySubscriptionPossible: z.boolean(),
    ticketPrice: z.number(),
    countryId: z.optional(z.string()),
    cityId: z.optional(z.string()),
    address: z.optional(z.string()),
    videoLink: z.optional(z.string()),
    eventLinkCreationDate: z.optional(z.string()),
    isCanceled: z.optional(z.boolean()),
    isClassroom: z.optional(z.boolean()),
    commission: z.object({
        type: z.enum(['fixed', 'percent']),
        amount: z.number(),
    }),
    reviews: z.optional(z.array(z.object({
        userId: z.string(),
        createdAt: z.date(),
        review: z.number().min(0).max(5),
    })))
}).required();

export const eventOnlineValidationSchema = eventValidationSchema.extend({
    videoLink: z.string(),
    eventBoldText: z.string(),
    eventRegularText: z.string(),
});

export const eventOfflineValidationSchema = eventValidationSchema.extend({
    countryId: z.string(),
    cityId: z.string(),
    address: z.string(),
    offlineEventListShortDefinition: z.string(),
});

export const classRoomOnlineSchema = eventOnlineValidationSchema.extend({
    eventDate: z.void(),
    classroomId: z.void(),
});

export const classRoomOfflineSchema = eventOfflineValidationSchema.extend({
    eventDate: z.void(),
    classroomId: z.void(),
});
