






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  RESET_ACTIVITY_REDUCER } from "../types";





export const resetActivityReducer = () => async dispatch => {
  dispatch({
    type: RESET_ACTIVITY_REDUCER,
    payload: null
  });
}  




