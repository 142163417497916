

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_LANGUAGES_FILTER } from "../types";





export const setLanguagesFilter = ({  
  newLanguageFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_LANGUAGES_FILTER,
    payload: {
      isReset,
      newLanguageFilterList: [ ...newLanguageFilterList ]
    }
  });
}  




