import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';


import {
  // Container,
  CircularProgress,
  Button,
  TableContainer,
  Table,
  TableBody,
  Paper,
  // Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { AudienceAccordingToAge } from './charts/AudienceAccordingToAge';
import { BarChartAgeDistribution } from './charts/BarChartAgeDistribution';
import { BarChartLanguageDistribution } from './charts/BarChartLanguageDistribution';
import { BarChartInterestDistribution } from './charts/BarChartInterestDistribution';
import { DashboardStat1 } from './statistics/DashboardStat1';
import { StatisticsComponent } from './statistics/StatisticsComponent';
import { BarChartPopularLocations } from './charts/BarChartPopularLocations';
import { getDashboardStatisticData } from '../../actions/statisticActions/getDashboardStatisticData';



export const DashboardScreen = () => {
    // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE

  const [ formData, setFormData ] = useState({
    percentageOfSubscribers: null,
    audienceAccordingToLanguage: null,
    audienceAccordingToAge: null,
    audienceAccordingToInterest: null,
    topPopularLocations: null,
  })

  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});

      dispatch(
        getDashboardStatisticData({
          cb: apiResponse => {
            console.log('DashboardScreen -> getDashboardStatisticData -> apiResponse -> ', apiResponse);
            setFormData(apiResponse);
            _setLoading(false);
          }
        })
      );
      // _setLoading(false);
      console.log('window.innerWidth -> , ', window.innerWidth)
    }, []
  );


  return (
    <Container
      maxWidth='lg'
      className='w-100 pb-4'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
      id='user-edit-screen-container'
    >
      {/* <Helmet defer={false}><title>{sponsorId ? 'Sponsor Guncelle' : 'Sponsor Ekle'}</title></Helmet> */}
      <Grid container className='w-100'>
        <ScreenTitle titleText={'Dashboard'} />
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100' sm={12}>
              <Grid container className='w-100' sm={12}>

                {/* CHARTS */}
                {/* <Grid item md={6}>
                  <AudienceAccordingToAge />
                </Grid> */}
                <Grid item md={6} className='pt-1'>
                  <BarChartAgeDistribution 
                    audienceAccordingToAge={formData.audienceAccordingToAge}
                  /> 
                </Grid>
                <Grid item md={6} className='pt-1'>
                  <BarChartLanguageDistribution 
                    audienceAccordingToLanguage={ formData.audienceAccordingToLanguage}
                    /> 
                </Grid>
                <Grid item sm={12} className='pt-1'>
                  <BarChartInterestDistribution 
                    audienceAccordingToInterest={ formData.audienceAccordingToInterest}
                  /> 
                </Grid>
                {/* <Grid item className='pt-6' md={6}>
                <AudienceAccordingToAge />
                </Grid> */}
                <Grid item md={6} sm={12} className='pt-1'>

                  <BarChartPopularLocations
                    topPopularLocations={formData.topPopularLocations}
                  />
                </Grid>
                {/* End of CHARTS */}


                {/* STATS */}
                <Grid item sm={12} md={12} lg={6} className='pt-1'>
                  {/* <DashboardStat1 


                  /> */}
                  <StatisticsComponent
                    percentageOfSubscribers={formData.percentageOfSubscribers}
                  />
                </Grid>
                {/* End of STATS */}

              </Grid>

              
              
            </Grid>
          )
        }
      </Grid>
      
    </Container>
  )
}
