import {EventForm} from "../event/EventForm";
import {Container} from "@material-ui/core";
import {clientAppConstants} from "../../constants";
import Helmet from "react-helmet";
import React from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {useHistory} from "react-router-dom";

export const CreateClassRoom = () => {
    const history = useHistory();
    return (
        <Container
            id='user-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Create classroom</title></Helmet>
            <ScreenTitle
                titleText="Create classroom"
            />
            <EventForm isClassroom={true} onSuccessfulSubmit={() => {
                history.push('/classroom');
            }} />
        </Container>
    );
}