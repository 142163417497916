import React, { useState } from 'react'
import{  NavLink ,withRouter, useHistory} from 'react-router-dom'




// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// CSS
import './SubscriptionTicketItem.css'

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { TextButtonForTableCell } from '../../components/buttons/TextButtonForTableCell';
 

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';



export const SubscriptionTicketItem = ({
  ticketItem
}) => {
  const history = useHistory();

  const [ isModalOpen, setIsModalOpen ] = useState(false);

  // {
  //   eventTitle: 'French Speaking Night',
  //   isEventOnline: true,
  //   subscriptionTitle: '-',
  //   eventDate: Date.now(),
  //   purchasePlatform: [
  //     'paypal', 'admin-panel'
  //   ],
  //   payment: 4.00,
  //   ticketStatus: 'Awaiting Event'
  // }


  const {
    eventId,
    ticketId,
    ticketCreationDate,
  } = ticketItem;

  const {
    ticketStatus,
  } = ticketId;

  const {
    eventDate,
    eventHost,
    eventTitle,
    isEventOnline,
    language,
  } = eventId;

  const {
    email,
    nameSurname
  } = eventHost;

  const {
    languageTitle,
  } = language;

  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >        
        <TextButtonForTableCell 
          to={'/event/edit/' + eventId._id } 
          buttonText={ eventTitle }
        />
      </StyledTableCell>

      {/* <StyledTableCell
        size='small'
      >
        {ticketItem.isEventOnline ? 'Online' : 'Offline'}
      </StyledTableCell> */}

      <StyledTableCell
        size='small'
      >
       <TextButtonForTableCell 
          to={'/user/edit/' + eventHost._id} 
          buttonText={  nameSurname ?? email ?? '-'}
        />
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        { convertToMomentDateTime( eventDate )  }
      </StyledTableCell>

      <StyledTableCell
        size='small'
      >
        { isEventOnline ? 'Online' : 'Offline'}
      </StyledTableCell>

      <StyledTableCell
        size='small'
      >
        { languageTitle }
      </StyledTableCell>

      


      {/* Ticket Count */}
      <StyledTableCell
        size='small'
        align='right'
      >
        {ticketStatus}
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        <IconButton component={ NavLink} to={'/ticket/edit/' + ticketId._id}>
          <MoreHorizIcon />
        </IconButton>
      </StyledTableCell>
          
      
      
    </StyledTableRow>
  )
}
