import React from 'react'
import{  NavLink , } from 'react-router-dom'



// Constants
import { clientAppConstants } from '../../constants'

// MUI
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// CSS
import './EventTicketItem.css'

// Components
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { TextButtonForTableCell } from '../../components/buttons/TextButtonForTableCell';
 

// Helpers




export const EventTicketItem = ({
  ticketItem
}) => {


  const {
    ticketId,
    userId,
    
  } = ticketItem;

  const {    
    email,
    nameSurname,
  } = userId;

  const isReservation = ticketId ? ticketId.isReservation : ticketItem.isReservation;
  const purchasePlatform = ticketId ? ticketId.purchasePlatform : ticketItem.purchasePlatform;
  const ticketPrice = ticketId ? ticketId.ticketPrice : ticketItem.ticketPrice;
  const ticketStatus = ticketId ? ticketId.ticketStatus : ticketItem.ticketStatus;
  const subscriptionId = ticketId ? ticketId.subscriptionId : ticketItem.subscriptionId;
  // const {
  //   isReservation,
  //   purchasePlatform,
  //   ticketPrice,
  //   ticketStatus,
  // } = ticketId;


  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >        
        <TextButtonForTableCell 
          to={'/user/edit/' + userId._id } 
          buttonText={
            nameSurname ?? email
          }
        />
      </StyledTableCell>



      <StyledTableCell
        size='small'
      >
       {subscriptionId ? subscriptionId.subscriptionTitle : '-'}
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        { isReservation ? 'Reservation' : 'Ticket'  }
      </StyledTableCell>

      <StyledTableCell
        align='center'
        size='small'
      >
        {
          (() => {
            let _color;
            switch (purchasePlatform) {
              case clientAppConstants.platformOptionsObject.PAYPAL:
                _color = "primary";
                break;
              case clientAppConstants.platformOptionsObject.ADMIN_PANEL:
                _color = "success";
                break;
              case clientAppConstants.platformOptionsObject.SUBSCRIPTION:
                _color = "error";
                break;
              default:
                _color = 'error';
                
            }
            return (
              <Chip className='speaklabs-activity-platform-chip' label={purchasePlatform} color={_color} />
            )
          })()
          
        }
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='right'
      >
        {ticketPrice ? ('$' + ticketPrice.toFixed(2) ) : '-'}
      </StyledTableCell>

      {/* Ticket Count */}
      <StyledTableCell
        size='small'
        align='center'
      >
        {ticketStatus}
      </StyledTableCell>

      <StyledTableCell
        size='small'
        align='center'
      >
        <IconButton component={ NavLink} to={'/ticket/edit/' + (ticketId ?  ticketId._id : ticketItem._id)}>
          <MoreHorizIcon />
        </IconButton>
      </StyledTableCell>
          
      
      
    </StyledTableRow>
  )
}
