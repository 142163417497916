import React from 'react'


// MUI
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import { urlConstants } from '../../constants';



export const UserTopBar = ({
  userAvatar,  // MongoDB Avatar ID
  userAvatarImage,
  socialAuthImageUrl,
  nameSurname,
  additionalTitle,
  size
}) => {


  return (
    <Grid className='pt-2' container spacing={0}>
      <Grid direction='row' alignItems='center' justify='flex-start' container> 
        <Grid item>
          <Avatar 
            sizes= {!size ? 'large' : size}
            style={{ marginLeft: '2rem', marginRight:'2rem' }}
            // className={classes.largeAvatar}
            alt={nameSurname} 
            sx={{ width: 120, height: 120 }}
            src= {
              userAvatar 
                ? urlConstants.getSingleUserAvatar( userAvatar )
                :
                (
                  userAvatarImage || socialAuthImageUrl
                )
            } 
          /> 
        </Grid> 
        <Grid item>
          <Container maxWidth='lg'>
            <h2 className='text-center mbs-0 pt-1  mb-1 w-100'>
              {
                nameSurname 
              }
              {
                additionalTitle && ` / ${additionalTitle}`
              }
              </h2>
          </Container>
        </Grid> 
      </Grid>
    </Grid>
  )
}
