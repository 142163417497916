import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'




// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export const UserTableHead = () => {


  return (
    <TableHead id='user-screen-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>User</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Login Method</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Phone number</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Birthdate</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Balance</StyledTableCell>
        <StyledTableCell size= 'small' align='right'>Last Month Purchase</StyledTableCell>
        <StyledTableCell size= 'small' align='right'>Last Month Transaction Count</StyledTableCell>
        <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
