// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_ACTIVITY_TYPE_FILTER } from "../types";





export const setActivityTypeFilter = ({  
  newActivityTypeFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_ACTIVITY_TYPE_FILTER,
    payload: {
      isReset,
      newActivityTypeFilterList: [ ...newActivityTypeFilterList ]
    }
  });
}  