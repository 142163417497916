






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_IS_DISMISSED_FILTER } from "../types";





export const setIsDismissedFilter = ({  
  newIsDismissedFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_IS_DISMISSED_FILTER,
    payload: {
      isReset,
      newIsDismissedFilterList: [ ...newIsDismissedFilterList ]
    }
  });
}  




