import React, { useState, useEffect } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Helmet from 'react-helmet';
import axios from "axios";



import {
  Button,
  CircularProgress,
  Container,
  TextField,
} from '@material-ui/core';
import {   makeStyles } from '@material-ui/core/styles';

// Constants
import { clientAppConstants } from '../../constants';
import { setAlert } from '../../actions/alertActions/setAlert';
import { login } from '../../actions/authActions/login';
import { loadAdmin } from '../../actions/authActions/loadAdmin';

export const AdminLoginScreen = ({
  
}) => {

  // Global Variables
  const loading = useSelector(state => state.authReducer.loading);
  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  const user =  useSelector(state => state.authReducer.user); 
  const userType =  useSelector(state => state.authReducer.userType); 

  const dispatch = useDispatch();



  const [formData, setFormData] = useState({
    // username: "",
    email: "",
    password: ""
  });

  const { 
    // username, 
    email, 
    password 
  } = formData;
  const _textFieldMinWidth = 350;


  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    }
  }));
  const classes = useStyles();

  useEffect(() => {
      dispatch(
        loadAdmin()
      )
    }, 
    // eslint-disable-next-line
    []
  );

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const onSubmit = async e => {
    e.preventDefault();
    // if ( !_isConfirmedFormRead ) {
    //   setAlert('Lutfen Formu Onaylayiniz!', 'error');
    //   return;
    // }
    // dispatch(
    //   setAlert('Please set Alert', 'error')
    // )
    // login({formData});
    dispatch(
      login({
        formData
      })
    )
    // console.log('SUCCESS');
  };


  // Redirect if Authenticated
  if (isAuthenticated     
    && !loading && user) {
    // console.log(isAuthenticated, userType, loading);
    if (   userType === "admin" ||  userType === "superAdmin"  ) {
      return <Redirect to='/dashboard' />;
    }
    if ( userType === "editor" || userType === "writer" ) {
      return <Redirect to='/mani' />;
    }
  }

  return (
    <Container
      maxWidth='lg'
      style={{
        // backgroundColor:'#ccc',
        // paddingTop: 64,
        minHeight:'90vh'
      }}    
    >
      <Helmet defer={false}><title>Login</title></Helmet>
      <div 
        className='flexrow justify-content-center' 
        style={{minHeight:'90vh'}}
      >
        <div 
          className='flexcol h-100 justify-content-center'
          style={{minHeight:'90vh'}}
        >
          {
            loading 
              ? <CircularProgress />
              : (

                <form className='col s12' onSubmit={e => onSubmit(e)}>
                  <h1 className='text-center mb-2'>Admin Login</h1>
                  {/* <div className="mb-2" style={{minWidth: _textFieldMinWidth}}>
                    <TextField
                      placeholder='Username'
                      label='Username'
                      required
                      id='userName'
                      name='username'
                      value={username}
                      onChange={e => onChange(e)}
                      variant='outlined'
                      fullWidth={true}
                    />
                  </div> */}
                  <div className="mb-2" style={{minWidth: _textFieldMinWidth}}>
                    <TextField
                      placeholder='Email'
                      label='Email'
                      required
                      id='email'
                      name='email'
                      value={email}
                      type='email'
                      onChange={e => onChange(e)}
                      variant='outlined'
                      fullWidth={true}
                    />
                  </div>            
                  <div className="mb-2" style={{minWidth: _textFieldMinWidth}}>
                    <TextField
                      placeholder='Enter Password'
                      label='Password'
                      required
                      id='password'
                      name='password'
                      value={password}
                      type='password'
                      onChange={e => onChange(e)}
                      variant='outlined'
                      fullWidth={true}
                    />
                  </div>             
                  {/* <div className='mb-1 mt-1'>
                    <a href='#'>Sifremi Unuttum?</a>
                  </div> */}
                      

                  {/* <Button
                    variant='contained'
                    color='secondary'
                    fullWidth
                    type='submit'
                  >
                    Login
                  </Button> */}
                  <Button
                    variant='contained'
                    color='secondary'
                    fullWidth
                    type='submit'
                    // onClick={() => {}

                    // }
                  >
                    Login
                  </Button>

                  <Button
                    variant='text'
                    color='secondary'
                    onClick={async () => {
                      const confirm = window.confirm("Are you sure you want to reset password?");
                      if(confirm) {
                        const response = await axios.post("/api/admin/auth/reset-main-admin-password");
                        dispatch(setAlert('New password has been sent to your email.', 'success', 2500));
                      }
                    }}
                  >
                    Reset Password
                  </Button>
                </form>
              )
          }
        </div>
      </div>
    </Container>
  )
}

export default AdminLoginScreen;
