import React, { useEffect,  useState } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import {   Redirect, NavLink } from 'react-router-dom';
import moment from 'moment';
import Helmet from 'react-helmet';


// Actions
import { fetchClassroomAddEditEntities } from '../../actions/classroomActions/fetchClassroomAddEditEntities';
import { createEventViaClassroom } from '../../actions/eventActions/createEventViaClassroom';
import { updateEvent } from '../../actions/eventActions/updateEvent';
import { fetchEventAddEditEntities } from '../../actions/eventActions/fetchEventAddEditEntities';

import './EventAddEditScreen.css'



import {
  Container,
} from '@material-ui/core';

// MUI
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import SendIcon from '@mui/icons-material/Send';
import ModeEditIcon from '@mui/icons-material/ModeEdit';


// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { SimpleCustomSelect } from '../../components/form/SimpleCustomSelect';
import { CustomDateTime2 } from '../../components/form/CustomDateTime2';
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import CustomTextAreaAutoSize from '../../components/form/CustomTextAreaAutoSize';
import { HostSelectionComponent } from '../../components/classroom/HostSelectionComponent';
import { RegistrationSetComponent } from '../../components/classroom/RegistrationSetComponent';
import { TicketSetComponent } from '../../components/classroom/TicketSetComponent';
import { ClassroomImageShowComponent } from '../../components/classroom/ClassroomImageShowComponent';
import { ClassroomImageSelectorComponent } from '../../components/classroom/ClassroomImageSelectorComponent';
import { EventDetailTable } from './EventDetailTable';
import { EventAddEditScreenBottomButtons } from './EventAddEditScreenBottomButtons';

// import DatePicker from '../../components/datePicker/DatePicker'
// import SelectedCategoryList from './SelectedCategoryList';


// Constants
import { clientAppConstants } from '../../constants';
// import { setAlert } from '../../actions/alertActions';





// Helpers
import { convertToIntegerGreaterThanOrEqualToMinValue } from '../../helpers/common/convertToIntegerGreaterThanOrEqualToMinValue';
import { uint8ArrayToImageSource } from '../../helpers/file/uint8ArrayToImageSource';
import { convertInputToCurrencyText } from '../../helpers/common/convertInputToCurrencyText';
import { detectMyTimezoneHelper } from '../../helpers/dateAndTime/detectMyTimezoneHelper';
import { addSubtractHourToDate } from '../../helpers/dateAndTime/addSubtractHourToDate';


// import { uint8ArrayToImageSource } from '../../utils/helpers';



export const EventAddEditScreen = ({
    // Location, History, etc
    match, // location -> match.params.eventId
    history,
}) => {
    // Global Variables
    // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
    // const appInitialised = useSelector(state => state.authReducer.appInitialised);
    // const userType = useSelector(state => state.authReducer.userType);

    const dispatch = useDispatch();

    const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
    const [eventId, setEventId ] = useState( match.params.eventId || null );
    const [classroomId, setClassroomId ] = useState( match.params.classroomId || null );
    const [formData, setFormData] = useState(null);

    const [ _languageList, _setLanguageList ] = useState([])
    const [ _interestList, _setInterestList ] = useState([])
    const [ _hostList, _setHostList ] = useState([])
    const [ _countryList, _setCountryList ] = useState([])
    const [ _cityList, _setCityList ] = useState([])


    const [ _cityListForSelectedCountry, _setCityListForSelectedCountry ] = useState([])





    const [ _isAddingOrUpdating, _setIsAddingOrUpdating] = useState(false);


    const [ _isEditMode, _setIsEditMode ] = useState(
      (classroomId && !eventId )
        ?   true
        :   false
    );


    const _initialOfflineEventState = {
      offlineEventImageId: null,
      offlineEventImage: null,
      isOfflineEventImageBuffer: false,
      isOfflineEventImageUpdated: false,

      offlineEventListShortDefinition: '',  // this is short def. on Mobile, where we list event list...

      eventInterestId: '',  // interestId
      offlineEventType: '',  
      offlineEventLocation: '',

      countryId: '', 
      cityId: '',
      countryEn: '', // Country in English Lang
      cityTitle: '',
      address: '',
    }


    const _initialOnlineEventState = {
      onlineEventVerticalImageId: null,
      onlineEventVerticalImage: null,
      isOnlineEventVerticalImageBuffer: false,
      isOnlineEventVerticalImageUpdated: false,
      //---------------------------------
      onlineEventHorizontalImageId: null,
      onlineEventHorizontalImage: null,
      isOnlineEventHorizontalImageBuffer: false,
      isOnlineEventHorizontalImageUpdated: false,
      videoLink: '',
    }



    const _registrationEventState = {
      isRegistrationPossible: false,
      isRegistrationFree: false,
      registrationFee: '2.00',
      //-----------------
      isNoTicketEvent: false,  // this is for offline events, where users pay money to place. we are only providing information about event
      eventOrganiserPrice: '2.00',  // this is price that people pay at entrance, not related with our app, we are only showing to inform users
      //-----------------
      ticketPrice: '2.00',
    };


    const _initialAddEventFormState = {
      isEventOnline: null,
      language: '',
      level: '',

      ..._initialOfflineEventState,

      ..._initialOnlineEventState,


      ..._registrationEventState,
      


      eventDate: null,
      timezone: detectMyTimezoneHelper(),
      clientTimeZone: detectMyTimezoneHelper(),

      isTicketBySubscriptionPossible: false,



      duration: null,
      maxAttendee: null,
      attendeeCount: 0,
      ticketOwnerList: [],
      ticketCount: null,
      registratorList: [],
      ticketList: [
        { ticketId: '', userId: ''   },
      ],
      eventHost: '',  // // host ID
      date: Date.now(),
      eventTitle: '',
      eventBoldText: '',
      eventRegularText: '',
      offlineEventType: '',
      offlineEventLocation: '',
    }  // End of _initialAddEventFormState


    useEffect(
      () => {
        setFormData({..._initialAddEventFormState});
        if ( classroomId && !eventId ) {  // Creating Event from Classroom
          dispatch(
            fetchClassroomAddEditEntities({
              classroomId,
              cb: apiResponse => {
                _cbFetchClassroomAddEditEntities(apiResponse);
              }
            })
          )
        } else if ( eventId && !classroomId ) {  // View Existing Event's Details
          dispatch(
            fetchEventAddEditEntities({
              eventId,
              cb :apiResponse => {
                _cbViewExistingEventDetails(apiResponse);
              }
            })
          );
        }        
        else {
          _setLoading(false);
        }
        
      }, []
    );



    const _cbViewExistingEventDetails = apiResponse => {
      if ( apiResponse ) {
        const {
          existingEvent,
            ticketsCount,
        } = apiResponse;
        _setHostList(apiResponse.hostList);
        _setInterestList(apiResponse.interestList);
        _setLanguageList(apiResponse.languageList);
        if ( existingEvent ) {
          setFormData({
            ..._initialAddEventFormState,
              ticketCount: ticketsCount,
            //----------------------------------------------------------------
            ...existingEvent,
            //----------------------------------------------------------------

            eventHostDocument: existingEvent.eventHost ?? null,
            eventHost: existingEvent.eventHost ? existingEvent.eventHost._id : '',


            ticketPrice: existingEvent.ticketPrice 
              ?  convertInputToCurrencyText({inputValue: existingEvent.ticketPrice.toFixed(2) })
              :  _registrationEventState.ticketPrice,
            registrationFee: existingEvent.registrationFee
              ?  convertInputToCurrencyText({inputValue: existingEvent.registrationFee.toFixed(2) })
              :  _registrationEventState.registrationFee ,
            eventOrganiserPrice: existingEvent.eventOrganiserPrice
              ?  convertInputToCurrencyText({inputValue: existingEvent.eventOrganiserPrice.toFixed(2) })
              :  _registrationEventState.eventOrganiserPrice ,

            // Online Vertical Image
            onlineEventVerticalImage: existingEvent.onlineEventVerticalImageId 
              ?
              uint8ArrayToImageSource( existingEvent.onlineEventVerticalImageId.image.data  )
              :
              _initialAddEventFormState.onlineEventVerticalImage,
            onlineEventVerticalImageId: existingEvent.onlineEventVerticalImageId 
              ? existingEvent.onlineEventVerticalImageId._id
              : _initialOnlineEventState.onlineEventVerticalImageId,
            isOnlineEventVerticalImageBuffer: true,
            isOnlineEventVerticalImageUpdated: false,

            // Online Horizontal Image
            onlineEventHorizontalImage: existingEvent.onlineEventHorizontalImageId 
              ?
              uint8ArrayToImageSource( existingEvent.onlineEventHorizontalImageId.image.data  )
              :
              _initialAddEventFormState.onlineEventHorizontalImage,
            onlineEventHorizontalImageId:     existingEvent.onlineEventHorizontalImageId 
              ? existingEvent.onlineEventHorizontalImageId._id
              : _initialOnlineEventState.onlineEventHorizontalImageId,
            isOnlineEventHorizontalImageBuffer: true,
            isOnlineEventHorizontalImageUpdated: false,

            // Offline Event Image    
            offlineEventImage: existingEvent.offlineEventImageId 
              ?
              uint8ArrayToImageSource( existingEvent.offlineEventImageId.image.data  )
              :
              _initialAddEventFormState.offlineEventImage,
            offlineEventImageId: existingEvent.offlineEventImageId 
              ? existingEvent.offlineEventImageId._id
              : _initialOfflineEventState.offlineEventImageId,
            isOfflineEventImageBuffer: true,
            isOfflineEventImageUpdated: false,
            // End of Offline Event Image

            // eventDate: new Date(Date.parse(existingEvent.eventDate)),
            eventDate: existingEvent.eventDate,
            timezone: existingEvent.timezone
          });  // End of setFormData          
        }
      }
      _setLoading(false);
    }  // End of _cbViewExistingEventDetails




    const _cbFetchClassroomAddEditEntities = apiResponse => {
      if ( apiResponse ) {
        const {
          classroom,                  
        } = apiResponse;
        _setHostList(apiResponse.hostList);
        _setInterestList(apiResponse.interestList);
        _setLanguageList(apiResponse.languageList);
        _setCountryList(apiResponse.countryList ?? []);
        _setCityList(apiResponse.cityList ?? []);
        if ( classroom ) {
          if ( classroom.isEventOnline === false ) {

          }
          setFormData({
            ..._initialAddEventFormState,
            //----------------------------------------------------------------
            ...classroom,
            //----------------------------------------------------------------

            eventHostDocument: classroom.eventHost ?? null,
            eventHost: classroom.eventHost ? classroom.eventHost._id : '',


            ticketPrice: classroom.ticketPrice 
              ?  convertInputToCurrencyText({inputValue: classroom.ticketPrice.toFixed(2) })
              :  _registrationEventState.ticketPrice,
            registrationFee: classroom.registrationFee
              ?  convertInputToCurrencyText({inputValue: classroom.registrationFee.toFixed(2) })
              :  _registrationEventState.registrationFee ,
            eventOrganiserPrice: classroom.eventOrganiserPrice
              ?  convertInputToCurrencyText({inputValue: classroom.eventOrganiserPrice.toFixed(2) })
              :  _registrationEventState.eventOrganiserPrice ,

            // Online Vertical Image
            onlineEventVerticalImage: classroom.onlineEventVerticalImageId 
              ?
              uint8ArrayToImageSource( classroom.onlineEventVerticalImageId.image.data  )
              :
              _initialAddEventFormState.onlineEventVerticalImage,
            onlineEventVerticalImageId: classroom.onlineEventVerticalImageId 
              ? classroom.onlineEventVerticalImageId._id
              : _initialOnlineEventState.onlineEventVerticalImageId,
            isOnlineEventVerticalImageBuffer: true,
            isOnlineEventVerticalImageUpdated: false,

            // Online Horizontal Image
            onlineEventHorizontalImage: classroom.onlineEventHorizontalImageId 
              ?
              uint8ArrayToImageSource( classroom.onlineEventHorizontalImageId.image.data  )
              :
              _initialAddEventFormState.onlineEventHorizontalImage,
            onlineEventHorizontalImageId:     classroom.onlineEventHorizontalImageId 
              ? classroom.onlineEventHorizontalImageId._id
              : _initialOnlineEventState.onlineEventHorizontalImageId,
            isOnlineEventHorizontalImageBuffer: true,
            isOnlineEventHorizontalImageUpdated: false,

            // Offline Event Image    
            offlineEventImage: classroom.offlineEventImageId 
              ?
              uint8ArrayToImageSource( classroom.offlineEventImageId.image.data  )
              :
              _initialAddEventFormState.offlineEventImage,
            offlineEventImageId: classroom.offlineEventImageId 
              ? classroom.offlineEventImageId._id
              : _initialOfflineEventState.offlineEventImageId,
            isOfflineEventImageBuffer: true,
            isOfflineEventImageUpdated: false,
            // End of Offline Event Image
          })
        }
      }
      _setLoading(false);
    }  // End of _cbFetchClassroomAddEditEntities



    const _cbCreateEventViaClassroom = apiResponse => {
      console.log('HEHEH')
      if ( apiResponse ) {
        const {
          classroom,
          newEvent,
          eventHostDocument,

          onlineEventVerticalImage,
          onlineEventHorizontalImage,
          offlineEventImage,
        } = apiResponse;

        if ( newEvent ) {
          setEventId( newEvent._id );                  
        }
        setFormData({
          ...formData,
          //------------
          ...newEvent,
          //------------
          ticketPrice: newEvent.ticketPrice 
            ?  convertInputToCurrencyText({inputValue: newEvent.ticketPrice.toFixed(2) })
            :  _registrationEventState.ticketPrice,
          registrationFee: newEvent.registrationFee
            ?  convertInputToCurrencyText({inputValue: newEvent.registrationFee.toFixed(2) })
            :  _registrationEventState.registrationFee ,
          eventOrganiserPrice: newEvent.eventOrganiserPrice
            ?  convertInputToCurrencyText({inputValue: newEvent.eventOrganiserPrice.toFixed(2) })
            :  _registrationEventState.eventOrganiserPrice ,


          // Online Vertical Image
          onlineEventVerticalImage: onlineEventVerticalImage 
            ?
            uint8ArrayToImageSource( onlineEventVerticalImage.image.data  )
            :
            formData.onlineEventVerticalImage,
          onlineEventVerticalImageId: onlineEventVerticalImage 
            ? onlineEventVerticalImage._id 
            : formData.onlineEventVerticalImageId,
          isOnlineEventVerticalImageBuffer: true,
          isOnlineEventVerticalImageUpdated: false,

          // Online Horizontal Image
          onlineEventHorizontalImage: onlineEventHorizontalImage 
            ?
            uint8ArrayToImageSource( onlineEventHorizontalImage.image.data  )
            :
            formData.onlineEventHorizontalImage,
          onlineEventHorizontalImageId: 
            onlineEventHorizontalImage 
            ? onlineEventHorizontalImage._id 
            : formData.onlineEventHorizontalImageId,
          isOnlineEventHorizontalImageBuffer: true,
          isOnlineEventHorizontalImageUpdated: false,
          offlineEventImage: offlineEventImage 
            ?
            uint8ArrayToImageSource( offlineEventImage.image.data  )
            :
            formData.offlineEventImage,
          offlineEventImageId: offlineEventImage ? offlineEventImage._id : null,
          isOfflineEventImageBuffer: true,
          isOfflineEventImageUpdated: false,
          // End of Offline Event Image

          // eventDate: new Date(Date.parse(newEvent.eventDate)),
            
          eventDate: newEvent.eventDate,
          timezone: newEvent.timezone,


          eventHostDocument : classroom.eventHost
          ?
          ( 
            eventHostDocument ?? formData.eventHostDocument
          )
          :
          _initialAddEventFormState.eventHostDocument
          ,

        })
      }
      _setIsAddingOrUpdating(false);
    }  // End of _cbCreateEventViaClassroom



    const _onSubmitHandler = (e) => {
      e.preventDefault();
      if (classroomId && !eventId) {
        console.log('Handle Create New Event From Classroom')
        _setIsAddingOrUpdating(true);
        dispatch(
          createEventViaClassroom({
            formData,
            classroomId,
            cb: _cbCreateEventViaClassroom
          })
        );
      } else if ( eventId ) {
        _setIsAddingOrUpdating(true);
        dispatch(
          updateEvent({
            eventId,
            formData,
            cb: apiResponse => {
              console.log('AHAH -> cb -> updateEvent');
              if (
                apiResponse 
                ) {
                const {
                  existingEvent,
                  onlineEventVerticalImage,
                  onlineEventHorizontalImage,
                  offlineEventImage,
                  eventHostDocument,
                } = apiResponse;
                setFormData({
                  ...formData,
                  
                  //----------------------------------------------------------------
                  ...existingEvent,
                  //----------------------------------------------------------------
                  ticketPrice: existingEvent.ticketPrice 
                    ?  convertInputToCurrencyText({inputValue: existingEvent.ticketPrice.toFixed(2) })
                    :  _registrationEventState.ticketPrice,
                  registrationFee: existingEvent.registrationFee
                    ?  convertInputToCurrencyText({inputValue: existingEvent.registrationFee.toFixed(2) })
                    :  _registrationEventState.registrationFee ,
                  eventOrganiserPrice: existingEvent.eventOrganiserPrice
                    ?  convertInputToCurrencyText({inputValue: existingEvent.eventOrganiserPrice.toFixed(2) })
                    :  _registrationEventState.eventOrganiserPrice ,
                    
  
                  // Online Vertical Image
                  onlineEventVerticalImage: onlineEventVerticalImage 
                    ?
                    uint8ArrayToImageSource( onlineEventVerticalImage.image.data  )
                    :
                    formData.onlineEventVerticalImage,
                  onlineEventVerticalImageId: onlineEventVerticalImage 
                    ? onlineEventVerticalImage._id 
                    : formData.onlineEventVerticalImageId,
                  isOnlineEventVerticalImageBuffer: true,
                  isOnlineEventVerticalImageUpdated: false,
  
                  // Online Horizontal Image
                  onlineEventHorizontalImage: onlineEventHorizontalImage 
                    ?
                    uint8ArrayToImageSource( onlineEventHorizontalImage.image.data  )
                    :
                    formData.onlineEventHorizontalImage,
                  onlineEventHorizontalImageId: 
                    onlineEventHorizontalImage 
                    ? onlineEventHorizontalImage._id 
                    : formData.onlineEventHorizontalImageId,
                  isOnlineEventHorizontalImageBuffer: true,
                  isOnlineEventHorizontalImageUpdated: false,
                  offlineEventImage: offlineEventImage 
                    ?
                    uint8ArrayToImageSource( offlineEventImage.image.data  )
                    :
                    formData.offlineEventImage,
                  offlineEventImageId: offlineEventImage ? offlineEventImage._id : null,
                  isOfflineEventImageBuffer: true,
                  isOfflineEventImageUpdated: false,
                  // End of Offline Event Image


                  // eventDate: new Date(Date.parse(existingEvent.eventDate)),
                  eventDate: existingEvent.eventDate,
  
                  eventHostDocument : existingEvent.eventHost
                    ?
                    ( 
                      eventHostDocument ?? formData.eventHostDocument
                    )
                    :
                    _initialAddEventFormState.eventHostDocument
                    ,
                })
              }
              _setIsAddingOrUpdating(false);
            }
          })
        )
      }
    }  // End of _onSubmitHandler


    

  return (
    <Container
      maxWidth='lg'
      className='w-100'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight:clientAppConstants.screenContainerMinHeight
      }}
      id='event-add-edit-screen-container'
    >
      <Helmet 
        defer={false}>
          <title>
            {
            eventId 
              ? 
              (
                ( 
                formData && formData.eventTitle
                )
                ?
                formData.eventTitle
                :
                'Event Title'
              )
              :  
              '' 
            }
          </title>
      </Helmet>
      <Grid container className='w-100'>
        <ScreenTitle 
          titleText={(
            <>
              <span>
                {
                  (classroomId && !eventId) 
                    ? 'Create Event From Classroom' 
                    : 
                    (
                      (eventId  && _isEditMode)
                        ?
                        'Update Event'
                        :
                        'Event Details'
                    )
                }
              </span>
              {
                eventId && (
                  <IconButton
                    onClick={() => {
                      try {
                        history.push('/event/edit/' + eventId);
                      } catch (err) {
                        console.log('err')
                      }
                    }}
                  >
                    <ModeEditIcon 
                      style={{
                        color: _isEditMode ? 'yellow' : 'green'
                      }}
                    />
                  </IconButton>
                )
              }
            </>
          )} 
        />
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100'sm={12}>
              {
                _isEditMode 
                  ?                   
                  (

                    <form                       
                      className='w-100' 
                      onSubmit={_onSubmitHandler}
                      style={{
                        minHeight: `calc( ${clientAppConstants.screenContainerMinHeight} - 57px  )`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent:'space-between'
                      }}
                    >
                      <Grid container className='w-100'sm={12}>

                        {/* //------------------------------------------------------------------------------------------- */}
                        {/* Show Images */}
                        <ClassroomImageShowComponent
                        formData={formData}
                        />
                        {/* End of Show Images */}

                        <Grid item className='w-100  ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            label='Online-Offline'
                            // fullWidth={true}
                            htmlId='add-event-screen-online-offline'
                            isNoneExist={ formData.isEventOnline === null }
                            noneText={'Select'}
                            value={ formData.isEventOnline   }
                            optionList={[{ value: true, definition: 'Online' }, { value: false, definition: 'Offline' }  ]}
                            onChange={ e => {

                              setFormData({ ...formData, isEventOnline:  e.target.value }) ;
                            } }
                            disabled={true}
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />

                        </Grid>

                        {/* Image Selecting */}                        
                        <ClassroomImageSelectorComponent
                          classroomId={classroomId}
                          eventId={eventId}
                          formData={formData}
                          setFormData={setFormData}
                        />
                        {/* End of Image Selecting */}

                        {/* Language */}                        
                        <Grid item className='w-100  ' sm={12} md={6} lg={4}>
                          <SimpleCustomSelect
                            // fullWidth={true}
                            required={true}
                            disabled={ true }
                            label='Language'
                            htmlId={'select-language'}
                            eventTargetNAme={'isEventOnline'}
                            isNoneExist={ formData.language === '' }
                            noneText={'Select'}
                            value={ formData.language   }
                            // optionList={[{ value: 'English', definition: 'English' }, { value: 'French', definition: 'French' }  ]}
                            optionList={[
                              ..._languageList.map(
                                languageItem => {
                                  return {
                                    value: languageItem._id,
                                    definition: languageItem.languageTitle
                                  }
                                }
                              )
                            ]}
                            onChange={ e => {

                              setFormData({ ...formData, language:  e.target.value }) ;
                            } }
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />
                        </Grid>
                        {/* End of Language */}

                        {/* Level */}                        
                        <Grid item
                          className='w-100  '
                          sm={12} md={6} lg={4} alignContent='center'
                          alignItems='center'  justifyContent='center'
                          // style={{backgroundColor:'red'}}
                        >
                          <SimpleCustomSelect
                            // fullWidth={true}

                            label='Level'
                            htmlId={'select-language-level'}
                            eventTargetNAme={'level'}
                            isNoneExist={ formData.level === '' }
                            noneText={'Select'}
                            value={ formData.level   }
                            optionList={ [ ...clientAppConstants.languageLevelOptionList] }
                            onChange={ e => {

                              setFormData({ ...formData, level:  e.target.value }) ;
                            } }
                            inputPropsId={'event-add-edit-screen-event-select'}
                          />
                        </Grid>
                        {/*End of  Level */}


                        {/* CustomDateTime2 */}
                        <Grid item className='w-100 mt-2'
                          // style={{backgroundColor: 'yellow'}}
                          sm={12} md={6} lg={4}
                        >
                          <Stack direction='column'>
                            <FormCustomTitle
                              formText='Event Date & Time'
                            />
                            <CustomDateTime2 
                              required={true} 
                              selectedValue={ formData.eventDate }
                              onChange={ selectedDate => {
                                setFormData({ 
                                  ...formData, 
                                  eventDate: selectedDate
                                });
                              }}
                            />
                          </Stack>
                        </Grid>
                        {/* End of CustomDateTime2 */}


                        {/* Timezone */}   
                        {

                            <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                              <TextField
                              type='number'
                                variant='standard'
                                label='Timezone'
                                placeholder='Please enter Timezone'
                                required={true}
                                fullWidth
                                value={formData.timezone}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    // maxAttendee: convertToIntegerGreaterThanOrEqualToMinValue({ timezone: e.target.value})
                                    timezone: e.target.value
                                  });
                                }}
                              />
                            </Grid>
                        }               
                        {/* End of Timezone */}




                        {/* Duration */}
                        <Grid item  className='w-100 mt-2 pr-3'
                          // style={{backgroundColor: 'pink'}}
                          sm={12} md={6} lg={4}
                        >
                          {/* <TextField
                            variant='standard'
                            label='Duration'
                            placeholder='Please enter Duration'
                            required={true}
                            fullWidth
                            value={formData.duration}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                duration: e.target.value
                              });
                            }}
                          /> */}
                          <FormCustomTitle
                            formText={'Duration: ' + formData.duration + 'min.'}
                          />
                          <Slider
                            className={'mt-1'}
                            step={15}
                            min={15}
                            max= { 600 }
                            value={formData.duration}
                            onChange={ (e, newValue ) => {
                              setFormData({
                                ...formData,
                                duration: newValue
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Duration */}


                        {/* Event Type */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={true}
                                label='Event Type'
                                htmlId={'select-event-type'}
                                eventTargetNAme={'level'}
                                isNoneExist={ formData.eventInterestId === '' }
                                noneText={'Select'}
                                // value={ formData.offlineEventType   }
                                value={ formData.eventInterestId   }
                                optionList={[
                                  ..._interestList.map(
                                    interestItem => {
                                      return {
                                        value: interestItem._id,
                                        definition: interestItem.interestTitle
                                      }
                                    }
                                  )
                                ]}
                                onChange={ e => {
                                  let newInterestId = e.target.value;
                                  setFormData({ 
                                    ...formData, 
                                    eventInterestId: newInterestId,
                                    offlineEventType: _interestList.find(
                                      item => item._id === newInterestId
                                    ).interestTitle  
                                  }) ;
                                } }
                                inputPropsId={'event-add-edit-screen-event-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of Event Type */}


                        {/* Host Selection */}                  
                        <HostSelectionComponent
                          formData={formData}
                          setFormData={setFormData}
                          hostList={_hostList}
                        />
                        {/* End of Host Selection */}


                        {/* Max Attendee */}                  
                        <Grid item  className='w-100 mt-2 pr-3'     sm={12} md={6} lg={4}>
                          <TextField
                          type='number'
                            variant='standard'
                            label='Max Attendee'
                            placeholder='Please enter Max Attendee'
                            required={true}
                            fullWidth
                            value={formData.maxAttendee}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                maxAttendee: convertToIntegerGreaterThanOrEqualToMinValue({ inputValue: e.target.value})
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Max Attendee */}


                        

                        { formData.isEventOnline === false && (
                            <Grid item sm={12} className='mt-2'>
                              <h4 className='text-center mbe-0 mbs-0 pt-2'>Location Information</h4>
                            </Grid>
                          ) 
                        }


                        {/* Country */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={classroomId}
                                label='Country'
                                htmlId={'select-country'}
                                eventTargetNAme={'country'}
                                isNoneExist={ formData.countryId === '' }
                                noneText={'Select'}
                                value={ formData.countryId   }
                                optionList={[
                                  {
                                    value: formData.countryId,
                                    definition: formData.countryEn 
                                  }
                                ]}
                                onChange={ e => {
                                  let newCountryId = e.target.value;
                                  setFormData({ 
                                    ...formData, 
                                    countryId: newCountryId,
                                    countryEn: _countryList.find(
                                      item => item._id === newCountryId
                                    ).en ,
                                    cityId: '',
                                    cityTitle: '' 
                                  }) ;
                                  _setCityListForSelectedCountry(
                                    [..._cityList.filter( item => item.countryId === newCountryId )]
                                  );
                                } }
                                inputPropsId={'speaklabs-classroom-event-country-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of  Country */}


                        {/* City Selection */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item
                              className='w-100  mt-1'
                              sm={12} md={6} lg={4} alignContent='center'
                              alignItems='center'  justifyContent='center'
                              // style={{backgroundColor:'red'}}
                            >
                              <SimpleCustomSelect
                                // fullWidth={true}
                                disabled={classroomId}
                                label='City'
                                htmlId={'select-city'}
                                eventTargetNAme={'city'}
                                isNoneExist={ formData.cityId === '' }
                                noneText={'Select'}
                                value={ formData.cityId   }
                                optionList={[
                                  {
                                    value: formData.cityId,
                                    definition: formData.cityTitle
                                  }
                                ]}
                                onChange={ e => {
                                  let newCityId = e.target.value;
                                  setFormData({ 
                                    ...formData, 
                                    cityId: newCityId,
                                    cityTitle: _cityList.find(
                                      item => item._id === newCityId
                                    ).en  
                                  }) ;
                                } }
                                inputPropsId={'speaklabs-classroom-event-city-select'}
                              />
                            </Grid>
                          )
                        }
                        {/* End of  City Selection */}



                        {/* Address */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item className='w-100 mt-2'     sm={12} >
                              <CustomTextAreaAutoSize 
                                title='Address'
                                value={formData.address}
                                placeholder={'Address'}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    address: e.target.value
                                  });
                                }}
                                required
                              />
                            </Grid>
                          )
                        }
                        {/* End of Address */}



                        {/* Registration */} 
                        <RegistrationSetComponent
                          formData={formData}
                          registrationEventState={ _registrationEventState }
                          setFormData={setFormData}
                        />
                        {/* End of Registration  */} 


                        {/* TICKET */}
                        <TicketSetComponent
                          formData={formData}
                          setFormData={setFormData}
                          registrationEventState={_registrationEventState}
                        />
                        {/* End of TICKET */}


                        {/* Event Title */}                  
                        <Grid item  className='w-100 mt-2 '     sm={12}>
                          <TextField
                            variant='standard'
                            label='Title'
                            disabled={true}
                            placeholder='Please enter Event Title'
                            required={true}
                            fullWidth
                            value={formData.eventTitle}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventTitle: e.target.value
                              });
                            }}
                          />
                        </Grid>
                        {/* End of Event Title */}


                        {/* Event List Definition Text */}
                        {
                          formData.isEventOnline === false && (
                            <Grid item className='w-100 mt-2'     sm={12} >
                              <CustomTextAreaAutoSize 
                                title='Event List Definition Text'
                                value={formData.offlineEventListShortDefinition}
                                placeholder={'Event List Definition Text'}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    offlineEventListShortDefinition: e.target.value
                                  });
                                }}
                                required
                              />
                            </Grid>
                          )
                        }
                        {/* End of Event List Definition Text */}


                        {/* Video LINK Text */}
                        {
                          formData.isEventOnline === true && (
                            <Grid item className='w-100 mt-2'     sm={12} >
                              <CustomTextAreaAutoSize 
                                title='Zoom Link'
                                value={formData.videoLink}
                                placeholder={'Zoom Link'}
                                onChange={e => {
                                  setFormData({
                                    ...formData,
                                    videoLink: e.target.value
                                  });
                                }}
                                // required
                              />
                            </Grid>
                          )
                        }
                        {/* End of Video LINK Text */}


                        {/* Event Bold Text */}
                        <Grid item className='w-100 mt-2'     sm={12} >
                          <CustomTextAreaAutoSize 
                            title='Event Bold Text'
                            value={formData.eventBoldText}
                            placeholder={'Event Bold Text'}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventBoldText: e.target.value
                              });
                            }}
                            required
                          />
                        </Grid>
                        {/* End of Event Bold Text */}


                        {/* Event Regular Text */}
                        <Grid item className='w-100 mt-2'     sm={12} >
                          <CustomTextAreaAutoSize 
                            title='Event Regular Text'
                            placeholder={'Event Regular Text'}
                            value={formData.eventRegularText}
                            onChange={e => {
                              setFormData({
                                ...formData,
                                eventRegularText: e.target.value
                              });
                            }}
                            required
                          />
                        </Grid>
                        {/* End of Event Regular Text */}
                        {/* //------------------------------------------------------------------------------------------- */}

                        <Backdrop
                          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                          open={ _isAddingOrUpdating }
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>


                      </Grid>


                      <Grid item className='w-100 pt-2 pb-1'>
                        <Button
                          type='submit'
                          variant='contained'
                          color='success'
                          endIcon={<SendIcon />}
                        >
                          {
                            (classroomId && !eventId)
                              ?   'Create Event from Classroom'
                              :   'Update Event'
                          }
                        </Button>
                      </Grid>
                    </form>
                  )
                  :
                  ( 
                    <Grid item className='w-100'sm={12}>
                      <Grid 
                        container 
                        className='w-100'
                        sm={12}
                        style={{
                          minHeight: `calc( ${clientAppConstants.screenContainerMinHeight} - 57px  )`,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent:'space-between'
                        }}
                      >
                        <EventDetailTable 
                          formData={formData}
                          languageTitle={
                            _languageList.find(
                              item => item._id === formData.language
                            ).languageTitle
                          }
                        />
                        <EventAddEditScreenBottomButtons 
                          eventId={eventId}
                          formData={formData}
                        />
                      </Grid>
                    </Grid>
                  )                  
              }
            </Grid>
          )
        }


      </Grid>
    </Container>
  )
}
