import React, { useState } from "react";
import { connect } from "react-redux";
import{  NavLink } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {  withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


export const CustomDialog = ({
  dialogTitle,
  dialogContentText,
  isModalOpen,
  onClose,
  ariaLabelledBy,
  ariaDescribedBy,
  confirmButtonText,
  cancelButtonText,
  handleCancel,
  handleConfirm
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose= {onClose}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
    >
      <DialogTitle
        id={ariaLabelledBy}
      >
       {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={ariaDescribedBy}>
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleCancel} 
          color='default'
        >
          {cancelButtonText}
        </Button>
        <Button 
          onClick={handleConfirm}
          color='secondary'
        >{confirmButtonText}</Button>
      </DialogActions>          
    </Dialog>
  )
}
