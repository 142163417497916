import   React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment';




// MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// Icons



// Actions
import { setSinceDateForFilter } from '../../actions/filterActions/setSinceDateForFilter';





export const SinceDateFilterComponent = (

) => {
  // Global Variables
  const sinceDate = useSelector(state => state.filterReducer.filteringParameters.sinceDate);
  // const untilDate = useSelector(state => state.filterReducer.filteringParameters.untilDate);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();


  // const [value, setValue] = React.useState(null);

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  return (
    <Grid 
      item 
      // className={'w-100 pb-1 pr-1' + isHideClassName}
      className={'pb-1 pr-1 pt-2' + isHideClassName}
      // style={{backgroundColor: 'yellow'}}
      sm={12} md={6} lg={4}
    >
      <Stack direction='column'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Since Date"
          inputFormat="yyyy-MM-dd"
          value={sinceDate}
          onChange={ (newDate) => {
            dispatch(
              setSinceDateForFilter({
                // newDate
                newDate: new Date(moment(new Date( newDate )).format('YYYY-MM-DD'))
              })
            );
          }}  
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
        {/* <FormCustomTitle
          formText='Since Date'
        />
        <CustomDateTime2  /> */}
      </Stack>
    </Grid>
  );
}