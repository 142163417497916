import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { SINGLE_USER_LOADING, SINGLE_USER_FETCHED,  USERS_FETCHED, SET_USERS_LOADING } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";
import { setFilteringItemCount } from "../filterActions/setFilteringItemCount";



export const fetchSingleUser = ({
  userId,
  cb,  // callBack
}) => async dispatch => {
  try { 
    dispatch({
      type: SINGLE_USER_LOADING,
      payload: true
    });


    const res = await axios.get(
      urlConstants.getSingleUser( userId ), 
      // formData ?? {},
      clientAppConstants.jsonConfig
    );  

    dispatch({
      type: SINGLE_USER_FETCHED,
      payload: res.data.user
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: SINGLE_USER_LOADING,
      payload: false
    });
    if ( cb ) {
      cb( null );
    }
  }
}  