import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';

import './CustomTextAreaAutoSize.css'
import { FormCustomTitle } from '../typography/FormCustomTitle';

export default function CustomTextAreaAutoSize({
  title,
  onChange,
  value,
  placeholder,
  required
}) {
  return (
    <Stack direction='column' className='custom-text-area-autosize'>
      {
        title && (

          <FormCustomTitle
            formText={title}
          />
        )
      }
      <TextareaAutosize        
        aria-label="empty textarea"
        placeholder={placeholder}        
        onChange={onChange}
        value={value}
        required={required}
      />

    </Stack>

  );
}
