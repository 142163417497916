import React from 'react'
import {  useSelector, useDispatch } from "react-redux";


// MUI
import Button from '@mui/material/Button';

// Icons
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Components
import { CreateEventDialog } from './CreateEventDialog';
import { checkLatestEventViaEventId } from '../../../actions/eventActions/checkLatestEventViaEventId';


export const CopyEventButton = ({
  eventId
}) => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const userType = useSelector(state => state.authReducer.userType);
  const dispatch = useDispatch();


  const [open, setOpen] = React.useState(false);
  const [ _isFetchingLatestEventDate, _setIsFetchingLatestEventDate ] = React.useState(false);
  const [ _latestEvent, _setLatestEvent ] = React.useState(null);

  const handleClickOpen = () => {
    _setIsFetchingLatestEventDate(true);
    dispatch(
      checkLatestEventViaEventId({
        eventId,
        cb: apiResponse => {
          if ( apiResponse && apiResponse.latestEvent ) {
            _setLatestEvent(apiResponse.latestEvent);
          }
          _setIsFetchingLatestEventDate(false);
        }
      })
    );
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        // component={NavLink}
        // to={'/event/tickets/' + 'eventTickets11' }
        endIcon={<ContentCopyIcon  />}
        onClick={() => {
          handleClickOpen()
        }}
      >
        Copy Event
      </Button> 
      <CreateEventDialog
        latestEvent={_latestEvent}
        isFetchingLatestEventDate={_isFetchingLatestEventDate}
        eventId={eventId}
        isOpen={open}
        handleClose={handleClose}
      />
    </>
  )
}
