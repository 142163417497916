import {
  USERS_FETCHED,
  SET_USERS_LOADING,
  SINGLE_USER_LOADING ,
  SINGLE_USER_FETCHED ,
  SET_USER_AS_HOST_LOADING,
  SET_USER_AS_HOST_SUCCESS,
  SET_USER_AS_VERIFIER_LOADING,
  SET_USER_AS_VERIFIER_SUCCESS,
  USERS_TICKET_LIST_FETCHED,
} from "../actions/types";


const initialState = {
  userList: [],
  isLoading: true,
  isUsersFetched: false,
  isAddingLanguage: false,

  isLoadingSingleUser: false,
  singleUser: null,  // UserEditScreen
  isSettingUserAsHost: false,
  isSettingUserAsVerifier: false,

  // UserTicketScreen
  userTicketList: [],
  userNameSurname: null,
  userEmail: null,
  // End of UserTicketScreen


}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case USERS_FETCHED:      
      return {
        ...state,
        userList: [...payload],
        isLoading: false,
        isUsersFetched: true,
      };
    case SET_USERS_LOADING:      
      return {
        ...state,
        userList: payload === true ? [] : state.userList,
        isLoading: payload
      }; 

    case SINGLE_USER_LOADING:      
      return {
        ...state,
        isLoadingSingleUser: payload
      }; 
    case SINGLE_USER_FETCHED:      
      return {
        ...state,
        singleUser: payload,
        isLoadingSingleUser: false,
      }; 
      
    case SET_USER_AS_HOST_LOADING:      
      return {
        ...state,
        isSettingUserAsHost: payload
      }; 
    case SET_USER_AS_HOST_SUCCESS:      
      return {  
        ...state,
        singleUser: {
          ...state.singleUser,
          isHost: payload
        },
        isSettingUserAsHost: false
      }; 

    case SET_USER_AS_VERIFIER_LOADING:      
      return {
        ...state,
        isSettingUserAsVerifier: payload
      }; 
    case SET_USER_AS_VERIFIER_SUCCESS:      
      return {  
        ...state,
        singleUser: {
          ...state.singleUser,
          isOfflineEventTicketVerifier: payload
        },
        isSettingUserAsVerifier: false
      }; 
    case USERS_TICKET_LIST_FETCHED:  
      return {  
        ...state,
        userTicketList: (payload &&  payload.ticketList && Array.isArray(payload.ticketList) ) ? payload.ticketList : [],
        userNameSurname: (payload && payload.userNameSurname) ? payload.userNameSurname : null,
        userEmail: (payload && payload.userEmail ) ? payload.userEmail : null,
      }; 

    default:
      return state;
  }
}