import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchSingleTicketDetail } from '../../actions/ticketActions/fetchSingleTicketDetail';



import {
  TableBody,
} from '@material-ui/core';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Icons


// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow';
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead';
import { CancelTicketButton } from './CancelTicketButton';



// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';




export const TicketEditScreen = ({
  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) => {
  // Global Variables
 

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  const [ticketId, setTicketId] = useState(match.params.ticketId || null);
  const [formData, setFormData] = useState(null);


  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      dispatch(
        fetchSingleTicketDetail({
          ticketId,
          cb: apiResponse => {
            // console.log('fetchSingleTicketDetail -> cb -> apiResponse ', apiResponse)
            if ( apiResponse && apiResponse.ticket ) {
              setFormData(apiResponse.ticket);
            }
            _setLoading(false);
          }
        })
      )
    }, []
  );




  const _onSubmitHandler = () => { }


  return (
    <Container
      maxWidth='lg'
      className='w-100 '
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
      id='user-edit-screen-container'
    >
      <Helmet defer={false}><title>{'Ticket Detail'}</title></Helmet>
      <Grid container className='w-100'>
        <ScreenTitle titleText={'Ticket Detail'} />
        {/* <UserTopBar
          nameSurname={(
            <>
              {'Emrah Ozyazgan'}
              <Tooltip title="Activities" arrow>
                <IconButton
                  component={NavLink}
                  to={`/user/activities/${userId}`}
                >
                  <MoreVertIcon/>
                </IconButton>
              </Tooltip>
            </>
          )}
        /> */}
        {
          _loading && <ScreenCircularProgress />
        }
        {
          !_loading && (
            <Grid item className='w-100' sm={12}>
              <form 
                className='w-100' 
                onSubmit={_onSubmitHandler}
                style={{
                  minHeight:`calc( ${clientAppConstants.screenContainerMinHeight} - 45px )`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent:'space-between'
                }}
              >
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  <CustomTable
                  // stickyHeader={false}
                  >
                    <DoubleColumnTableHead />
                    <TableBody>
                      {/* <UserItem />
                      <UserItem />
                      <UserItem /> */}
                      <DoubleColumnTableRow
                        keyTitle='Event Name'
                        valueText={(
                          <TableNavlinkTextButton
                            buttonLink={`/event/edit/`+ formData.eventId._id }
                            buttonText={ formData.eventId.eventTitle }
                          />
                        )}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Event Date'
                        valueText={convertToMomentDateTime( formData.eventId.eventDate  )}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Ticket Purchase Date'
                        valueText={convertToMomentDateTime( formData.ticketPurchaseDate )}
                      />
                      <DoubleColumnTableRow
                        keyTitle='User'
                        valueText={(
                          <TableNavlinkTextButton
                            buttonLink={`/user/edit/`+ formData.userId._id}
                            buttonText={formData.userId.nameSurname ?? formData.userId.email }
                          />
                        )}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Host'
                        valueText={(
                          <TableNavlinkTextButton
                            buttonLink={`/user/edit/`+ formData.eventId.eventHost._id}
                            buttonText={formData.eventId.eventHost.nameSurname ?? formData.eventId.eventHost.email}
                          />
                        )}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Platform'
                        valueText={formData.purchasePlatform}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Price'
                        valueText={'$' + formData.ticketPrice.toFixed(2)}
                      />

                      {
                        formData.subscriptionId && formData.subscriptionTitle && (
                          <DoubleColumnTableRow
                            keyTitle='Subscription'
                            valueText={(
                              <TableNavlinkTextButton
                                buttonLink={`/subscription/edit/`+ formData.subscriptionId}
                                buttonText={formData.subscriptionTitle}
                              />
                            )}
                          />
                        )
                      }
                      <DoubleColumnTableRow
                        keyTitle='Language'
                        valueText={formData.eventId.language.languageTitle}
                      />
                      <DoubleColumnTableRow
                        keyTitle='Status'
                        valueText={formData.ticketStatus}
                      />
                      {
                        formData.isEventOnline === false && (
                          <DoubleColumnTableRow
                            keyTitle='Ticket Verified'
                            valueText={formData.isVerified ?  'YES' : 'NO'}
                          />
                        )
                      }

                      {
                        formData.verificationDate && (
                          <DoubleColumnTableRow
                            keyTitle='Verification Time'
                            valueText={convertToMomentDateTime(formData.verificationDate)}
                          />
                        )
                      }

                      {
                        formData.verifierUserId && (
                          <DoubleColumnTableRow
                            keyTitle='Ticket Verifier'
                            valueText={(
                              <TableNavlinkTextButton
                                buttonLink={`/user/edit/`+ formData.verifierUserId._id}
                                buttonText={ formData.verifierUserId.nameSurname ?? formData.verifierUserId.email ?? '-'}
                              />
                            )}
                          />
                        )
                      }
                      
                      

                    </TableBody>
                  </CustomTable>
                  {/* End of Table */}



                </Grid>

                <Grid container className='w-100 pb-1' sm={12}>
                  <Grid item className='pr-2'>
                    <CancelTicketButton />  
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )
        }


      </Grid>
    </Container>
  )
}
