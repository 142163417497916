




// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_HOSTS_FILTER, SET_INTERESTS_FILTER } from "../types";





export const setHostsFilter = ({  
  newHostFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_HOSTS_FILTER,
    payload: {
      isReset,
      newHostFilterList: [ ...newHostFilterList ]
    }
  });
}  




