

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK,  } from "../types";

// Actions



export const handleTicketReservationItemClick = ({
  ticketReservationItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK,
    payload: ticketReservationItem
  });
}  