
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchInterests } from '../../actions/interestActions/fetchInterests';



import {
  // Container,
  // CircularProgress,
  // Button,
  // TableContainer,
  // Table,
  TableBody,
  // Paper,
  // Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';


// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';

// Styles
import'./InterestScreen.css'

// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { DoubleColumnTableRow } from '../../components/table/DoubleColumnTableRow';
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead';
import { InterestItemComponent } from './InterestItemComponent';


// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { addInterest } from '../../actions/interestActions/addInterest';





export const InterestScreen = () => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  const isInterestsLoading = useSelector(state => state.interestReducer.loading);
  const isAddingInterest = useSelector(state => state.interestReducer.isAddingInterest);
  const interests = useSelector(state => state.interestReducer.interests);
  // const user = useSelector(state => state.authReducer.user);

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  // const [ticketId, setTicketId] = useState(match.params.ticketId || null);

  const _initialFormData = { interestTitle: '' }
  const [formData, setFormData] = useState({ ..._initialFormData });


  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      dispatch(
        fetchInterests({
          cb: () => {
            _setLoading(false)
          }
        })
      );
      // _setLoading(false);
    }, []
  );

  const _dummyInterests = [
    { interestTitle: 'Dancing', _id: 'werwerwewerew' },
    { interestTitle: 'Singing', _id: 'werwerwsdfewerew' },
  ]




  const _onSubmitHandler = () => { }


  return (
    <Container
      maxWidth='lg'
      className='w-100 pb-1'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      id='speaklabs-interest-screen-container'
    >
      <Helmet defer={false}><title>{'Interests'}</title></Helmet>
      <Grid container className='w-100 pb-2'>
        <ScreenTitle titleText={'Interests'} />
        {/* <UserTopBar
          nameSurname={(
            <>
              {'Emrah Ozyazgan'}
              <Tooltip title="Activities" arrow>
                <IconButton
                  component={NavLink}
                  to={`/user/activities/${userId}`}
                >
                  <MoreVertIcon/>
                </IconButton>
              </Tooltip>
            </>
          )}
        /> */}
        {
          (_loading || isInterestsLoading ) && <ScreenCircularProgress />
        }
        {
          !(_loading || isInterestsLoading ) && (
            <Grid item className='w-100' sm={12}>
              <form className='w-100' onSubmit={_onSubmitHandler}>
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  <CustomTable
                  // stickyHeader={false}
                  >
                    <DoubleColumnTableHead 
                      firstColumnText='Interest'
                      secondColumnText='Actions'
                    />
                    <TableBody>
                      
                      {
                        interests && interests.map(
                          (interestItem, ind) => (
                            <InterestItemComponent
                              interestItem={interestItem}
                              key={ind}
                            />
                          ) 
                        )
                      }
                      
                      

                    </TableBody>
                  </CustomTable>
                  {/* End of Table */}



                </Grid>
              </form>
            </Grid>
          )
        }


      </Grid>

      <Grid container sm={12} className='w-100'>
        <Grid item sm={12}>
          <TextField
            placeholder='Enter new Interest!'
            label='Interest'
            required={true}
            disabled={_loading}
            fullWidth={true}
            variant='outlined'
            id='title'
            name='title'
            value={formData.interestTitle}
            size='medium'
            type='text'
            autoComplete={'off'}
            onChange={e => setFormData({...formData, interestTitle: e.target.value})}
          />
        </Grid>
        <Grid item className='mt-1'>
          <Button 
            variant='contained' 
            endIcon={<SendIcon />}
            color='success'
            disabled={isAddingInterest}
            onClick={() => {
              dispatch(
                addInterest({
                  formData,
                  cb: () => setFormData({..._initialFormData})
                })
              );
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}









