

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK,  } from "../types";

// Actions



export const handleActivityTypeItemClick = ({
  activityTypeItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK,
    payload: activityTypeItem
  });
}  