import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  LANGUAGE_FETCH_FOR_FILTERING_STARTED, LANGUAGES_FETCHED_FOR_FILTERING } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const fetchLanguagesForFilter = ({
  cb  // callBack
}) => async dispatch => {
  try { 
    dispatch({
      type: LANGUAGE_FETCH_FOR_FILTERING_STARTED,
      payload: null
    });
    const res = await axios.get(
      // urlConstants.apiUrl +  
      urlConstants.fetchLanguageUrl, 
      clientAppConstants.jsonConfig
    );  

    dispatch({
      type: LANGUAGES_FETCHED_FOR_FILTERING,
      payload: res.data
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    dispatch({
      type: LANGUAGES_FETCHED_FOR_FILTERING,
      payload: []
    });
    if ( cb ) {
      cb([]);
    }
  }
}  