






// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_ONLINE_OFFLINE_FILTER } from "../types";





export const setOnlineOfflineFilter = ({  
  newOnlineOfflineFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_ONLINE_OFFLINE_FILTER,
    payload: {
      isReset,
      newOnlineOfflineFilterList: [ ...newOnlineOfflineFilterList ]
    }
  });
}  




