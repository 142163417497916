import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { setUserTypeFilter } from '../../actions/filterActions/setUserTypeFilter';
import { handleUserItemClick } from '../../actions/filterActions/handleUserItemClick';
import { handleOnlineOfflineItemClick } from '../../actions/filterActions/handleOnlineOfflineItemClick';
import { setOnlineOfflineFilter } from '../../actions/filterActions/setOnlineOfflineFilter';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;









export const OnlineOfflineFilter = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const onlineOffline = useSelector(state => state.filterReducer.filteringParameters.onlineOffline);
  const dispatch = useDispatch();

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      >
      {/* <AutoCompleteMultipleCheckbox 
        options={[ ...clientAppConstants.onlineOfflineOptions]}
        autoCompleteLabel='Online-Offline'
        placeHolder=''
        htmlId='event-screen-online-offline-autocomplete'
        handleAutoCompleteOnChange={
          ( e, newValue ) => {
            _setOnlineOfflineFilter( newValue );
        }}
      /> */}
      <Autocomplete
        multiple
        id={ 'speaklabs-online-offline-autocomplete' }
        options={[...clientAppConstants.onlineOfflineOptions]}
        onChange={(e, newVal )=> {
          dispatch(
            setOnlineOfflineFilter({
              newOnlineOfflineFilterList: newVal
            })
          )
        }}
        value={onlineOffline}
        // filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        // defaultValue={[top100Films[13]]}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleOnlineOfflineItemClick({
                    onlineOfflineItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                // checked={selected} 
                checked={
                  onlineOffline.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params

              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  // <>YARRAK AMK</>
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'Online - Offline'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  );
}