import React, { useEffect,  useState } from 'react'
import {  useSelector, useDispatch } from "react-redux";
import Helmet from 'react-helmet';

import './forms.css';




import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
} from '@material-ui/core';

import {   makeStyles } from '@material-ui/core/styles';




export const SimpleCustomSelect = ({
  htmlId,
  fullWidth = false,
  optionTextAlign = false,
  label,
  value,
  disabled,
  onChange,
  inputPropsId,
  noneText,
  isNoneExist,
  optionList  = [],
  required,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    selectStyle: {
      minWidth: 200,
    },
    formControlFullWidth: {
      margin: theme.spacing(1),
      minWidth: 120,
      width:'100%',
      // maxWidth: 300,
    },

  }));
  const classes = useStyles();

  const _style={
    textAlign: optionTextAlign ? 'center' : 'left'
  };
  return (
    <FormControl 
      fullWidth={fullWidth} 
      component="fieldset" 
      // style={{backgroundColor:'red', width:'100%'}} 
      className={fullWidth ? (classes.formControlFullWidth + ' w-100') : classes.formControl  }
    >
      <FormLabel component="legend">
        {label}
      </FormLabel>
      <FormControlLabel
        control={
          <Select
            required={required}
            className={classes.selectStyle}
            style={{
              textAlign: fullWidth ? 'center' : 'left'
            }}
            fullWidth={fullWidth}
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            native
            value={value}
            disabled={ disabled }
            onChange={onChange}
            inputProps={{
              id: inputPropsId
            }}
          >
            {
              isNoneExist && noneText && (
                <option style={_style} aria-label='None' value='' >{noneText}</option>
              )
            }
            {
              optionList.map(
                (optionItem, index) => <option style={_style}  key={index} value={optionItem.value} >{optionItem.definition}</option>
              ) 
            }
          </Select>
        }
      />
    </FormControl> 
  )
}
