import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React from "react";

export const AsyncAutocomplete = ({
    url,
    value,
    onChange,
    label,
    ...rest
}) => {
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const timeoutRef = React.useRef();

    const fetchOptions = async (searchText) => {
        setLoading(true);
        try {
            const response = await axios.get(url,  {
                params: {
                    query: searchText
                },
            });
            setOptions(response.data.options ?? []);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    const onInputChange = (e, searchText) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            fetchOptions(searchText);
        }, 500);
    }

    console.log("Autocomplete", rest.name, value);
    return (
        <Autocomplete
            {...rest}
            options={options}
            value={value}
            sx={{ width: 300 }}
            loading={loading}
            size="small"
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => <TextField size="small" {...params} label={label} />}
        />)
}