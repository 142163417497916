// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_PLATFORM_FILTER } from "../types";





export const setPlatformFilter = ({  
  newPlatformFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_PLATFORM_FILTER,
    payload: {
      isReset,
      newPlatformFilterList: [ ...newPlatformFilterList ]
    }
  });
}  