import React from 'react'

export const FormCustomTitle = ({
  formText,
  isLineHeightOne = false
}) => {
  return (
    <p
      style={{
        color:'rgba(0,0,0, 0.54)',
        fontSize: '1rem',
        lineHeight: isLineHeightOne  ? 1 : 19/16,
        fontWeight: '400',
        marginBlockStart: 0,
        marginBlockEnd: 0
      }}
    >
      {formText}
    </p>
  )
}
