import React from 'react'



// MUI
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';


// Components
import { FormCustomTitle } from '../typography/FormCustomTitle';




export const CustomImageTextField = ({
  customTitle,
  handleImageOnChange,
  required = false,
}) => {

  const _fileSelectedHandler = e => { 
    return e.target.files[0];   
  }


  return (
    <Stack direction='column'>
      <FormCustomTitle
        isLineHeightOne={true}
        formText={customTitle}
      />
      <TextField
        // fullWidth={true}
        required={required}
        variant='standard'
        type='file'      
        onChange={ e => {
          let tempImage = _fileSelectedHandler( e );
          handleImageOnChange(tempImage);
        }}
      />
    </Stack>
  );
}
