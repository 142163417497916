import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components


// Actions
import { setEmailFilter } from '../../actions/filterActions/setEmailFilter';
import { handleEmailItemClick } from '../../actions/filterActions/handleEmailItemClick';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;





export const EmailFilterComponent = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const email = useSelector(state => state.filterReducer.filteringParameters.email);
  const dispatch = useDispatch();


  const _dummyEmailOptions = [
    { label: 'Email Off' },  { label: 'Email On' }, 
  ]


  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <Autocomplete
        multiple
        id={ 'speaklabs-email-autocomplete' }
        options={_dummyEmailOptions}
        onChange={(e, newVal )=> {
          console.log('hello')
          dispatch(
            setEmailFilter({
              newEmailFilterList: newVal
            })
          )
        }}
        value={email}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleEmailItemClick({
                    emailItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                // checked={selected} 
                checked={
                  email.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'Emails'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  );
}