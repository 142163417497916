import axios from "axios";

// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Actions
import { setAlert } from "../alertActions/setAlert";



export const resetPasswordViaToken = ({ 
  token, 
  formData,
  cb 
}) =>  async dispatch => {
  try {
    // const res = await axios.post(
    //   `/api/admin/email/validate-email/${token}`, 
    //   config
    // );

    let _formData = {
      password: formData.password,
      cryptoToken: token,
    }
    const res = await axios.post(
      urlConstants.resetPasswordViaTokenUrl, 
      _formData,
      clientAppConstants.jsonConfig
    ); 
    dispatch(setAlert(res.data.msg, "success", 2500));
    // dispatch({
    //   type: JOB_ADDED,
    //   payload: res.data.job
    // });
    if ( cb ) { cb( res.data ); }
    // dispatch(  setAlert(res.data.msg, 'success', 3000)   );
  } catch (err) {
    const errors = err.response.data.errors;
    if ( cb ) { cb( null ); }
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)));
    }
  }
};  // End of resetPasswordViaToken

