

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_USER_TYPE_FILTER_ITEM_CLICK } from "../types";

// Actions



export const handleUserItemClick = ({
  userItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_USER_TYPE_FILTER_ITEM_CLICK,
    payload: userItem
  });
}  