import React, {useMemo} from 'react'


import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';
import ReactHowler from 'react-howler'
import ReactAudioPlayer from 'react-audio-player';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';


import {
  // Container,
  // CircularProgress,
  // Button,
  // TableContainer,
  // Table,
  // TableBody,
  // Paper,
  // Grid
} from '@material-ui/core';
// import Pagination from '@material-ui/lab/Pagination';

// MUI
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import moment from "moment";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import is from 'date-fns/esm/locale/is/index.js';

// Components
import { AudioPlayerComponent } from '../../components/audio/AudioPlayerComponent';
import AudioPlayerComponent2 from '../../components/audio/AudioComponentComponent2';
import { VideoPlayerComponent } from '../../components/video/VideoPlayerComponent';
import {getChatImage} from "../../helpers/common/image";
import {convertToMomentDateTime} from "../../helpers/moment/convertToMomentDateTime";


export const ChatMessageItem = ({
  chatItem
}) => {

  const { _id } = useSelector(state => state.authReducer.user)
  const {
    chatMessageId,
    userId,
    userAvatar,
    languageId,
    senderEmail,
    senderNameSurname,
    messageType,
    messageText,
    voiceRecordFileId,
    isSingleImage,
    imageList,
    isAnswer,
    answeredMessageId,
    creationDate,
  } = chatItem;

  const { date, isAudioMessage } = useMemo(() => {
    return {
      date: convertToMomentDateTime(creationDate),
      isAudioMessage: messageType === clientAppConstants.chatMessageTypes.AUDIO_MESSAGE,
    };
  }, [chatItem]);
  const isMyMessage = userId === _id;
  if ( isMyMessage ) {
    return (
      <Stack
        className='mb-1 pr-1'
        style={{
          // backgroundColor: '#ddd'
        }}
        direction='row'
        justifyContent={'flex-end'}
      >
        <Stack 
          direction='column'
          alignItems='flex-end'
        className='speaklabs-my-message-column-stack'

        >
          <span className='speaklabs-chat-my-message-text-span'>{messageText}</span>
          <span className='speaklabs-chat-message-time-span'>{date}</span>
        </Stack>
      </Stack>
    )
  }

  // Incoming Message
  return (
    <Stack
      className={'mb-1 pl-1 ' + ( isAudioMessage ? ' speaklabs-audio-stack' : '' )}
      style={{
        // backgroundColor: '#ddd'
      }}
      direction='row'
      justifyContent={ 'flex-start' }
      
    >
      <Avatar className='mr-1'>H</Avatar>
      <Stack 
        direction='column'
        className={'speaklabs-incoming-message-column-stack'  }
        alignItems='flex-end'
        // style={{backgroundColor:'yellow'}}
      >
        

        <span className={'speaklabs-chat-message-text-span' }>

          {/* Message Sender Line */}
          <span className='speaklabs-message-sender-span'>
            <span className='speaklabs-message-sender-flex-span'>

              <span>{ senderNameSurname ?? senderEmail  }</span>
              <a  
                className='speaklabs-message-sender-button'
                onClick={(e) => { 
                  e.preventDefault();
                  console.log('ChatMessageItem -> Button -> Clicked'); 
                }}
              >
                Reply
              </a>
            </span>
            
          </span>
          {/* End of Message Sender Line */}

          {/* if it is an answer to another message */}          
          {
            isAnswer && answeredMessageId && (
            <span
              className='speaklabs-chat-answered-message' 
            >
              <span
                className='speaklabs-chat-message-answered-message-sender'
              >
                {answeredMessageId.senderNameSurname }
              </span>
              <span className='speaklabs-chat-answered-message-span'>
              { answeredMessageId.messageText }
              </span>
            </span>
            )
          }
          {/* End of if it is an answer to another message */}

          {/* if message text & image exists -> image */}          
          {imageList?.length && imageList.map(image =>
              (<span
                  key={image}
                  style={{
                    maxWidth: '250px',
                    display: 'block'
                    // height: '190px',
                  }}
              >
                <img
                    style={{
                      maxWidth: '250px',
                      // height: '190px',
                      borderRadius: '.4rem'
                    }}

                    src={getChatImage(image)}
                />
              </span>))}
          {/* End of if message text & image exists -> image */}

          {
            isAudioMessage && (
                  <ReactAudioPlayer
                      src={chatItem.audio}
                      controls
                      controlsList="nodownload"
                      className="voicenote"
                  />
              // <p>sdfsdfdsfsdfsdfsdf</p>
            )
          }



          {/* Message Text */}          
          {
            messageText && messageText
          }
          {/* End of Message Text */}

        </span>

        <span className='speaklabs-chat-message-time-span'>{date}</span>
      </Stack>
    </Stack>
  )
}
