import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {SocketContext} from "../context/SocketContext";
import {useReactMediaRecorder} from "react-media-recorder";
import axios from "axios";
import {clientAppConstants, urlConstants} from "../constants";
import {getErrorMessage} from "../helpers/common/errors";
import {toast} from "react-toastify";

const sendRecordedVoice = (formData) => axios.post(urlConstants.chatSendRecordedVoice, formData, clientAppConstants.multipartFormDataConfig);
const sendImage = (formData) => axios.post(urlConstants.chatSendImage, formData, clientAppConstants.multipartFormDataConfig);
const listenRecordedVoice = (voiceRecordFileId) => axios.get(urlConstants.chatListenRecordedVoice(voiceRecordFileId), {responseType: "blob", ...clientAppConstants.jsonConfig});


export const useChat = (roomId) => {
    const socket = useContext(SocketContext);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [pickedImages, setPickedImages] = useState([]);
    const [sendAudio, setSendAudio] = useState(false);
    const messagesEnd = useRef(null);
    const filesFetched = useRef(false);

    const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({mediaRecorderOptions: {type: "audio/aac"}});

    const sendMessage = useCallback(message => {
        if(!message) return 0;
        socket.emit("sendMessage", {languageId: roomId, messageText: message});
        setMessage("");
    }, [roomId]);

    const sendMessageForm = useCallback((e) => {
        e.preventDefault();
        setMessage(message);
    }, [message]);

    const sendAudioMessage = useCallback(async () => {
        try {
            const formData = new FormData();
            const mediaBlob = await fetch(mediaBlobUrl).then(response => response.blob());
            formData.append('voicerecord', new File([mediaBlob], "voicenote.aac", {type: "audio/aac"}));
            formData.append('languageId', roomId);
            await sendRecordedVoice(formData);
        } catch(err) {
            toast.error(getErrorMessage(err));
        }
    }, [mediaBlobUrl]);

    const sendImageMessage = useCallback(async (images) => {
        try {
            const formData = new FormData();
            formData.append('images[]', images.item(0));
            formData.append('languageId', roomId);
            await sendImage(formData);
            setPickedImages([]);
        } catch(err) {
            console.log(err.message);
        }
    }, []);

    const handleSend = useCallback(() => {
        if(status === "recording") {
            setSendAudio(true);
            stopRecording();
        }
        else if(pickedImages.length) {
            sendImageMessage(pickedImages);
        } else {
            sendMessage(message);
        }
    }, [status, message, pickedImages]);

    const displayPickedImages = useMemo(() => {
        const images = [];
        for(let i=0; i<pickedImages.length; i++) {
            images.push(pickedImages.item(i).name);
        }
        return images;
    }, [pickedImages]);

    const getAudioMessages = useCallback(async (audioMessages) => {
        try {
            const audioFiles = [];
            for(let i=0; i<audioMessages.length; i++) {
                if(audioMessages[i].messageType === "AUDIO_MESSAGE" && !audioMessages[i].audio) {
                    const response = await listenRecordedVoice(audioMessages[i].voiceRecordFileId);
                    audioFiles.push({chatMessageId: audioMessages[i].chatMessageId, file: URL.createObjectURL(response.data)});
                }
            }
            audioMessages.forEach(message => {
                if(message.messageType === "AUDIO_MESSAGE" && !message.audio) {
                    const audioFile = audioFiles.find(file => String(message.chatMessageId) === String(file.chatMessageId));
                    if(audioFile.file) message.audio = audioFile.file;
                }
            });
            setMessages([...audioMessages]);
        } catch(err) {
            console.log(err.message);
        }
    }, []);

    useEffect(() => {
        if(socket) {
            socket.emit("join", {languageId: roomId });
            socket.on("existingChatListOnLanguageRoom", ({chatList}) => {
                setMessages(chatList);
            });
            socket.on("message", message => {
                filesFetched.current = false;
                setMessages(prevValue => [...prevValue, message]);
            });
        }
    }, [socket]);

    useEffect(() => {
        if(messages.length && !filesFetched.current) {
            getAudioMessages(messages);
            filesFetched.current = true;
        }

        setTimeout(() => {
            messagesEnd.current?.scrollIntoView();
        }, 100);
    }, [messages]);

    useEffect(() => {
        if(sendAudio && mediaBlobUrl) {
            setSendAudio(false);
            sendAudioMessage(messages);
        }
    }, [mediaBlobUrl]);

    return {
        message,
        messages,
        pickedImages,
        sendAudio,
        messagesEnd,
        filesFetched,
        status,
        startRecording,
        stopRecording,
        setMessage,
        handleSend,
        displayPickedImages,
        sendMessage,
        sendMessageForm,
        setPickedImages,
    };
}