import Container from "@mui/material/Container";
import {clientAppConstants, filterTypes, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useCallback, useEffect} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {CustomTable} from "../../components/table/CustomTable";
import {StyledTableCell} from "../../components/table/StyledTableComponents";
import {IconButton, TableBody, TableHead, TableRow} from "@material-ui/core";
import axios from "axios";
import {CustomPaginationV2} from "../../components/CustomPaginationV2";
import Grid from "@mui/material/Grid";
import {GlobalCircularProgress} from "../../components/loader/GlobalCircularProgress";
import Box from "@mui/material/Box";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import {FiltersV2} from "../../components/filterV2/Filters";
import Button from "@mui/material/Button";
import {useFiltersWithPagination} from "../../hooks/useFiltersWithPagination";
import {omit} from "lodash";
import {NavLink} from "react-router-dom";


const filterConfig = [
    {
        name: "nameSurname",
        label: "User name",
        type: filterTypes.SEARCH,
    },
    {
        name: "email",
        label: "Email",
        type: filterTypes.SEARCH,
    },
];
export const HostsScreen = () => {



    const [hosts, setHosts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const { page, filters, setFilters, setPage, requestParams } = useFiltersWithPagination(filterConfig);

    const fetchHosts = useCallback(async (params) => {
        try {
            setIsLoading(true);
            const response = await axios.get(urlConstants.fetchHosts, {
                params: {
                    ...omit(params, ["filters"]),
                    ...(params.filters || {}),
                    limit: 10,
                }
            });
            setHosts(response.data.hosts);
            setCount(response.data.count);
        } catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }, []);



    useEffect(() => {
        fetchHosts(requestParams);
    }, [JSON.stringify(requestParams)]);
    
    return (<Container
        id='user-screen-container'
        maxWidth='lg'
        style={{
            backgroundColor: clientAppConstants.pageContainerBackgroundColor,
            // paddingTop: 64,
            minHeight: clientAppConstants.screenContainerMinHeight,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-between'
        }}
    >
        <Helmet defer={false}><title>Hosts</title></Helmet>
        <Grid
            container
            direction='column'
            className='w-100'
        >
            <ScreenTitle
                titleText="Hosts"
            />
            <GlobalCircularProgress show={isLoading}/>
            <Box  className="d-flex w-100" mt={2} mb={2}>
                <FiltersV2 filtersConfig={filterConfig} filters={filters} onFiltersChange={setFilters} />
            </Box>
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell size= 'small' align='left'>Name</StyledTableCell>
                        <StyledTableCell size= 'small' align='left'>email</StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Balance</StyledTableCell>
                        <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {hosts.map((host) => (
                        <TableRow
                            key={host.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell size= 'small' align='left'>{host.nameSurname}</StyledTableCell>
                            <StyledTableCell size= 'small' align='left'>{host.email}</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>$ {host.hostBalance ?? 0}</StyledTableCell>
                            <StyledTableCell size= 'small' align='center'>
                                <Button size="small"  component={NavLink} variant="contained" color="primary"  to={`/transactions/${host._id}`}>Transactions</Button>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
            <CustomPaginationV2
                currentPaginationIndex={page}
                rowsPerPage={10}
                count={Math.ceil(count / 10)}
                handleOnChange={(event, newPage) => setPage(newPage)}
            />
        </Grid>
    </Container>)
}