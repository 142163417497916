import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import{  NavLink } from 'react-router-dom'

import { updateSingleUser } from '../../actions/userActions/updateSingleUser';

// Components
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import Button from '@mui/material/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



export const DoubleColumnTableRow = ({
  keyTitle,
  valueText,
  valueComponent,
  editingMode,
  setEditingMode,
  editingKey,
  data,
  userId
}) => {
  const [newValue, setNewValue] = useState(!(editingKey === "dateOfBirth" && valueText === "-") ? valueText : new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    if(editingKey === "interests" || editingKey === "languageList") setNewValue([]);
  }, [editingMode]);

  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
        align='left'
      >
        {keyTitle}
      </StyledTableCell>
      <StyledTableCell
        size='small'
        align='left'
        style={{width: "50%"}}
      >
      {!editingMode ?
       valueComponent ?? valueText
       :
        <>
          {!["isEmailVerified", "isSubscribedToNotifications", "isSubscribedToEmails", 
          "dateOfBirth", "interests", "languageList", "notes"].includes(editingKey) &&
            <input type="text" value={editingKey !== "balance" ? newValue : newValue.replace("$", "")} onChange={({target}) => setNewValue(target.value)} />}
          
          {["isEmailVerified", "isSubscribedToNotifications", "isSubscribedToEmails"].includes(editingKey) &&
          <select onChange={({target}) => setNewValue(target.value)}>
            <option value={true} selected={newValue === "YES"}>Yes</option>
            <option value={false} selected={newValue === "NO"}>No</option>
          </select>}

          {editingKey === "dateOfBirth" &&
            <DatePicker dateFormat="yyyy-MM-dd" selected={new Date(newValue)} onChange={date => setNewValue(date)} />
          }

          {editingKey === "notes" &&
            <textarea cols={50} rows={10} value={newValue} onChange={({target}) => setNewValue(target.value)} />
          }

          {(editingKey === "interests" || editingKey === "languageList") &&
            data?.map(value => {
              return (
                  <div>
                      <input type="checkbox" id={value} name={value} 
                        onChange={({target}) => {
                          if(target.checked) {
                            setNewValue([...newValue, target.name]);
                          } else {
                            setNewValue(newValue.filter(element => element !== target.name));
                          }
                        }} 
                        className="ml-0 position-relative mr-1"
                      />
                      <label htmlFor={value}>{value}</label>
                  </div>
              );
            })
          }

        </>}
      {editingMode && 
        <Button
          onClick={() => {
            dispatch(updateSingleUser({userId, newValue, editingKey}));
            setEditingMode(false);
          }}
          variant='text'
          color='primary'
          size="small"
        >
          Update
        </Button>
      }

      </StyledTableCell>
    </StyledTableRow>
  )
}
