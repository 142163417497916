

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_UNTIL_DATE_FILTER } from "../types";

// Actions
// import { setAlert } from "../alertActions/setAlert";



export const setUntilDateForFilter = ({
  newDate = null,
  isReset = false, 
}) => async dispatch => {
  dispatch({
    type: SET_UNTIL_DATE_FILTER,
    payload: {
      isReset,
      newDate
    }
  });
}  

