
// Resource:
// https://stackoverflow.com/questions/50722477/convert-input-value-to-currency-format-when-user-type

export const convertInputToCurrencyText = ({
  inputValue
}) => {
  try {
    const value = inputValue.replace(/,/g, '');
    const converted =   parseFloat(value).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
    if (converted === 'NaN' ) {
      return '0.00';
    }
    return converted;
  } catch (err) {
    return '0.00';
  }
} 