import React from 'react'
import {
  StyledTableCell ,
} from '../../components/table/StyledTableComponents'


// import {
//   TableHead,
//   TableRow,
// } from '@material-ui/core';
// Styles


// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



export const EventTableHead = () => {


  return (
    <TableHead id='manidar-mani-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>Type</StyledTableCell>
        <StyledTableCell size= 'small' align='left'>Event Title</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Language</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Date</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Max. Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Attendee</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Ticket</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Status</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
