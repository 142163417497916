

export const clientAppConstants = {
  jsonConfig: {
    headers: {
      "Content-Type": "application/json"
    }
  },
  multipartFormDataConfig: {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  },
  chatMessageTypes: {
    PLAIN_TEXT_MESSAGE: 'PLAIN_TEXT_MESSAGE',
    PLAIN_TEXT_MESSAGE_AS_RESPONSE: 'PLAIN_TEXT_MESSAGE_AS_RESPONSE',
    AUDIO_MESSAGE: 'AUDIO_MESSAGE',
    AUDIO_MESSAGE_AS_RESPONSE: 'AUDIO_MESSAGE_AS_RESPONSE',
    SINGLE_IMAGE_MESSAGE: ' SINGLE_IMAGE_MESSAGE',
    MULTIPLE_IMAGE_MESSAGE: ' MULTIPLE_IMAGE_MESSAGE',
  },
  isDeletingBackGroundColor: '#f53827',
  pagePaginationFilterCount: 15,
  buttonIconBlueColor: '#3949AB',
  screenContainerMinHeight: '100vh',
  pageContainerBackgroundColor: '#fff',
  screenDisplaySpaceBetweenStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'space-between'
  },
  categoryIconList: [
    'mucevher',
    'kalp',
    'tekrar cevir',
    'ask oku',
    'stretoskop',
    'dart tahtasi',
    'para kesesi',
    'arkadaslar',
    'aile',
    'kupon',
  ],
  socialAccountTypeList: [
    'instagram',
    'twitter',
    'facebook'
  ],
  temporaryAccountLimitationsList: [
    { value: 'day', definition: 'Gunluk Limit'  },
    { value: 'week', definition: 'Haftalik Limit'  },
    { value: 'month', definition: 'Aylik Limit'  },
  ],
  adminUserAddList: [ // 'admin' || 'editor' || 'writer'  || 'superAdmin' (developer)
    { value: 'editor', definition: 'Editor' },
    { value: 'writer', definition: 'Yazar' },
  ],
  filterComponentClassname : 'pr-1 pb-1',
  linkButtonTextColor: 'rgb(25, 118, 210)',
  platformOptions: [
    {label: 'paypal'},
    {label: 'tinkoff'},
    {label:'panel'},
    {label: 'subscription'},
  ],
  platformOptionsObject: {
    PAYPAL: 'paypal',
    TINKOFF: 'tinkoff',
    ADMIN_PANEL: 'panel',
    SUBSCRIPTION: 'subscription',
    STRIPE: 'stripe',
    ROBOKASSA: 'robokassa',
  },
  activityTypeOptions: [
    { label: 'Add Balance' },
    { label: 'Event Cancelled' },
    { label: 'Event Create' },
    { label: 'Refund' },
    { label: 'Refund From Balance' },
    { label: 'Reservation' },
    { label: 'Subscription Purchase' },
    { label: 'Ticket Cancel+Refund' },
    { label: 'Ticket Purchase' },
  ],
  onlineOfflineOptions: [
    { label: 'Online' },  { label: 'Offline' },
  ],
  isDismissedOptions: [
    { label: 'Dismissed' },  { label: 'Not Dismissed' },
  ],
  ticketReservationOptions: [
    {label: 'Ticket'},
    {label:'Reservation'},
  ],
  contactMessageDismissedOptions: [
    { label: 'Dismissed' },  { label: 'No Dismissed' },
  ],
  languageLevelOptionList: [
    { value: 'A1', definition: 'A1' },
    { value: 'A2', definition: 'A2' },
    { value: 'B1', definition: 'B1' },
    { value: 'B2', definition: 'B2' },
    { value: 'C1', definition: 'C1' },
    { value: 'C2', definition: 'C2' },
    { value: 'D1', definition: 'D1' },
    { value: 'D2', definition: 'D2' },
  ],

  chatScreenAppbarHeight: '45px',
  chatScreenSendMessageHeight: '45px',
  chatScreenChatContainerHeight: `calc( 100vh - 90px )`,

}



export const urlConstants = {

  apiUrl: "http://localhost:5000",

  // AUTH
  adminLoginUrl : "/api/admin/auth/auth",       // POST
  loadAdminnUrl : "/api/admin/auth/load-user",  // GET
  //CHAT
  chatSendRecordedVoice: "/api/user/send-recorded-voice",
  chatSendImage: "/api/user/send-image",
  chatListenRecordedVoice: (id) => `/api/user/listen-recorded-voice/${id}`,
  // ACTIVITY
  fetchActivityList: "/api/admin/activity/get-activity-list", // POST
  fetchUserActivityListUrl: userId =>  "/api/admin/activity/get-user-activity-list/" + userId, // POST
  // INTEREST
  fetchInterestUrl : "/api/admin/interest",
  addInterestUrl : "/api/admin/interest",
  updateInterestUrl : interestId => "/api/admin/interest/info/" + interestId,
  deleteInterestUrl : interestId => "/api/admin/interest/info/" + interestId,
  // LANGUAGE
  fetchLanguageUrl : "/api/admin/language",
  addLanguageUrl : "/api/admin/language",
  updateLanguageUrl : languageId => "/api/admin/language/info/" + languageId,
  deleteLanguageUrl : languageId => "/api/admin/language/info/" + languageId,
  // NOTIFICATION
  createNotificationUrl : "/api/admin/notification",  // POST
  getNotificationListUrl : "/api/admin/notification/get-notification-list",  // POST
  getSingleNotificationUrl : notificationId =>  "/api/admin/notification/get-single-notification/" + notificationId,  // GET
  // PUBLIC
  checkResetPasswordTokenUrl : "/api/admin/auth/check-reset-password-token",  // POST
  resetPasswordViaTokenUrl : "/api/admin/auth/reset-password",  // POST
  validateEmailViaTokenUrl : "/api/admin/auth/validate-user-email",  // POST
  // STATISTICS
  getDashboardStatisticUrl : "/api/admin/statistic/get-dashboard-statistics",  // GET
  // SUBSCRIPTION
  fetchSingleSubscriptionDetails: subscriptionId =>  "/api/admin/subscription/get-subscription-details/" + subscriptionId, // POST
  toggleSubscriptionActive: subscriptionId =>  "/api/admin/subscription/toggle-active/" + subscriptionId, // POST '',
  // SUBSCRIPTION CLASS
  addSubscriptionClass: "/api/admin/subscription-class/add-subscription-class", // POST
  addSubscription: '/api/admin/subscription/add',
  updateSubscriptionClassUrl : subsciptionClassId => "/api/admin/subscription-class/info/" + subsciptionClassId,  // PUT
  fetchSubscriptionClassList: "/api/admin/subscription-class/get-subscription-list", // POST
  getSubscriptionClassAddEditEntities: "/api/admin/subscription-class/get-subscription-class-add-edit-entities", // POST
  // USERS
  fetchHostList : "/api/admin/user/get-host-list",  // GET
  fetchUserList : "/api/admin/user/get-user-list",  // POST
  getSingleUser : userId => "/api/admin/user/get-single-user/" + userId,
  getSingleUserAvatar : imageId => "/api/admin/user/get-user-avatar/" + imageId,
  updateSingleUser : "/api/admin/user/update-single-user", // POST
  userAutocomplete: "/api/admin/user/autocomplete", // GET
  setUserAsHost : "/api/admin/user/set-user-as-host", // POST
  setUserAsVerifier : "/api/admin/user/set-user-as-verifier", // POST
  getUserTicketList : userId => "/api/admin/user/get-user-ticket-list/" + userId,  // POST
  // CLASSROOM
  fetchClassroomListForFiltering: "/api/admin/classroom/get-classroom-list-for-filtering", // GET
  fetchClassroomList: "/api/admin/classroom/get-classroom-list", // POST
  getClassroomAddEditEntities: "/api/admin/classroom/get-classroom-add-edit-entities", // POST
  getClassroom: id => `/api/admin/classroom/get-classroom/${id}`, // GET
  addClassroom: "/api/admin/classroom/add-classroom", // POST
  hideClassroom: "/api/admin/classroom/hide-classroom/", // POST
  updateClassroom: classroomId =>  "/api/admin/classroom/update-classroom/" + classroomId, // PUT
  updateClassroomNoImage: classroomId =>  "/api/admin/classroom/update-classroom-no-image-update/" + classroomId, // PUT
  // CONTACT MESSAGES
  fetchContactMessageListUrl : "/api/admin/contact-message/get-contact-message-list",  // POST
  updateIsDismissedStatusOfContactMessageItemtUrl : contactMessageId =>  "/api/admin/contact-message/update-is-dismissed-contact-message/" + contactMessageId,  // POST
  dismissAllContactMessagestUrl : "/api/admin/contact-message/dismiss-all-contact-messages",  // POST
  fetchPublicContactMessagesUrl: "/api/admin/contact-message/get-public-contact-messages",//GET
  // COUNTRY
  fetchCountryList: "/api/admin/location/get-country-list", // POST
  fetchCountryCityList: "/api/admin/location/get-city-list-by-countryid", // POST
  addCityUrl : countryId => "/api/admin/location/add-city/" + countryId,  // POST
  updateCityUrl : cityId => "/api/admin/location/update-city/" + cityId,  // PUT
  deleteCitytUrl : cityId => "/api/admin/location/delete-city/" + cityId,
  // EVENT
  fetchEventList: "/api/admin/event/get-event-list", // POST
  getEvent: eventId => `/api/admin/event/get-event/${eventId}`, // GET
  createEvent: classRoomId => `/api/admin/event/create-event/${classRoomId}`, // POST
  updateEvent: eventId => `/api/admin/event/update-event/${eventId}`, // PUT
  getEventAddEditEntities: "/api/admin/event/get-event-add-edit-entities", // POST
  createEventViaClassroomImageUpdated: classroomId =>  "/api/admin/event/create-event-image-updated/" + classroomId, // POST
  createEventViaClassroomNoImage: classroomId =>  "/api/admin/event/create-event-no-image-update/" + classroomId, // POST
  updateEventImageUpdated: eventId =>  "/api/admin/event/update-event-image-updated/" + eventId, // PUT
  updateEventNoImage: eventId =>  "/api/admin/event/update-event-no-image-update/" + eventId, // PUT
  checkLatestEventViaEventIdUrl: eventId =>  "/api/admin/event/check-latest-event-via-event-id/" + eventId, // GET
  copyEventUrl: eventId =>  "/api/admin/event/copy-event/" + eventId, // POST
  cancelEventUrl: eventId =>  "/api/admin/event/cancel/" + eventId, // POST
  getTicketListOfEventUrl:   eventId => "/api/admin/event/event-ticket-list/" +  eventId , // POST
  eventAutocomplete: "/api/admin/event/autocomplete", // GET
  // TICKET
  getSingleTicketDetailUrl:   ticketId => "/api/admin/ticket/get-ticket-detail/" +  ticketId , // GET
  getTicketVerificationListlUrl:    "/api/admin/ticket/get-ticket-verification-list" , // POST
  getTickets: "/api/admin/ticket/get-tickets", // GET

  // PROMO CODES
    fetchPromoCodeList: "/api/admin/promo-code/list", // GET
    createPromoCode: "/api/admin/promo-code/create", // POST
    updatePromoCode: promoCodeId => "/api/admin/promo-code/update/" + promoCodeId, // PUT
  getSubscriptions: '/api/admin/subscription/get-subscriptions',
  fetchHosts: '/api/admin/hosts',
  fetchTransactions: '/api/admin/transactions',
  createTransaction: '/api/admin/transactions',

}

export const filterTypes = {
  DATE: "date",
  SEARCH: "search",
  SELECT: "select",
  NUMBER: "number",
  BOOLEAN: "boolean",
}

export const  transaction = {
  type: {
    INCOME: "income",
    PAYOUT: "payout",
  },
  platform: {
    STRIPE: "stripe",
    MANUAL: "manual",
    SYSTEM: "system",
  },
  status: {
    PENDING: "pending",
    SUCCESS: "success",
    FAILED: "failed",
  }
};
