import {EventForm} from "./EventForm";
import {Container} from "@material-ui/core";
import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useEffect} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {useHistory, useParams } from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";

export const CreateEvent = () => {
    const history = useHistory();
    const {classroomId} = useParams();
    const [classRoom, setClassRoom] = React.useState(null);

    const fetchClassroom = async () => {
        try {
            const response = await axios.get(urlConstants.getClassroom(classroomId));
            setClassRoom(response.data.data);
        } catch (e) {
            toast(e.message);
        }
    };

    console.log(classRoom);
    useEffect(() => {
        fetchClassroom();
    }, []);
    return (
        <Container
            id='user-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Create event</title></Helmet>
            <ScreenTitle
                titleText="Create event"
            />
            {classRoom ? <EventForm isClassroom={false} baseItem={classRoom} onSuccessfulSubmit={() => {
                history.push('/event');
            }} /> : null}
        </Container>
    );
}