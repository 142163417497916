

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK,  } from "../types";

// Actions



export const handleOnlineOfflineItemClick = ({
  onlineOfflineItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK,
    payload: onlineOfflineItem
  });
}  