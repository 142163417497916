import React, { useState } from 'react'
import{  NavLink, useHistory } from 'react-router-dom'

// Constants
import { clientAppConstants } from '../../constants';




// Material Components
import {
  Button,
  IconButton,
} from '@material-ui/core';

// MUI

// MUI Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { EventTicketsIconButton } from '../../components/buttons/EventTicketsIconButton';
import { Stack } from '@mui/material';
import {displayEventDate} from "../../helpers/dateAndTime/eventDate";



export const EventItem = ({
  eventItem
}) => {
  const {
    _id,
    isEventOnline,
      language,

      //-----------------
      isNoTicketEvent,  // this is for offline events, where users pay money to place. we are only providing information about event
      eventOrganiserPrice,  // this is price that people pay at entrance, not related with our app, we are only showing to inform users
      //-----------------
      ticketPrice,
      tickets,
      isCanceled,
      eventDate,

      maxAttendee,

      ticketList,
      timezone,
      eventTitle,




  } = eventItem;
  const history = useHistory();
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        {isEventOnline ? 'Online' : 'Offline'}
      </StyledTableCell>
      <StyledTableCell
        size='small'
      >
        <Button
          variant="text"
          style={{
            textTransform: 'none',
            lineHeight: 1.2,
            // color:'teal',
            color: clientAppConstants.linkButtonTextColor,
          }}
          // onClick={() => {
          //   history.push('/event/edit/' + '_id')
          // }}
          component={NavLink}
          // to={'/event/edit/' + _id}
          // to={'/classroom/edit/' + classroomId}
          to={'/event/view/' + _id}
        >
          {eventTitle}
        </Button>
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {language.languageTitle}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
          {displayEventDate(eventDate, timezone)}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {maxAttendee}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
          {tickets.length || 0}
      </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
        {
          isNoTicketEvent
          ?
          ( '$' + eventOrganiserPrice + ' (Org.)' )
          :
          ( '$' + ticketPrice ? ticketPrice.toFixed(2) : '0' )
        }
      </StyledTableCell>
      <StyledTableCell
        align='right'
        size='small'
      >
          {isCanceled ? 'Canceled' : 'Active'}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        <Stack direction='row'>

          <IconButton
            size='small'
            component={NavLink}
            // to={`/event/edit/${_id}`}
            to={`/event/edit/${_id}`}
          >

            <MoreHorizIcon
            />
          </IconButton>
          <EventTicketsIconButton eventId={_id} />
        </Stack>

      </StyledTableCell>
    </StyledTableRow>
  )
}
