import {
  TICKET_VERIFICATION_LIST_FETCHED,
  SET_TICKET_VERIFICATION_LIST_LOADING,
} from "../actions/types";


const initialState = {
  ticketVerificationList: [],
  isLoadingTicketVerifications: true,
  isTicketVerificationsFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case TICKET_VERIFICATION_LIST_FETCHED:      
      return {
        ...state,
        ticketVerificationList: [...payload],
        isLoadingTicketVerifications: false,
        isTicketVerificationsFetched: true,
      };
    case SET_TICKET_VERIFICATION_LIST_LOADING:      
      return {
        ...state,
        ticketVerificationList: payload === true ? [] : state.ticketVerificationList,
        isLoadingTicketVerifications: payload
      }; 

    

    default:
      return state;
  }
}