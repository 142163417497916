import React, {useState} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';

import {
  Container,
  Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomPagination } from '../../components/CustomPagination';
import { AsyncAutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AsyncAutoCompleteMultipleCheckbox';
import { AutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AutoCompleteMultipleCheckbox';
import { CustomDateTime2 } from '../../components/form/CustomDateTime2';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import CustomCollapsibleTable from '../../components/table/collapsableTableComponents/CustomCollapsableTable';
import { CustomMuiTable } from '../../components/table/CustomMuiTable';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { HostCalculationInformationComponent } from './HostCalculationInformationComponent';
import { CollapsableEventRow } from './muiComponents/CollapsableEventRow';
import { HostCalculationMuiTableHead } from './muiComponents/HostCalculationMuiTableHead';


// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';



export const  HostUserCalculationScreen = ({

  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);


  const [userId, setUserId] = useState(match.params.userId || null);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();






  // Filter Parameters for User
  const _initialFilteringParameters = {
    onlineOffline: [],
    dateRange: [ null, null ],
    // registrator: [],
    // approver: [],
    // status: [],
    orderBy: null, // 'Goruntulenme' etc
    // order: 'asc'  // 'asc' || 'desc'
  };
  const _initialOrder = 'asc';
  const [_filteringParameters, _setFilteringParameters] = useState({ ..._initialFilteringParameters });
  const [order, setOrder] = useState(_initialOrder);  // 'asc' || 'desc' || null
  const _setOnlineOfflineFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, onlineOffline: [...newValue] });
  }
  const _setDateRangeFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, dateRange: [...newValue] });
  }
  


  let _isFilteringActive = () => {    
    if( 
      true      
      // _filteringParameters.categories.length > 0
      // || _filteringParameters.registrator.length > 0
      // || _filteringParameters.approver.length > 0
      // || _filteringParameters.status.length > 0
      // || _filteringParameters.orderBy !== null
    ) {
      return true;
    }
    return false;
  }






  const OnlineOfflineFilter = () => (
    <Grid item className='pb-1 pr-1 pt-2' 
      sm={12} 
      md={6} lg={4}
      >
      <AutoCompleteMultipleCheckbox 
        options={[ ...clientAppConstants.onlineOfflineOptions]}
        autoCompleteLabel='Online-Offline'
        placeHolder=''
        htmlId='event-screen-online-offline-autocomplete'
        handleAutoCompleteOnChange={
          ( e, newValue ) => {
            _setOnlineOfflineFilter( newValue );
        }}
      />
    </Grid>
  );



  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _eventTicketFilterOptions, _seteventTicketFilterOptions ]  = useState([
    { label: 'Online-Offline', isChecked: false, component: OnlineOfflineFilter, 
      defaultFilterValue: _initialFilteringParameters.onlineOffline  ,
      defaultFilterKey: 'onlineOffline'  ,
    },
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }


  

  const _dummyHostEventList2 = [
    {
      row: {
        eventTitle: 'English Event',
        subscriptionTitle: '-',
        isReservation: true,
        isEventOnline: true,
        maxAttendee: 30,
        ticketCount: 18,
        reservationCount: 2,
        ticketPrice: 5.00,
        totalTransaction: 150.00,
        eventDate: Date.now(),
        purchasePlatform: [
          'paypal', 'admin-panel'
        ],
        payment: 4.00,
        ticketStatus: 'Awaiting Event'
      },
      collapse: (<h3>COLLAPSE DATA HERE</h3>)
    },
    {
      row: {
        eventTitle: 'Russian Event',
        subscriptionTitle: '30 Days Super Package',
        isReservation: false,
        isEventOnline: true,
        maxAttendee: 40,
        ticketCount: 18,
        reservationCount: 2,
        ticketPrice: 4.00,
        totalTransaction: 150.00,
        eventDate: Date.now(),
        purchasePlatform: [
          'subscription', 
        ],
        payment: undefined,
        ticketStatus: 'Awaiting Event'
      },
      collapse: (<h3>COLLAPSE DATA HERE</h3>)
    },
    {
      row: {
        eventTitle: 'Chinese Event',
        subscriptionTitle: '30 Days Super Package',
        isReservation: false,
        isEventOnline: false,
        maxAttendee: 25,
        ticketCount: 18,
        reservationCount: 2,
        ticketPrice: 4.00,
        totalTransaction: 150.00,
        eventDate: Date.now(),
        purchasePlatform: [
          'paypal', 'admin-panel'
        ],
        payment: 4.00,
        ticketStatus: 'Awaiting Event'
      },
      collapse: (<h3>COLLAPSE DATA HERE</h3>)
    },
  ]




  return (
    <Container
      id='host-user-calculation-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      // className='flexcol justify-content-space-between'
    >
      <Helmet defer={false}><title>{'H. Calc. - Emrah Ozyazgan'}</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <Button 
                size="small"
                style={{
                  // color: 'black'
                  fontSize: '1.2rem'
                }}
                component={NavLink}
                color={  'primary'  }
                to={'/user/edit/' + userId}
              >
                Emrah Ozyazgan
              </Button>
              <span>{'/ Host Calculation'}</span>
              <FilterDialogButton
                filterOptions={ _eventTicketFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  _setIsHideActiveUiFilters(false);
                  _seteventTicketFilterOptions(
                    [
                      ..._eventTicketFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  _setFilteringParameters({..._initialFilteringParameters});
                  setOrder(_initialOrder);  
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._eventTicketFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component
                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                    _setFilteringParameters({
                      ..._filteringParameters,
                      [_clickedOption.defaultFilterKey]: _clickedOption.defaultFilterValue
                    });
                  }
                  _setIsHideActiveUiFilters(false);
                  _seteventTicketFilterOptions(
                    [
                      ..._eventTicketFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                    onClick={() => {
                      _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    }}
                  >
                    {
                      _isHideActiveUiFilters 
                        ?
                        <VisibilityIcon color='error' />
                        :
                        <VisibilityOffIcon />

                    }
                    
                  </IconButton>
                )
              }
            </>
          )}
        />

        <HostCalculationInformationComponent 
          dateRangeValue={ _filteringParameters.dateRange }
          handleDateRangeChange={ _setDateRangeFilter }          
        />

        {/* FILTERING COMPONENTS */}
        <Grid item className={ _isHideActiveUiFilters ? 'd-none' : '' } >
          <Grid container  >
          {
            // !_isHideActiveUiFilters
            // &&
            _filterUiElements && _filterUiElements.length > 0 &&
            _filterUiElements.map(
              ( item , ind )=> {
                return (
                  <React.Fragment key={ind}>
                    { item.component &&  item.component() }
                  </React.Fragment>
                );
              }
            )
          }
          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}

        <Grid item className='pb-1' />




        <CustomMuiTable tableClassname='speaklabs-mui-collapsable-table'>
          <HostCalculationMuiTableHead />
          {
            _dummyHostEventList2.map(
              (eventItem, ind) => (
                <CollapsableEventRow
                  key={ind}
                  eventItem={eventItem.row}
                  collapse={eventItem.collapse}
                />

              )
            )
          }
        </CustomMuiTable>






       

        


      </Grid>
      
      {
        _isFilteringActive && (
          <Grid container sm={12} className={'w-100 mb-2'}>
            <Grid item sm={12} className={'w-100'}>
              <Paper className='pr-1'>
                <Stack 
                  direction='row'
                  justifyContent='end'
                >
                  <p
                    // style={{
                    //   fontWeight: 'bold',                      
                    // }}
                    className='font-weight-bold mr-1'
                  >
                    Total Sum:
                  </p>
                  <p
                    className='font-weight-bold mr-1'
                  >
                    {'$' + '75.00'}
                  </p>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        )
      }
    </Container>
  )
}


