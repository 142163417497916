

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK,  } from "../types";

// Actions



export const handleIsDismissedItemClick = ({
  isDismissedItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK,
    payload: isDismissedItem
  });
}  