import axios from "axios";

// Redux
import store from '../../store'


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const updateIsDismissedStatusOfContactMessage = async  ({
  contactMessageId,
  newIsDismissedValue,
  cb,  // callBack
}) =>   {
  try { 
    const formData = {
      newIsDismissedValue
    }


    const res = await axios.post(
      // urlConstants.apiUrl +  
      urlConstants.updateIsDismissedStatusOfContactMessageItemtUrl(contactMessageId), 
      formData ?? {},
      clientAppConstants.jsonConfig
    )

    store.dispatch(setAlert(res.data.msg, "success", 2500));
    
    store.dispatch(
      {
          type: CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED,
          payload: res.data.contactMessage
      }
    );
    if ( cb ) {      
      cb(res.data);      
    }
    


  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => store.dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
}  