import React from 'react'


// MUI
import Button from '@mui/material/Button';

// Icons
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';


// Components
import {NotificationDialog} from './NotificationDialog';

export const SendNotificationButton = ({

                                           filteringParameters,
                                           userCount,
                                           isSingleUser
                                       }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant='contained'
                color='secondary'
                // component={NavLink}
                // to={'/event/tickets/' + 'eventTickets11' }
                endIcon={<NotificationAddIcon/>}
                onClick={() => {
                    handleClickOpen()
                }}
            >
                Send Notification {isSingleUser && " to User"}
            </Button>
            <NotificationDialog
                isOpen={open}
                handleClose={handleClose}
                filteringParameters={filteringParameters}
                userCount={userCount}
            />
        </>
    )
}
