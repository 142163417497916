import React, {  useState } from 'react';
import {  useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import {
  NavLink,
  withRouter,
  useHistory
} from 'react-router-dom';

// Actions
// import {
//   logout
// } from '../../actions/authActions';
// import authContext from '../../../context/auth/authContext'

import {
  Drawer,
  Button,
  makeStyles,
  // FOR MODAL
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // End of FOR MODAL
} from '@material-ui/core';
import {
  // createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'


import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// MUI
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';

// Style
import './Navbar.css'

// Actions
import { setNavbarOnOff } from '../../actions/decorationActions/setNavbarOnOff';
import { setHideNavButtons } from '../../actions/decorationActions/setHideNavButtons';
import { logout } from '../../actions/authActions/logout';

const Navbar = ({
  handleClose,
  // Actions
  // logout,
  location,
  // history,


}) => {



  // Global Variables
  const isNavbarOn = useSelector(state => state.decorationReducer.isNavbarOn);
  const isHideNavButtons = useSelector(state => state.decorationReducer.isHideNavButtons);

  const loading = useSelector(state => state.authReducer.loading);
  const userType =  useSelector(state => state.authReducer.userType);
    console.log(userType);
    const user =  useSelector(state => state.authReducer.user);
  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  const appInitialised = useSelector(state => state.authReducer.appInitialised);

  const dispatch = useDispatch();

  const history = useHistory();

  // const userType = 'admin';
  // const loading = false;
  // const user = 'fake user';
  // const isAuthenticated = true;


  let classes;
  const useStyles = makeStyles( () => ({
    mobileDrawer: {
      width: 180
    },
    desktopDrawer: {
      width: 180,
      // top: 64,
      backgroundColor:'#777'
    }
  }));
  classes=useStyles();

  const CustomNavButton = ({content, navPath, onClick, isAppbar = false}) => {
    const _sidebarButtonTheme = createTheme({
      palette: {
        primary: {
          main: '#fafafa',  // you can not write any hex color here. there is a list of colors supported. For further info, look at material ui colors
        },
      }
    });
    if ( isAppbar ) {
      return (
        <ThemeProvider theme={_sidebarButtonTheme}>
          <Button
            component={NavLink}
            color={  'primary'  }
            to={navPath}
            variant= { location.pathname === navPath ? 'outlined' : 'inherit'}
          >
            {content}
          </Button>
        </ThemeProvider>
      );
    }
    if ( onClick ) {
      return (
        <ThemeProvider theme={_sidebarButtonTheme}>
          <Button
            onClick={onClick}
          >
            {content}
          </Button>
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider theme={_sidebarButtonTheme}>
        <Button
          component={NavLink}
          color={ location.pathname === navPath ? 'secondary' : 'primary'  }
          to={navPath}
        >
          {content}
        </Button>
      </ThemeProvider>
    );
  }  // End of CustomNavButton

  const [_isLogoutModalOpen, _setIsLogoutModalOpen] = useState(false);
  const _handleClickOpenLogoutModal = () => {
    _setIsLogoutModalOpen(true);
  }
  const _handleClickCloseLogoutModal = () => {
    _setIsLogoutModalOpen(false);
  }
  const _LogoutModalDialog = () => {
    return (
      <Dialog
          open={_isLogoutModalOpen}
          onClose= {_handleClickCloseLogoutModal}
          aria-labelledby={`logout-modal-title`}
          aria-describedby={`logout-modal-description`}
        >
          <DialogTitle
            id={`logout-modal-title`}
          >
            Logout Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={`logout-modal-description`}>
              Please Confirm Logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={_handleClickCloseLogoutModal} color='inherit'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                _handleClickCloseLogoutModal();
                localStorage.removeItem('token');
                dispatch(
                  logout()
                );
                history.push('/');

                // logout();
              }}
              color='secondary'
            >Logout</Button>
          </DialogActions>
        </Dialog>
    );
  }



  const adminMenu = () => (
    <>
        <div className='pt-2'></div>
        {
          (userType === 'admin' || userType === 'superAdmin') && (
            <CustomNavButton
              navPath= '/dashboard'
              content='Dashboard'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin') && (
            <CustomNavButton
              navPath= '/activities'
              content='Activities'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin') && (
            <CustomNavButton
              navPath= '/classroom'
              content='Classrooms'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin') && (
            <CustomNavButton
              navPath= '/event'
              content='Events'
            />
          )
        }
        {
          (userType !== 'writer' ) && (
            <CustomNavButton
              navPath= '/user'
              content='Users'
            />
          )
        }
        <CustomNavButton
          navPath= '/language'
          content='Languages'
        />
        <CustomNavButton
          navPath= '/interest'
          content='Interests'
        />
        {
          (userType === 'admin' || userType === 'superAdmin' || user.canPerformCouponActions === true) && (
            <CustomNavButton
              navPath= '/contact-messages'
              content='Contact Messages'
            />
          )
        }
        {
            (userType === 'admin' || userType === 'superAdmin' || user.canPerformCouponActions === true) && (
                <CustomNavButton
                    navPath= '/tickets'
                    content='Tickets'
                />
            )
        }
        {
          (userType === 'admin' || userType === 'superAdmin' || user.canPerformCouponActions === true) && (
            <CustomNavButton
              navPath= '/subscription'
              content='Subscriptions'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin' || user.canPerformJobActions === true) && (
            <CustomNavButton
              navPath= '/chats'
              content='chats'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin' || user.canPerformNotificationActions === true) && (
            <CustomNavButton
              navPath= '/notification'
              content='Notifications'
            />
          )
        }
        {
          (userType === 'admin' || userType === 'superAdmin' || user.canPerformNotificationActions === true) && (
            <CustomNavButton
              navPath= '/ticket-verification'
              content='Verifications'
            />
          )
        }
        <CustomNavButton
          navPath= '/country'
          content='Countries'
        />
        <CustomNavButton
            navPath= '/promo-codes'
            content='Promo Codes'
        />
        <CustomNavButton
            navPath= '/hosts'
            content='Hosts'
        />
        <CustomNavButton
            navPath= '/transactions'
            content='Transactions'
        />
        <CustomNavButton
            navPath= '/public-contact-form-messages'
            content='Contact Form'
        />
        <CustomNavButton
          navPath= '/my-account'
          content='My Account'
        />
        {/* <CustomNavButton
          navPath= '/gambling-wheel'
          content='Cark'
        /> */}
        {/* <CustomNavButton
          navPath= '/dashboard/services'
          content='Services'
        />
        <CustomNavButton
          navPath= '/dashboard/employee-list'
          content='Employee'
        />
        <CustomNavButton
          navPath= '/dashboard/settings'
          // content={<TuneIcon />}
          content={'Settings'}
        />      */}
        <CustomNavButton
          onClick= { _handleClickOpenLogoutModal }
          content={
            <>
              <p style={{color:'#fafafa', marginLeft:'.75rem'}}>Logout</p>
              <ExitToAppIcon color='primary'/>
            </>
          }
        />
        <_LogoutModalDialog/>
    </>
  );  // End of adminMenu

  const userMenu = () => (
    <>
      <CustomNavButton
        navPath= '/user-main'
        content='Orders'
      />
      <CustomNavButton
        navPath= '/user-main/account'
        content='Account'
      />
      <CustomNavButton
        onClick= { _handleClickOpenLogoutModal }
        content={
          <>
            <p style={{color:'#fafafa', marginLeft:'.75rem'}}>Logout</p>
            <ExitToAppIcon color='primary'/>
          </>
        }
      />
      <_LogoutModalDialog/>
    </>
  )


  return (
    <>



      {
        (
          userType === "admin"
          ||  userType === "editor" || userType === "writer" ||  userType === "superAdmin"
        ) && isAuthenticated
        && user && (
          <Drawer
            id='manidar-left-drawer'
            anchor='left'
            variant='permanent'
            classes={{paper: classes.desktopDrawer}}
          >
            {
              !isHideNavButtons && (

                <div className='flexrow justify-content-flex-end'>
                  <IconButton
                    // style={{
                    //   position: 'absolute',
                    //   left: open ?  'calc( 180px + 2rem )' : '2rem'
                    // }}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      dispatch(
                        setNavbarOnOff( false )
                      )
                    }}
                    edge="start"
                    // sx={{ mr: 2, ...(open && { display: 'none' }) }}
                  >

                    <ChevronLeftIcon
                      // fontSize={30}

                      style={{
                        fontSize: 40,
                        color: 'white'
                      }}
                    />
                  </IconButton>
                </div>
              )
            }
              {!loading &&
                (
                  userType === "admin"
                  ||  userType === "editor" || userType === "writer" ||  userType === "superAdmin"
                )
                &&
                user &&
                isAuthenticated &&
                adminMenu()}
              {!loading &&
                userType === "user" &&
                user &&
                isAuthenticated &&
                userMenu()}
          </Drawer>
        )
      }

    </>
  );
}






export default withRouter(Navbar);
