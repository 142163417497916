import React, {useCallback, useState} from 'react'


// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {createNotification} from "../../actions/notificationActions/createNotification";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

// Icons


export const NotificationDialog = ({
                                       filteringParameters,
                                       userCount = 0,
                                       isOpen,
                                       handleClose
                                   }) => {
    console.log(filteringParameters);

    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const onSubmit = async (e) => {
        e.preventDefault();
        dispatch(
            createNotification({
                formData: {
                    notificationTitle: title,
                    notificationMessage: message,
                    filteringParameters,
                },
                cb: apiResponse => {
                    toast("Notification sent!");
                    handleClose();
                    console.log('notification -> apiResponse -> ', apiResponse)
                }
            })
        )
    };
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <form onSubmit={onSubmit}>
            <DialogTitle>Send Notification to Event Participants</DialogTitle>
            <DialogContent>
                {filteringParameters && (
                    <DialogContentText>
                        <p>Notification will be sent to <strong>{userCount}</strong> users witch next filters like:</p>
                        {Object.entries(filteringParameters).map(([key, value]) => (
                            <p key={key}><strong>{key}:</strong> {Array.isArray(value) ? value.join(',') : value}</p>
                        ))}
                    </DialogContentText>
                )}
                <DialogContentText>
                    Please enter Notification Message and Notification Title.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Notification Title"
                    type="text"
                    fullWidth
                    // multiline
                    required={true}
                    variant="standard"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
                <TextField
                    value={message}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Notification Message"
                    type="text"
                    fullWidth
                    multiline
                    required={true}
                    variant="standard"
                    onChange={e => setMessage(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Send</Button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
