import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components

// Actions
import { setTicketReservationFilter } from '../../actions/filterActions/setTicketReservationFilter';
import { handleTicketReservationItemClick } from '../../actions/filterActions/handleTicketReservationItemClick';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;









export const TicketReservationFilterComponent = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const ticketReservation = useSelector(state => state.filterReducer.filteringParameters.ticketReservation);
  const dispatch = useDispatch();

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
    >
      <Autocomplete
        multiple
        id={ 'speaklabs-ticket-reservation-filter-autocomplete' }
        options={[...clientAppConstants.ticketReservationOptions]}
        onChange={(e, newVal )=> {
          dispatch(
            setTicketReservationFilter({
              newTicketReservationFilterList: newVal
            })
          )
        }}
        value={ticketReservation}
        // filterSelectedOptions
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleTicketReservationItemClick({
                    ticketReservationItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                // checked={selected} 
                checked={
                  ticketReservation.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params

              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  // <>YARRAK AMK</>
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'Ticket-Reservation'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  );
}



























































// import React from 'react'




// import {
//   Grid
// } from '@material-ui/core';


// // Components
// import { AutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AutoCompleteMultipleCheckbox';



// export const TicketReservationFilterComponent = ({
//   setTicketReservationFilter,
//   isDisplayNone = false
// }) => {
//   const _options = [  
//     { label: 'Ticket' },  
//     { label: 'Reservation' }, 
//   ]

//   const _additionalGridClassName = isDisplayNone ? ' d-none' : '';



//   return (
//     <Grid item className={'pb-1 pr-1 pt-2' +   _additionalGridClassName }
//     sm={12} 
//     md={6} lg={4}
//     >
//     <AutoCompleteMultipleCheckbox 
//       options={[ ..._options]}
//       autoCompleteLabel='Ticket-Reservation'
//       placeHolder=''
//       htmlId='ticket-reservation-autocomplete'
//       handleAutoCompleteOnChange={
//         ( e, newValue ) => {
//           setTicketReservationFilter( newValue );
//       }}
//     />
//   </Grid>
//   )
// }




