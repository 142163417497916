import React from 'react'
import{  NavLink } from 'react-router-dom'

// Constants
import { clientAppConstants } from '../../constants';




// Material Components
import {
  IconButton,
} from '@material-ui/core';

// MUI
import { Stack } from '@mui/material';

// MUI Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Components
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { EventTicketsIconButton } from '../../components/buttons/EventTicketsIconButton';



export const CountryItem = ({
  countryItem
}) => {
  const {
    _id,
    en,
  } = countryItem;
  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        { en }
      </StyledTableCell>
      
      <StyledTableCell
        align='center'
        size='small'
      >
        <Stack direction='row'>
            
          <IconButton
            size='small'
            component={NavLink}
            // to={`/event/edit/${_id}`}
            to={`/country/info/${_id}`}
          >
            
            <MoreHorizIcon
            />
          </IconButton>
          <EventTicketsIconButton eventId={'_id'} />
        </Stack>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
