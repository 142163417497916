




// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  SET_INTERESTS_FILTER } from "../types";





export const setInterestsFilter = ({  
  newInterestFilterList = [],
  isReset = false
}) => async dispatch => {
  dispatch({
    type: SET_INTERESTS_FILTER,
    payload: {
      isReset,
      newInterestFilterList: [ ...newInterestFilterList ]
    }
  });
}  




