// import React, {useState, useEffect} from 'react';

// import {

//   TableHead,
//   TableRow,
//   TableCell,
// } from '@material-ui/core';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// // Styles
// import {  withStyles , makeStyles} from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//   },
//   paper: {
//     width: '100%',
//     marginBottom: theme.spacing(2),
//   },
//   table: {
//     minWidth: 750,
//   },
//   visuallyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//     // color: theme.palette.common.white
//   },
// }));






// export const  StyledTableCell = withStyles( 
//   theme => {
//     const classes = useStyles();
//     return  (
//       {
//         head: {
//           backgroundColor: theme.palette.common.black,
//           color: theme.palette.common.white
//         },
//         body: {
//           // fontSize: 14
//           fontSize: 12.5
//         },
//       }
//     )
//   }
// )(TableCell)



// export const StyledTableRow = withStyles( (theme) => ({
//   root: {
//     '&:nth-of-type(odd)':{ 
//       backgroundColor:  theme.palette.action.hover,        
//     },
//   }
// }))(TableRow);



// export const StyledFilterableCell = ({
//   cellLabel,
//   align= 'right',
//   isActiveField = false,
//   order

// }) => {
//   const classes = useStyles();
//   return (
//     <StyledTableCell 
//       size='small' 
//       align={align}
//       sortDirection='asc'
      
//     >
//       <TableSortLabel
//         active={isActiveField}
//         direction={isActiveField ? order :  'asc' }
//         onClick={() => {
//           const isAsc = isActiveField && order === 'asc';
//           // setOrder(isAsc ? 'desc' : 'asc');
//           // setOrderBy( cellLabel );
//         }}
//         style={{color: 'white'}}
//       >
//         { cellLabel }
//         {
//           isActiveField 
//             ? 
//             (
//               <span className={classes.visuallyHidden}>
//                 {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
//                 {order === 'desc' ? 'sdfsdf' : 'sdfsd lksd l'}
//               </span>
//             )
//             :
//             null
//         }

//       </TableSortLabel>
//     </StyledTableCell>
//   )
// }



import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));