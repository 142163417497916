import axios from 'axios';

// Types
import {
  LOGOUT
} from '../../actions/types'

// Actions
import { setAlert } from '../../actions/alertActions/setAlert';


/* 
  resource: https://stackoverflow.com/questions/70205965/vuejs-axios-interceptors-not-recieving-401-response

  DONT FORGET TO UPDATE  AXIOS VERSION to MINIMUM 0.21.3

*/

export const axiosResponseMiddleware = ({
  store,
}) => {
  axios.interceptors.response.use(
    ( response ) => {
      return response;
    },
    (err) => {
      const res = err.response;
      if ( res?.status === 401 ) {
        store.dispatch({ type: LOGOUT });
      }
      if ( res?.status === 404 ) {
        store.dispatch(setAlert('404! Connection Error or Server is Not Accessible!', 'error', 2500));
      }
      return Promise.reject(err);
    }
  );
}