import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// MUI
import Grid from '@mui/material/Grid';


// Components
import { CustomSearchTextField } from '../form/textfield/CustomSearchTextField';

// Actions
import { setSearchQueryText } from '../../actions/filterActions/setSearchQueryText';


export const SearchQueryComponent = ({
  textfieldLabel='Search Users'
}) => {
  // Global Variables
  // const searchQueryText = useSelector(state => state.filterReducer.filteringParameters.searchQueryText);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <CustomSearchTextField
        onChange={ e => {
          dispatch(
            setSearchQueryText({
              newSearchTextValue: e.target.value
            })
          )
        }}
        htmlId='speaklabs-search-query-textfield'
        label={textfieldLabel}
      /> 
    </Grid>
  )
};