import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
// import {
//   fetchManiList,
//   resetAllUserAndManiHistory,
//   fetchFilteredManiList,
// } from '../../actions/maniActions';

import {
  Container,
  CircularProgress,
  // Button,
  TableContainer,
  Table,
  TableBody,
  // Paper,
  Grid
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// MUI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


// Components
import { CustomTable } from '../../components/table/CustomTable';
import { FormCustomTitle } from '../../components/typography/FormCustomTitle';
import { SubscriptionTicketsTableHead } from './SubscriptionTicketsTableHead';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { SubscriptionTicketItem } from './SubscriptionTicketItem';
import { CustomPagination } from '../../components/CustomPagination';
import { AsyncAutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AsyncAutoCompleteMultipleCheckbox';
import { AutoCompleteMultipleCheckbox } from '../../components/form/autoComplete/AutoCompleteMultipleCheckbox';
import { CustomDateTime2 } from '../../components/form/CustomDateTime2';
import { FilterDialogButton } from '../../components/filterDialog/FilterDialogButton';
import CustomCollapsibleTable from '../../components/table/collapsableTableComponents/CustomCollapsableTable';
import { CustomMuiTable } from '../../components/table/CustomMuiTable';
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { SubsciptionInformationComponent } from './SubsciptionInformationComponent';
import { LanguageFilterComponent } from '../../components/filter/LanguageFilterComponent';


// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { fetchSingleSubscriptionDetails } from '../../actions/subscriptionActions/fetchSingleSubscriptionDetails';



export const  SubscriptionEditScreen = ({

  // Location, History, etc
  match, // location -> match.params.eventId
  history,
}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);


  const [subscriptionId, setSubscriptionId] = useState(match.params.subscriptionId || null);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true);


  const [ formData, setFormData ] = useState ({});

  useEffect(() => {
    dispatch(
      fetchSingleSubscriptionDetails({
        subscriptionId,
        cb: apiResponse => {
          console.log('fetchSingleSubscriptionDetails -> cb')
          if ( apiResponse ) {
            if ( apiResponse.subscription ) {

              setFormData(apiResponse.subscription)
              _setLoading(false);

              
            }
          }
        }
      })
    );
  
    return () => {
      
    };
  }, []);
  



  const _dummyActivityTypeOptions = [
    { label: 'Refund' },  
    { label: 'Event Cancelled' },  
    { label: 'Ticket Purchase' },  
    { label: 'Refund From Balance' },  
    { label: 'Subscription Purchase' },  
    { label: 'Ticket  Cancel+Refund' },  
    { label: 'Reservation' },  
    { label: 'Add Balance' },  
  ]



  const _dummyClassrooms = [
    { label: 'French Speaking Club' },  { label: 'English Dancing' }, { label: 'Russian Karaoke' },
  ]
  const [ _classroomOptions, _setClassroomOptions] = useState([])
  const [ _isClassroomOptionsLoading, _setIsClassroomOptionsLoading ]= useState(false)
  const [  _isClassroomOptionsOpen, _setIsClassroomOptionsOpen ] = useState(false)


  // const _dummyLanguages = [
  //   { label: 'English' },  { label: 'French' },  { label: 'German' },  { label: 'Spanish' },
  // ]
  // const [ _languageOptions, _setLanguageOptions] = useState([])
  // const [ _isLanguageOptionsLoading, _setIsLanguageOptionsLoading ]= useState(false)
  // const [  _isLanguageOptionsOpen, _setIsLanguageOptionsOpen ] = useState(false)
  
  const _dummyHosts = [
    { label: 'George Best' },  { label: 'Grigory Leps' },  { label: 'Tom Cruise' },  { label: 'Maria Sharapova' },
  ]
  const [ _hostOptions, _setHostOptions] = useState([])
  const [ _isHostOptionsLoading, _setIsHostOptionsLoading ]= useState(false)
  const [  _isHostOptionsOpen, _setIsHostOptionsOpen ] = useState(false)

  // Filter Parameters for User
  const _initialFilteringParameters = {
    onlineOffline: [],
    classroom: [],
    searchQuery:  '',
    languages: [],
    hosts: [],
    interests: [],
    notification: [],
    email: [],
    platform: [],
    // registrator: [],
    // approver: [],
    // status: [],
    orderBy: null, // 'Goruntulenme' etc
    // order: 'asc'  // 'asc' || 'desc'
  };
  const _initialOrder = 'asc';
  const [_filteringParameters, _setFilteringParameters] = useState({ ..._initialFilteringParameters });
  const [order, setOrder] = useState(_initialOrder);  // 'asc' || 'desc' || null
  const _setOnlineOfflineFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, onlineOffline: [...newValue] });
  }
  const _setClassroomFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, classroom: [...newValue] });
  }
  const _setLanguageFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, languages: [...newValue] });
  }
  const _setHostFilter = (newValue) => {
    _setFilteringParameters({ ..._filteringParameters, hosts: [...newValue] });
  }


  let _isFilteringActive = () => {    
    if( 
      true      
      // _filteringParameters.categories.length > 0
      // || _filteringParameters.registrator.length > 0
      // || _filteringParameters.approver.length > 0
      // || _filteringParameters.status.length > 0
      // || _filteringParameters.orderBy !== null
    ) {
      return true;
    }
    return false;
  }






  const OnlineOfflineFilter = () => (
    <Grid item className='pb-1 pr-1 pt-2' 
      sm={12} 
      md={6} lg={4}
      >
      <AutoCompleteMultipleCheckbox 
        options={[ ...clientAppConstants.onlineOfflineOptions]}
        autoCompleteLabel='Online-Offline'
        placeHolder=''
        htmlId='event-screen-online-offline-autocomplete'
        handleAutoCompleteOnChange={
          ( e, newValue ) => {
            _setOnlineOfflineFilter( newValue );
        }}
      />
    </Grid>
  );


  
  // const LanguageFilterComponent = () => (
  //   <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName() }
  //     sm={12} 
  //     md={6} lg={4}
  //     // style={{backgroundColor: 'red'}}
  //     zeroMinWidth={true}
  //   >
  //     <AsyncAutoCompleteMultipleCheckbox
  //       isOpen={ _isLanguageOptionsOpen }
  //       handleAutoCompleteOnChange={
  //         (event, newValue) => {
  //           _setLanguageFilter( [ ...newValue ] )
  //         }
  //       }
  //       handleOnOpen={() => {
  //         if ( _languageOptions.length === 0 ) {
  //           _setIsLanguageOptionsLoading(true)
  //           _setIsLanguageOptionsOpen(true)
  //           setTimeout(() => {
  //             _setLanguageOptions( [ ..._dummyLanguages ] );
  //             _setIsLanguageOptionsLoading(false)
  //           }, 750)
  //         } else {
  //           _setIsLanguageOptionsOpen(true)
  //         }
  //       }}
  //       handleOnClose={() => {
  //         _setIsLanguageOptionsOpen(false)
  //       }}
  //       loading={  _isLanguageOptionsLoading }
  //       options = {[ ..._languageOptions ]}
  //       htmlId='user-screen-language-autocomplete'
  //       autoCompleteLabel='Languages'
  //     />
  //   </Grid>
  // );


  const ClassroomFilterComponent = () => (
    <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName()}
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isClassroomOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setClassroomFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _classroomOptions.length === 0 ) {
            _setIsClassroomOptionsLoading(true)
            _setIsClassroomOptionsOpen(true)
            setTimeout(() => {
              _setClassroomOptions( [ ..._dummyClassrooms ] );
              _setIsClassroomOptionsLoading(false)
            }, 750)
          } else {
            _setIsClassroomOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsClassroomOptionsOpen(false)
        }}
        loading={  _isClassroomOptionsLoading }
        options = {[ ..._classroomOptions ]}
        htmlId='event-screen-classroom-autocomplete'
        autoCompleteLabel='Classrooms'
      />
    </Grid>
  );


  const SinceDateFilterComponent = () => (
    <Grid item className='w-100 pb-1 pr-1'
      // style={{backgroundColor: 'yellow'}}
      sm={12} md={6} lg={4}
    >
      <Stack direction='column'>
        <FormCustomTitle
          formText='Since Date'
        />
        <CustomDateTime2  />
      </Stack>
    </Grid>
  );

  const UntilDateFilterComponent = () => (
    <Grid item className='w-100 '
      // style={{backgroundColor: 'yellow'}}
      sm={12} md={6} lg={4}
    >
      <Stack direction='column'>
        <FormCustomTitle
          formText='Until Date'
        />
        <CustomDateTime2  />
      </Stack>
    </Grid>
  );



  const HostFilterComponent = () => (
    <Grid item  className={'pb-1 pr-1 pt-2' + _isHideClassName()}
      sm={12} 
      md={6} lg={4}
      // style={{backgroundColor: 'red'}}
      zeroMinWidth={true}
    >
      <AsyncAutoCompleteMultipleCheckbox
        isOpen={ _isHostOptionsOpen }
        handleAutoCompleteOnChange={
          (event, newValue) => {
            _setHostFilter( [ ...newValue ] )
          }
        }
        handleOnOpen={() => {
          if ( _hostOptions.length === 0 ) {
            _setIsHostOptionsLoading(true)
            _setIsHostOptionsOpen(true)
            setTimeout(() => {
              _setHostOptions( [ ..._dummyHosts ] );
              _setIsHostOptionsLoading(false)
            }, 750)
          } else {
            _setIsHostOptionsOpen(true)
          }
        }}
        handleOnClose={() => {
          _setIsHostOptionsOpen(false)
        }}
        loading={  _isHostOptionsLoading }
        options = {[ ..._hostOptions ]}
        htmlId='user-screen-host-autocomplete'
        autoCompleteLabel='Hosts'
      />
    </Grid>
  )









  const [ _filterUiElements, _setFilterUiElements ] = useState([]);
  const[ _eventTicketFilterOptions, _seteventTicketFilterOptions ]  = useState([
    { label: 'Online-Offline', isChecked: false, component: OnlineOfflineFilter, 
      defaultFilterValue: _initialFilteringParameters.onlineOffline  ,
      defaultFilterKey: 'onlineOffline'  ,
    },
    { label: 'Classrooms', isChecked: false, component: null  ,
    defaultFilterValue: _initialFilteringParameters.classroom  ,
      defaultFilterKey: 'classroom'  ,
    },
    { label: 'Languages', isChecked: false, 
      // component: LanguageFilterComponent  
      component: null  ,
      defaultFilterValue: _initialFilteringParameters.languages  ,
      defaultFilterKey: 'languages'  ,
    },
    { label: 'Hosts', isChecked: false, component: null  ,
    defaultFilterValue: _initialFilteringParameters.hosts  ,
      defaultFilterKey: 'hosts'  ,
    },
    { label: 'Since-Date', isChecked: false, component: SinceDateFilterComponent  ,
    defaultFilterValue: null,
      defaultFilterKey: 'sinceDate'  ,
    },
    { label: 'Until-Date', isChecked: false, component: UntilDateFilterComponent  ,
    defaultFilterValue: null,
      defaultFilterKey: 'untilDate'  ,
    },
  ])
  const [ _isHideActiveUiFilters, _setIsHideActiveUiFilters ] = useState( false);
  const _isHideClassName = ( ) => {
    if ( _isHideActiveUiFilters ) return ' d-none';
    return '';
  }


  const _dummySubscriptionTicketList = [
    {
      eventTitle: 'English Wonderful Night',
      hostName: 'Alex Kostin',
      subscriptionTitle: '-',
      isEventOnline: true,
      language: 'Russian',
      isReservation: true,
      eventDate: Date.now(),
      purchasePlatform: [
        'paypal', 'admin-panel'
      ],
      payment: 4.00,
      ticketStatus: 'Awaiting Event'
    },
    {
      eventTitle: 'French Karaoke Event',
      hostName: 'Tatyana Kuznetsova',
      subscriptionTitle: '30 Days Super Package',
      isEventOnline: false,
      language: 'English',
      isReservation: false,
      eventDate: Date.now(),
      purchasePlatform: [
        'subscription', 
      ],
      payment: undefined,
      ticketStatus: 'Awaiting Event'
    },
    {
      eventTitle: 'Russian Speaking Club',
      hostName: 'Igor Ragozin',
      subscriptionTitle: '30 Days Super Package',
      isEventOnline: true,
      language: 'Chinese',
      isReservation: false,
      eventDate: Date.now(),
      purchasePlatform: [
        'paypal', 'admin-panel'
      ],
      payment: 4.00,
      ticketStatus: 'Awaiting Event'
    },
  ]




  return (
    <Container
      id='event-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      // className='flexcol justify-content-space-between'
    >
      <Helmet defer={false}><title>{'S. Tickets - SUPER 30 DAYS PACKAGE'}</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          // minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <Button 
                size="small"
                style={{
                  // color: 'black'
                  fontSize: '1.2rem'
                }}
                component={NavLink}
                color={  'primary'  }
                to={'/subscription/edit-package/' + formData.subscriptionClassId}
              >
                {/* {'Super 30 Days Package'} */}
                {formData.subscriptionTitle}
              </Button>
              <span>{'/ Tickets'}</span>
              <FilterDialogButton
                filterOptions={ _eventTicketFilterOptions }
                handleResetFilters={() => {
                  _setFilterUiElements([]);
                  _setIsHideActiveUiFilters(false);
                  _seteventTicketFilterOptions(
                    [
                      ..._eventTicketFilterOptions.map(
                        item => ({
                          ...item,
                          isChecked: false
                        })
                      )
                    ]
                  );
                  _setFilteringParameters({..._initialFilteringParameters});
                  setOrder(_initialOrder);  
                }}

                handleClickOption={(inputIndex) => {
                  const _clickedOption = { ..._eventTicketFilterOptions[inputIndex]};
                  if ( _clickedOption.isChecked === false ) {  // add component
                    _setFilterUiElements([
                      ... _filterUiElements,
                      { 
                        label: _clickedOption.label, 
                        component: _clickedOption.component 
                      }
                    ])
                  } else {  // remove component
                    _setFilterUiElements([
                      ... _filterUiElements.filter(
                        item => item.label !== _clickedOption.label
                      )
                    ]);
                    _setFilteringParameters({
                      ..._filteringParameters,
                      [_clickedOption.defaultFilterKey]: _clickedOption.defaultFilterValue
                    });
                  }
                  _setIsHideActiveUiFilters(false);
                  _seteventTicketFilterOptions(
                    [
                      ..._eventTicketFilterOptions.map(
                        (item, ind) => {
                          return {
                            ...item,
                            isChecked: (ind === inputIndex) 
                              ?
                              !item.isChecked
                              :
                              item.isChecked
                          }
                        }
                      )
                    ]
                  );
                }}
              />
              {
                _filterUiElements.length > 0 && (
                  <IconButton
                    onClick={() => {
                      _setIsHideActiveUiFilters( !_isHideActiveUiFilters );
                    }}
                  >
                    {
                      _isHideActiveUiFilters 
                        ?
                        <VisibilityIcon color='error' />
                        :
                        <VisibilityOffIcon />

                    }
                    
                  </IconButton>
                )
              }
            </>
          )}
        />

        {
          _loading === false && (
            <SubsciptionInformationComponent formData={formData}/>
          )
        }


        {/* FILTERING COMPONENTS */}
        <Grid item className={ _isHideActiveUiFilters ? 'd-none' : '' } >
          <Grid container  >

          {
            _eventTicketFilterOptions 
              && _eventTicketFilterOptions.find( item => item.label === 'Languages')
              && _eventTicketFilterOptions.find( item => item.label === 'Languages').isChecked
              // && !_isHideActiveUiFilters
              // &&  LanguageFilterComponent(
              //   {
              //     // setLanguageFilter: _setLanguageFilter,
              //     isDisplayNone: _isHideActiveUiFilters
              //   }
              // )
              && <LanguageFilterComponent
                  isDisplayNone={ _isHideActiveUiFilters }
                  setLanguageFilter={_setLanguageFilter  }
                />
          }
          {
            _eventTicketFilterOptions 
              && _eventTicketFilterOptions.find( item => item.label === 'Classrooms')
              && _eventTicketFilterOptions.find( item => item.label === 'Classrooms').isChecked
              // && !_isHideActiveUiFilters
              &&  ClassroomFilterComponent()
          }
          {
            _eventTicketFilterOptions 
              && _eventTicketFilterOptions.find( item => item.label === 'Hosts')
              && _eventTicketFilterOptions.find( item => item.label === 'Hosts').isChecked
              // && !_isHideActiveUiFilters
              &&  HostFilterComponent()
          }

          {
            // !_isHideActiveUiFilters
            // &&
            _filterUiElements && _filterUiElements.length > 0 &&
            _filterUiElements.map(
              ( item , ind )=> {
                return (
                  <React.Fragment key={ind}>
                    { item.component &&  item.component() }
                  </React.Fragment>
                );
              }
            )
          }



            




          </Grid>
        </Grid>
        {/* End of FILTERING COMPONENTS */}

        <Grid item className='pb-1' />


        

        {/* Table */}
        <CustomTable 
        >
          <SubscriptionTicketsTableHead/>
          <TableBody>
            {/* <UserTicketItem />
            <UserTicketItem />
            <UserTicketItem /> */}
            {
              // _dummySubscriptionTicketList.map(
              //   (ticketItem, ind) => (
              //     <SubscriptionTicketItem
              //       key={ind}
              //       ticketItem={ticketItem}
              //     />

              //   )
              // )
              formData && formData.ticketList && formData.ticketList.map(
                (ticketItem, ind) => (
                  <SubscriptionTicketItem
                    key={ind}
                    ticketItem={ticketItem}
                  />

                )
              )
            }
          </TableBody>
        </CustomTable>
        {/* End of Table */}





       

        


      </Grid>
      
      
    </Container>
  )
}


