import React from 'react';
import { useSelector, useDispatch } from "react-redux";

// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


// Components


// Actions
import { setActivityTypeFilter } from '../../actions/filterActions/setActivityTypeFilter';
import { handleActivityTypeItemClick } from '../../actions/filterActions/handleActivityTypeItemClick';




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;









export const ActivityTypeFilterComponent = () => {
  // Global Variables
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const activityTypeList = useSelector(state => state.filterReducer.filteringParameters.activityTypeList);
  const dispatch = useDispatch();

  return (
    <Grid item  className={'pb-1 pr-1 pt-2' + isHideClassName }
      sm={12} 
      md={6} lg={4}
      >
      <Autocomplete
        multiple
        id={ 'speaklabs-activity-type-autocomplete' }
        options={[...clientAppConstants.activityTypeOptions]}
        onChange={(e, newVal )=> {
          dispatch(
            setActivityTypeFilter({
              newActivityTypeFilterList: newVal
            })
          )
        }}
        value={activityTypeList}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
              onClick={(e) => {
                dispatch(
                  handleActivityTypeItemClick({
                    activityTypeItem: option
                  })
                )
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  activityTypeList.findIndex(
                    item => item.label === option.label
                  ) > -1
                }
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {
                ...params

              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  // <>YARRAK AMK</>
                  <React.Fragment>
                  {(() => {
                    return params.InputProps.startAdornment;
                  })()}
                </React.Fragment>
                )
              }}
              variant="outlined"
              label={'Activity Type'}
              placeholder={''}
            />
          )
        }}
      />
    </Grid>
  );
}





























// const ActivityType = () => (
//   <Grid item className='pb-1 pr-1 pt-2' 
//     sm={12} 
//     md={6} lg={4}
//     >
//     <AutoCompleteMultipleCheckbox 
//       options={[ ...clientAppConstants.activityTypeOptions]}
//       autoCompleteLabel='Activity Type'
//       placeHolder=''
//       htmlId='activities-screen-activity-type-autocomplete'
//       handleAutoCompleteOnChange={
//         ( e, newValue ) => {
//           _setActivityTypeFilter( newValue );                    
//       }}
//       />
//   </Grid>
// );