import   React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux"
import moment from 'moment';





// MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// Icons



// Actions
import { setUntilDateForFilter } from '../../actions/filterActions/setUntilDateForFilter';





export const UntilDateFilterComponent = (

) => {
  // Global Variables
  // const sinceDate = useSelector(state => state.filterReducer.filteringParameters.sinceDate);
  const untilDate = useSelector(state => state.filterReducer.filteringParameters.untilDate);
  const isHideClassName = useSelector(state => state.filterReducer.isHideClassName);
  const dispatch = useDispatch();


  // const [value, setValue] = React.useState(null);

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  return (
    <Grid 
      item 
      // className={'w-100 pb-1 pr-1' + isHideClassName}
      className={'pb-1 pr-1 pt-2' + isHideClassName}
      // style={{backgroundColor: 'yellow'}}
      sm={12} md={6} lg={4}
    >
      <Stack direction='column'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Until Date"
          inputFormat="yyyy-MM-dd"
          value={untilDate}
          onChange={ (newDate) => {
            dispatch(
              setUntilDateForFilter({
                newDate: new Date(moment(new Date( newDate )).format('YYYY-MM-DD'))
              })
            );
          }}  
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
        {/* <FormCustomTitle
          formText='Since Date'
        />
        <CustomDateTime2  /> */}
      </Stack>
    </Grid>
  );
}