import React , { useState }  from 'react'
import { useSelector, useDispatch } from "react-redux";





// MUI
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

// Icons
import SendIcon from '@mui/icons-material/Send';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

// Components
import { StyledTableCell, StyledTableRow } from '../../components/table/StyledTableComponents'
import { CustomDialog } from '../../components/CustomDialog';


// Actions
import { deleteInterest } from '../../actions/interestActions/deleteInterest';
import { updateInterest } from '../../actions/interestActions/updateInterest';




export const InterestItemComponent = ({
  interestItem
}) => {
  const {
    _id,
    interestTitle,
  } = interestItem;

  const dispatch = useDispatch();
  
  const _initialFormData = {  
    newInterestTitle : ''
  };
  const [formData, setFormData ] = useState({..._initialFormData});
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ _isUpdating, _setIsUpdating ] = useState(false);
  const [ _isShowUpdateTextField, _setIsShowUpdateTextField ] = useState(false);


  const _cbUpdateInterest = (responseFromApi) => {
    _setIsUpdating(false);
    setFormData({_initialFormData});
    _setIsShowUpdateTextField(false);
  }

  return (
    <StyledTableRow>
      <StyledTableCell size={'small'} className='text-bold'>
        {interestTitle}
      </StyledTableCell>


      <StyledTableCell size={'small'}>
        {
          isDeleting
            ?
            (
              <p>Is Deleting...</p>
            )
            :
            (
              <>
                {
                  _isShowUpdateTextField && (
                    <div className="mr-2" style={{display:'inline'}}>
                      <TextField
                        placeholder='Update Interest!'
                        label='Update Interest'
                        // required={true}
                        // disabled={_loading}
                        // fullWidth={true}
                        // variant='outlined'
                        // id='title'
                        // name='title'
                        // https://stackoverflow.com/questions/48343802/controlled-textfield-loosing-focus-after-every-character-from-keyboard
                        autoFocus={true}  // to prevent losing focus  
                        value={formData.newInterestTitle}
                        size='medium'
                        type='text'
                        onBlur={() => {}}
                        // autoComplete={false}
                        onChange={ e => {
                          setFormData({...formData, newInterestTitle: e.target.value});
                        }}
                      />
                      <IconButton
                        disabled={_isUpdating}
                        onClick={() => {                            
                          _setIsUpdating(true);
                          dispatch(
                            updateInterest({
                              interestId:  _id,
                              formData: { ...formData, interestTitle: formData.newInterestTitle },
                              cb: _cbUpdateInterest
                            })
                          );
                          _setIsShowUpdateTextField( false );
                        }}
                      >
                        <SendIcon color='primary'/>
                      </IconButton>
                      <IconButton
                        disabled={_isUpdating}
                        onClick={() => {
                          setFormData({...formData, newInterestTitle: ''});
                          _setIsShowUpdateTextField( false );
                        }}
                      >
                        <HighlightOffIcon color='inherit'/>
                      </IconButton>
                    </div>
                  )
                }
                {
                  !_isShowUpdateTextField && (
                    <IconButton
                      disabled={_isUpdating}
                      onClick={() => {
                        _setIsShowUpdateTextField( !_isShowUpdateTextField );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )
                }
                <IconButton
                  onClick={() => setIsModalOpen(true)}
                  disabled={_isUpdating}
                  color="secondary"       
                >
                  <DeleteForeverIcon />
                </IconButton>

              </>
            )
        }
        <CustomDialog
          ariaLabelledBy={`interest-id`}
          ariaDescribedBy={`interest-id-2`}
          cancelButtonText={ 'Cancel' }
          confirmButtonText={ 'OK' }
          dialogTitle={'Delete Confirmation!'}
          dialogContentText={'Are you sure you want to delete?'}
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleCancel={() => {
            setIsModalOpen(false);
            // and whatever
          }}
          handleConfirm={() => {
            setIsModalOpen(false);
            // and whatever
            setIsDeleting(true);
            dispatch(
              deleteInterest({
                interestId: _id,
                cb: data => setIsDeleting(false)
              })
            )
          }}
        />
      </StyledTableCell>



    </StyledTableRow>
  )
}
