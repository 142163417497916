import React, { useState } from 'react'
import{  NavLink ,withRouter, useHistory} from 'react-router-dom'




// Material Components
// import {
//   TableRow,
//   TableCell,
//   Button,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
// } from '@material-ui/core';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// MUI
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// CSS
import './UserTicketItem.css'

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { EventItemTicketButton } from './EventItemTicketButton';
 

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { EventTicketsIconButton } from '../../components/buttons/EventTicketsIconButton';



export const HostEventItem = ({
  eventItem
}) => {
  const history = useHistory();

  const [ isModalOpen, setIsModalOpen ] = useState(false);

  // {
  //   eventTitle: 'French Speaking Night',
  //   isEventOnline: true,
  //   subscriptionTitle: '-',
  //   eventDate: Date.now(),
  //   purchasePlatform: [
  //     'paypal', 'admin-panel'
  //   ],
  //   payment: 4.00,
  //   ticketStatus: 'Awaiting Event'
  // }

  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        <Button 
          variant="text"
          style={{
            textTransform: 'none',
            lineHeight: 1.2
          }}
          onClick={() => {
            history.push('/event/edit/' + '_id')
          }}
        >
          {eventItem.eventTitle}
        </Button>  
      </StyledTableCell>

      <StyledTableCell
        size='small'
      >
        {eventItem.isEventOnline ? 'Online' : 'Offline'}
      </StyledTableCell>


      <StyledTableCell
        size='small'
      >
        {convertToMomentDateTime(eventItem.eventDate)}
      </StyledTableCell>

      




      <StyledTableCell
        size='small'
        align='center'
      >
        <EventTicketsIconButton eventId={'eventID'} />
      </StyledTableCell>
          
      
      
    </StyledTableRow>
  )
}
