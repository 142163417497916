
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
// import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchLanguages } from '../../actions/languageActions/fetchLanguages';
import { addLanguage } from '../../actions/languageActions/addLanguage';




import {
  TableBody,
} from '@material-ui/core';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


// Icons
import SendIcon from '@mui/icons-material/Send';

// Styles
import './LanguageScreen.css'

// Components
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { CustomTable } from '../../components/table/CustomTable';
import { DoubleColumnTableHead } from '../../components/table/DoubleColumnTableHead';
import { LanguageItemComponent } from './LanguageItemComponent';


// Helpers
// import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';





export const LanguageScreen = () => {
  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  const isLanguagesLoading = useSelector(state => state.languageReducer.loading);
  const isAddingLanguage = useSelector(state => state.languageReducer.isAddingLanguage);
  const languages = useSelector(state => state.languageReducer.languages);
  // const user = useSelector(state => state.authReducer.user);

  const dispatch = useDispatch();

  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE
  // const [ticketId, setTicketId] = useState(match.params.ticketId || null);

  const _initialFormData = { languageTitle: '' }
  const [formData, setFormData] = useState({ ..._initialFormData });


  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      dispatch(
        fetchLanguages({
          cb: () => {
            _setLoading(false)
          }
        })
      );
      // _setLoading(false);
    }, []
  );

  // const _dummyLanguages = [
  //   { languageTitle: 'English', _id: 'werwerwewerew' },
  //   { languageTitle: 'French', _id: 'werwerwsdfewerew' },
  // ]




  const _onSubmitHandler = () => { }


  return (
    <Container
      maxWidth='lg'
      className='w-100 pb-1'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'space-between'
      }}
      id='speaklabs-language-screen-container'
    >
      <Helmet defer={false}><title>{'Languages'}</title></Helmet>
      <Grid container className='w-100 pb-2'>
        <ScreenTitle titleText={'Languages'} />

        {
          (_loading || isLanguagesLoading ) && <ScreenCircularProgress />
        }
        {
          !(_loading || isLanguagesLoading ) && (
            <Grid item className='w-100' sm={12}>
              <form className='w-100' onSubmit={_onSubmitHandler}>
                <Grid container className='w-100' sm={12}>
                  
                  
                  <Grid item className='w-100 pb-1'/>

                  {/* Table */}
                  <CustomTable
                  // stickyHeader={false}
                  >
                    <DoubleColumnTableHead 
                      firstColumnText='Language'
                      secondColumnText='Actions'
                    />
                    <TableBody>
                      
                      {
                        languages && languages.map(
                          (languageItem, ind) => (
                            <LanguageItemComponent
                              languageItem={languageItem}
                              key={ind}
                            />
                          ) 
                        )
                      }
                      
                      

                    </TableBody>
                  </CustomTable>
                  {/* End of Table */}



                </Grid>
              </form>
            </Grid>
          )
        }


      </Grid>

      <Grid container sm={12} className='w-100'>
        <Grid item sm={12}>
          <TextField
            placeholder='Enter new Language!'
            label='Language'
            required={true}
            disabled={_loading}
            fullWidth={true}
            variant='outlined'
            id='title'
            name='title'
            value={formData.languageTitle}
            size='medium'
            type='text'
            autoComplete={'off'}
            onChange={e => setFormData({...formData, languageTitle: e.target.value})}
          />
        </Grid>
        <Grid item className='mt-1'>
          <Button 
            variant='contained' 
            endIcon={<SendIcon />}
            color='success'
            disabled={isAddingLanguage}
            onClick={() => {
              dispatch(
                addLanguage({
                  formData,
                  cb: () => setFormData({..._initialFormData})
                })
              );
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}









