import React from 'react'
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';



// Icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export const AutoCompleteMultipleCheckbox = ({
  handleOnOpen,
  handleOnClose,
  loading,
  options = [],
  isOpen,
  handleAutoCompleteOnChange,
  htmlId= 'AutoCompleteMultipleCheckbox'+ Math.random(),
  autoCompleteLabel='AutoComplete Label',
  placeHolder='PlaceHolder'
}) => {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  return (
    <Autocomplete
        multiple
        id={ htmlId }
        options={options}
        onChange={(e, newVal )=> {
          // console.log('hello')
          handleAutoCompleteOnChange( e, newVal );
        }}
        
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        sx={{ width: 300 }}
        // defaultValue={[top100Films[13]]}
        renderOption={(props, option, { selected }) => {
          return (
            <div 
              { ...props }
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected} 
              />
              {option.label}
            </div>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={autoCompleteLabel}
            placeholder={placeHolder}
          />
        )}
      />
  )
}
