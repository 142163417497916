import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import Helmet from 'react-helmet';

// Constants
import { clientAppConstants } from '../../constants';

// Actions
import { fetchLanguages } from '../../actions/languageActions/fetchLanguages';




// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// Icons


// Components
import { ScreenTitle } from '../../components/title/ScreenTitle';
import { ScreenCircularProgress } from '../../components/loader/ScreenCircularProgress';




export const  ChatScreen = ({

}) =>  {

  // Global Variables
  // const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
  // const appInitialised = useSelector(state => state.authReducer.appInitialised);
  // const user = useSelector(state => state.authReducer.user);

  const isLanguagesLoading = useSelector(state => state.languageReducer.loading);
  const languages = useSelector(state => state.languageReducer.languages);
  const isLanguagesFetched = useSelector(state => state.languageReducer.isLanguagesFetched);

  const user = 'SOME USER';
  const isAuthenticated = true;
  const appInitialised = true;

  const dispatch = useDispatch();


  const [_loading, _setLoading] = useState(true); // ->>> SHOULD BE TRUE


  useEffect(
    () => {
      // setFormData({..._initialAddEventFormState});
      if ( !isLanguagesFetched ) {
        dispatch(
          fetchLanguages({
            cb: () => {
              _setLoading(false)
            }
          })
        );
      } else {
        _setLoading(false)
      }
    }, []
  );




  return (
    <Container
      id='chat-screen-container'
      maxWidth='lg'
      style={{
        backgroundColor: clientAppConstants.pageContainerBackgroundColor,
        // paddingTop: 64,
        minHeight: clientAppConstants.screenContainerMinHeight
      }}
    >
      <Helmet defer={false}><title>Chats</title></Helmet>
      <Grid
        container
        direction='column'
        // justify='space-between'
        style={{
          minHeight: clientAppConstants.screenContainerMinHeight
        }}
      >
        <ScreenTitle   
          titleText={(
            <>
              <span>{'Chat Languages'}</span> 
            </>
          )}  
        />

        {
          (_loading || isLanguagesLoading ) 
            ?
            <ScreenCircularProgress />
            :
            <>
              <Grid item className=' pt-4' />
              {
                languages && languages.length > 0 && languages.map(
                  (chatLanguageItem, index) => (
                    <Grid 
                      key={index}
                      item                 
                      className='w-100'
                      justifyContent='center'
                      direction='row'
                      alignItems='center'
                      // style={{backgroundColor: 'red'}}
                    >
                      <Stack direction='row' justifyContent='center' className='w-100 mb-2'>
                        <Button
                          component={NavLink}
                          to={`/chat-room/` + chatLanguageItem._id }
                          variant='contained'
                          style={{
                            minWidth: '150px'
                          }}
                        >
                          {chatLanguageItem.languageTitle}
                        </Button>
                      </Stack>
                    </Grid>
                  )
                )
              }
            </>
        }
      </Grid>

    </Container>
  )
}


