import {
  COUNTRIES_FETCHED,
  SET_COUNTRIES_LOADING,
} from "../actions/types";


const initialState = {
  countryList: [],
  isLoading: true,
  isCountriesFetched: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case COUNTRIES_FETCHED:      
      return {
        ...state,
        countryList: [...payload],
        isLoading: false,
        isCountriesFetched: true,
      };
    case SET_COUNTRIES_LOADING:      
      return {
        ...state,
        countryList: payload === true ? [] : state.countryList,
        isLoading: payload
      }; 

    

    default:
      return state;
  }
}