import React, { useState } from 'react'
import{  NavLink } from 'react-router-dom'






// MUI
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Components

import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { xDaysLater } from '../../helpers/common/xDaysLater';
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';
import { updateIsDismissedStatusOfContactMessage } from '../../actions/contactMessageActions/updateIsDismissedStatusOfContactMessage';



export const ContactMessageItem = ({
  contactMessageItem
}) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const {
    senderId,
    contactMessageText,
    contactMessageDate,
    isContactMessageDismissed,

  } = contactMessageItem;

  const userId = senderId._id;
  const {
    email,
    nameSurname
  } = senderId;

  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        <TableNavlinkTextButton
          buttonText={nameSurname ?? email}
          buttonLink={'user/edit/' + userId}
        />        
      </StyledTableCell>
      <StyledTableCell
        size='small'
      >
        {contactMessageText}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {convertToMomentDateTime(  contactMessageDate )}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        <Switch 
          checked={isContactMessageDismissed}
          onChange={
            e => {
              console.log('ContactMessageItem -< Switch -> onchange -> e.target.checked -> ', e.target.checked)
              updateIsDismissedStatusOfContactMessage({
                newIsDismissedValue: e.target.checked,
                contactMessageId:  contactMessageItem._id,
                cb: apiResponse => {
                  let _apiResponse = apiResponse;
                }

              })
            }
          }
        />
      </StyledTableCell>
      
      
      <StyledTableCell
        align='center'
        size='small'
      >
        <IconButton
          component={NavLink}
          // to={`/event/edit/${_id}`}
          to={`/user/edit/_id`}
        >
          <MoreHorizIcon
          />
        </IconButton>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
