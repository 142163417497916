import React from 'react'


// MUI
import Grid from '@mui/material/Grid';


// Components
import { CustomImageTextField } from '../../components/classroom/CustomImageTextField';




export const ClassroomImageSelectorComponent = ({
  setFormData,
  classroomId,
  eventId,
  formData,
}) => {
  const _isRequired = (() => {
    if (  classroomId || eventId ) {
      return false;
    };
    return true;
  })();


  return (
    <>
      {
        formData.isEventOnline !== null && (
          <>
            {
              formData.isEventOnline && (
                <>
                  <Grid item className='w-100 mt-1 pr-3'     sm={12} md={6} lg={4}>
                    <CustomImageTextField
                      customTitle={'Online Event Vertical Image'}
                      required={_isRequired}
                      handleImageOnChange={  imageFile => {
                        setFormData({ 
                          ...formData, 
                          onlineEventVerticalImage:  imageFile,
                          isOnlineEventVerticalImageBuffer: false,
                          isOnlineEventVerticalImageUpdated: ( classroomId || eventId ) ? true : false,
                        });
                      }}
                    />                                      
                  </Grid>
                  <Grid item className='w-100 mt-1 pr-3'     sm={12} md={6} lg={4}>
                    <CustomImageTextField
                      customTitle={'Online Event Horizontal Image'}
                      handleImageOnChange={  imageFile => {
                        setFormData({ 
                          ...formData, 
                          onlineEventHorizontalImage:  imageFile,
                          isOnlineEventHorizontalImageBuffer: false,
                          isOnlineEventHorizontalImageUpdated: ( classroomId || eventId ) ? true : false,
                        });
                      }}
                      required={_isRequired}
                    />  
                  </Grid>
                </>
              )
              
            }
            {  /*  OFFLINE  */
              formData.isEventOnline === false && (                                  
                <Grid item className='w-100 mt-1 pr-3'     sm={12} md={6} lg={4}>
                  <CustomImageTextField
                    customTitle={'Offline Event Image'}
                    handleImageOnChange={  imageFile => {
                      setFormData({ 
                        ...formData, 
                        offlineEventImage:  imageFile,
                        isOfflineEventImageBuffer: false,
                        isOfflineEventImageUpdated: ( classroomId || eventId ) ? true : false,
                      });
                    }}
                    required={_isRequired}
                  />
                </Grid>
              )                                
            }
          </>
        )
      }
    </>
  )
}
