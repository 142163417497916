import {
  // Pagination
  SET_ITEM_COUNT,
  SET_IS_PAGINATION_LOADING,
  SET_PAGINATION_PAGE,
  // Filtering
  SET_IS_FILTERING_LOADING,
  // Order
  SET_FILTERING_ORDER,  // 'asc' || 'desc'
  // Hide
  SET_IS_HIDE_ACTIVE_UI_FILTERS,
  // Reset
  RESET_ALL_FILTERS,
  // Search Query
  SET_SEARCH_QUERY_TEXT,
  // Activity Type
  SET_ACTIVITY_TYPE_FILTER,
  HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK ,
  // platform
  SET_PLATFORM_FILTER,
  HANDLE_PLATFORM_FILTER_ITEM_CLICK ,
  // Languages
  LANGUAGE_FETCH_FOR_FILTERING_STARTED,
  LANGUAGES_FETCHED_FOR_FILTERING,
  SET_LANGUAGES_FILTER,
  HANDLE_LANGUAGE_FILTER_ITEM_CLICK,
  // Locations
  LOCATION_FETCH_FOR_FILTERING_STARTED,
  LOCATIONS_FETCHED_FOR_FILTERING,
  SET_LOCATIONS_FILTER,
  HANDLE_LOCATION_FILTER_ITEM_CLICK,
  // Interests
  INTEREST_FETCH_FOR_FILTERING_STARTED,
  INTERESTS_FETCHED_FOR_FILTERING,
  SET_INTERESTS_FILTER,
  HANDLE_INTEREST_FILTER_ITEM_CLICK,
  // Hosts
  HOST_FETCH_FOR_FILTERING_STARTED,
  HOSTS_FETCHED_FOR_FILTERING,
  SET_HOSTS_FILTER ,
  HANDLE_HOST_FILTER_ITEM_CLICK ,
  // classroom
  CLASSROOM_FETCH_FOR_FILTERING_STARTED,
  CLASSROOMS_FETCHED_FOR_FILTERING ,
  SET_CLASSROOMS_FILTER ,
  HANDLE_CLASSROOM_FILTER_ITEM_CLICK,
  // Is Dismissed
  SET_IS_DISMISSED_FILTER ,
  HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK ,
  // Notifications
  SET_NOTIFICATIONS_FILTER,
  HANDLE_NOTIFICATION_FILTER_ITEM_CLICK,
  // User Types
  SET_USER_TYPES_FILTER,
  HANDLE_USER_TYPE_FILTER_ITEM_CLICK,
  // Online-Offline
  SET_ONLINE_OFFLINE_FILTER ,
  HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK ,
  // Ticket=Reservation
  SET_TICKET_RESERVATION_FILTER ,
  HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK,
  // Email
  SET_EMAILS_FILTER,
  HANDLE_EMAIL_FILTER_ITEM_CLICK,
  // Date (Since Date & Until Date)
  SET_SINCE_DATE_FILTER ,
  SET_UNTIL_DATE_FILTER ,
} from "../actions/types";
import { clientAppConstants } from "../constants";


const initialFilterParameters = {
  searchQueryText: '',  
  activityTypeList: [],
  platformList: [],
  languageList: [],
  locationList: [],
  interestList: [],
  hostList: [],
  classroomList: [],
  isDismissed: [],
  notification: [],
  userType: [],
  onlineOffline: [],
  ticketReservation: [],
  email: [],  // 'Email Off' || 'Email On'
  sinceDate: null,
  untilDate: null,
};
const _initialOrder = 'asc';  // 'asc' || 'desc' || null

const initialState = {
  isPaginationLoading: false,
  isFilteringLoading: false,
  paginationPage: 1,
  itemCount: null,
  pageFilterCount: clientAppConstants.pagePaginationFilterCount,
  // pageFilterCount: 1,
  filteringParameters: { ...initialFilterParameters },
  order: _initialOrder, // 'asc' || 'desc' || null
  isHideActiveUiFilters: false,  
  isHideClassName: '',
  // Languages
  fetchedLanguages: [],
  isLanguagesFetched: false,
  isLanguagesLoading: false,
  // Locations
  fetchedLocations: [],
  isLocationsFetched: false,
  isLocationsLoading: false,
  // Interests
  fetchedInterests: [],
  isInterestsFetched: false,
  isInterestsLoading: false,
  // Hosts
  fetchedHosts: [],
  isHostsFetched: false,
  isHostsLoading: false,
  // Classrooms
  fetchedClassrooms: [],
  isClassroomsFetched: false,
  isClassroomsLoading: false,  
}



const _isHideClassName = ( isHideActiveUiFilters ) => {
  if ( isHideActiveUiFilters )  return '';
  return ' d-none';  
}

export default function (state = { ...initialState }, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_PAGINATION_LOADING:      
      return {
        ...state,
        isPaginationLoading: payload
      };
    case SET_PAGINATION_PAGE:      
      return {
        ...state,
        paginationPage: payload
      };
    case SET_IS_FILTERING_LOADING:      
      return {
        ...state,
        isFilteringLoading: payload
      };
    case SET_ITEM_COUNT:      
      return {
        ...state,
        itemCount: payload
      };
    case SET_FILTERING_ORDER:      
      return {
        ...state,
        order: payload.isInitial ? _initialOrder : payload.newFilteringOrder
      };
    case SET_IS_HIDE_ACTIVE_UI_FILTERS:      
      return {
        ...state,
        isHideActiveUiFilters: state.isHideActiveUiFilters ? false : true,
        isHideClassName: _isHideClassName( state.isHideActiveUiFilters  )
      };
    case RESET_ALL_FILTERS:      
      return {
        ...initialState
      };
    case SET_SEARCH_QUERY_TEXT:   
      if ( payload.isReset ) {
        if ( state.filteringParameters.searchQueryText === '' ) {
          return state;
        }
      }    
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          searchQueryText: payload.isReset
            ?     ''  
            :     payload.newSearchTextValue
        }
      };
    //---------------------------------------------------------------------------------------------
    // Activity Type
    case HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK:  
    let isExistActivityTypeItem = state.filteringParameters.activityTypeList.findIndex(
      item => item.label === payload.label
    )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          activityTypeList: isExistActivityTypeItem
            ?
            [
              ...state.filteringParameters.activityTypeList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.activityTypeList,
              payload
            ]
        }
      };
    case SET_ACTIVITY_TYPE_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.activityTypeList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          // activityTypeList: [ ...payload.newOnlineOfflineFilterList]
          activityTypeList: [ ...payload.newActivityTypeFilterList]
        }
      };
    //---------------------------------------------------------------------------------------------
    // platform
    // platform
  // SET_PLATFORM_FILTER,
  // HANDLE_PLATFORM_FILTER_ITEM_CLICK ,
    case HANDLE_PLATFORM_FILTER_ITEM_CLICK:  
      let isExistPlatformItem = state.filteringParameters.platformList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          platformList: isExistPlatformItem
            ?
            [
              ...state.filteringParameters.platformList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.platformList,
              payload
            ]
        }
      };
    case SET_PLATFORM_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.platformList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          platformList: [ ...payload.newPlatformFilterList]
        }
      };
    //---------------------------------------------------------------------------------------------
    // Languages
    case LANGUAGE_FETCH_FOR_FILTERING_STARTED:      
      return {
        ...state,
        isLanguagesLoading: true,
      };
    case LANGUAGES_FETCHED_FOR_FILTERING:      
      return {
        ...state,
        isLanguagesLoading: false,
        fetchedLanguages: payload
      };
    case SET_LANGUAGES_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.languageList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          languageList: [ ...payload.newLanguageFilterList]
        }
      };
    case HANDLE_LANGUAGE_FILTER_ITEM_CLICK:  
      let isExistLanguage = state.filteringParameters.languageList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          languageList: isExistLanguage
            ?
            [
              ...state.filteringParameters.languageList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.languageList,
              payload
            ]
        }
      };
      // return {
      //   ...state
      // }
    // Locations
    case LOCATION_FETCH_FOR_FILTERING_STARTED:      
      return {
        ...state,
        isLocationsLoading: true,
      };
    case LOCATIONS_FETCHED_FOR_FILTERING:      
      return {
        ...state,
        isLocationsLoading: false,
        fetchedLocations: payload
      };
    case SET_LOCATIONS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.locationList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          locationList: [ ...payload.newLocationFilterList]
        }
      };
    case HANDLE_LOCATION_FILTER_ITEM_CLICK:  
      let isExistLocation = state.filteringParameters.locationList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          locationList: isExistLocation
            ?
            [
              ...state.filteringParameters.locationList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.locationList,
              payload
            ]
        }
      };
    // Interests
    case INTEREST_FETCH_FOR_FILTERING_STARTED:      
      return {
        ...state,
        isInterestsLoading: true,
      };
    case INTERESTS_FETCHED_FOR_FILTERING:      
      return {
        ...state,
        isInterestsLoading: false,
        fetchedInterests: payload
      };
    case SET_INTERESTS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.interestList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          interestList: [ ...payload.newInterestFilterList]
        }
      };
    case HANDLE_INTEREST_FILTER_ITEM_CLICK:  
      let isExistInterest = state.filteringParameters.interestList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          interestList: isExistInterest
            ?
            [
              ...state.filteringParameters.interestList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.interestList,
              payload
            ]
        }
      };


    // Hosts
    case HOST_FETCH_FOR_FILTERING_STARTED:      
      return {
        ...state,
        isHostsLoading: true,
      };
    case HOSTS_FETCHED_FOR_FILTERING:      
      return {
        ...state,
        isHostsLoading: false,
        fetchedHosts: payload
      };
    case SET_HOSTS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.hostList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          hostList: [ ...payload.newHostFilterList]
        }
      };
    case HANDLE_HOST_FILTER_ITEM_CLICK:  
      let isExistHost = state.filteringParameters.hostList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          hostList: isExistHost
            ?
            [
              ...state.filteringParameters.hostList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.hostList,
              payload
            ]
        }
      };
//-----------------------------------------------------------------------------------
// CLASSROOM_FETCH_FOR_FILTERING_STARTED,
// CLASSROOMS_FETCHED_FOR_FILTERING ,
// SET_CLASSROOMS_FILTER ,
// HANDLE_CLASSROOM_FILTER_ITEM_CLICK,

  // // Classrooms
  // fetchedClassrooms: [],
  // isClassroomsFetched: false,
  // isClassroomsLoading: false,
    // Classrooms
    case CLASSROOM_FETCH_FOR_FILTERING_STARTED:      
      return {
        ...state,
        isClassroomsLoading: true,
      };
    case CLASSROOMS_FETCHED_FOR_FILTERING:      
      return {
        ...state,
        isClassroomsLoading: false,
        fetchedClassrooms: payload
      };
    case SET_CLASSROOMS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.classroomList.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          classroomList: [ ...payload.newClassroomFilterList]
        }
      };
    case HANDLE_CLASSROOM_FILTER_ITEM_CLICK:  
      let isExistClassroom = state.filteringParameters.classroomList.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          classroomList: isExistClassroom
            ?
            [
              ...state.filteringParameters.classroomList.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.classroomList,
              payload
            ]
        }
      };
      
    //---------------------------------------------------------------------------------------------------
    // Is Dismissed
    case HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK:  
      let isExistIsDismissedItem = state.filteringParameters.isDismissed.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          isDismissed: isExistIsDismissedItem
            ?
            [
              ...state.filteringParameters.isDismissed.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.isDismissed,
              payload
            ]
        }
      };
    case SET_IS_DISMISSED_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.isDismissed.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          isDismissed: [ ...payload.newIsDismissedFilterList]
        }
      };
    //---------------------------------------------------------------------------------------------------


    // Notifications
    case HANDLE_NOTIFICATION_FILTER_ITEM_CLICK:  
      let isExist = state.filteringParameters.notification.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          notification: isExist
            ?
            [
              ...state.filteringParameters.notification.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.notification,
              payload
            ]
        }
      };
    case SET_NOTIFICATIONS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.notification.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          notification: [ ...payload.newNotificationFilterList]
        }
      };
    // User Type
    case HANDLE_USER_TYPE_FILTER_ITEM_CLICK:  
      let isExistUserItem = state.filteringParameters.notification.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          userType: isExistUserItem
            ?
            [
              ...state.filteringParameters.userType.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.userType,
              payload
            ]
        }
      };
    case SET_USER_TYPES_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.userType.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          userType: [ ...payload.newUserTypeFilterList]
        }
      };
    //---------------------------------------------------------------------------------------------------
    // Online - Offline
    case HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK:  
      let isExistOnlineOfflineItem = state.filteringParameters.onlineOffline.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          onlineOffline: isExistOnlineOfflineItem
            ?
            [
              ...state.filteringParameters.onlineOffline.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.onlineOffline,
              payload
            ]
        }
      };
    case SET_ONLINE_OFFLINE_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.onlineOffline.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          onlineOffline: [ ...payload.newOnlineOfflineFilterList]
        }
      };
    //-----------------------------------------------------------------------------------------------
    // Ticket=Reservation
  // SET_TICKET_RESERVATION_FILTER ,
  // HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK,
    case HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK:  
      let isExistTicketReservationItem = state.filteringParameters.ticketReservation.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          ticketReservation: isExistTicketReservationItem
            ?
            [
              ...state.filteringParameters.ticketReservation.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.ticketReservation,
              payload
            ]
        }
      };
    case SET_TICKET_RESERVATION_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.ticketReservation.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          ticketReservation: [ ...payload.newTicketReservationFilterList]
        }
      };
    //-----------------------------------------------------------------------------------------------
    // Email
    case HANDLE_EMAIL_FILTER_ITEM_CLICK:  
      let isExistEmailItem = state.filteringParameters.email.findIndex(
        item => item.label === payload.label
      )  > -1;
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          email: isExistEmailItem
            ?
            [
              ...state.filteringParameters.email.filter( item => item.label !== payload.label  )
            ]
            :
            [
              ...state.filteringParameters.email,
              payload
            ]
        }
      };
    case SET_EMAILS_FILTER:    
      if ( payload.isReset ) {
        if ( state.filteringParameters.email.length === 0 ) {
          return state;
        }
      }  
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          email: [ ...payload.newEmailFilterList]
        }
      };
      
    // // Date (Since Date & Until Date)
    case SET_SINCE_DATE_FILTER: 
      if ( payload.isReset ) {
        if ( state.filteringParameters.sinceDate === null || state.filteringParameters.sinceDate === undefined ) {
          return state;
        }
      }
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          sinceDate: payload.newDate
        }
      };
    case SET_UNTIL_DATE_FILTER: 
      if ( payload.isReset ) {
        if ( state.filteringParameters.untilDate === null || state.filteringParameters.untilDate === undefined ) {
          return state;
        }
      }
      return {
        ...state,
        filteringParameters: {
          ...state.filteringParameters,
          untilDate: payload.newDate
        }
      };

        
    default:
      return state;
  }
}