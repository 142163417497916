import React from 'react'
import { 
  StyledTableCell ,
} from '../../components/table/StyledTableComponents'




// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




export const CountryTableHead = () => {


  return (
    <TableHead id='speaklabs-country-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>Country</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
