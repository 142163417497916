

// Constants
// import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {  HANDLE_PLATFORM_FILTER_ITEM_CLICK,  } from "../types";

// Actions



export const handlePlatformItemClick = ({
  platformItem
}) => async dispatch => {
  dispatch({
    type: HANDLE_PLATFORM_FILTER_ITEM_CLICK,
    payload: platformItem
  });
}  