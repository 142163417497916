import React, {useMemo, useState} from 'react'
import{  NavLink } from 'react-router-dom'



// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

// Icons


// CSS
import './ActivityItem.css'

// Components
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';
import { convertToMomentOnlyTime } from '../../helpers/moment/convertToMomentOnlyTime';



export const ActivityItem = ({
  activityItem
}) => {

  const {
    _id,
    userId,
    activityType,
    activityCreationDate,
    subscriptionId,
    eventTitle,
    eventId,
    classroomId,
    platform,
    payment,
    balance,
  } = activityItem

  const [ isModalOpen, setIsModalOpen ] = useState(false);

    const platformItem = useMemo(() => {
        const result = {
            type: "unknown",
            chipType: "error"
        };
        if(platform && platform.length > 0) {
            const platformItem = platform[0]?.replace('[', '').replace(']', '') ?? '';
            result.type = platformItem;
            switch(platformItem) {
                case clientAppConstants.platformOptionsObject.PAYPAL:
                case clientAppConstants.platformOptionsObject.STRIPE:
                case clientAppConstants.platformOptionsObject.ROBOKASSA:
                    result.chipType = "primary";
                    break;
                case clientAppConstants.platformOptionsObject.TINKOFF:
                    result.chipType = "secondary";
                case clientAppConstants.platformOptionsObject.ADMIN_PANEL:
                    result.chipType = "success";
                    break;
                case clientAppConstants.platformOptionsObject.SUBSCRIPTION:
                default:
                    result.chipType = "error";
            }
        }
        return result;
    }, [platform])

    const paymentText = useMemo(() => {
        if(!payment) {
            return "-";
        }
        let parsedPrice = parseFloat(payment).toFixed(2);
        if(platformItem.type === clientAppConstants.platformOptionsObject.ROBOKASSA) {
            return `${parsedPrice} RUB`;
        }
        return `${parsedPrice} USD`;
    }, [platformItem, payment])
  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
        align={userId ? 'left' : 'center'}
      >
        {
          userId
            ?
            <Button 
              variant="text"
              style={{
                textTransform: 'none',
                lineHeight: 1.2
              }}
              component={NavLink}
              to={'/user/edit/' + userId._id}
            >
              {/* Alex Kostin */}
              {
                userId
                  ?
                  userId.nameSurname ?? userId.email
                  :
                  '-'
              }
            </Button>
            :
            '-'
        }
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* Refund From Ballance */}
        {activityType}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
        style={{
          whiteSpace: 'nowrap'
        }}
      >        
        {convertToMomentOnlyDate(activityCreationDate)}
      </StyledTableCell>
      <StyledTableCell
        size='small'
        align={ (subscriptionId && subscriptionId.length > 0) ? 'left' : 'center'}
      >
        {
          (subscriptionId && subscriptionId.length > 0)
          ?
          subscriptionId.map(
            (subscriptionIdItem, ind) => {
              return (
                  <TableNavlinkTextButton
                    key={ind}
                    // buttonText={'French 3 Months Unlimited'}
                    buttonText={subscriptionIdItem.subscriptionTitle}
                    // buttonLink={'/subscription/edit/' + 'subscriptionIdFromUserActivityScreen'}
                    buttonLink={'/subscription/edit/' + subscriptionIdItem._id}
                  />
                )
            }
          )
          // (
          //   <TableNavlinkTextButton
          //     // buttonText={'French 3 Months Unlimited'}
          //     buttonText={subscriptionId.subscriptionTitle}
          //     // buttonLink={'/subscription/edit/' + 'subscriptionIdFromUserActivityScreen'}
          //     buttonLink={'/subscription/edit/' + subscriptionId._id}
          //   />
          // )
          :
          '-'
        }
        
      </StyledTableCell>
      <StyledTableCell
        size='small'
      >
        {
          eventTitle
          ?
          (
            <Button 
              variant="text"
              style={{
                textTransform: 'none',
                lineHeight: 1.2
              }}
              component={NavLink}
              to={'/event/edit/' + eventId._id}
            >
              {/* French Speaking Club */}
              {eventTitle}
            </Button> 
          )
          :
          '-'
        }
               
        
      </StyledTableCell>
      <StyledTableCell
        size='small'
      >
        {
          eventId
          ?
          <>
            <span style={{whiteSpace: 'nowrap'}}>{convertToMomentOnlyDate(eventId.eventDate)}</span>
            <span>{' '}</span>
            <span style={{whiteSpace: 'nowrap'}}>{convertToMomentOnlyTime(eventId.eventDate)}</span>
          </>
          :
          '-'
        }
        {/* {convertToMomentDateTime(Date.now())} */}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
          <Chip className='speaklabs-activity-platform-chip' label={platformItem.type} color={platformItem.chipType} />
      </StyledTableCell>
      <StyledTableCell
        size='small'
        // align='right'
        align={payment ? 'right' : 'center'}
      >
          {paymentText}
      </StyledTableCell>
      <StyledTableCell
        size='small'
        // align='right'
        align={balance ? 'right' : 'center'}
      >
        {/* {'$4.00'} */}
        {
          (balance !== null && balance !== undefined )
          ?
          (`\$${balance.toFixed(2)}`)
          :
          '-'
        }
        
        
      </StyledTableCell>
      
    </StyledTableRow>
  )
}
