import {Container, Grid, TableBody} from "@material-ui/core";
import {clientAppConstants} from "../../constants";
import Helmet from "react-helmet";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {ScreenCircularProgress} from "../../components/loader/ScreenCircularProgress";
import {CustomTable} from "../../components/table/CustomTable";
import {SubscriptionTableHead} from "../subscriptionPackages/SubscriptionTableHead";
import {SubscriptionItem} from "../subscriptionPackages/SubscriptionItem";
import {CustomPagination} from "../../components/CustomPagination";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";

export const SubscriptionsScreen = () => {
    const [loading, setLoading] = useState(false);
    return (
        <Container
            id='speaklabs-subscription-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Subscriptions</title></Helmet>
            <Grid
                container
                direction='column'
                // justify='space-between'
                style={{
                    // minHeight: clientAppConstants.screenContainerMinHeight
                }}
            >
                <ScreenTitle   titleText='Subscriptions'    />

                {
                    loading
                        ?
                        (
                            <ScreenCircularProgress />
                        )
                        :
                        (
                            <>
                                <Grid item className='pb-2' />
                                {/* Table */}
                                <CustomTable
                                    // stickyHeader={false}
                                >
                                    <SubscriptionTableHead/>
                                    <TableBody>

                                    </TableBody>
                                </CustomTable>
                                {/* End of Table */}

                                <CustomPagination
                                    currentPaginationIndex={1}
                                    handleOnChange={() => {} /* dont forget to update CustomGrid also  */ }
                                    isPaginationLoading={ false }
                                    itemCount={60}
                                    isScreenLoading={false}

                                />
                            </>
                        )
                }




            </Grid>
            <Grid container sm={12} className='w-100 pb-1'>
                <Grid item>
                    <Button
                        variant='contained'
                        endIcon={<AddIcon />}
                        component={NavLink}
                        to={'/subscription/create-package'}
                    >
                        Create Subscription
                    </Button>

                </Grid>
            </Grid>
        </Container>
    );
}