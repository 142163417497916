import {EventForm} from "./EventForm";
import {Container} from "@material-ui/core";
import {clientAppConstants, urlConstants} from "../../constants";
import Helmet from "react-helmet";
import React, {useEffect} from "react";
import {ScreenTitle} from "../../components/title/ScreenTitle";
import {useHistory, useParams } from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";

export const UpdateEvent = () => {
    const history = useHistory();
    const {eventId} = useParams();
    const [event, setEvent] = React.useState(null);

    const fetchEvent = async () => {
        try {
            const response = await axios.get(urlConstants.getEvent(eventId));
            setEvent(response.data.data);
        } catch (e) {
            toast(e.message);
        }
    };

    useEffect(() => {
        fetchEvent();
    }, []);
    return (
        <Container
            id='user-screen-container'
            maxWidth='lg'
            style={{
                backgroundColor: clientAppConstants.pageContainerBackgroundColor,
                // paddingTop: 64,
                minHeight: clientAppConstants.screenContainerMinHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'space-between'
            }}
        >
            <Helmet defer={false}><title>Edit event</title></Helmet>
            <ScreenTitle
                titleText="Edit event"
            />
            {event ? <EventForm isClassroom={false} item={event} onSuccessfulSubmit={() => {
                history.push('/event');
            }} /> : null}
        </Container>
    );
}