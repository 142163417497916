import {useCallback} from "react";
import {ImageControl} from "./ImageControl";
import FormControl from "@mui/material/FormControl";
import {FormHelperText} from "@material-ui/core";
import {useController} from "react-hook-form";

export const FormikImageControl = ({name, control, ...rest}) => {
    const {field, fieldState} = useController({name, control});

    const onChange = useCallback((value) => {
        field.onChange(value);
    }, [field]);
    return (
        <FormControl error={Boolean(fieldState.error)} className="w-100">
            <ImageControl name={name} {...rest} {...field} onChange={onChange} />
            <FormHelperText>{fieldState.error ? fieldState.error.message : undefined}</FormHelperText>
        </FormControl>
    );
}