import React, { useState } from 'react'
import{  NavLink, useHistory } from 'react-router-dom'

// Constants
import { clientAppConstants } from '../../constants';


// MUI
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// MUI Icon
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RepeatIcon from '@mui/icons-material/Repeat';

// Components
import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';


// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';



export const NotificationItem = ({
  notificationItem
}) => {

  const {
    title,
    message,
    recipients,
    notificationType,
    dbSentDate,
    eventId,
    classroomId,
    eventTitle,
    userId,
  } = notificationItem;
  const history = useHistory();
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <StyledTableRow>
      {/* Title */}
      <StyledTableCell
        size='small'
        >
        {title}
      </StyledTableCell>
      {/* End of Title */}

      {/* Event */}
      
      <StyledTableCell
        size='small'
        align={
          (eventId && eventTitle ) ? 'left' : 'center'
        }
      >
        {
          (eventId && eventTitle )
            ?
            (              
              <TableNavlinkTextButton
                buttonLink={'/event/edit/' + eventId}
                buttonText={eventTitle}
              />        
            )
            :
            (
              <span>-</span>
            )
        }
      </StyledTableCell>
      {/* End of Event */}
      

      <StyledTableCell
        size='small'
        align={
          (userId ) ? 'left' : 'center'
        }
        
      >
        {
          ( userId  )
            ?
            (              
              <TableNavlinkTextButton
                buttonLink={'/user/edit/' + userId._id}
                buttonText={userId.nameSurname ?? userId.email ?? 'Name Doesnt Exist!'}
              />       
            )
            :
            (
              <span>-</span>
            )
        }

                
      </StyledTableCell>

      {/* <StyledTableCell
        align='center'
        size='small'
      >
        English
      </StyledTableCell> */}

      {/* <StyledTableCell
        align='center'
        size='small'
      >
        Dancing
      </StyledTableCell> */}

      <StyledTableCell
        align='center'
        size='small'
      >
        { recipients ?? '0' }
      </StyledTableCell>


      <StyledTableCell
        align='center'
        size='small'
      >
        {/* {convertToMomentOnlyDate(Date.now())} */}
        {convertToMomentDateTime( dbSentDate )}
      </StyledTableCell>
      
      
      <StyledTableCell
        align='center'
        size='small'
      >
        <Stack direction='row'>
            
          <IconButton
            component={NavLink}
            // to={`/event/edit/${_id}`}
            to={`/notification/detail/`  + notificationItem._id }
          >
            
            <MoreHorizIcon
            />
          </IconButton>
          <Tooltip arrow title='Re-Send Notification'>

            <IconButton
              onClick={() => { setIsModalOpen(true)}}
            >
              <RepeatIcon />
            </IconButton>

          </Tooltip>
          <CustomDialog
            ariaLabelledBy={`id1`}
            ariaDescribedBy={`id2`}
            cancelButtonText={ 'Cancel' }
            confirmButtonText={ 'OK' }
            dialogTitle={'Resend Confirmation!'}
            dialogContentText={'Are you sure you want to Re-send Notification?'}
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            handleCancel={() => {
              setIsModalOpen(false);
              // and whatever
            }}
            handleConfirm={() => {
              setIsModalOpen(false);
              // and whatever
            }}
          />
        </Stack>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
