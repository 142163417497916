import React from 'react'

// MUI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

// Icons
import CalculateIcon from '@mui/icons-material/Calculate';

// Components
import {MuiBasicDateRangePicker} from '../../components/form/MuiBasicDateRangePicker';




// Components
import { TableNavlinkTextButton } from '../../components/table/TableNavlinkTextButton';

// Helpers
import { convertToMomentDateTime } from '../../helpers/moment/convertToMomentDateTime';

export const HostCalculationInformationComponent = ({
  dateRangeValue,
  handleDateRangeChange,
}) => {
  return (
    <Grid item sm={12} className='w-100'>
      <Paper 
        className='p-1'
      >
      {/* <Grid container sm={12} className='w-100'>

          <Grid item className='pr-2'>
            <MuiBasicDateRangePicker
              value={ dateRangeValue }
              onChange={  handleDateRangeChange }
              isSmallSize
            />
          </Grid>


      </Grid> */}

      <Stack 
        direction='row' 
        className='w-100'
        justifyContent='space-between'
        alignItems='center'
      >
        <MuiBasicDateRangePicker
          value={ dateRangeValue }
          onChange={  handleDateRangeChange }
          isSmallSize
        />
        <Button
          variant='contained'
          endIcon={<CalculateIcon />}
          onClick={() => {}}
        >
          {'Calculate'}
        </Button>
      </Stack>
      </Paper>
    </Grid>
  )
}
