import React from 'react'




// MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';








export const ClassroomImageShowComponent = ({
  formData,  
}) => {

  const CustomImageComponent = ({ 
    isBuffer ,
    image,
    isVertical = false,
  }) => {

    const _imageWidth = isVertical ? (1080 / 5) : (1920 / 5);
    const _imageHeight = isVertical ? (1920 / 5) : (1080 / 5);
    if ( isBuffer ) {
      return (
        <div className='flexrow justify-content-center pt-2 pb-2'>
          <img
            style={{
              width: _imageWidth,
              height: _imageHeight,
            }}
            className='mb-2'
            src={image}
          />
        </div>
      )
    }
    const objectUrl = URL.createObjectURL( image );     
    return (
      <div className='flexrow justify-content-center pt-2 pb-2'>
        <img
          style={{
            width: _imageWidth,
            height: _imageHeight,
          }}
          className='mb-2'
          src={objectUrl}
        />
      </div>
    )
  }


  return (
    <>
      <Paper  className='w-100 my-2'> 
        {/* Online Event Image */}                        
        {
          formData.isEventOnline === true 
          &&  ( formData.onlineEventHorizontalImage || formData.onlineEventVerticalImage ) 
          && (                                                            
            <Grid container className='w-100'>
              {
                formData.onlineEventVerticalImage && (
                  <Grid item className='w-100 mt-2 pr-3'     sm={12} md={6}>
                    <CustomImageComponent
                      image={formData.onlineEventVerticalImage}
                      isBuffer={ formData.isOnlineEventVerticalImageBuffer }
                      isVertical={true}
                    />
                  </Grid>
                )
              }
              {
                formData.onlineEventHorizontalImage && (
                  <Grid item className='w-100 mt-2 pr-3'     sm={12} md={6}>
                    <CustomImageComponent
                      image={formData.onlineEventHorizontalImage}
                      isBuffer={ formData.isOnlineEventHorizontalImageBuffer }
                      // isVertical={true}
                    />
                  </Grid>
                )
              }
            </Grid>                              
          ) 
        }
        {/* End of Online Event Image */}

        {/* Offline Event Image */}                        
        {
          formData.isEventOnline === false &&  formData.offlineEventImage && (
            <Grid item className='w-100 mt-2 pr-3'     sm={12}>
              <CustomImageComponent
                image={formData.offlineEventImage}
                isBuffer={ formData.isOfflineEventImageBuffer }
              />
            </Grid>
          ) 
        }
        {/* End of Offline Event Image */}

        {
          !formData.offlineEventImage 
          &&   !formData.onlineEventVerticalImage 
          &&  !formData.onlineEventHorizontalImage && (
            <Stack className='w-100 py-2' direction='row' justifyContent='center'>
              <span>No Images! Please Select!</span>
            </Stack>
          )
        }
      </Paper>
    </>
  ) 
}
