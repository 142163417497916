import React from 'react'



// MUI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

// Icons
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';

// Constants
import { clientAppConstants } from '../../constants';


export const ChatSendMessageComponent = ({ message, setMessage, handleSend }) => {
  return (
    <Grid
      item
      className='w-100'
      id='speaklabs-chat-screen-send-message-row-grid'
      style={{
        // height: clientAppConstants.chatScreenSendMessageHeight,
        // maxHeight: clientAppConstants.chatScreenSendMessageHeight,
      }}
    >
      <Paper>

        <Stack 
          direction='row'
          className='w-100'
        >
          {/* //------------------------------------------------- */}
          <Grid 
            item 
            // className='w-100'
            style={{
              width: `calc( 100%  - 100px )`
            }}
          >
            <TextField 
              fullWidth
              multiline
              value={message}
              placeholder='Type to Send Message...'
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item
            style={{
              width: '100px',
              // backgroundColor: 'red'
              
            }}
            className=' flexrow justify-content-flex-end'

          >
            {/* <Button
              style={{
                maxHeight: '56px'
              }} 
              variant='contained'
            >
  }           
              <SendIcon />
            </Button> */}
            <Button
              style={{
                maxHeight: '56px'
              }} 
              variant='contained'
              onClick={handleSend}
            >        
              Send
            </Button>
            {/* <IconButton
              style={{
                maxHeight: '56px'
              }} 
              variant='contained'
            >

              <SendIcon />
            </IconButton> */}
          </Grid>

          {/* //------------------------------------------------- */}
        </Stack>  
      </Paper>
      
    </Grid>
  )
}
