


// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const TOKEN_FAIL = "TOKEN_FAIL";
export const APP_INITIALISED = "APP_INITIALISED";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";


// ACTIVITY
export const ACTIVITIES_FETCHED = "ACTIVITIES_FETCHED";
export const SET_ACTIVITIES_LOADING = "SET_ACTIVITIES_LOADING";
export const USER_ACTIVITIES_FETCHED = "USER_ACTIVITIES_FETCHED";
export const SET_USER_ACTIVITIES_LOADING = "SET_USER_ACTIVITIES_LOADING";
export const RESET_ACTIVITY_REDUCER = "RESET_ACTIVITY_REDUCER";


// ALERT
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";


// CLASSROOM
export const CLASSROOMS_FETCHED = "CLASSROOMS_FETCHED";
export const SET_CLASSROOMS_LOADING = "SET_CLASSROOMS_LOADING";


// CONTACT MESSAGES
export const CONTACT_MESSAGES_FETCHED = "CONTACT_MESSAGES_FETCHED";
export const SET_CONTACT_MESSAGES_LOADING = "SET_CONTACT_MESSAGES_LOADING";
export const CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED = "CONTACT_MESSAGE_ITEM_IS_DISMISSED_UPDATED";
export const SET_IS_DISMISSING_ALL_CONTACT_MESSAGES = "SET_IS_DISMISSING_ALL_CONTACT_MESSAGES";
export const ALL_CONTACT_MESSAGES_DISMISSED = "ALL_CONTACT_MESSAGES_DISMISSED";


// COUNTRY
export const COUNTRIES_FETCHED = "COUNTRIES_FETCHED";
export const SET_COUNTRIES_LOADING = "SET_COUNTRIES_LOADING";


// DECORATION
export const SET_NAVBAR_ON_OFF = "SET_NAVBAR_ON_OFF";
export const SET_HIDE_NAV_BUTTONS = "SET_HIDE_NAV_BUTTONS";
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";



// EVENT
export const EVENTS_FETCHED = "EVENTS_FETCHED";
export const SET_EVENTS_LOADING = "SET_EVENTS_LOADING";



// INTEREST
export const INTEREST_ADDED = "INTEREST_ADDED";
export const INTEREST_ADD_STARTED = "INTEREST_ADD_STARTED";
export const INTEREST_ADD_ERROR = "INTEREST_ADD_ERROR";
export const INTEREST_UPDATED = "INTEREST_UPDATED";
export const INTERESTS_FETCHED = "INTERESTS_FETCHED";
export const INTEREST_DELETED = "INTEREST_DELETED";


// LANGUAGE
export const LANGUAGE_ADDED = "LANGUAGE_ADDED";
export const LANGUAGE_ADD_STARTED = "LANGUAGE_ADD_STARTED";
export const LANGUAGE_ADD_ERROR = "LANGUAGE_ADD_ERROR";
export const LANGUAGE_UPDATED = "LANGUAGE_UPDATED";
export const LANGUAGES_FETCHED = "LANGUAGES_FETCHED";
export const LANGUAGE_DELETED = "LANGUAGE_DELETED";


// NOTIFICATION
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const NOTIFICATION_LIST_fETCHED = "NOTIFICATION_LIST_fETCHED";


// SUBSCRIPTION-CLASS
export const SUBSCRIPTION_CLASSES_FETCHED = "SUBSCRIPTION_CLASSES_FETCHED";
export const SET_SUBSCRIPTION_CLASS_LOADING = "SET_SUBSCRIPTION_CLASS_LOADING";




// TICKET & TICKET VERIFICATION
export const TICKET_VERIFICATION_LIST_FETCHED = "TICKET_VERIFICATION_LIST_FETCHED";
export const SET_TICKET_VERIFICATION_LIST_LOADING = "SET_TICKET_VERIFICATION_LIST_LOADING";


// USER
export const USERS_FETCHED = "USERS_FETCHED";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const SINGLE_USER_LOADING = "SINGLE_USER_LOADING";
export const SINGLE_USER_FETCHED = "SINGLE_USER_FETCHED";
export const SET_USER_AS_HOST_LOADING = "SET_USER_AS_HOST_LOADING";
export const SET_USER_AS_HOST_SUCCESS = "SET_USER_AS_HOST_SUCCESS";
export const SET_USER_AS_VERIFIER_LOADING = "SET_USER_AS_VERIFIER_LOADING";
export const SET_USER_AS_VERIFIER_SUCCESS = "SET_USER_AS_VERIFIER_SUCCESS";



// USER TICKET LIST
export const USERS_TICKET_LIST_FETCHED = "USERS_TICKET_LIST_FETCHED";



// FILTER REDUCER
export const SET_ITEM_COUNT = "SET_ITEM_COUNT";
export const SET_IS_PAGINATION_LOADING = "SET_IS_PAGINATION_LOADING";
export const SET_IS_FILTERING_LOADING = "SET_IS_FILTERING_LOADING";
export const SET_PAGINATION_PAGE = "SET_PAGINATION_PAGE";
export const SET_FILTERING_ORDER = "SET_FILTERING_ORDER";
export const SET_SEARCH_QUERY_TEXT = "SET_SEARCH_QUERY_TEXT";
export const SET_IS_HIDE_ACTIVE_UI_FILTERS = "SET_IS_HIDE_ACTIVE_UI_FILTERS";
// Reset All
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
// Activity Type
export const SET_ACTIVITY_TYPE_FILTER = "SET_ACTIVITY_TYPE_FILTER";
export const HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK = "HANDLE_ACTIVITY_TYPE_FILTER_ITEM_CLICK";
// platform
export const SET_PLATFORM_FILTER = "SET_PLATFORM_FILTER";
export const HANDLE_PLATFORM_FILTER_ITEM_CLICK = "HANDLE_PLATFORM_FILTER_ITEM_CLICK";
// language
export const LANGUAGE_FETCH_FOR_FILTERING_STARTED = "LANGUAGE_FETCH_FOR_FILTERING_STARTED";
export const LANGUAGES_FETCHED_FOR_FILTERING = "LANGUAGES_FETCHED_FOR_FILTERING";
export const SET_LANGUAGES_FILTER = "SET_LANGUAGES_FILTER";
export const HANDLE_LANGUAGE_FILTER_ITEM_CLICK = "HANDLE_LANGUAGE_FILTER_ITEM_CLICK";
//locations
export const LOCATION_FETCH_FOR_FILTERING_STARTED = "LOCATION_FETCH_FOR_FILTERING_STARTED";
export const LOCATIONS_FETCHED_FOR_FILTERING = "LOCATIONS_FETCHED_FOR_FILTERING";
export const SET_LOCATIONS_FILTER = "SET_LOCATIONS_FILTER";
export const HANDLE_LOCATION_FILTER_ITEM_CLICK = "HANDLE_LOCATION_FILTER_ITEM_CLICK";
// interest
export const INTEREST_FETCH_FOR_FILTERING_STARTED = "INTEREST_FETCH_FOR_FILTERING_STARTED";
export const INTERESTS_FETCHED_FOR_FILTERING = "INTERESTS_FETCHED_FOR_FILTERING";
export const SET_INTERESTS_FILTER = "SET_INTERESTS_FILTER";
export const HANDLE_INTEREST_FILTER_ITEM_CLICK = "HANDLE_INTEREST_FILTER_ITEM_CLICK";
// host
export const HOST_FETCH_FOR_FILTERING_STARTED = "HOST_FETCH_FOR_FILTERING_STARTED";
export const HOSTS_FETCHED_FOR_FILTERING = "HOSTS_FETCHED_FOR_FILTERING";
export const SET_HOSTS_FILTER = "SET_HOSTS_FILTER";
export const HANDLE_HOST_FILTER_ITEM_CLICK = "HANDLE_HOST_FILTER_ITEM_CLICK";
// classroom
export const CLASSROOM_FETCH_FOR_FILTERING_STARTED = "CLASSROOM_FETCH_FOR_FILTERING_STARTED";
export const CLASSROOMS_FETCHED_FOR_FILTERING = "CLASSROOMS_FETCHED_FOR_FILTERING";
export const SET_CLASSROOMS_FILTER = "SET_CLASSROOMS_FILTER";
export const HANDLE_CLASSROOM_FILTER_ITEM_CLICK = "HANDLE_CLASSROOM_FILTER_ITEM_CLICK";
// Is Dismissed
export const SET_IS_DISMISSED_FILTER = "SET_IS_DISMISSED_FILTER";
export const HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK = "HANDLE_IS_DISMISSED_FILTER_ITEM_CLICK";
// Notifications
export const SET_NOTIFICATIONS_FILTER = "SET_NOTIFICATIONS_FILTER";
export const HANDLE_NOTIFICATION_FILTER_ITEM_CLICK = "HANDLE_NOTIFICATION_FILTER_ITEM_CLICK";
// User Type
export const SET_USER_TYPES_FILTER = "SET_USER_TYPES_FILTER";
export const HANDLE_USER_TYPE_FILTER_ITEM_CLICK = "HANDLE_USER_TYPE_FILTER_ITEM_CLICK";
// Online-Offline
export const SET_ONLINE_OFFLINE_FILTER = "SET_ONLINE_OFFLINE_FILTER";
export const HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK = "HANDLE_ONLINE_OFFLINE_FILTER_ITEM_CLICK";
// Ticket=Reservation
export const SET_TICKET_RESERVATION_FILTER = "SET_TICKET_RESERVATION_FILTER";
export const HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK = "HANDLE_TICKET_RESERVATION_FILTER_ITEM_CLICK";
// Email Type
export const SET_EMAILS_FILTER = "SET_EMAILS_FILTER";
export const HANDLE_EMAIL_FILTER_ITEM_CLICK = "HANDLE_EMAIL_FILTER_ITEM_CLICK";
// Date (Since Date & Until Date)
export const SET_SINCE_DATE_FILTER = "SET_SINCE_DATE_FILTER";
export const SET_UNTIL_DATE_FILTER = "SET_UNTIL_DATE_FILTER";
// End of FILTER REDUCER


