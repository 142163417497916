import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import { INTERESTS_FETCHED } from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";


// Fetch All Maritals
export const fetchInterests = ({
  cb  // callBack
}) => async dispatch => {
  try {
 
    const res = await axios.get(
      urlConstants.fetchInterestUrl, 
      clientAppConstants.jsonConfig
    );  

    dispatch({
      type: INTERESTS_FETCHED,
      payload: res.data
    });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb([]);
    }
  }
}  // End of Fetch All Maritals