import React, { useState } from 'react'
import{  NavLink } from 'react-router-dom'



// Material Components
import {
  IconButton,
} from '@material-ui/core';

// MUI

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


// Components
// import { CustomDialog } from '../../components/CustomDialog';
import { StyledTableRow, StyledTableCell } from "../../components/table/StyledTableComponents";

// Helpers
// import { convertToMomentOnlyDate } from '../../helpers/moment/convertToMomentOnlyDate';



export const ClassroomItem = ({
  classroomItem
}) => {
  const {
    _id,
    isEventOnline,
      language,
      //-----------------   
      ticketPrice,
      maxAttendee,
      eventTitle,
      eventInterestId,


  } = classroomItem;
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <StyledTableRow>
      <StyledTableCell
        size='small'
      >
        {isEventOnline ? 'Online' : 'Offline'}        
      </StyledTableCell>
      <StyledTableCell
        size='small'
      >
        {eventTitle}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* English */}
        {language.languageTitle}
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* {offlineEventType}*/}
        {
          eventInterestId
            ?
            eventInterestId.interestTitle
            :
            '-'
        }
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* 30 */}
        { maxAttendee }
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        {/* $5.00 */}
        {
          ticketPrice ? `\$${ticketPrice.toFixed(2)}` : '-'
        }
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        $6.00
      </StyledTableCell>
      <StyledTableCell
        align='center'
        size='small'
      >
        <IconButton
          size='small'
          component={NavLink}
          // to={`/event/edit/${_id}`}
          to={`/classroom/view/` + _id}
        >
          <MoreHorizIcon
          />
        </IconButton>
        
      </StyledTableCell>
    </StyledTableRow>
  )
}
