import React from 'react'
import { 
  StyledTableCell ,
  StyledFilterableCell,
  StyledTableRow
} from '../../components/table/StyledTableComponents'


// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




export const SubscriptionTableHead = () => {


  return (
    <TableHead id='manidar-mani-table-head'>
      <TableRow>
        <StyledTableCell size= 'small' align='left'>Title</StyledTableCell>
        <StyledTableCell size= 'small' align='right'>Price</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Active Users</StyledTableCell>
         <StyledTableCell size= 'small' align='right'>Total Users</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Active</StyledTableCell>
         <StyledTableCell size= 'small' align='center'>Actions</StyledTableCell>
      </TableRow>
    </TableHead>
  )
}
