


import axios from "axios";


// Constants
import { clientAppConstants, urlConstants } from '../../constants'

// Types
import {   
  // USERS_FETCHED, SET_USERS_LOADING 
} from "../types";

// Actions
import { setAlert } from "../alertActions/setAlert";



export const fetchEventAddEditEntities = ({
  classroomId = null,
  eventId = null,
  cb,  // callBack
}) => async dispatch => {
  try { 
    const formData = {
      classroomId,
      eventId,
    }
    // dispatch({
    //   type: SET_USERS_LOADING,
    //   payload: true
    // });


    const res = await axios.post(
      urlConstants.getEventAddEditEntities, 
      formData ?? {},
      clientAppConstants.jsonConfig
    );  
    // dispatch(setFilteringItemCount({itemCount: res.data.userCount}));

    // dispatch({
    //   type: USERS_FETCHED,
    //   payload: res.data.userList
    // });
    if ( cb ) {      
      cb(res.data);      
    }
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "error", 2500)) );
    }
    if ( cb ) {
      cb(null);
    }
  }
}  